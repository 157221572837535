import React, { FC, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowBackIcon, InfoIcon } from '../../../assets/icons';
import { Column, Heading, Text, View, Row, LinkButton } from '../../../components/atoms';
import EditButton from '../../../components/molecules/EditButton';
import { Select, TextInput } from '../../../components/molecules/form';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { DangerModal } from '../../../components/organisms/DangerModal';
import { useUser } from '../../../providers';
import {
    Location,
    useMembersQuery,
    useMemberLocationsQuery,
    Role,
    State,
    useUserBlockMutation,
    useUserRestrictMutation,
    useUserUnblockMutation,
    useUserClearRestrictionMutation,
    useUserDeleteMutation,
} from '../../../service';
import { Theme } from '../../../theme';
import { getInitialsFromName } from '../../../util/helperFunctions';
import { rolesToString } from '../../../util/roleNameMapping';
import { EditUsernameForm } from '../../member-management/components/EditUsernameForm';
import {
    LocationSelector,
    LocationSelectorType,
} from '../../member-management/components/LocationSelector';
import { RoleSelector } from '../../member-management/components/RoleSelector';

type ReasonObject = {
    [key: string]: string;
};

const BLOCK_REASON: ReasonObject = {
    RequestedByMember: 'Requested by member',
    Other: 'Other',
};
const defaultBlockReason = Object.keys(BLOCK_REASON)[0];

const RESTRICT_REASON: ReasonObject = {
    PaymentFailed: 'Payment failed',
    CommunityGuidelinesViolation: 'Community Guidelines Violation',
    Other: 'Other',
};
const defaultRestrictReason = Object.keys(RESTRICT_REASON)[0];

export const UserDetails: FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { currentRole } = useUser();
    const { data: userData } = useMembersQuery({
        variables: {
            filter: {
                userId: userId!,
            },
        },
    });
    const { data: locationsData, loading } = useMemberLocationsQuery();
    const [blockUser, { loading: userBlockLoading }] = useUserBlockMutation();
    const [unblockUser, { loading: userUnblockLoading }] = useUserUnblockMutation();
    const [restrictUser, { loading: userRestrictLoading }] = useUserRestrictMutation();
    const [clearUserRestriction, { loading: userClearRestrictLoading }] =
        useUserClearRestrictionMutation();
    const [deleteUser, { loading: userDeleteLoading }] = useUserDeleteMutation();

    const user = userData?.users && userData.users.length > 0 ? userData.users[0] : null;
    const allLocations = locationsData?.locations as Location[];
    const accessLocations = user?.accessLocations as Location[];
    const serviceLocations = user?.serviceLocations as Location[];

    const blockUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const restrictUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const deleteUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const editUserBottomSheetRef = useRef<BottomSheetRef>(null);

    const [selectedBlockReason, setSelectedBlockReason] = useState(defaultBlockReason);
    const [customBlockReason, setCustomBlockReason] = useState('');

    const [selectedRestrictReason, setSelectedRestrictReason] = useState(defaultRestrictReason);
    const [customRestrictReason, setCustomRestrictReason] = useState('');

    const restrictActionVisible =
        (currentRole === Role.Admin || currentRole === Role.Com) &&
        (user?.state === State.Active || user?.state === State.Restricted);
    const blockActionVisible =
        (currentRole === Role.Admin || currentRole === Role.Com) &&
        (user?.state === State.Active || user?.state === State.Blocked);

    const userRoles = useMemo(() => {
        const roles = user?.roles;
        if (roles) {
            return rolesToString(roles.filter(Boolean) as Role[]); // filter - removes null values
        }
        return '';
    }, [user?.roles]);

    const onUserBlock = async () => {
        const reason =
            selectedBlockReason === BLOCK_REASON.Other
                ? customBlockReason
                : BLOCK_REASON[selectedBlockReason];

        if (userId) {
            await blockUser({
                variables: {
                    input: {
                        id: userId,
                        reason,
                    },
                },
                refetchQueries: ['Members'],
            });
            setSelectedBlockReason('');
            setCustomBlockReason('');
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserUnblock = async () => {
        if (userId) {
            await unblockUser({
                variables: {
                    id: userId,
                },
                refetchQueries: ['Members'],
            });
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserRestrict = async () => {
        const reason =
            selectedRestrictReason === RESTRICT_REASON.Other
                ? customRestrictReason
                : RESTRICT_REASON[selectedRestrictReason];

        if (userId) {
            await restrictUser({
                variables: {
                    input: {
                        id: userId,
                        reason,
                    },
                },
                refetchQueries: ['Members'],
            });
            setSelectedRestrictReason('');
            setCustomRestrictReason('');
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    const onClearRestriction = async () => {
        if (userId) {
            await clearUserRestriction({
                variables: {
                    id: userId,
                },
                refetchQueries: ['Members'],
            });
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    const onUserDelete = async () => {
        if (userId) {
            await deleteUser({
                variables: {
                    input: {
                        id: userId,
                    },
                },
                refetchQueries: ['Members'],
            });
            deleteUserBottomSheetRef.current?.hide();
            navigate(-1);
        }
    };

    return (
        <Wrapper>
            <Header px={90} pb={25}>
                <LinkButton onClick={() => navigate('/user-management/users')}>
                    <>
                        <ArrowBack width={20} height={20} />
                        Back to user list
                    </>
                </LinkButton>
            </Header>
            <ContentWrapper
                borderRadius={['20px 20px 0px 0px', '64px 64px 0px 0px', '64px 64px 0px 0px']}
            >
                <Content px={[25, 25, 115]} py={[25, 25, 45]}>
                    <Row alignItems={'flex-start'} justifyContent={'space-between'}>
                        <ProfilePhoto mb={25}>
                            <Text fontSize={35}>{getInitialsFromName(user?.name || '')}</Text>
                        </ProfilePhoto>
                    </Row>
                    <Row alignItems={'flex-start'} justifyContent={'flex-start'}>
                        <Heading mb={10} mr={15}>
                            {user?.name}
                        </Heading>
                        <EditButton onClick={() => editUserBottomSheetRef?.current?.show()} />
                    </Row>

                    {loading ? (
                        <Row mt={50} justifyContent={'center'} alignItems={'center'}>
                            <LoadingIndicator variant={'medium'} />
                        </Row>
                    ) : (
                        <>
                            <View my={[20, 40, 40]}>
                                <Text fontSize={'medium'} fontWeight={'bold'}>
                                    Employee details
                                </Text>
                                <Divider />
                                <Row
                                    justifyContent={'space-between'}
                                    alignItems={'flex-start'}
                                    pt={10}
                                >
                                    <Column my={20} flex={1}>
                                        <Column>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Status
                                            </Text>
                                            <Text fontSize={'medium'}>{user?.state}</Text>
                                        </Column>
                                        <Column mt={30}>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Email
                                            </Text>
                                            <Text fontSize={'medium'}>{user?.email}</Text>
                                        </Column>
                                        <Column mt={30}>
                                            {currentRole === Role.Admin ? (
                                                user?.id && (
                                                    <RoleSelector
                                                        title="Roles"
                                                        roles={user?.roles as Role[]}
                                                        userId={user.id}
                                                    />
                                                )
                                            ) : (
                                                <>
                                                    <Text
                                                        fontSize={'medium'}
                                                        color={'text.secondary'}
                                                    >
                                                        Roles
                                                    </Text>
                                                    <Text fontSize={'medium'}>{userRoles}</Text>
                                                </>
                                            )}
                                        </Column>
                                        <Column mt={30}>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Company
                                            </Text>
                                            <CompanyLink
                                                fontSize={'medium'}
                                                onClick={() => {
                                                    navigate(
                                                        '/member-management/companies/' +
                                                            user?.company?.id,
                                                    );
                                                }}
                                            >
                                                {user?.company?.name}
                                            </CompanyLink>
                                        </Column>
                                        <View mt={70}>
                                            {restrictActionVisible && (
                                                <Row>
                                                    <LinkButton
                                                        onClick={() =>
                                                            restrictUserBottomSheetRef.current?.show()
                                                        }
                                                    >
                                                        <ProfileActionLabel
                                                            fontSize={'medium'}
                                                            color={'text.danger'}
                                                        >
                                                            {user.state === State.Restricted
                                                                ? 'Clear User Access Restriction'
                                                                : 'Restrict User Access'}
                                                        </ProfileActionLabel>
                                                    </LinkButton>
                                                </Row>
                                            )}
                                            {blockActionVisible && (
                                                <Row mt={20}>
                                                    <LinkButton
                                                        onClick={() =>
                                                            blockUserBottomSheetRef.current?.show()
                                                        }
                                                    >
                                                        <ProfileActionLabel
                                                            fontSize={'medium'}
                                                            color={'text.danger'}
                                                        >
                                                            {user.state === State.Blocked
                                                                ? 'Unblock User'
                                                                : 'Block User'}
                                                        </ProfileActionLabel>
                                                    </LinkButton>
                                                </Row>
                                            )}
                                            {currentRole === Role.Admin && (
                                                <Row mt={20}>
                                                    <LinkButton
                                                        onClick={() =>
                                                            deleteUserBottomSheetRef.current?.show()
                                                        }
                                                    >
                                                        <ProfileActionLabel
                                                            fontSize={'medium'}
                                                            color={'text.danger'}
                                                        >
                                                            Delete user
                                                        </ProfileActionLabel>
                                                    </LinkButton>
                                                </Row>
                                            )}
                                        </View>
                                    </Column>
                                    <Column flex={1} alignItems={'flex-end'}>
                                        <LocationSelector
                                            type={LocationSelectorType.Access}
                                            memberId={user?.id}
                                            title={'Access to locations:'}
                                            allLocations={allLocations}
                                            selectedLocations={accessLocations}
                                        />
                                        <LocationSelector
                                            type={LocationSelectorType.Service}
                                            memberId={user?.id}
                                            title={'Can manage services at:'}
                                            allLocations={allLocations}
                                            selectedLocations={serviceLocations}
                                        />
                                    </Column>
                                </Row>
                            </View>
                        </>
                    )}
                </Content>
            </ContentWrapper>
            <BottomSheet ref={editUserBottomSheetRef}>
                <EditUsernameForm
                    onDone={() => editUserBottomSheetRef?.current?.hide()}
                    user={user}
                />
            </BottomSheet>
            <DangerModal
                ref={deleteUserBottomSheetRef}
                heading="Delete User"
                subheading="Are you sure you want to delete user from Saltbox OS?"
                loading={userDeleteLoading}
                onConfirm={onUserDelete}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Deleting user will remove it completely from the system including
                            Saltbox account and Slack contact.
                        </Text>
                    </Row>
                </NoteWrapper>
            </DangerModal>

            <DangerModal
                ref={blockUserBottomSheetRef}
                heading={user?.state === State.Blocked ? 'Unblock User' : 'Block User'}
                subheading={
                    user?.state === State.Blocked
                        ? 'Are you sure you want to unblock this user?'
                        : 'Are you sure you want to block this user?'
                }
                loading={userBlockLoading || userUnblockLoading}
                primaryButtonDisabled={
                    selectedBlockReason === BLOCK_REASON.Other && customBlockReason === ''
                }
                onConfirm={user?.state === State.Blocked ? onUserUnblock : onUserBlock}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users that are blocked will not be able to login to Saltbox OS using
                            their account until Admin or Manager unblocks them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {user?.state !== State.Blocked && (
                    <Select
                        label="Reason"
                        value={selectedBlockReason}
                        values={Object.keys(BLOCK_REASON)}
                        labels={Object.values(BLOCK_REASON)}
                        onChange={(option) => setSelectedBlockReason(option)}
                    />
                )}
                {selectedBlockReason === BLOCK_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomBlockReason(e.target.value)}
                        />
                    </View>
                )}
            </DangerModal>

            <DangerModal
                ref={restrictUserBottomSheetRef}
                heading={
                    user?.state === State.Restricted
                        ? 'Clear User Access Restriction'
                        : 'Restrict User Access'
                }
                subheading={
                    user?.state === State.Restricted
                        ? 'Are you sure you want to clear restriction access for this user?'
                        : 'Are you sure you want to restrict access for this user?'
                }
                loading={userRestrictLoading || userClearRestrictLoading}
                onConfirm={user?.state === State.Restricted ? onClearRestriction : onUserRestrict}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users having restricted access will be able to login to Saltbox OS using
                            their account but all features will be disabled for them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {user?.state !== State.Restricted && (
                    <Select
                        label="Reason"
                        value={selectedRestrictReason}
                        values={Object.keys(RESTRICT_REASON)}
                        labels={Object.values(RESTRICT_REASON)}
                        onChange={(option) => setSelectedRestrictReason(option)}
                    />
                )}
                {selectedRestrictReason === RESTRICT_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomRestrictReason(e.target.value)}
                        />
                    </View>
                )}
                {user?.state !== State.Restricted && (
                    <NoteWrapper>
                        <Row>
                            <InfoIcon />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                Users with restricted access will be able to see this reason.
                            </Text>
                        </Row>
                    </NoteWrapper>
                )}
            </DangerModal>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Theme.colors.background.accent};
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

const Header = styled(View)`
    width: 100%;
    min-height: 130px;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled(View)`
    background: ${Theme.colors.background.light};
    flex: 1;
`;

const Content = styled(View)`
    max-width: 1200px;
`;

const ProfilePhoto = styled(View)`
    background: ${Theme.colors.background.disabled};
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Divider = styled(View)`
    background-color: #505354;
    height: 1px;
    width: 100%;
    margin: 2px 0;
    opacity: 0.1;
`;

const ArrowBack = styled(ArrowBackIcon)`
    margin-right: 5px;
`;

const ProfileActionLabel = styled(Text)`
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: normal;
    user-select: none;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;

const CompanyLink = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
`;
