import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PeopleIcon, RightArrowIcon } from '../../../../assets/icons';
import { Column, Row, Text } from '../../../../components/atoms';
import { BuildingAccess } from '../../components/BuildingAccess/BuildingAccess';

export const Dashboard = () => {
    const navigate = useNavigate();
    return (
        <Column>
            <BuildingAccess />
            <QuickAccessCard
                mt={[5, 10, 10]}
                onClick={() => navigate('/member-management/companies')}
            >
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center">
                        <PeopleIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Manage Members
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
        </Column>
    );
};

const QuickAccessCard = styled(Row)`
    min-height: 50px;
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    align-items: center;
    cursor: pointer;
`;
