import React, { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Heading, Row, Text, View } from '../../components/atoms';
import { useUser } from '../../providers';
import { Role, State } from '../../service';
import { Theme } from '../../theme';
import { MobileMenu, SideMenu } from './components';
import { AdminMenu } from './view/AdminMenu';
import { AssociateMenu } from './view/AssociateMenu';
import { CentralMenu } from './view/CentralMenu';
import { ComMenu } from './view/ComMenu';
import { MemberMenu } from './view/MemberMenu';

export const Home = () => {
    const { currentRole, user, note } = useUser();

    const { logout } = useAuth0();
    const apolloClient = useApolloClient();

    const onLogout = async () => {
        await apolloClient.clearStore();
        await logout({ returnTo: window.location.origin.toString() });
        localStorage.clear();
    };

    const menuItems = useMemo(() => {
        switch (currentRole) {
            case Role.Member:
                return MemberMenu;
            case Role.Com:
                return ComMenu;
            case Role.Admin:
                return AdminMenu;
            case Role.Associate:
                return AssociateMenu;
            case Role.Central:
                return CentralMenu;
            default:
                return [];
        }
    }, [currentRole]);

    if (!user) {
        return null;
    }

    if (user?.state === State.Restricted) {
        return (
            <RestrictedWrapper>
                <Heading mb={10}>Restricted Access</Heading>
                <Text mb={10} textAlign={'center'}>
                    Your access to Saltbox OS has been restricted.
                    <br />
                    Reason: {note}
                    <br />
                    For more info you can contact Saltbox OS using following link:
                </Text>
                <a href="https://help.saltbox.com" target="_blank" rel="noopener noreferrer">
                    <Text fontSize={Theme.fontSizes.default}>https://help.saltbox.com</Text>
                </a>
                <Row mt={20}>
                    <Button onClick={() => onLogout()}>Go Back</Button>
                </Row>
            </RestrictedWrapper>
        );
    }

    return (
        <Wrapper flexDirection={['column', 'column', 'row']}>
            <SideMenu items={menuItems} />
            <MobileMenu items={menuItems} />
            <ContentWrapper>
                <Content px={[25, 25, 115]} py={[25, 25, 45]}>
                    <Outlet />
                </Content>
            </ContentWrapper>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    display: flex;
    background-color: ${Theme.colors.background.primary};
    height: ${window.innerHeight}px;
    overflow: hidden;
`;

const ContentWrapper = styled(View)`
    flex: 1;
    position: relative;
    overflow: auto;
`;

const Content = styled(View)`
    flex: 1;
    max-width: 1200px;
    max-height: 100vh;
`;

const RestrictedWrapper = styled(View)`
    flex: 1;
    background-color: ${Theme.colors.background.accent};
    height: ${window.innerHeight}px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
