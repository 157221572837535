import React, { FC, useEffect, useMemo } from 'react';

import { Controller } from 'react-hook-form';
import SelectInput from 'react-select';
import styled from 'styled-components';

import { View } from '../../atoms';
import { BaseInputProps } from './BaseInputProps';
import { ControlledInputProps } from './ControlledInputProps';
import { Label } from './Label';

type Props<T = any> = BaseInputProps<T> & {
    values: T[];
    labels: string[];
} & React.ComponentPropsWithoutRef<'div'>;
const SelectComponent: FC<Props> = ({ label, values, labels, onChange, value, ...rest }) => {
    const options = useMemo(() => {
        return values.map((val, i) => ({
            value: val,
            label: labels[i],
        }));
    }, [values, labels]);

    return (
        <View {...rest}>
            {label && <Label>{label}</Label>}
            <SelectInputField
                value={options.find((option) => option.value === value)}
                options={options}
                onChange={(e) => {
                    onChange && onChange((e as any).value);
                }}
            />
        </View>
    );
};

export const Select: FC<Props & ControlledInputProps> = ({ control, name, ...rest }) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            render={({ field: { onChange, value } }) => (
                <SelectComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <SelectComponent {...rest} />
    );
};

const SelectInputField = styled(SelectInput)`
    font-family: 'Inter', serif;
    font-size: 15px;
    color: black;
`;
