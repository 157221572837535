import React, { useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import { AlertIcon, CheckIcon, CloseIcon, InfoIcon } from '../../../assets/icons';
import { Button, Column, Heading, LinkButton, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { useUser } from '../../../providers';
import { Maybe, Role, useUserDeleteMutation } from '../../../service';
import { roleName } from '../../../util/roleNameMapping';
import { ProfileWidget } from '../../home/components/ProfileWidget';

export const Profile = () => {
    const { user, setCurrentRole, currentRole } = useUser();
    const [deleteUser, { loading: userDeleteLoading }] = useUserDeleteMutation();
    const { logout } = useAuth0();
    const apolloClient = useApolloClient();

    const roleBottomSheetRef = useRef<BottomSheetRef>(null);
    const deleteAccountBottomSheetRef = useRef<BottomSheetRef>(null);

    const deleteAccount = async () => {
        if (user?.id) {
            await deleteUser({
                variables: {
                    input: {
                        id: user.id,
                    },
                },
            });
            await apolloClient.clearStore();
            localStorage.clear();
            deleteAccountBottomSheetRef.current?.hide();
            logout({ returnTo: window.location.origin.toString() });
        }
    };

    return (
        <>
            <Heading>Profile</Heading>
            <ProfileWidgetWrapper mt={20}>
                <ProfileWidget
                    showRole
                    showEmail
                    showCompany={false}
                    onClick={() => {
                        roleBottomSheetRef.current?.show();
                    }}
                />
            </ProfileWidgetWrapper>

            <Column alignItems={'flex-start'}>
                <Heading mt={50} mb={20}>
                    Danger Zone
                </Heading>
                <DeleteAccountButton
                    onClick={() => deleteAccountBottomSheetRef.current?.show()}
                    style={{ width: 'fit-content' }}
                >
                    <TerminateLabel
                        fontSize={'medium'}
                        color={'text.danger'}
                        style={{ width: 'fit-content' }}
                    >
                        Delete my account
                    </TerminateLabel>
                </DeleteAccountButton>
            </Column>

            <BottomSheet ref={roleBottomSheetRef}>
                <View px={30} py={15}>
                    <Heading>Account access</Heading>
                    <Text mt={10}>To change your environment, choose one of the roles below:</Text>
                    <View mt={30}>
                        {user?.roles?.map((role?: Maybe<Role>) => (
                            <RoleWrapper
                                key={role}
                                active={currentRole === role}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                clickable
                                onClick={() => {
                                    setCurrentRole(role!);
                                    roleBottomSheetRef.current?.hide();
                                }}
                            >
                                <View>
                                    <Text fontSize={'medium'} fontWeight={'bold'}>
                                        {roleName[role!]}
                                    </Text>
                                    <Text fontSize={'medium'} color={'text.secondary'}>
                                        {currentRole === role
                                            ? 'Currently active'
                                            : 'Press to change environment'}
                                    </Text>
                                </View>
                                {currentRole === role && <CheckIcon />}
                            </RoleWrapper>
                        ))}
                    </View>
                </View>
            </BottomSheet>
            <BottomSheet ref={deleteAccountBottomSheetRef}>
                <View py={30} px={30}>
                    <Row mb={10} justifyContent={'flex-end'}>
                        <LinkButton onClick={() => deleteAccountBottomSheetRef.current?.hide()}>
                            <CloseIcon width={20} height={20} />
                        </LinkButton>
                    </Row>
                    <Heading>Delete Account</Heading>
                    <Text my={10}>Are you sure you want to delete your Saltbox OS account?</Text>
                    <NoteWrapper>
                        <Row alignItems={'center'}>
                            <InfoIcon width={25} height={25} />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                This action will remove your account permanently from the system.
                            </Text>
                        </Row>
                        <Row mt={15} alignItems={'center'}>
                            <AlertIcon width={25} height={25} />
                            <Text ml={10} fontSize={'medium'} color={'text.danger'}>
                                This cannot be undone.
                            </Text>
                        </Row>
                    </NoteWrapper>
                    <ButtonsWrapper mb={10}>
                        <Button
                            mt={30}
                            variant={'outlined'}
                            onClick={() => deleteAccountBottomSheetRef.current?.hide()}
                        >
                            NO
                        </Button>
                        <Button
                            mt={30}
                            variant={'danger'}
                            loading={userDeleteLoading}
                            onClick={deleteAccount}
                        >
                            YES
                        </Button>
                    </ButtonsWrapper>
                </View>
            </BottomSheet>
        </>
    );
};

export const ProfileWidgetWrapper = styled(View)`
    box-shadow: 0 0 6px #00000012;
    border-radius: 5px;
    background-color: white;
    padding: 0 10px;
`;

export const RoleWrapper = styled(Row)<{ active?: boolean }>`
    padding: 15px 20px;
    border-radius: 5px;
    background-color: ${(props) => (props.active ? '#F9F8F6' : 'white')};
`;

const TerminateLabel = styled(Text)`
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: normal;
    user-select: none;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;

const ButtonsWrapper = styled(Row)`
    gap: 20px;
`;

const DeleteAccountButton = styled(LinkButton)`
    width: fit-content;
`;
