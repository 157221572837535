import React, { FC, ReactElement } from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { RightArrowIcon } from '../../../assets/icons';
import { Row, Text } from '../../../components/atoms';

type Props = {
    title: string;
    icon?: ReactElement;
} & NavLinkProps;
export const MenuItem: FC<Props> = ({ title, icon, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Row alignItems={'center'}>
                {icon && (
                    <Row mr={12} alignItems={'center'} justifyContent={'center'}>
                        {icon}
                    </Row>
                )}
                <Text>{title}</Text>
            </Row>
            <RightArrowIcon />
        </Wrapper>
    );
};

const Wrapper = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: black;
    border-radius: 7px;
    ${Text} {
        font-weight: bold;
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 6px;
    }

    svg {
        width: 25px;
        height: 25px;
    }

    &.active {
        background-color: #f9f8f6;
    }

    &:hover {
        background-color: #f9f8f6;
    }
`;
