import React, { FC, useRef, useState } from 'react';

import styled from 'styled-components';

import { InfoIcon, LocationPinIcon, RoundPlusIcon } from '../../../assets/icons';
import { Heading, Row, View, Text, Column, LinkButton } from '../../../components/atoms';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { DangerModal } from '../../../components/organisms/DangerModal';
import {
    Company,
    CompanyLocation,
    Location,
    PreferredMemberSpecialistResponse,
    SubscriptionEForceResponse,
    useSubscriptionEForceCancelMutation,
    useSubscriptionsEForceQuery,
    useUserDeleteMutation,
} from '../../../service';
import { LocationSelectorType } from './LocationSelector';
import { SubscriptionCreateForm } from './SubscriptionCreateForm';

type Props = {
    company: Company | undefined;
};

function displayMembersNames(members: Array<PreferredMemberSpecialistResponse>) {
    let names = '';
    members.forEach((memberEntry) => (names += `, ${memberEntry.memberSpecialist.name}`));
    return names.substring(2);
}

export const CompanySubscriptionList: FC<Props> = ({ company }) => {
    const { data: companySubscriptions, loading: subscriptionsLoading } =
        useSubscriptionsEForceQuery({
            variables: {
                input: {
                    companyId: company?.id,
                },
            },
        });
    const createSubscriptionBottomSheetRef = useRef<BottomSheetRef>(null);
    const deleteSubscriptionBottomSheetRef = useRef<BottomSheetRef>(null);

    // delete subscription
    const [selectedSubscription, setSelectedSubscription] = useState<any>('');
    const [deleteSubscription, { loading: subscriptionDeleteLoading }] =
        useSubscriptionEForceCancelMutation();

    const onSubscriptionDelete = async () => {
        if (selectedSubscription) {
            await deleteSubscription({
                variables: {
                    input: {
                        id: selectedSubscription.id,
                    },
                },
                refetchQueries: ['SubscriptionsEForceQuery'],
            });
            deleteSubscriptionBottomSheetRef.current?.hide();
        }
    };

    return (
        <View>
            <Row mt={30} alignItems={'center'} justifyContent={'space-between'}>
                <Heading>{company?.name} Subscriptions</Heading>
                <Row alignItems={'center'} justifyContent={'center'}>
                    <LinkButton
                        icon={<RoundPlusIcon />}
                        onClick={() => createSubscriptionBottomSheetRef?.current?.show()}
                    >
                        Add subscription
                    </LinkButton>
                </Row>
            </Row>
            {subscriptionsLoading ? (
                <Row mt={50} justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : (
                <Row mt={30}>
                    <SubscriptionListWrapper>
                        {!companySubscriptions?.subscriptionsEForce?.length ? (
                            <Text>No subscriptions</Text>
                        ) : (
                            <></>
                        )}
                        {companySubscriptions ? (
                            companySubscriptions?.subscriptionsEForce
                                ?.filter(Boolean)
                                .filter(
                                    (subscription) =>
                                        subscription?.preferredMemberSpecialists[0]?.status ==
                                        'Active',
                                )
                                .map((subscription) => {
                                    return (
                                        <Subscription
                                            key={subscription?.id}
                                            justifyContent={'space-between'}
                                        >
                                            <Column>
                                                <View>
                                                    <Text
                                                        fontSize="medium"
                                                        fontWeight="bold"
                                                        mt={5}
                                                    >
                                                        Preferred Member Specialists
                                                    </Text>
                                                    <Text fontSize="medium">
                                                        {subscription?.preferredMemberSpecialists &&
                                                            displayMembersNames(
                                                                subscription.preferredMemberSpecialists as Array<PreferredMemberSpecialistResponse>,
                                                            )}
                                                    </Text>
                                                </View>
                                                <View>
                                                    <Text
                                                        fontSize="medium"
                                                        fontWeight="bold"
                                                        mt={10}
                                                    >
                                                        Time slot
                                                    </Text>
                                                </View>
                                                <Text fontSize="medium">
                                                    {`${subscription?.slot.from.slice(
                                                        0,
                                                        -3,
                                                    )} - ${subscription?.slot.to.slice(0, -3)}`}
                                                </Text>
                                            </Column>
                                            <Column>
                                                <Row mt={5} alignItems={'center'}>
                                                    <LocationPinIcon />
                                                    <Text fontSize={'medium'} ml={2} size="medium">
                                                        {subscription?.location.name}
                                                    </Text>
                                                </Row>
                                            </Column>
                                            <Column>
                                                <DeleteButton
                                                    mt={5}
                                                    fontSize={'medium'}
                                                    color={'text.danger'}
                                                    onClick={() => {
                                                        setSelectedSubscription(subscription);
                                                        deleteSubscriptionBottomSheetRef.current?.show();
                                                    }}
                                                >
                                                    Delete
                                                </DeleteButton>
                                            </Column>
                                        </Subscription>
                                    );
                                })
                        ) : (
                            <></>
                        )}
                    </SubscriptionListWrapper>
                </Row>
            )}
            <BottomSheet ref={createSubscriptionBottomSheetRef}>
                <SubscriptionCreateForm
                    companyId={company?.id || ''}
                    locations={company?.locations ? (company.locations as CompanyLocation[]) : []}
                    onDone={() => createSubscriptionBottomSheetRef.current?.hide()}
                />
            </BottomSheet>
            <DangerModal
                ref={deleteSubscriptionBottomSheetRef}
                heading="Delete Subscription"
                subheading="Are you sure you want to delete subscription?"
                loading={subscriptionDeleteLoading}
                onConfirm={onSubscriptionDelete}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Deleting subscription will remove it completely from the system.
                        </Text>
                    </Row>
                </NoteWrapper>
            </DangerModal>
        </View>
    );
};

const SubscriptionListWrapper = styled(View)`
    min-height: 100px;
    width: 100%;
`;

const Subscription = styled(Row)`
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    padding: 15px 20px;
    background: white;
`;

const DeleteButton = styled(Text)`
    cursor: pointer;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;
