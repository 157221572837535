import React, { FC, useMemo, useRef, useState } from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { InfoIcon, RoundPlusIcon } from '../../../assets/icons';
import { Heading, LinkButton, Row, Text, View } from '../../../components/atoms';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { Table } from '../../../components/molecules/Table';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { DangerModal } from '../../../components/organisms/DangerModal';
import {
    Company,
    Location,
    Role,
    State,
    useCompanyClearRestrictionMutation,
    useCompanyRestrictMutation,
    useMemberCompanyQuery,
    useMembersQuery,
    User,
} from '../../../service';
import { CompanyLocationSelector } from '../components/CompanyLocationSelector';
import { CompanySubscriptionList } from '../components/CompanySubscriptionList';
import { MemberForm } from '../components/MemberForm';
import { MemberInfoCard } from '../components/MemberInfoCard';

export const MemberManagementMembers: FC = () => {
    const [tab, setTab] = useState('members');
    const { id: companyId } = useParams();

    const { data: companyData } = useMemberCompanyQuery({
        variables: { companyId: companyId! },
    });
    const { data: usersData, loading: membersDataLoading } = useMembersQuery({
        variables: {
            filter: {
                roles: [Role.Member],
            },
        },
    });

    const [restrictCompany, { loading: companyRestrictLoading }] = useCompanyRestrictMutation();
    const [clearCompanyRestriction, { loading: companyClearRestrictLoading }] =
        useCompanyClearRestrictionMutation();

    const navigate = useNavigate();
    const restrictBottomSheetRef = useRef<BottomSheetRef>(null);

    const companyMembers = usersData?.users?.filter((user) => user?.company?.id === companyId);
    const company: Company | undefined = companyData?.company
        ? (companyData?.company as Company)
        : undefined;

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'eMail',
                accessor: 'email',
            },
            {
                Header: 'Company',
                accessor: 'company.name',
            },
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Locations',
                accessor: (row: User) => {
                    return (
                        row.serviceLocations?.reduce((out, current) => {
                            return out + (out !== '' ? ', ' : '') + current?.name;
                        }, '') || '/'
                    );
                },
            },
        ];
    }, []);

    const onCompanyRestrict = async () => {
        const companyId = companyData?.company?.id;

        if (companyId) {
            await restrictCompany({
                variables: {
                    input: {
                        id: companyId,
                    },
                },
                refetchQueries: ['MemberCompany', 'Members'],
            });
            restrictBottomSheetRef.current?.hide();
        }
    };

    const onCompanyClearRestriction = async () => {
        const companyId = companyData?.company?.id;

        if (companyId) {
            await clearCompanyRestriction({
                variables: {
                    companyId,
                },
                refetchQueries: ['MemberCompany', 'Members'],
            });
            restrictBottomSheetRef.current?.hide();
        }
    };
    const createMemberBottomSheetRef = useRef<BottomSheetRef>(null);

    return (
        <View>
            <Heading>{companyData?.company?.name}</Heading>
            <CompanyLocationSelector
                companyId={companyData?.company?.id}
                title={'Locations:'}
                selectedLocations={companyData?.company?.locations as Location[]}
            />
            <Tabs mt={20}>
                <Tab
                    active={tab === 'members'}
                    onClick={() => {
                        setTab('members');
                    }}
                >
                    Members
                </Tab>
                <Tab
                    active={tab === 'subscriptions'}
                    onClick={() => {
                        setTab('subscriptions');
                    }}
                >
                    Subscriptions
                </Tab>
            </Tabs>
            {tab == 'subscriptions' ? <CompanySubscriptionList company={company} /> : <></>}
            {tab == 'members' ? (
                <>
                    <View>
                        <Row mt={30} alignItems={'center'} justifyContent={'space-between'}>
                            <Heading>{companyData?.company?.name} Members</Heading>
                            <Row alignItems={'center'} justifyContent={'center'}>
                                <LinkButton
                                    icon={<RoundPlusIcon />}
                                    onClick={() => createMemberBottomSheetRef?.current?.show()}
                                >
                                    Add member
                                </LinkButton>
                            </Row>
                        </Row>
                        <LinkButton onClick={() => restrictBottomSheetRef.current?.show()}>
                            <ActionLabel mt={15} mb={-5} fontSize={'medium'} color={'text.danger'}>
                                {companyData?.company?.state === State.Restricted
                                    ? 'Clear Access Restriction'
                                    : 'Restrict Company Access'}
                            </ActionLabel>
                        </LinkButton>
                    </View>

                    {membersDataLoading ? (
                        <Row mt={50} justifyContent={'center'} alignItems={'center'}>
                            <LoadingIndicator variant={'medium'} />
                        </Row>
                    ) : (
                        <>
                            <ScrollView flex={1} mt={30} display={['none', 'block']}>
                                <Table
                                    data={companyMembers || []}
                                    columns={columns}
                                    onRowClicked={(user) => {
                                        navigate(
                                            '/member-management/companies/' +
                                                user?.company?.id +
                                                '/members/' +
                                                user?.id,
                                        );
                                    }}
                                />
                            </ScrollView>

                            <ScrollView flex={1} mt={30} display={['block', 'none']}>
                                {companyMembers?.map((user) => (
                                    <StyledLink
                                        key={user?.id}
                                        to={
                                            '/member-management/companies/' +
                                            user?.company?.id +
                                            '/members/' +
                                            user?.id
                                        }
                                    >
                                        <View key={user?.id} mb={10}>
                                            <MemberInfoCard member={user!} />
                                        </View>
                                    </StyledLink>
                                ))}
                            </ScrollView>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
            <DangerModal
                ref={restrictBottomSheetRef}
                heading={
                    companyData?.company?.state === State.Restricted
                        ? 'Clear Access Restriction'
                        : 'Restrict Company Access'
                }
                subheading={
                    companyData?.company?.state === State.Restricted
                        ? 'Are you sure you want to clear restriction access for this company?'
                        : 'Are you sure you want to restrict access for this company?'
                }
                loading={companyRestrictLoading || companyClearRestrictLoading}
                onConfirm={
                    companyData?.company?.state === State.Restricted
                        ? onCompanyClearRestriction
                        : onCompanyRestrict
                }
            >
                <NoteWrap>
                    <Row>
                        <InfoIcon width={45} />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Companies having restricted access will restrict their users from using
                            Saltbox OS features. Users will only be able to login to Saltbox OS
                            using their account but all features will be disabled.
                        </Text>
                    </Row>
                </NoteWrap>
            </DangerModal>
            <BottomSheet ref={createMemberBottomSheetRef}>
                <MemberForm onDone={() => createMemberBottomSheetRef.current?.hide()} />
            </BottomSheet>
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 220}px;
`;

const StyledLink = styled(NavLink)`
    color: black;
    text-decoration: none;
`;

const ActionLabel = styled(Text)`
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: normal;
    user-select: none;
`;

const NoteWrap = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;

const Tabs = styled(Row)`
    background-color: white;
    border-radius: 5px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #00000012;
    overflow: hidden;
`;
const Tab = styled(View)<{ active: boolean }>`
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 12px;
    text-decoration: none;
    padding: 10px 15px;
    flex: 1;
    text-align: center;
    background-color: ${(props) => (props.active ? 'black' : 'white')};
    color: ${(props) => (props.active ? 'white' : 'black')};
`;
