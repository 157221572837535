import React from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { Page } from '../../../components/molecules/Page';
import { AssociateForm } from '../components/AssociateForm';
import { AssociateDetails } from './AssociateDetails';
import { AssociatesListing } from './AssociatesListing';

export const TeamManagementOverview = () => {
    return (
        <Routes>
            <Route element={<TeamManagementWrapper />}>
                <Route path={'associates'} element={<AssociatesListing />} />
                <Route path={'associates/:id'} element={<AssociateDetails />} />
            </Route>
        </Routes>
    );
};

const TeamManagementWrapper = () => {
    return (
        <Page addForm={AssociateForm}>
            <Outlet />
        </Page>
    );
};
