import React, { FC } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { LockedIcon } from '../../../assets/icons';
import { Column, Row, Text, View } from '../../../components/atoms';
import { Location } from '../../../service';

type Props = {
    location: DeepPartial<Location>;
};
export const LocationInfoCard: FC<Props> = ({ location }) => {
    return (
        <>
            <Row>
                <Card>
                    <Row pb={10} alignItems={'flex-start'}>
                        <Column justifyContent={'center'} ml={10} width={'100%'}>
                            <Text fontWeight={'bold'} fontSize={'medium'}>
                                {location?.name}
                            </Text>
                        </Column>
                    </Row>
                    <Row pb={20} alignItems={'flex-start'} justifyContent={'space-between'}>
                        <Column justifyContent={'center'} ml={10} width={'100%'}>
                            <Row>
                                <Text fontSize={'medium'}>{location?.zone}</Text>
                            </Row>
                        </Column>
                        <Column justifyContent={'center'} ml={10} width={'100%'}>
                            <Row justifyContent={'flex-end'} alignItems={'center'}>
                                <LockedIcon width={15} height={15} />
                                <DoorCount>{location?.doors?.length || 0}</DoorCount>
                            </Row>
                        </Column>
                    </Row>
                </Card>
            </Row>
        </>
    );
};

const DoorCount = styled(Text)`
    display: inline;
    margin-left: 5px;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 0;
`;

const Card = styled(View)`
    padding: 20px 20px 0 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    flex: 1;
    position: relative;
`;
