import { DashboardIcon, FlexStorageIcon, LocationPinIcon, PeopleIcon } from '../../../assets/icons';
import { MenuSection } from '../models/MenuSection';

export const AdminMenu: MenuSection[] = [
    {
        name: 'General',
        showNameInMenu: false,
        items: [
            {
                name: 'Dashboard',
                icon: DashboardIcon,
                route: '/',
            },
            {
                name: 'Companies',
                icon: FlexStorageIcon,
                route: 'member-management/companies',
            },
            {
                name: 'Users',
                icon: PeopleIcon,
                route: 'user-management/users',
            },
            {
                name: 'Locations',
                icon: LocationPinIcon,
                route: 'location-management/locations',
            },
        ],
    },
];
