import React, { FC } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { AlertIcon, LocationPinIcon, RightArrowIcon } from '../../../assets/icons';
import { Row, Text, View } from '../../../components/atoms';
import { Company } from '../../../service';

const DELETED_STATE = 'DELETED';

type Props = {
    company: DeepPartial<Company>;
};
export const CompanyInfoCard: FC<Props> = ({ company }) => {
    return (
        <>
            <Row>
                <Card>
                    <Row alignItems={'center'} justifyContent={'space-between'}>
                        <Text fontWeight={'bold'} fontSize={'medium'}>
                            {company.name}
                        </Text>
                        {company.state === DELETED_STATE && (
                            <Deleted>
                                <AlertIcon />
                                Deleted
                            </Deleted>
                        )}
                    </Row>
                    <Divider />
                    <Row justifyContent={'space-between'}>
                        <Row>
                            {company?.locations && company.locations.length > 0 && (
                                <LocationPinIcon />
                            )}
                            <Text fontSize={'medium'}>
                                {company?.locations?.reduce((out, current) => {
                                    return out + (out !== '' ? ', ' : '') + current?.name;
                                }, '') || '/'}
                            </Text>
                        </Row>
                        <RightArrowIcon />
                    </Row>
                </Card>
            </Row>
        </>
    );
};

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 10px 0;
`;

const Deleted = styled(View)`
    display: flex;
    align-items: center;
    border-radius: 11px;
    padding: 3px 10px;
    background-color: #fcf2ed;
    font-size: 10px;
`;

const Card = styled(View)`
    margin-left: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    flex: 1;
    max-width: 300px;
    position: relative;
`;
