import React, { FC, useMemo } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { User } from '../../service';
import { Text, View } from '../atoms';

const DEFAULT_AVATAR_SIZE = 50;

type Props = {
    user?: DeepPartial<User>;
    size?: number | number[];
    variant?: 'regular' | 'light';
};
export const Avatar: FC<Props> = ({ user, size = DEFAULT_AVATAR_SIZE, variant = 'regular' }) => {
    const initials = useMemo(() => {
        return (
            user?.name
                ?.split(' ')
                .map((nameComponent) => nameComponent?.[0])
                .join('') || 'SB'
        );
    }, [user]);

    return (
        <Wrapper variant={variant} width={size} height={size} minWidth={size}>
            {user?.photo?.url ? (
                <Image src={user?.photo?.url}></Image>
            ) : (
                <Text fontWeight={'bold'} fontSize={['medium', 'medium', 'small']}>
                    {initials}
                </Text>
            )}
        </Wrapper>
    );
};

const Image = styled.img`
    overflow: hidden;
    border-radius: 1000px;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

type WrapperProps = {
    variant: string;
};
const Wrapper = styled(View)<WrapperProps>`
    background-color: ${(props) => (props.variant === 'regular' ? '#f9f8f6' : 'white')};
    overflow: hidden;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    display: flex;
`;
