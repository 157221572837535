import React, { FC, useMemo } from 'react';

import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Column, Heading, View, Row } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../../providers';
import { StorageItem, StorageState, useGetFlexStorageItemsQuery } from '../../../../../service';
import { FlexStorageStoredCard } from '../../../components/FlexStorageStoredCard';

export const FlexStorageStoredItems: FC = () => {
    const { user, currentServiceLocation } = useUser();

    // fetch storage items
    const { data, loading } = useGetFlexStorageItemsQuery({
        variables: {
            filter: {
                companyId: user?.company?.id || '',
                locations: currentServiceLocation ? [currentServiceLocation.id] : [],
                states: [StorageState.Stored, StorageState.ReturnRequested],
            },
        },
    });

    const flexStorageItems: StorageItem[] = useMemo(() => {
        const items = data?.storageItems;
        if (items) {
            return items.filter(Boolean) as StorageItem[];
        }
        return [];
    }, [data]);
    return (
        <Column>
            <BrowserView>
                <Heading mb={30}>Stored</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}

            <FlexStorageItemList>
                {flexStorageItems.map((flexStorageItem) => (
                    <FlexStorageStoredCard
                        inContextOf={'Stored'}
                        key={flexStorageItem.id}
                        flexStorageItem={flexStorageItem}
                    />
                ))}
            </FlexStorageItemList>
        </Column>
    );
};

const FlexStorageItemList = styled(View)`
    overflow: auto;
    height: ${window.innerHeight - 150}px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;
