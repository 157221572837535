import React, { FC } from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { RightArrowIcon } from '../../../assets/icons';
import { Row, Text } from '../../../components/atoms';

type Props = {
    title: string;
} & NavLinkProps;
export const SubMenuItem: FC<Props> = ({ title, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Row alignItems={'center'}>
                <Text>{title}</Text>
            </Row>
            <RightArrowIcon />
        </Wrapper>
    );
};

const Wrapper = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px 6px 10px;
    margin-bottom: 5px;
    margin-left: 36px;
    cursor: pointer;
    text-decoration: none !important;
    color: black;
    border-radius: 5px;
    ${Text} {
        font-size: 10px;
    }

    &.active {
        background-color: #f9f8f6;
        ${Text} {
            font-weight: bold;
        }
    }

    &:hover {
        background-color: #f9f8f6;
    }
`;
