import React, { FC } from 'react';

import styled from 'styled-components';

import { DownArrowIcon } from '../../../assets/icons';
import { Text, View } from '../../../components/atoms';

type Props = React.ComponentPropsWithoutRef<'div'>;
export const StoreButton: FC<Props> = ({ children, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Icon />
            <Label fontSize={['small', 'small', 'medium']}>{children}</Label>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    right: 11px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 7px;
    border-radius: 5px;
`;

const Label = styled(Text)`
    margin-left: 2.5px;
    color: white;
`;

const Icon = styled(DownArrowIcon)`
    margin-right: 3px;
`;
