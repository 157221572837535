import React, { FC, useMemo } from 'react';

import { format } from 'date-fns';
import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Heading, View, Text, Row } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../../providers';
import { ScheduleType, useGetEforceComBookingsQuery } from '../../../../../service';
import { DateUtils } from '../../../../../util/date';
import { BlockedTimeCard } from '../../../components/BlockedTimeCard';
import { MemberBookingCard } from '../../../components/MemberBookingCard';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const EForceScheduled: FC = () => {
    const { currentServiceLocation } = useUser();

    const timezone = useMemo(
        () => currentServiceLocation?.zone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        [currentServiceLocation],
    );

    const { data: bookingsData, loading } = useGetEforceComBookingsQuery({
        variables: {
            locationId: currentServiceLocation?.id,
            date: {
                from: format(DateUtils.daysFromToday(-7), EFORCE_BOOKING_FILTER_DATE_FORMAT),
                to: format(DateUtils.daysFromToday(21), EFORCE_BOOKING_FILTER_DATE_FORMAT),
            },
        },
    });

    const scheduledBookings = bookingsData?.bookings?.filter(Boolean) || [];

    return (
        <View>
            <BrowserView>
                <Heading>Scheduled bookings</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}
            {scheduledBookings.length ? (
                <ScrollView flex={1} mt={30}>
                    {scheduledBookings
                        .sort((a, b) => (a?.slot?.startTime > b?.slot?.startTime ? 1 : -1))
                        .map((booking) => {
                            return booking?.scheduleType === ScheduleType.BlockingTime ? (
                                <View key={booking?.id} mb={10}>
                                    <BlockedTimeCard booking={booking!} timezone={timezone} />
                                </View>
                            ) : (
                                <View key={booking?.id} mb={10}>
                                    <MemberBookingCard booking={booking!} timezone={timezone} />
                                </View>
                            );
                        })}
                </ScrollView>
            ) : (
                <Text mt={30} color={'text.secondary'}>
                    No upcoming bookings
                </Text>
            )}
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    height: ${window.innerHeight - 150}px;
`;
