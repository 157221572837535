import React, { useMemo } from 'react';

import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import * as _ from 'lodash';
import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Column, Heading, Row, View, Text } from '../../../../components/atoms';
import { LoadingIndicator } from '../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../providers';
import { ScheduleType, useGetEforceAssociateBookingsQuery } from '../../../../service';
import { DateUtils } from '../../../../util/date';
import { AssociateBlockedTimeCard } from '../../components/AssociateBlockedTimeCard';
import { AssociateBookingCard } from '../../components/AssociateBookingCard';
import { DateBox } from '../../components/DateBox';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const EForceSchedule = () => {
    const { user, serviceLocationBoundTimezone } = useUser();
    const { data: bookingsData, loading } = useGetEforceAssociateBookingsQuery({
        variables: {
            filter: {
                associateId: user?.id,
                date: {
                    from: format(DateUtils.yesterday(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
                    to: format(DateUtils.getEndOfCurrentMonth(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
                },
            },
        },
        fetchPolicy: 'no-cache',
    });

    const groupedBookings = useMemo(() => {
        if (bookingsData?.bookings && serviceLocationBoundTimezone) {
            const startDate = DateUtils.yesterday();
            const endDate = DateUtils.getEndOfCurrentMonth();
            let initialDates = {};
            const iterator = startDate;

            while (iterator <= endDate) {
                const key = format(iterator, EFORCE_BOOKING_FILTER_DATE_FORMAT);
                initialDates = {
                    ...initialDates,
                    [key]: [],
                };
                iterator.setDate(iterator.getDate() + 1);
            }

            const groupedByDate = _.groupBy(bookingsData.bookings.filter(Boolean), (booking) =>
                formatInTimeZone(
                    parseISO(booking!.slot.startTime),
                    serviceLocationBoundTimezone,
                    'yyyy-MM-dd',
                ),
            );
            return _.merge(groupedByDate, initialDates);
        }
        return {};
    }, [bookingsData, serviceLocationBoundTimezone]);

    return (
        <>
            <BrowserView>
                <Heading>My schedule</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}
            {Object.keys(groupedBookings) && (
                <BookingsWrapper flex={1} mt={30}>
                    {_.sortBy(Object.keys(groupedBookings), (key) => key).map((date) => (
                        <Row key={date}>
                            <DateBox date={date} />
                            <Column pl="11px" flex={1}>
                                {_.sortBy(
                                    groupedBookings[date],
                                    (booking) => booking?.slot.startTime,
                                ).map((booking: any) => {
                                    return booking.scheduleType === ScheduleType.BlockingTime ? (
                                        <AssociateBlockedTimeCard
                                            booking={booking}
                                            key={booking.id}
                                            timezone={serviceLocationBoundTimezone}
                                        />
                                    ) : (
                                        <AssociateBookingCard
                                            booking={booking}
                                            key={booking.id}
                                            timezone={serviceLocationBoundTimezone}
                                        />
                                    );
                                })}
                                {groupedBookings[date].length === 0 && (
                                    <EmptyBookingCard>
                                        <EmptyBookingCardText>
                                            No scheduled jobs for this day
                                        </EmptyBookingCardText>
                                    </EmptyBookingCard>
                                )}
                            </Column>
                        </Row>
                    ))}
                </BookingsWrapper>
            )}
        </>
    );
};

const BookingsWrapper = styled(Column)`
    overflow: auto;
    height: ${window.innerHeight - 180}px;
`;
const EmptyBookingCard = styled(View)`
    background-color: #e3e3e3;
    box-shadow: none;
    margin-bottom: 10px;
    padding: 19px 16px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 83px;
    align-items: flex-start;
    max-width: 300px;
    margin-left: 10px;
`;

const EmptyBookingCardText = styled(Text)`
    color: #505354;
    font-size: 12px;
    font-weight: 700;
`;
