import {
    DashboardIcon,
    EForceIcon,
    FlexStorageIcon,
    FulfillmentIcon,
    PeopleIcon,
    TeamIcon,
} from '../../../assets/icons';
import { MenuSection } from '../models/MenuSection';

export const ComMenu: MenuSection[] = [
    {
        name: 'General',
        showNameInMenu: false,
        items: [
            {
                name: 'Dashboard',
                icon: DashboardIcon,
                route: '/',
            },
            {
                name: 'Team',
                icon: TeamIcon,
                route: '/team-management/associates',
            },
            {
                name: 'Companies',
                icon: FlexStorageIcon,
                route: 'member-management/companies',
            },
        ],
    },
    {
        name: 'Services',
        showNameInMenu: true,
        items: [
            {
                name: 'eForce',
                icon: EForceIcon,
                route: 'eforce',
                subItems: [
                    {
                        name: 'Scheduled',
                        route: 'eforce/scheduled',
                    },
                    {
                        name: 'Awaiting approval',
                        route: 'eforce/completed',
                    },
                    {
                        name: 'Reports',
                        route: 'eforce/reports',
                    },
                ],
            },
            {
                name: 'Flex Storage',
                icon: FlexStorageIcon,
                route: '/flex-storage',
                subItems: [
                    {
                        name: 'Requests',
                        route: '/flex-storage/requests',
                    },
                    {
                        name: 'Stored',
                        route: '/flex-storage/stored',
                    },
                ],
            },
            {
                name: 'Fulfillment',
                icon: FulfillmentIcon,
                route: 'https://www.saltbox.com/fulfillment',
                externalLink: true,
            },
            /*{
                name: 'Rental Equipment',
                icon: RentalEquipmentIcon,
                route: '/rental-equipment',
            },*/
        ],
    },
];
