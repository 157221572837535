import React, { FC, useMemo, useRef } from 'react';

import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { Row, Tag, TagVariant, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { BookingResponse, BookingStatus } from '../../../service';
import { EForceBookingOverview } from './EForceBookingOverview';

type Props = {
    booking: DeepPartial<BookingResponse>;
    timezone?: string;
};
export const AssociateBookingCard: FC<Props> = ({
    booking,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
    const bookingBottomSheetRef = useRef<BottomSheetRef>(null);

    const tagVariant: TagVariant = useMemo(() => {
        if (booking?.status) {
            switch (booking.status) {
                case BookingStatus.Completed:
                    return 'dark';
                case BookingStatus.InProgress:
                    return 'accent';
                default:
                    return 'neutral';
            }
        }
        return 'neutral';
    }, [booking]);

    return (
        <>
            <Card onClick={() => bookingBottomSheetRef.current?.show()}>
                <Row alignItems={'center'}>
                    <Text fontWeight={'bold'} fontSize={'medium'}>
                        {booking.company?.name}
                    </Text>
                </Row>
                <Text mt={10} fontWeight={'bold'}>
                    {formatInTimeZone(parseISO(booking?.slot?.startTime), timezone, 'h:mm aa')} -
                    {formatInTimeZone(parseISO(booking?.slot?.endTime), timezone, 'h:mm aa')}
                </Text>
                <PositionedTag variant={tagVariant}>{booking?.status}</PositionedTag>
            </Card>

            <BottomSheet ref={bookingBottomSheetRef}>
                <EForceBookingOverview booking={booking} />
            </BottomSheet>
        </>
    );
};

const Card = styled(View)`
    margin-left: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    position: relative;
    margin-bottom: 10px;
    max-width: 300px;
    width: 100%;
    flex: 1;
`;

const PositionedTag = styled(Tag)`
    position: absolute;
    top: 15px;
    right: 15px;
`;
