import React, { FC } from 'react';

import { format } from 'date-fns';
import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Heading, Row, Text, View } from '../../../components/atoms';
import { TimeInput, TimeInputType } from '../../../components/molecules/form';
import { ModifyAssociateInput, useModifyWorkingHoursMutation, User } from '../../../service';

const DEFAULT_START_TIME = '09:00:00';
const DEFAULT_END_TIME = '17:00:00';
const TIME_PARSE_FORMAT = 'kk:mm:ss';

type Props = {
    onDone?: () => void;
    associate: DeepPartial<User>;
};
export const WorkingHoursForm: FC<Props> = ({ associate, onDone }) => {
    const { control, handleSubmit, reset } = useForm<ModifyAssociateInput>({
        defaultValues: {
            fullName: associate?.name || '',
            phoneNumber: '',
            workHoursStart: associate?.workHoursStart || DEFAULT_START_TIME,
            workHoursEnd: associate?.workHoursEnd || DEFAULT_END_TIME,
        },
    });
    const [modifyAssociate, { loading: modifyAssociateLoading }] = useModifyWorkingHoursMutation({
        refetchQueries: ['CurrentAssociate'],
    });

    const submit = handleSubmit(async (data) => {
        await modifyAssociate({
            variables: {
                input: {
                    ...data,
                    id: associate!.id!,
                    workHoursStart: format(new Date(data.workHoursStart), TIME_PARSE_FORMAT),
                    workHoursEnd: format(new Date(data.workHoursEnd), TIME_PARSE_FORMAT),
                },
            },
        });
        reset();
        onDone && onDone();
    });

    return (
        <View px={[20, 20, 40]} py={30}>
            <Heading>Adjust working hours</Heading>
            <View>
                <Text my={10}>What are their working hours?</Text>
                <Row
                    my={20}
                    maxWidth={['none', 300, 300]}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <TimeInput
                        control={control}
                        type={TimeInputType.Time}
                        name={'workHoursStart'}
                        format={TIME_PARSE_FORMAT}
                    />
                    <Line />
                    <TimeInput
                        control={control}
                        type={TimeInputType.Time}
                        name={'workHoursEnd'}
                        format={TIME_PARSE_FORMAT}
                    />
                </Row>
                <Row>
                    <Text mr={5} size="medium">
                        Note:{' '}
                    </Text>
                    <Text color="text.secondary">
                        You will be able to adjust this inside employee profile settings.
                    </Text>
                </Row>
            </View>
            <Button mt={30} mb={10} onClick={() => submit()} loading={modifyAssociateLoading}>
                Save working hours
            </Button>
        </View>
    );
};

const Line = styled(View)`
    width: 7px;
    height: 1px;
    background-color: black;
`;
