import React, { FC } from 'react';

import { createPortal } from 'react-dom';

type Props = {
    children?: React.ReactNode;
    wrapperId?: string;
};
export const Portal: FC<Props> = ({ children, wrapperId = 'saltbox-portal' }) => {
    let element = document.getElementById(wrapperId);
    if (!element) {
        element = createPortalWrapper(wrapperId);
    }

    return createPortal(children, element);
};

const createPortalWrapper = (wrapperId: string) => {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
};
