import React, { FC, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon } from '../../../assets/icons';
import { Button, View, Text, Heading, Row, LinkButton } from '../../../components/atoms';
import { TimeInput, TimeInputType } from '../../../components/molecules/form';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { SearchInput } from '../../../components/molecules/SearchInput';
import {
    CompanyLocation,
    useAssociatesQuery,
    useSubscriptionEForceAddMutation,
} from '../../../service';
import { Theme } from '../../../theme';

type Props = {
    companyId: string;
    locations: CompanyLocation[];
    onDone: () => void;
};

enum Steps {
    Location,
    Associates,
    Time,
    Summary,
}
const DEFAULT_START_TIME = '09:00:00';
const DEFAULT_END_TIME = '17:00:00';
const TIME_PARSE_FORMAT = 'kk:mm:ss';
function getAssociatesNames(associatesIds: string[], associatesData: any): Array<string> {
    const associatesNames: Array<string> = [];
    associatesData.filter(Boolean).forEach((associateEntry: any) => {
        if (associatesIds.includes(associateEntry.id)) {
            associatesNames.push(associateEntry.name!);
        }
    });
    return associatesNames;
}

export const SubscriptionCreateForm: FC<Props> = ({ companyId, locations = [], onDone }) => {
    // Local state
    const initialStep = Steps.Location;
    const [step, setStep] = useState<Steps>(initialStep);
    const [locationsFilter, setLocationsFilter] = useState('');
    const [associatesFilter, setAssociatesFilter] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(locations[0]);
    const [selectedAssociatesIds, setSelectedAssociatesIds] = useState<string[]>([]);

    const { control, watch } = useForm({
        defaultValues: {
            subscriptionTimeStart: DEFAULT_START_TIME,
            subscriptionTimeEnd: DEFAULT_END_TIME,
        },
    });
    const subscriptionTimeStart = watch('subscriptionTimeStart');
    const subscriptionTimeEnd = watch('subscriptionTimeEnd');
    // GraphQL
    const { data: associatesData, loading: associatesLoading } = useAssociatesQuery({
        variables: {
            filter: {
                locationId: selectedLocation.id,
            },
        },
    });
    const [createSubscription, { loading: createSubscriptionLoading }] =
        useSubscriptionEForceAddMutation();
    const onSubmit = async () => {
        await createSubscription({
            variables: {
                input: {
                    companyId,
                    locationId: selectedLocation.id,
                    preferredMemberSpecialists: selectedAssociatesIds,
                    slot: {
                        from: format(new Date(subscriptionTimeStart), TIME_PARSE_FORMAT),
                        to: format(new Date(subscriptionTimeEnd), TIME_PARSE_FORMAT),
                    },
                },
            },
            refetchQueries: ['SubscriptionsEForce'],
        });
        onDone && onDone();
    };

    const toggleAssociate = (associateId: string) => {
        if (!selectedAssociatesIds.includes(associateId)) {
            setSelectedAssociatesIds((selectedIds) => [...selectedIds, associateId]);
        } else {
            // deselect user (remove its id from the state array)
            const newIdsArray = [...selectedAssociatesIds];
            newIdsArray.splice(selectedAssociatesIds.indexOf(associateId), 1);
            setSelectedAssociatesIds([...newIdsArray]);
        }
    };
    useEffect(() => {
        setSelectedAssociatesIds([]);
    }, [selectedLocation]);
    return (
        <>
            <View px={[20, 20, 40]} py={40}>
                {step !== initialStep && (
                    <>
                        <Row
                            alignItems={'center'}
                            width={'fit-content'}
                            mb={25}
                            ml={-5}
                            onClick={() => setStep(step.valueOf() - 1)}
                        >
                            <LeftArrow width={20} height={18} />
                            <LinkButton>Back</LinkButton>
                        </Row>
                    </>
                )}
                <Heading mb={10}>Create EForce Subscription</Heading>
                {step === Steps.Location && (
                    <>
                        <Text>What location you creating subscription for?</Text>
                        <View mt={20}>
                            <SearchInputField
                                placeholder="Search locations..."
                                onInput={(event: React.FormEvent<HTMLInputElement>) =>
                                    setLocationsFilter(event.currentTarget.value)
                                }
                            />
                            <ScrollView>
                                {locations
                                    .filter((locationEntry) =>
                                        (locationEntry?.name || '')
                                            .toLowerCase()
                                            .includes(locationsFilter.toLowerCase()),
                                    )
                                    .map((locationEntry) => (
                                        <Option
                                            key={locationEntry?.id}
                                            onClick={() => {
                                                if (locationEntry) {
                                                    setSelectedLocation(locationEntry);
                                                    setLocationsFilter('');
                                                }
                                                setStep(Steps.Associates);
                                            }}
                                        >
                                            <Text>{locationEntry?.name}</Text>
                                        </Option>
                                    ))}
                            </ScrollView>
                        </View>
                    </>
                )}
                {step === Steps.Associates && (
                    <>
                        <Text>Who are preferred associates for this subscription?</Text>
                        <View mt={20}>
                            <SearchInputField
                                placeholder="Search associates..."
                                onInput={(event: React.FormEvent<HTMLInputElement>) =>
                                    setAssociatesFilter(event.currentTarget.value)
                                }
                            />
                            {associatesLoading ? (
                                <LoadingIndicator variant={'medium'} />
                            ) : (
                                <AssociateScrollView>
                                    {associatesData?.associates &&
                                        associatesData.associates
                                            .filter((associateEntry) =>
                                                (associateEntry?.name || '')
                                                    .toLowerCase()
                                                    .includes(associatesFilter.toLowerCase()),
                                            )
                                            .filter(Boolean)
                                            .map((associateEntry) => (
                                                <SelectableField
                                                    key={associateEntry?.id}
                                                    active={selectedAssociatesIds.includes(
                                                        associateEntry?.id || '',
                                                    )}
                                                    onClick={() =>
                                                        toggleAssociate(associateEntry?.id || '')
                                                    }
                                                >
                                                    <Text>{associateEntry?.name}</Text>
                                                </SelectableField>
                                            ))}
                                </AssociateScrollView>
                            )}
                        </View>
                        <View mt={15}>
                            <View>
                                <Text fontWeight="bold" color={Theme.colors.text.primary}>
                                    Note:
                                </Text>
                                <Text fontSize="medium" color={Theme.colors.text.secondary}>
                                    You have to choose at least one preferred associate.
                                </Text>
                            </View>
                        </View>
                        <Button
                            mt={30}
                            disabled={selectedAssociatesIds.length === 0}
                            onClick={() => setStep(Steps.Time)}
                        >
                            Next
                        </Button>
                    </>
                )}
                {step === Steps.Time && (
                    <>
                        <Text mb={30}>Choose subscription time slot</Text>
                        <Row justifyContent="flex-start" alignItems="center">
                            <TimeInput
                                control={control}
                                type={TimeInputType.Time}
                                name={'subscriptionTimeStart'}
                                format={TIME_PARSE_FORMAT}
                            />
                            <Line mt={20} mx={15} />
                            <TimeInput
                                control={control}
                                type={TimeInputType.Time}
                                name={'subscriptionTimeEnd'}
                                format={TIME_PARSE_FORMAT}
                            />
                        </Row>
                        <Button
                            mt={40}
                            disabled={selectedAssociatesIds.length === 0}
                            onClick={() => setStep(Steps.Summary)}
                        >
                            Go to summary
                        </Button>
                    </>
                )}
                {step === Steps.Summary && (
                    <>
                        <Text>Does this look ok?</Text>
                        <ScrollView>
                            <FieldsWrapper>
                                <Field key="location">
                                    <Row
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        mb={5}
                                    >
                                        <Text fontWeight="bold" fontSize={12}>
                                            Location
                                        </Text>
                                        <View onClick={() => setStep(Steps.Location)}>
                                            <Text fontSize="medium" color="text.secondary">
                                                Edit
                                            </Text>
                                        </View>
                                    </Row>
                                    <Text fontSize={12}>{selectedLocation.name}</Text>
                                </Field>
                                <Field key="preferredAssociates">
                                    <Row
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        mb={5}
                                    >
                                        <Text fontWeight="bold" fontSize={12}>
                                            Preferred Member Specialists
                                        </Text>
                                        <View onClick={() => setStep(Steps.Associates)}>
                                            <Text fontSize="medium" color="text.secondary">
                                                Edit
                                            </Text>
                                        </View>
                                    </Row>
                                    <Text fontSize={12}>
                                        {getAssociatesNames(
                                            selectedAssociatesIds,
                                            associatesData?.associates || [],
                                        ).join(', ')}
                                    </Text>
                                </Field>
                                <Field key="timeSlot">
                                    <Row
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        mb={5}
                                    >
                                        <Text fontWeight="bold" fontSize={12}>
                                            Time slot
                                        </Text>
                                        <View onClick={() => setStep(Steps.Time)}>
                                            <Text fontSize="medium" color="text.secondary">
                                                Edit
                                            </Text>
                                        </View>
                                    </Row>
                                    <Text fontSize={12}>
                                        {format(new Date(subscriptionTimeStart), 'kk:mm')} -{' '}
                                        {format(new Date(subscriptionTimeEnd), 'kk:mm')}
                                    </Text>
                                </Field>
                            </FieldsWrapper>
                        </ScrollView>
                        <Button mt={30} loading={createSubscriptionLoading} onClick={onSubmit}>
                            Confirm
                        </Button>
                    </>
                )}
            </View>
        </>
    );
};
const Option = styled(View)`
    background-color: #f9f8f6;
    padding: 11px 15px;
    margin-bottom: 5px;
    border-radius: 5px;
`;
const FieldsWrapper = styled(View)`
    margin-top: 20px;
    margin-bottom: 10px;
`;
const Field = styled(View)`
    flex-direction: column;
    font-size: 12px;
    padding: 15px 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: ${Theme.colors.background.primary};
    min-width: 140px;
`;
const SelectableField = styled(View)<{ active?: boolean }>`
    flex-direction: row;
    font-size: 16px;
    padding: 15px 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: ${Theme.colors.background.primary};
    border: 1px solid;
    border-color: ${(props) =>
        props.active ? Theme.colors.background.dark : Theme.colors.background.primary};
`;
const Line = styled(View)`
    width: 7px;
    height: 1px;
    margin-bottom: 20px;
    background-color: black;
`;

const ScrollView = styled(View)`
    overflow: auto;
    height: 300px;
`;
const AssociateScrollView = styled(View)`
    overflow: auto;
    height: 170px;
`;

const LeftArrow = styled(LeftArrowIcon)`
    &:hover {
        cursor: pointer;
    }
`;

const SearchInputField = styled(SearchInput)`
    margin-bottom: 20px;
`;
