import { format, parseISO } from 'date-fns';

const getBeginningOfCurrentMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    return new Date(currentYear, currentMonth, 1);
};

const getBeginningOfMonth = (month: number, year: number) => {
    return new Date(year, month - 1, 1);
};

const getEndOfCurrentMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    return new Date(currentYear, currentMonth + 1, 0);
};

const getEndOfMonth = (month: number, year: number) => {
    return new Date(year, month, 0);
};

const getSDTTimezoneOffset = () => {
    const today = new Date();
    const jan = new Date(today.getFullYear(), 0, 1);
    const jul = new Date(today.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

const isDSTObserved = () => {
    const today = new Date();
    return today.getTimezoneOffset() < getSDTTimezoneOffset();
};

const parseWithDaylightSaving = (dateString: string) => {
    const date = new Date(dateString);
    return new Date(date.valueOf() + (isDSTObserved() ? 60 * 1000 : 0));
};

const yesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
};

const daysFromToday = (daysDiff: number) => {
    const date = new Date();
    date.setDate(date.getDate() + daysDiff);
    return date;
};

const dateComponents = (targetDate: Date | string) => {
    const date = typeof targetDate === 'string' ? parseISO(targetDate) : targetDate;
    return {
        month: format(date, 'MMM'),
        day: format(date, 'dd'),
        partOfDay: format(date, 'aa'),
        time: format(date, 'hh:mm'),
    };
};
const isToday = (targetDate: Date) => {
    const today = new Date();
    return format(targetDate, 'MM/dd/yyyy') === format(today, 'MM/dd/yyyy');
};
export const DateUtils = {
    getBeginningOfCurrentMonth,
    getEndOfCurrentMonth,
    isDSTObserved,
    parseWithDaylightSaving,
    yesterday,
    daysFromToday,
    dateComponents,
    getBeginningOfMonth,
    getEndOfMonth,
    isToday,
};
