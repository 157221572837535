import React, { createContext, FC, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { Splash } from './Splash';

export const ACCESS_TOKEN = 'com.saltbox.access_token';
export enum AuthStatus {
    Unauthenticated,
    Authenticated,
    Pending,
}
export type SessionContextType = {
    authStatus: AuthStatus;
};

export const SessionContext = createContext<SessionContextType>({} as SessionContextType);

type SessionProviderProps = {
    children?: React.ReactNode;
};
export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Pending);
    const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setAuthStatus(AuthStatus.Unauthenticated);
            loginWithRedirect();
        } else {
            restoreSession();
        }
    }, [isLoading, isAuthenticated]);

    const restoreSession = async () => {
        const token = await getAccessTokenSilently();
        if (token) {
            localStorage.setItem(ACCESS_TOKEN, token);
            setAuthStatus(AuthStatus.Authenticated);
        }
    };

    return (
        <SessionContext.Provider value={{ authStatus }}>
            {authStatus === AuthStatus.Authenticated ? children : <Splash />}
        </SessionContext.Provider>
    );
};
