import React, { FC } from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import { variant } from 'styled-system';

type VariantProps = {
    variant?: 'small' | 'medium' | 'large';
};

type Props = VariantProps;

export const LoadingIndicator: FC<Props> = ({ ...rest }) => {
    return (
        <ActivityIndicator
            {...rest}
            animate={{
                rotate: 360,
            }}
            initial={{
                rotate: 0,
            }}
            transition={{
                flip: Infinity,
                duration: 1,
                ease: 'easeInOut',
            }}
        />
    );
};

const ActivityIndicator = styled(motion.div)<VariantProps>`
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #383636; /* Black */
    border-radius: 50%;
    ${variant({
        variants: {
            small: {
                width: 15,
                height: 15,
                borderWidth: 2,
            },
            medium: {
                width: 30,
                height: 30,
                borderWidth: 4,
            },
            large: {
                width: 60,
                height: 60,
                borderWidth: 8,
            },
        },
    })}
`;
