import { DashboardIcon } from '../../../assets/icons';
import { MenuSection } from '../models/MenuSection';

export const CentralMenu: MenuSection[] = [
    {
        name: 'General',
        showNameInMenu: false,
        items: [
            {
                name: 'Dashboard',
                icon: DashboardIcon,
                route: '/',
            },
        ],
    },
];
