import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { DeepPartial } from 'react-hook-form';

import { Role, ServiceLocation, useCurrentUserQuery, User } from '../../service';

export type UserContextType = {
    user?: DeepPartial<User>;
    note?: string;
    currentRole?: Role;
    setCurrentRole: (role: Role) => void;

    currentServiceLocation?: ServiceLocation;
    setCurrentServiceLocation: (location: ServiceLocation) => void;

    serviceLocationBoundTimezone: string;
};

export const SELECTED_ROLE_KEY = 'selected_role';

export const UserContext = createContext<UserContextType>({} as UserContextType);

type UserProviderProps = {
    children?: React.ReactNode;
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const launchDarklyClient = useLDClient();
    const [currentRole, setCurrentRoleState] = useState<Role>();
    const [currentServiceLocation, setCurrentServiceLocation] = useState<ServiceLocation>();
    const { data } = useCurrentUserQuery();

    useEffect(() => {
        setCurrentRole(
            (localStorage.getItem(SELECTED_ROLE_KEY) as Role) || data?.me?.roles?.[0] || '',
        );
        setCurrentServiceLocation(data?.me?.serviceLocations?.[0] || undefined);
        if (data?.me?.name && data?.me?.email) {
            launchDarklyClient?.identify({
                key: data.me.email,
                name: data.me.name,
                email: data.me.email,
            });
        }
    }, [data]);

    const setCurrentRole = (role: Role) => {
        localStorage.setItem(SELECTED_ROLE_KEY, role?.toString());
        setCurrentRoleState(role);
    };

    const serviceLocationBoundTimezone = useMemo(() => {
        return currentServiceLocation?.zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [currentServiceLocation]);

    return (
        <UserContext.Provider
            value={{
                user: data?.me,
                currentRole,
                setCurrentRole,
                currentServiceLocation,
                setCurrentServiceLocation,
                serviceLocationBoundTimezone,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
