import React, { FC } from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { BlockedTimeCardBrowser } from './BlockedTimeCardBrowser';
import { BlockedTimeCardMobile, BlockedTimeCardProps } from './BlockedTimeCardMobile';

export const BlockedTimeCard: FC<BlockedTimeCardProps> = (props: BlockedTimeCardProps) => {
    return (
        <>
            <MobileView>
                <BlockedTimeCardMobile {...props} />
            </MobileView>
            <BrowserView>
                <BlockedTimeCardBrowser {...props} />
            </BrowserView>
        </>
    );
};
