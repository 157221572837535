import React, { FC } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { AlertIcon, RightArrowIcon } from '../../../assets/icons';
import { Column, Row, Text, View } from '../../../components/atoms';
import { Avatar } from '../../../components/molecules/Avatar';
import { User } from '../../../service';

const DELETED_STATE = 'DELETED';

type Props = {
    member: DeepPartial<User>;
};
export const MemberInfoCard: FC<Props> = ({ member }) => {
    return (
        <>
            <Row>
                <Card>
                    <Row alignItems={'center'}>
                        <Avatar size={45} user={member} />
                        <Column justifyContent={'center'} ml={10} width={'100%'}>
                            <NameRow>
                                <Text fontWeight={'bold'} fontSize={'medium'}>
                                    {member.name}
                                </Text>
                                {member.state === DELETED_STATE && (
                                    <Deleted>
                                        <AlertIcon />
                                        Deleted
                                    </Deleted>
                                )}
                            </NameRow>
                            <ElipsedText fontSize={'medium'}>{member.email}</ElipsedText>
                        </Column>
                    </Row>
                    <Divider />
                    <Row justifyContent={'space-between'}>
                        <Text fontSize={'medium'}>
                            {member?.serviceLocations?.reduce((out, current) => {
                                return out + (out !== '' ? ', ' : '') + current?.name;
                            }, '') || '/'}
                        </Text>
                        <RightArrowIcon />
                    </Row>
                </Card>
            </Row>
        </>
    );
};

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 10px 0;
`;

const NameRow = styled(Row)`
    height: 18px;
    width: calc(100% - 45px);
    align-items: center;
    justify-content: space-between;
    p {
        line-height: 12px;
    }
`;

const Card = styled(View)`
    margin-left: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    flex: 1;
    max-width: 300px;
    position: relative;
`;

const Deleted = styled(View)`
    display: flex;
    align-items: center;
    border-radius: 11px;
    padding: 3px 10px;
    background-color: #fcf2ed;
    font-size: 10px;
`;

const ElipsedText = styled(Text)`
    width: calc(100% - 45px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
