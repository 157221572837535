import React, { FC, useMemo } from 'react';

import { format } from 'date-fns';
import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Heading, View, Text, Row } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../../providers';
import { useGetEforceMemberBookingsQuery } from '../../../../../service';
import { DateUtils } from '../../../../../util/date';
import { MemberBookingCard } from '../../../components/MemberBookingCard';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const EForceScheduled: FC = () => {
    const { user, currentServiceLocation } = useUser();
    const timezone = useMemo(
        () => currentServiceLocation?.zone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        [currentServiceLocation],
    );

    const { data: bookingsData, loading } = useGetEforceMemberBookingsQuery({
        variables: {
            companyId: user?.company?.id,
            date: {
                from: format(
                    DateUtils.getBeginningOfCurrentMonth(),
                    EFORCE_BOOKING_FILTER_DATE_FORMAT,
                ),
                to: format(DateUtils.getEndOfCurrentMonth(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
            },
            today: format(new Date(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
        },
    });

    return (
        <View>
            <BrowserView>
                <Heading>Scheduled bookings</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}
            {bookingsData?.upcoming?.length ? (
                <ScrollView flex={1} mt={30}>
                    {bookingsData?.upcoming
                        ?.filter(Boolean)
                        .sort((a, b) => (a?.slot?.startTime > b?.slot?.startTime ? 1 : -1))
                        .map((booking) => (
                            <View key={booking!.id} mb={10}>
                                <MemberBookingCard booking={booking!} timezone={timezone} />
                            </View>
                        ))}
                </ScrollView>
            ) : (
                <Text mt={30} color={'text.secondary'}>
                    No upcoming bookings
                </Text>
            )}
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    height: ${window.innerHeight - 150}px;
`;
