import React, { FC } from 'react';

import { DeepPartial, useForm } from 'react-hook-form';

import { Button, Heading, View } from '../../../components/atoms';
import { Select, TextInput } from '../../../components/molecules/form';
import { configuration } from '../../../configuration';
import {
    Location,
    Maybe,
    Role,
    useMemberLocationsQuery,
    UserInput,
    useUserCreateMutation,
} from '../../../service';

type Props = {
    onDone?: () => void;
};
export const AssociateForm: FC<Props> = ({ onDone }) => {
    const { control, handleSubmit, reset } = useForm<UserInput>({
        defaultValues: {
            name: '',
            email: '',
            phoneNumber: '',
            locationId: undefined,
            companyId: configuration.saltboxId,
        },
    });

    const { data: locationsData } = useMemberLocationsQuery();

    const [createAssociate, { loading: associateCreationLoading }] = useUserCreateMutation({
        refetchQueries: ['Associates'],
    });

    const submit = handleSubmit(async (data) => {
        const payload = {
            ...data,
            role: Role.Associate,
        };

        await createAssociate({
            variables: {
                input: {
                    ...payload,
                },
            },
        });
        reset();
        onDone && onDone();
    });

    return (
        <View px={[20, 20, 40]} py={40}>
            <Heading>Create an associate</Heading>
            <View mt={20}>
                <TextInput
                    placeholder="Full name"
                    label="Full name"
                    name="name"
                    control={control}
                />
            </View>
            <View mt={10}>
                <TextInput
                    placeholder="Email address"
                    label="Email address"
                    autoCapitalize="none"
                    name="email"
                    control={control}
                />
            </View>
            <View mt={10}>
                <TextInput
                    placeholder="Phone number"
                    label="Phone number"
                    autoCapitalize="none"
                    name="phoneNumber"
                    control={control}
                />
            </View>
            <View mt={10}>
                <Select
                    label="Location"
                    control={control}
                    name="locationId"
                    values={
                        locationsData?.locations?.map(
                            (location: Maybe<DeepPartial<Location>>) => location!.id!,
                        ) || []
                    }
                    labels={
                        locationsData?.locations?.map(
                            (location: Maybe<DeepPartial<Location>>) => location!.name!,
                        ) || []
                    }
                />
            </View>
            <Button
                mt={30}
                mb={20}
                onClick={() => !associateCreationLoading && submit()}
                loading={associateCreationLoading}
            >
                Create
            </Button>
        </View>
    );
};
