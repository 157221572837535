import { useMemo, useState } from 'react';

export const useFlag = (initialValue = false): [boolean, () => void, () => void, () => void] => {
    const [value, setValue] = useState(initialValue);

    const setTruthy = () => setValue(true);
    const setFaulty = () => setValue(false);
    const toggle = () => setValue(!value);

    return useMemo(() => {
        return [value, setTruthy, setFaulty, toggle];
    }, [value]);
};
