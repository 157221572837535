import React from 'react';

import styled from 'styled-components';

import { MonochromeLogo } from '../../assets/graphics';
import { Column, View } from '../../components/atoms';
import { LoadingIndicator } from '../../components/molecules/LoadingIndicator';

export const Splash = () => (
    <Wrapper>
        <MonochromeLogo height={30} width={200} />
        <View mt={20}>
            <LoadingIndicator variant={'medium'} />
        </View>
    </Wrapper>
);

const Wrapper = styled(Column)`
    height: 100vh;
    background-color: ${(props) => props.theme.colors.background.accent};
    display: flex;
    justify-content: center;
    align-items: center;
`;
