import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, View, Text, Heading } from '../../../components/atoms';
import { Select, TextInput } from '../../../components/molecules/form';
import { useLocationCreateMutation } from '../../../service';

const timezones = ['America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles'];

type Props = {
    onDone?: () => void;
};

export const CreateLocationForm: FC<Props> = ({ onDone }) => {
    const [createLocation, { loading }] = useLocationCreateMutation();

    // Form
    const { control, watch } = useForm({
        defaultValues: {
            name: '',
            timezone: 'America/New_York',
        },
    });
    const name = watch('name');
    const timezone = watch('timezone');

    const onLocationCreate = async () => {
        await createLocation({
            variables: {
                input: { name, zone: timezone },
            },
            refetchQueries: ['Locations'],
        });

        onDone && onDone();
    };

    return (
        <View px={[20, 20, 40]} py={40}>
            <Heading>Create a location</Heading>
            <View>
                <View mt={30}>
                    <Select
                        label="Timezone"
                        control={control}
                        name={'timezone'}
                        values={timezones}
                        labels={timezones}
                    />
                </View>
                <View mt={20}>
                    <TextInput
                        name="name"
                        control={control}
                        label="Location name"
                        placeholder="Type location name..."
                    />
                </View>

                <Button mt={50} onClick={onLocationCreate} disabled={!name} loading={loading}>
                    Create
                </Button>
            </View>
        </View>
    );
};
