import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Heading, View } from '../../../components/atoms';
import { TextInput } from '../../../components/molecules/form';
import { UserInput, useUserUpdateMutation } from '../../../service';

type Props = {
    onDone?: () => void;
    user: any;
};
export const EditUsernameForm: FC<Props> = ({ onDone, user }) => {
    const { control, handleSubmit, reset } = useForm<UserInput>({
        defaultValues: {
            name: user.name,
        },
    });

    const [updateUser, { loading: userUpdateLoading }] = useUserUpdateMutation();

    const submit = handleSubmit(async (data) => {
        const { name } = data;

        await updateUser({
            variables: {
                input: {
                    name,
                    userId: user.id,
                },
            },
        });
        reset();
        onDone && onDone();
    });

    return (
        <UserEditFormWrapper style={{ minHeight: 250 }}>
            <Heading>Edit User Full Name</Heading>
            <View mt={20}>
                <TextInput
                    placeholder="Full name"
                    label="Full Name"
                    name="name"
                    control={control}
                />
            </View>
            <Button
                mt={30}
                mb={20}
                onClick={() => !userUpdateLoading && submit()}
                loading={userUpdateLoading}
            >
                Update
            </Button>
        </UserEditFormWrapper>
    );
};
const UserEditFormWrapper = styled(View)`
    padding: 20px;
`;
