import React, { FC, useState } from 'react';

import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon } from '../../../assets/icons';
import { Button, Heading, LinkButton, Row, Text, View, Column } from '../../../components/atoms';
import { TimeInput, Stepper } from '../../../components/molecules/form';
import { UserCard } from '../../../components/organisms';
import { useUser } from '../../../providers';
import {
    BookingResponse,
    BookingStatus,
    Maybe,
    Role,
    useAdjustEForceBookingSessionMutation,
    useApproveEForceBookingSessionMutation,
    useCompleteTimeTrackingMutation,
    usePauseTimeTrackingMutation,
    useResumeTimeTrackingMutation,
    useStartTimeTrackingMutation,
} from '../../../service';

type Props = {
    booking: Maybe<DeepPartial<BookingResponse>>;
    onDone?: () => void;
};
export const EForceBookingOverview: FC<Props> = ({ booking, onDone }) => {
    const { currentRole, serviceLocationBoundTimezone: timezone } = useUser();

    const [isEditing, setIsEditing] = useState(false);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            startTime: booking?.timeTrackingSummary?.started,
            endTime: booking?.timeTrackingSummary?.finished,
            breaks: booking?.timeTrackingSummary?.breaksMinutes,
        },
    });

    const [adjust, { loading: adjustmentLoading }] = useAdjustEForceBookingSessionMutation();

    const timeTrackingMutationOptions = {
        variables: { bookingId: booking?.id || '' },
        refetchQueries: ['GetEforceAssociateBookings', 'GetEforceComBookings'],
    };
    const [startTracking, { loading: startTrackingLoading }] = useStartTimeTrackingMutation(
        timeTrackingMutationOptions,
    );
    const [pauseTracking, { loading: pauseTrackingLoading }] = usePauseTimeTrackingMutation(
        timeTrackingMutationOptions,
    );
    const [resumeTracking, { loading: resumeTrackingLoading }] = useResumeTimeTrackingMutation(
        timeTrackingMutationOptions,
    );
    const [completeTracking, { loading: completeTrackingLoading }] =
        useCompleteTimeTrackingMutation(timeTrackingMutationOptions);

    const [approveBooking, { loading: approvalLoading }] = useApproveEForceBookingSessionMutation(
        timeTrackingMutationOptions,
    );

    const attemptAdjustment = handleSubmit(async (data) => {
        if (booking) {
            await adjust({
                variables: {
                    input: {
                        bookingId: booking.id!,
                        started: formatInTimeZone(
                            data.startTime,
                            timezone,
                            "yyyy-MM-dd'T'HH:mm:ssXXX",
                        ),
                        finished: formatInTimeZone(
                            data.endTime,
                            timezone,
                            "yyyy-MM-dd'T'HH:mm:ssXXX",
                        ),
                        breaks: data.breaks || 0,
                    },
                },
                refetchQueries: ['GetEforceAssociateBookings', 'GetEforceComBookings'],
            });
            setIsEditing(false);
        }
    });

    return (
        <View px={[20, 40, 40]} pt={[20, 40]} pb={30}>
            {isEditing ? (
                <>
                    <Row alignItems={'center'} mb={10} ml={-5} onClick={() => setIsEditing(false)}>
                        <LeftArrowIcon width={20} height={18} />
                        <LinkButton>Back</LinkButton>
                    </Row>
                    <Heading>Adjust session</Heading>
                    <Text mt={5} fontSize={'medium'} mb={20}>
                        Adjust your working time on this request
                    </Text>
                    <Column justifyContent={'space-between'}>
                        <View mb={15}>
                            <Text fontSize={'small'} fontWeight={'bold'} mb={3}>
                                Start time
                            </Text>
                            <TimeInput
                                control={control}
                                name={'startTime'}
                                timezone={timezone}
                                format={"yyyy-MM-dd'T'HH:mm:ss.SSSXXX"}
                            />
                        </View>
                        <View mb={15}>
                            <Text fontSize={'small'} fontWeight={'bold'} mb={3}>
                                End time
                            </Text>
                            <TimeInput
                                control={control}
                                name={'endTime'}
                                timezone={timezone}
                                format={"yyyy-MM-dd'T'HH:mm:ss.SSSXXX"}
                            />
                        </View>
                    </Column>
                    <View mt={10}>
                        <Text fontSize={'small'} fontWeight={'bold'} mb={3}>
                            Breaks
                        </Text>
                        <Stepper
                            control={control}
                            name={'breaks'}
                            measurement={'min'}
                            upper={60}
                            step={5}
                        />
                    </View>
                    <Button mt={30} onClick={attemptAdjustment} loading={adjustmentLoading}>
                        Adjust booking
                    </Button>
                </>
            ) : (
                <>
                    <Heading>{booking?.company?.name}</Heading>
                    <Row mt={10}>
                        <Text fontWeight="bold" fontSize={16} mr={3}>
                            {formatInTimeZone(
                                parseISO(booking?.slot?.startTime),
                                timezone,
                                'h:mm aa',
                            )}
                        </Text>
                        <Text fontWeight="bold" fontSize={16}>
                            -
                        </Text>
                        <Text fontWeight="bold" fontSize={16} ml={3}>
                            {formatInTimeZone(
                                parseISO(booking?.slot?.endTime),
                                timezone,
                                'h:mm aa',
                            )}
                        </Text>
                        <StatusText ml={15}>{booking?.status}</StatusText>
                    </Row>
                    <Text mt={10} fontSize={'medium'}>
                        {booking?.description}
                    </Text>
                    <View>
                        <UserCard user={booking!.assignee!} />
                    </View>
                    {[BookingStatus.Completed, BookingStatus.Approved].includes(
                        booking?.status as BookingStatus,
                    ) && (
                        <ActualSessionView mt={10}>
                            <Text fontWeight="bold">Actual Session Time</Text>
                            <Row
                                mt={10}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                <Text fontSize={'medium'} color={'text.secondary'}>
                                    Start time:
                                </Text>
                                <Text fontSize={'medium'}>
                                    {formatInTimeZone(
                                        booking?.timeTrackingSummary?.started,
                                        timezone,
                                        'hh:mm aa',
                                    )}
                                </Text>
                            </Row>
                            <Row
                                mt={10}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                <Text fontSize={'medium'} color={'text.secondary'}>
                                    End time:
                                </Text>
                                <Text fontSize={'medium'}>
                                    {formatInTimeZone(
                                        booking?.timeTrackingSummary?.finished,
                                        timezone,
                                        'hh:mm aa',
                                    )}
                                </Text>
                            </Row>
                            <Row
                                mt={10}
                                mb={15}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                <Text fontSize={'medium'} color={'text.secondary'}>
                                    Breaks:
                                </Text>
                                <Text fontSize={'medium'}>
                                    {booking?.timeTrackingSummary?.breaksMinutes} minutes
                                </Text>
                            </Row>
                            <Divider />
                            <Row
                                mt={10}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                <Text fontSize={'medium'} color={'text.secondary'}>
                                    Total Billable Time
                                </Text>
                                <Text fontSize={'medium'} fontWeight={'bold'}>
                                    {booking?.timeTrackingSummary?.billableTimeMinutes} minutes
                                </Text>
                            </Row>
                        </ActualSessionView>
                    )}
                    <View mt={20}>
                        {booking?.status === BookingStatus.NotStarted && (
                            <Button onClick={startTracking} loading={startTrackingLoading}>
                                Clock in
                            </Button>
                        )}
                        {booking?.status === BookingStatus.InProgress && (
                            <Row>
                                <Button
                                    flex={2}
                                    mr={5}
                                    onClick={completeTracking}
                                    loading={completeTrackingLoading}
                                >
                                    Complete work
                                </Button>
                                <Button
                                    ml={5}
                                    variant={'outlined'}
                                    onClick={pauseTracking}
                                    loading={pauseTrackingLoading}
                                >
                                    Pause
                                </Button>
                            </Row>
                        )}
                        {booking?.status === BookingStatus.Paused && (
                            <Row>
                                <Button
                                    flex={2}
                                    mr={5}
                                    onClick={completeTracking}
                                    loading={completeTrackingLoading}
                                >
                                    Complete work
                                </Button>
                                <Button
                                    ml={5}
                                    variant={'dark'}
                                    onClick={resumeTracking}
                                    loading={resumeTrackingLoading}
                                >
                                    Resume
                                </Button>
                            </Row>
                        )}
                        {booking?.status === BookingStatus.Completed && (
                            <>
                                <Text fontWeight={'bold'} mt={40}>
                                    Adjust logged time
                                </Text>
                                <Text fontSize={'medium'} mb={10} mt={5}>
                                    You can adjust this booking as many time as you want until it is
                                    not approved by your manager.
                                </Text>
                                <Button
                                    mt={30}
                                    variant={'outlined'}
                                    onClick={() => setIsEditing(true)}
                                >
                                    Adjust
                                </Button>
                            </>
                        )}

                        {booking?.status === BookingStatus.Completed && currentRole === Role.Com && (
                            <>
                                <Text fontWeight={'bold'} mt={40}>
                                    Approve this booking
                                </Text>
                                <Text fontSize={'medium'} mb={10} mt={5}>
                                    If everything looks ok, check things once again 😉 and press
                                    approve.
                                </Text>
                                <Button
                                    mt={30}
                                    variant={'regular'}
                                    onClick={async () => {
                                        await approveBooking();
                                        onDone && onDone();
                                    }}
                                    loading={approvalLoading}
                                >
                                    Approve
                                </Button>
                            </>
                        )}
                    </View>
                </>
            )}
        </View>
    );
};

const StatusText = styled(Text)`
    font-size: 8px;
    font-weight: bold;
    line-height: 8px;
    padding: 4px 10px;
    text-transform: uppercase;
    border-radius: 8px;
    color: #ffffff;
    background-color: #000000;
`;

const ActualSessionView = styled(View)`
    background-color: #f9f8f6;
    border-radius: 10px;
    padding: 15px;
`;

const Divider = styled(View)`
    background-color: #505354;
    height: 1px;
    width: 100%;
    margin: 2px 0;
    opacity: 0.1;
`;
