import React, { ReactNode, useImperativeHandle, useRef } from 'react';

import styled from 'styled-components';

import { CloseIcon } from '../../assets/icons';
import { Button, Heading, LinkButton, Row, Text, View } from '../atoms';
import { BottomSheet, BottomSheetRef } from './BottomSheet';

type Props = {
    heading: string;
    subheading: string;
    loading: boolean;
    primaryButtonDisabled?: boolean;
    children?: ReactNode;
    onConfirm: () => void;
};

export const DangerModal = React.forwardRef<BottomSheetRef, Props>(
    ({ heading, subheading, children, loading, primaryButtonDisabled, onConfirm }, ref) => {
        const bottomSheetRef = useRef<BottomSheetRef>(null);

        useImperativeHandle(ref, () => ({
            show: () => bottomSheetRef.current?.show(),
            hide: () => bottomSheetRef.current?.hide(),
        }));

        return (
            <BottomSheet ref={bottomSheetRef}>
                <View py={30} px={30}>
                    <Row mb={10} justifyContent={'flex-end'}>
                        <LinkButton onClick={() => bottomSheetRef.current?.hide()}>
                            <CloseIcon width={20} height={20} />
                        </LinkButton>
                    </Row>
                    <Heading>{heading}</Heading>
                    <Text my={10}>{subheading}</Text>

                    <View mt={20}>{children}</View>

                    <ButtonsWrapper mt={50} mb={10}>
                        <Button variant={'outlined'} onClick={() => bottomSheetRef.current?.hide()}>
                            NO
                        </Button>
                        <Button
                            variant={'danger'}
                            disabled={primaryButtonDisabled}
                            loading={loading}
                            onClick={onConfirm}
                        >
                            YES
                        </Button>
                    </ButtonsWrapper>
                </View>
            </BottomSheet>
        );
    },
);

DangerModal.displayName = 'Modal';

const ButtonsWrapper = styled(Row)`
    gap: 20px;
`;
