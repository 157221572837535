import React, { useEffect, useRef } from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { RoundPlusIcon } from '../../../../assets/icons';
import { Heading, LinkButton, Row, Text, View } from '../../../../components/atoms';
import { Tab, Tabs } from '../../../../components/molecules/Tabs';
import { BottomSheet, BottomSheetRef } from '../../../../components/organisms';
import { EForceBookingForm } from '../../components/EForceBookingForm';
import { EForceCompleted } from './EForceCompleted';
import { EForceScheduled } from './EForceScheduled';

export const EForce = () => {
    return (
        <Routes>
            <Route element={<EForceMemberWrapper />}>
                <Route index element={<EForceScheduled />} />
                <Route path={'scheduled'} element={<EForceScheduled />} />
                <Route path={'completed'} element={<EForceCompleted />} />
            </Route>
        </Routes>
    );
};

const EForceMemberWrapper = () => {
    const bottomSheetRef = useRef<BottomSheetRef>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/eforce') {
            navigate('/eforce/scheduled');
        }
    }, [location]);

    return (
        <View>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <MobileView>
                    <Heading mb={5}>eForce</Heading>
                </MobileView>
                <BrowserView>
                    <Text mb={5} color={'text.secondary'}>
                        eForce
                    </Text>
                </BrowserView>
                <Row alignItems={'center'} justifyContent={'center'}>
                    <LinkButton
                        icon={<RoundPlusIcon />}
                        onClick={() => bottomSheetRef?.current?.show()}
                    >
                        Add
                    </LinkButton>
                </Row>
            </Row>

            <MobileView>
                <Tabs mt={20}>
                    <Tab to={'scheduled'}>Scheduled</Tab>
                    <Tab to={'completed'}>Completed</Tab>
                    <Tab to={'reports'}>Reports</Tab>
                </Tabs>
            </MobileView>
            <Outlet />
            <BottomSheet ref={bottomSheetRef}>
                <EForceBookingForm
                    onDone={() => {
                        bottomSheetRef.current?.hide();
                    }}
                />
            </BottomSheet>
        </View>
    );
};
