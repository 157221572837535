import React, { FC } from 'react';

import styled from 'styled-components';

import { SearchIcon } from '../../assets/icons';
import { View } from '../atoms';
import { TextInput, TextInputProps } from './form';

type Props = TextInputProps;
export const SearchInput: FC<Props> = (props) => {
    return (
        <SearchWrapper>
            <SearchInputIcon />
            <SearchTextInput {...props}></SearchTextInput>
        </SearchWrapper>
    );
};

const SearchWrapper = styled(View)`
    position: relative;
`;

const SearchInputIcon = styled(SearchIcon)`
    position: absolute;
    left: 20px;
    top: 15px;
`;

const SearchTextInput = styled(TextInput)`
    height: 45px;
    border-radius: 23px;
    box-shadow: 0px 0px 6px #00000012;
    padding: 15px 15px 15px 50px;
    border-width: 0;
    font-size: 14px;
    color: #505354;
`;
