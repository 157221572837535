import React from 'react';

import { useUser } from '../../providers';
import { Role } from '../../service';
import { EForce as AssociateEForce } from './view/associate/EForce';
import { EForce as ComEForce } from './view/com/EForce';
import { EForce as MemberEForce } from './view/member/EForce';

export const EForceModule = () => {
    const { currentRole } = useUser();

    switch (currentRole) {
        case Role.Member:
            return <MemberEForce />;
        case Role.Associate:
            return <AssociateEForce />;
        case Role.Com:
            return <ComEForce />;
        default:
            return null;
    }
};
