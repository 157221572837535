import React, { FC, useCallback, useRef } from 'react';

import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Button, Heading, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef, ContextMenu } from '../../../components/organisms';
import { useUser } from '../../../providers';
import {
    Role,
    StorageItem,
    useCancelStorageRequestMutation,
    useMarkStorageItemAsStoredMutation,
} from '../../../service';
import { FlexStorageForm } from './FlexStorageForm';
import { FlexStorageImage } from './FlexStorageImage';
import { StoreButton } from './StoreButton';
type Props = {
    flexStorageItem: StorageItem;
};
export const FlexStorageStoreRequestCard: FC<Props> = ({ flexStorageItem }) => {
    const { currentRole: role } = useUser();
    // cancel storage request
    const cancelBookingBottomSheetRef = useRef<BottomSheetRef>(null);
    const [cancelRequest, { loading: cancelRequestLoading }] = useCancelStorageRequestMutation();

    const attemptCancelRequest = async () => {
        if (flexStorageItem && flexStorageItem.id) {
            await cancelRequest({
                variables: { id: flexStorageItem.id },
                refetchQueries: ['getFlexStorageItems'],
            });
            cancelBookingBottomSheetRef.current?.hide();
        }
    };

    // edit storage item
    const editBottomSheetRef = useRef<BottomSheetRef>(null);

    // mark stored
    const markStoredBottomSheetRef = useRef<BottomSheetRef>(null);
    const [markStored, { loading: markStoredLoading }] = useMarkStorageItemAsStoredMutation();
    const attemptMarkStored = async () => {
        if (flexStorageItem && flexStorageItem.id) {
            await markStored({
                variables: { id: flexStorageItem.id },
                refetchQueries: ['getFlexStorageItems'],
            });
            markStoredBottomSheetRef.current?.hide();
        }
    };

    const renderButton = useCallback(() => {
        switch (role) {
            case Role.Com:
            case Role.Associate:
                return (
                    <StoreButton onClick={() => markStoredBottomSheetRef.current?.show()}>
                        Mark Stored
                    </StoreButton>
                );
            default:
                return null;
        }
    }, [role]);

    return (
        <Row mb={10} height={[132, 132, 160]}>
            <FlexStorageImage
                state={flexStorageItem?.state}
                imageUrl={flexStorageItem?.photo?.url || ''}
            />
            <RequestDetails>
                <div>
                    <Text fontSize={'medium'} color={'text.secondary'}>
                        {flexStorageItem.company?.name}
                    </Text>
                    <Text fontSize={'medium'} fontWeight={'bold'}>
                        {flexStorageItem.description}
                    </Text>
                </div>

                <Row alignItems={'flex-end'} justifyContent={'space-between'}>
                    <DateText fontSize={'small'}>
                        {format(parseISO(flexStorageItem?.created?.at), 'MMM dd, yyyy')}
                    </DateText>
                    {renderButton()}
                </Row>
                <ContextMenu
                    options={['Edit', 'Cancel']}
                    onOptionSelected={(option) => {
                        if (option === 'Cancel') {
                            cancelBookingBottomSheetRef.current?.show();
                        }
                        if (option === 'Edit') {
                            editBottomSheetRef.current?.show();
                        }
                    }}
                />
            </RequestDetails>
            <BottomSheet ref={markStoredBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Mark flex-storage as stored</Heading>
                    <Text mt={5} mb={30}>
                        Are you sure you want to mark this storage as stored?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => markStoredBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptMarkStored()}
                            loading={markStoredLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
            <BottomSheet ref={cancelBookingBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Cancel flex-storage request</Heading>
                    <Text mt={5} mb={30}>
                        Are you sure you want to cancel this storage request?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => cancelBookingBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptCancelRequest()}
                            loading={cancelRequestLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
            <BottomSheet ref={editBottomSheetRef}>
                <FlexStorageForm
                    storageItem={flexStorageItem}
                    onClose={() => editBottomSheetRef.current?.hide()}
                />
            </BottomSheet>
        </Row>
    );
};

const DateText = styled(Text)`
    color: #505354;
    flex: 1;
`;

const RequestDetails = styled(View)`
    position: relative;
    flex: 1;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 14px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 10px 6px #00000012;
`;
