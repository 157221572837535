import React, { FC } from 'react';

import {
    ApolloClient,
    ApolloProvider as StockApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { configuration } from '../configuration';
import { ACCESS_TOKEN } from './SessionProvider';

type ApolloProviderProps = {
    children?: React.ReactNode;
};
export const ApolloProvider: FC<ApolloProviderProps> = ({ children }) => {
    const httpLink = createHttpLink(configuration.apollo);
    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

    return <StockApolloProvider client={client}>{children}</StockApolloProvider>;
};
