import React, { FC, useCallback, useRef } from 'react';

import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Button, Heading, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { StorageItem, StorageState, useRequestStorageReturnMutation } from '../../../service';
import { FlexStorageImage } from './FlexStorageImage';
import { ReturnButton } from './ReturnButton';
type Props = {
    inContextOf?: string;
    flexStorageItem: StorageItem;
};
export const FlexStorageStoredCard: FC<Props> = ({ inContextOf = '', flexStorageItem }) => {
    // request return
    const requestReturnBottomSheetRef = useRef<BottomSheetRef>(null);
    const [requestReturn, { loading: requestReturnLoading }] = useRequestStorageReturnMutation();

    const attemptRequestReturn = async () => {
        if (flexStorageItem && flexStorageItem.id) {
            await requestReturn({
                variables: { id: flexStorageItem.id },
                refetchQueries: ['getFlexStorageItems'],
            });
            requestReturnBottomSheetRef.current?.hide();
        }
    };

    const renderButton = useCallback(() => {
        if (flexStorageItem.state == StorageState.Stored) {
            return (
                <ReturnButton onClick={() => requestReturnBottomSheetRef.current?.show()}>
                    Initiate Return
                </ReturnButton>
            );
        }
        return null;
    }, [flexStorageItem]);

    return (
        <Row mb={10} height={[132, 132, 160]}>
            <FlexStorageImage
                inContextOf={inContextOf}
                price={flexStorageItem?.cost?.amount || 0}
                state={flexStorageItem?.state}
                imageUrl={flexStorageItem?.photo?.url || ''}
            />
            <RequestDetails>
                <div>
                    <Text fontSize={'medium'} color={'text.secondary'}>
                        {flexStorageItem.company?.name}
                    </Text>
                    <Text fontSize={'medium'} fontWeight={'bold'}>
                        {flexStorageItem.description}
                    </Text>
                </div>
                <Row alignItems={'flex-end'} justifyContent={'space-between'}>
                    <DateText fontSize={'small'}>
                        {format(parseISO(flexStorageItem?.created?.at), 'MMM dd, yyyy')}
                    </DateText>
                    {renderButton()}
                </Row>
            </RequestDetails>
            <BottomSheet ref={requestReturnBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Initiate flex-storage return</Heading>
                    <Text mt={5} mb={30}>
                        Are you sure you want to return this storage?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => requestReturnBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptRequestReturn()}
                            loading={requestReturnLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
        </Row>
    );
};

const DateText = styled(Text)`
    color: #505354;
    flex: 1;
`;

const RequestDetails = styled(View)`
    position: relative;
    flex: 1;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 14px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 10px 6px #00000012;
`;
