import React, { FC, useRef } from 'react';

import { differenceInHours, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import styled from 'styled-components';

import { InfoIcon } from '../../../../assets/icons';
import { Button, Heading, Row, Text, View } from '../../../../components/atoms';
import { Avatar } from '../../../../components/molecules/Avatar';
import { BottomSheet, BottomSheetRef, ContextMenu } from '../../../../components/organisms';
import { useCancelEForceBookingMutation } from '../../../../service';
import { DateBox } from '../DateBox';
import { BlockedTimeCardProps } from './BlockedTimeCardMobile';

const REASON_OTHER = 'Other';

export const BlockedTimeCardBrowser: FC<BlockedTimeCardProps> = ({
    booking,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
    const cancelBookingBottomSheetRef = useRef<BottomSheetRef>(null);

    const [cancelBooking, { loading: cancellationLoading }] = useCancelEForceBookingMutation();

    const attemptBookingCancellation = async () => {
        if (booking) {
            await cancelBooking({
                variables: { bookingId: booking.id! },
                refetchQueries: [
                    'GetEforceMemberBookings',
                    'GetEforceAssociateBookings',
                    'GetEforceComBookings',
                ],
            });
            cancelBookingBottomSheetRef.current?.hide();
        }
    };
    return (
        <>
            <Row width={'100%'}>
                <DateBox date={booking.slot?.startTime} />
                <Card minHeight="75px">
                    <Row flex="3">
                        <Text fontSize={'medium'}>
                            Blocked Time for {booking.reason?.replace('_', ' ')}{' '}
                            {booking.reason === REASON_OTHER && <>- {booking.description}</>}
                        </Text>
                    </Row>
                    <Row flex="0.25" height="100%">
                        <Line />
                    </Row>
                    <Row flex="2" alignItems={'center'}>
                        <Avatar size={35} user={booking.assignee} />
                        <Text fontWeight={'bold'} ml={10}>
                            {booking.assignee?.name}
                        </Text>
                    </Row>
                    <Row flex="1" justifyContent={'center'}>
                        <Text fontSize={'medium'}>
                            From:{' '}
                            {formatInTimeZone(
                                parseISO(booking?.slot?.startTime),
                                timezone,
                                'h:mm aa',
                            )}
                        </Text>
                    </Row>
                    <Row flex="1" justifyContent={'center'}>
                        <Text fontSize={'medium'}>
                            {differenceInHours(
                                parseISO(booking?.slot?.endTime),
                                parseISO(booking?.slot?.startTime),
                            )}
                            h
                        </Text>
                    </Row>
                    <ContextMenu
                        options={['Cancel']}
                        onOptionSelected={(option) => {
                            if (option === 'Cancel') {
                                cancelBookingBottomSheetRef.current?.show();
                            }
                        }}
                    />
                </Card>
            </Row>

            <BottomSheet ref={cancelBookingBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Cancel blocked time?</Heading>

                    <NoteWrapper>
                        <Row>
                            <InfoIcon />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                Canceling request will remove it permanently from the system. You
                                can always create a new one.
                            </Text>
                        </Row>
                    </NoteWrapper>
                    <Text mt={5} mb={30}>
                        Are you sure you want to cancel this blocked time?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => cancelBookingBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptBookingCancellation()}
                            loading={cancellationLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
        </>
    );
};

const Line = styled.div`
    width: 1px;
    height: 100%;
    background-color: #e3e3e3;
`;

const Card = styled(View)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-left: 10px;
    padding: 15px 20px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #ffeae0;
    box-shadow: 0 0 6px #00000012;
    position: relative;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;
