import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Heading, Row, View } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { Table } from '../../../../../components/molecules/Table';
import { BottomSheet, BottomSheetRef } from '../../../../../components/organisms';
import { useUser } from '../../../../../providers';
import {
    BookingResponse,
    ScheduleType,
    useGetEforceComBookingsQuery,
} from '../../../../../service';
import { DateUtils } from '../../../../../util/date';
import { EForceBookingOverview } from '../../../components/EForceBookingOverview';
import { MemberBookingCard } from '../../../components/MemberBookingCard';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const EForceCompleted: FC = () => {
    const { currentServiceLocation, serviceLocationBoundTimezone: timezone } = useUser();
    const [selectedBooking, setSelectedBooking] = useState<BookingResponse>();
    const bookingModalRef = useRef<BottomSheetRef>(null);

    const { data: bookingsData, loading } = useGetEforceComBookingsQuery({
        variables: {
            locationId: currentServiceLocation?.id,
            date: {
                from: format(
                    DateUtils.getBeginningOfCurrentMonth(),
                    EFORCE_BOOKING_FILTER_DATE_FORMAT,
                ),
                to: format(DateUtils.getEndOfCurrentMonth(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
            },
        },
    });

    useEffect(() => {
        if (selectedBooking) {
            bookingsData?.completed?.filter(Boolean).map((booking) => {
                if (booking && booking?.id === selectedBooking.id) {
                    setSelectedBooking(booking as BookingResponse);
                }
            });
        }
    }, [bookingsData]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Member',
                accessor: 'company.name',
            },
            {
                Header: 'Member specialist',
                accessor: 'assignee.name',
            },
            {
                Header: 'Requested start',
                accessor: (row: BookingResponse) =>
                    formatInTimeZone(row?.slot?.startTime, timezone, 'MM/dd hh:mm aa'),
            },
            {
                Header: 'Requested end',
                accessor: (row: BookingResponse) =>
                    formatInTimeZone(row?.slot?.endTime, timezone, 'MM/dd hh:mm aa'),
            },
            {
                Header: 'Actual start',
                accessor: (row: BookingResponse) =>
                    formatInTimeZone(row?.timeTrackingSummary?.started, timezone, 'MM/dd hh:mm aa'),
            },
            {
                Header: 'Actual end',
                accessor: (row: BookingResponse) =>
                    formatInTimeZone(
                        row?.timeTrackingSummary?.finished,
                        timezone,
                        'MM/dd hh:mm aa',
                    ),
            },
            {
                Header: 'Breaks',
                accessor: (row: BookingResponse) =>
                    ((row.timeTrackingSummary?.breaksMinutes || 0) / 60).toFixed(2),
            },
        ];
    }, []);

    const completedBookings =
        bookingsData?.completed?.filter(
            (booking) => booking && booking?.scheduleType === ScheduleType.Booking,
        ) || [];

    return (
        <View>
            <BrowserView>
                <Heading>Awaiting approval</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}
            <ScrollView flex={1} mt={30} display={['none', 'block']}>
                <Table
                    data={completedBookings}
                    columns={columns}
                    onRowClicked={(booking) => {
                        setSelectedBooking(booking);
                        bookingModalRef.current?.show();
                    }}
                />
            </ScrollView>

            <ScrollView flex={1} mt={30} display={['block', 'none']}>
                {completedBookings
                    .sort((a, b) => (a?.slot?.startTime > b?.slot?.startTime ? 1 : -1))
                    .map((booking) => (
                        <View
                            key={booking!.id}
                            mb={10}
                            onClick={() => {
                                setSelectedBooking(booking as BookingResponse);
                                bookingModalRef.current?.show();
                            }}
                        >
                            <MemberBookingCard booking={booking!} timezone={timezone} />
                        </View>
                    ))}
            </ScrollView>

            <BottomSheet variant="narrow" ref={bookingModalRef}>
                {selectedBooking && (
                    <EForceBookingOverview
                        booking={selectedBooking}
                        onDone={() => {
                            bookingModalRef.current?.hide();
                        }}
                    />
                )}
            </BottomSheet>
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    height: ${window.innerHeight - 150}px;
`;
