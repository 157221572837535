import React, { FC, useCallback, useRef } from 'react';

import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Button, Heading, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef, ContextMenu } from '../../../components/organisms';
import { useUser } from '../../../providers';
import {
    Role,
    StorageItem,
    useCancelStorageReturnRequestMutation,
    useMarkStorageItemAsReturnedMutation,
} from '../../../service';
import { FlexStorageImage } from './FlexStorageImage';
import { ReturnButton } from './ReturnButton';

type Props = {
    flexStorageItem: StorageItem;
};

export const FlexStorageReturnRequestCard: FC<Props> = ({ flexStorageItem }) => {
    const { currentRole: role } = useUser();
    // mark returned
    const markReturnedBottomSheetRef = useRef<BottomSheetRef>(null);
    const [markReturned, { loading: markReturnedLoading }] = useMarkStorageItemAsReturnedMutation();

    const attemptMarkReturned = async () => {
        if (flexStorageItem && flexStorageItem.id) {
            await markReturned({
                variables: { id: flexStorageItem.id },
                refetchQueries: ['getFlexStorageItems'],
            });
            markReturnedBottomSheetRef.current?.hide();
        }
    };

    // cancel return
    const cancelBookingBottomSheetRef = useRef<BottomSheetRef>(null);
    const [cancelRequest, { loading: cancelRequestLoading }] =
        useCancelStorageReturnRequestMutation();

    const attemptCancelRequest = async () => {
        if (flexStorageItem && flexStorageItem.id) {
            await cancelRequest({
                variables: { id: flexStorageItem.id },
                refetchQueries: ['getFlexStorageItems'],
            });
            cancelBookingBottomSheetRef.current?.hide();
        }
    };

    const renderButton = useCallback(() => {
        switch (role) {
            case Role.Com:
            case Role.Associate:
                return (
                    <ReturnButton onClick={() => markReturnedBottomSheetRef.current?.show()}>
                        Mark Returned
                    </ReturnButton>
                );
            default:
                return null;
        }
    }, [role]);

    return (
        <Row mb={10} height={[132, 132, 160]}>
            <FlexStorageImage
                state={flexStorageItem?.state}
                imageUrl={flexStorageItem?.photo?.url || ''}
            />
            <RequestDetails>
                <div>
                    <Text fontSize={'medium'} color={'text.secondary'}>
                        {flexStorageItem.company?.name}
                    </Text>
                    <ItemDescription>{flexStorageItem.description}</ItemDescription>
                </div>
                <Row alignItems={'flex-end'} justifyContent={'space-between'}>
                    <DateText fontSize={'small'}>
                        {format(parseISO(flexStorageItem?.created?.at), 'MMM dd, yyyy')}
                    </DateText>
                    {renderButton()}
                </Row>
                <ContextMenu
                    options={['Cancel']}
                    onOptionSelected={(option) => {
                        if (option === 'Cancel') {
                            cancelBookingBottomSheetRef.current?.show();
                        }
                    }}
                />
            </RequestDetails>
            <BottomSheet ref={markReturnedBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Mark flex-storage returned</Heading>
                    <Text mt={5} mb={30}>
                        Are you sure you want to return this storage?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => markReturnedBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptMarkReturned()}
                            loading={markReturnedLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
            <BottomSheet ref={cancelBookingBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Cancel flex-storage return request</Heading>
                    <Text mt={5} mb={30}>
                        Are you sure you want to cancel this return request?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => cancelBookingBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptCancelRequest()}
                            loading={cancelRequestLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
        </Row>
    );
};

const ItemDescription = styled(Text)`
    font-size: 12px;
    font-weight: bold;
`;

const DateText = styled(Text)`
    color: #505354;
    flex: 1;
`;

const RequestDetails = styled(View)`
    flex: 1;
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 14px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 10px 6px #00000012;
`;
