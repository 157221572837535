import React from 'react';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
    HouseIcon,
    InfoIcon,
    PeopleIcon,
    RightArrowIcon,
    TeamIcon,
} from '../../../../assets/icons';
import { Column, Heading, Row, Text, View } from '../../../../components/atoms';
import { useUser } from '../../../../providers';
import { useGetEforceCompletedBookingsPerLocationQuery } from '../../../../service';
import { DateUtils } from '../../../../util/date';
import { BuildingAccess } from '../../components/BuildingAccess/BuildingAccess';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const Dashboard = () => {
    const navigate = useNavigate();
    const { currentServiceLocation } = useUser();
    const { data: bookingsData } = useGetEforceCompletedBookingsPerLocationQuery({
        variables: {
            locationId: currentServiceLocation?.id,
            date: {
                from: format(
                    DateUtils.getBeginningOfCurrentMonth(),
                    EFORCE_BOOKING_FILTER_DATE_FORMAT,
                ),
                to: format(DateUtils.getEndOfCurrentMonth(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
            },
        },
    });
    return (
        <Column>
            <BuildingAccess />
            <Heading mt={[27, 37, 37]}>eForce Overview</Heading>
            <QuickAccessCard mt={15} onClick={() => navigate('/eforce/completed')}>
                <Row
                    justifyContent={'space-between'}
                    alignItems="center"
                    width={'100%'}
                    pl={0}
                    py={10}
                    pr={10}
                >
                    <Row alignItems="center" pl={1}>
                        <InfoIcon />
                        <Text ml={10} mr={10}>
                            You have {bookingsData?.bookings?.length} bookings awaiting approval
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            <Heading mt={[40, 50, 50]}>Quick Actions</Heading>
            <QuickAccessCard mt={[15, 20, 20]} onClick={() => navigate('/eforce/scheduled')}>
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center" pl={1}>
                        <PeopleIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Book eForce for members
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            <QuickAccessCard mt={[5, 10, 10]} onClick={() => navigate('/flex-storage/requests')}>
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center" pl={1}>
                        <HouseIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Reserve Flex Storage for members
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            {/*TODO add proper link for Team page*/}
            <QuickAccessCard
                mt={[5, 10, 10]}
                onClick={() => navigate('/team-management/associates')}
            >
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center">
                        <TeamIcon />
                        <Text ml={10} mr={10}>
                            Manage Saltbox Team
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
        </Column>
    );
};

const QuickAccessCard = styled(Row)`
    min-height: 50px;
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    align-items: center;
    cursor: pointer;
`;
