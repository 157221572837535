import React, { FC, useMemo, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, View, Row } from '../../../components/atoms';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { SearchInput } from '../../../components/molecules/SearchInput';
import { Table } from '../../../components/molecules/Table';
import { Company, useMemberCompaniesQuery } from '../../../service';
import { CompanyInfoCard } from '../components/CompanyInfoCard';

export const MemberManagementCompanies: FC = () => {
    const { data: companyData, loading } = useMemberCompaniesQuery();

    const navigate = useNavigate();
    const [query, setQuery] = useState<string>('');

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Locations',
                accessor: (row: Company) => {
                    return (
                        row.locations?.reduce((out, current) => {
                            return out + (out !== '' ? ', ' : '') + current?.name;
                        }, '') || '/'
                    );
                },
            },
        ];
    }, []);

    const notDeletedCompanies = companyData?.companies.filter(
        (company) => company?.state !== 'DELETED',
    );

    const filteredCompanies = !query
        ? notDeletedCompanies
        : notDeletedCompanies?.filter((company) =>
              company?.name.toLowerCase().includes(query.toLowerCase()),
          );

    return (
        <View>
            <Heading>Companies</Heading>

            <View mt={30}>
                <SearchInput
                    placeholder="Search for companies..."
                    onInput={(event: React.FormEvent<HTMLInputElement>) =>
                        setQuery(event.currentTarget.value)
                    }
                />
            </View>

            {loading ? (
                <Row mt={20} justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : (
                <>
                    <ScrollView flex={1} mt={30} display={['none', 'block']}>
                        <Table
                            data={filteredCompanies || []}
                            columns={columns}
                            onRowClicked={(company) => {
                                navigate('/member-management/companies/' + company?.id);
                            }}
                        />
                    </ScrollView>

                    <ScrollView flex={1} mt={30} display={['block', 'none']}>
                        {filteredCompanies?.map((company) => (
                            <StyledLink
                                key={company?.id}
                                to={'/member-management/companies/' + company?.id}
                            >
                                <View mb={10}>
                                    <CompanyInfoCard company={company!} />
                                </View>
                            </StyledLink>
                        ))}
                    </ScrollView>
                </>
            )}
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 250}px;
`;

const StyledLink = styled(NavLink)`
    color: black;
    text-decoration: none;
`;
