import React, { useCallback } from 'react';

import { useUser } from '../../providers';
import { Role } from '../../service';
import { MemberManagementOverview } from './view/Overview';

export const MemberManagementModule = () => {
    const { currentRole } = useUser();

    const renderContent = useCallback(() => {
        if (currentRole) {
            switch (currentRole) {
                case Role.Member:
                case Role.Associate:
                    return <></>;
                case Role.Com:
                case Role.Admin:
                    return <MemberManagementOverview></MemberManagementOverview>;
            }
        }
        return null;
    }, [currentRole]);

    return renderContent();
};
