import React, { FC } from 'react';

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import { RightArrowIcon } from '../../../assets/icons';
import { Row, Text } from '../../../components/atoms';

export const LogoutButton: FC = () => {
    const { logout } = useAuth0();
    const apolloClient = useApolloClient();

    const onLogout = async () => {
        await apolloClient.clearStore();
        await logout({ returnTo: window.location.origin.toString() });
        localStorage.clear();
    };
    return (
        <Wrapper onClick={() => onLogout()}>
            <Row alignItems={'center'}>
                <Text>Sign out</Text>
            </Row>
            <RightArrowIcon color={'#D25A1B'} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: black;
    ${Text} {
        color: #d25a1b;
        font-size: 12px;
    }

    &.active {
        background-color: #f9f8f6;
        ${Text} {
            font-weight: bold;
        }
    }

    &:hover {
        background-color: #f9f8f6;
    }
`;
