import React, { FC, useEffect, useState } from 'react';

import { parse } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { BaseInputProps } from './BaseInputProps';
import { ControlledInputProps } from './ControlledInputProps';

const TIME_FORMAT = 'HH:mm';
const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm";

export enum TimeInputType {
    DateTime = 'datetime-local',
    Time = 'time',
}

type Props = BaseInputProps<Date | string> & {
    type?: TimeInputType;
    timezone?: string;
    format?: string;
};
const TimeInputComponent: FC<Props> = ({
    value,
    type = TimeInputType.DateTime,
    format = DATETIME_FORMAT,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    onChange,
}) => {
    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
        if (typeof value === 'string') {
            setDate(parse(value, format, new Date()));
        } else {
            setDate(value || new Date());
        }
    }, [value]);

    useEffect(() => {
        onChange && onChange(date);
    }, [date]);

    const convertToTimeZone = (date: Date) => {
        return new Date(
            date.getTime() + date.getTimezoneOffset() * 60 * 1000 + getTimezoneOffset(timezone),
        );
    };

    const revertFromTimeZone = (date: Date) => {
        return new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000 - getTimezoneOffset(timezone),
        );
    };

    const defaultFormat = type === TimeInputType.Time ? TIME_FORMAT : DATETIME_FORMAT;

    return (
        <>
            <TimeInputField
                type={type}
                value={formatInTimeZone(date, timezone, defaultFormat)}
                onChange={(event) => {
                    setDate(
                        revertFromTimeZone(parse(event.target.value, defaultFormat, new Date())),
                    );
                }}
            />
        </>
    );
};

export const TimeInput: FC<Props & ControlledInputProps> = ({ control, name, ...rest }) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            render={({ field: { onChange, value } }) => (
                <TimeInputComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <TimeInputComponent {...rest} />
    );
};

const TimeInputField = styled.input`
    font-family: Inter, sans-serif;

    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 10px;
    position: relative;
`;
