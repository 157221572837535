import React, { createContext, FC, useEffect } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import FullStory, { identify } from 'react-fullstory';

import { configuration } from '../../configuration';
import { useUser } from '../UserProvider';

export type FullStoryContextType = Record<string, never>;

const FullStoryContext = createContext<FullStoryContextType>({} as FullStoryContextType);

type FullStoryProviderProps = {
    children?: React.ReactNode;
};

const { fullStory } = configuration;

function filterFeatures(features = {}) {
    return Object.entries(features)
        .filter((entry) => typeof entry[1] === 'boolean')
        .map((entry) => entry[0])
        .join(',');
}

export const FullStoryProvider: FC<FullStoryProviderProps> = ({ children }) => {
    const { user, currentRole } = useUser();
    const features = useFlags();
    const filteredFeatures = filterFeatures(features);

    useEffect(() => {
        if (fullStory.isEnabled && user?.id) {
            identify(user.id, {
                displayName: user.name!,
                email: user.email,
                currentRole,
                roles: user?.roles!.join(','),
                featureFlags: filteredFeatures,
            });
        }
    }, [user, currentRole, features]);

    return (
        <>
            {fullStory.isEnabled && <FullStory org={fullStory.orgId} debug={fullStory.isDebug} />}
            <FullStoryContext.Provider value={{}}>{children}</FullStoryContext.Provider>
        </>
    );
};
