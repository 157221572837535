import { Role } from '../service';

export const roleName = {
    COM: 'Manager',
    ASSOCIATE: 'Member Specialist',
    MEMBER: 'Member',
    ADMIN: 'Admin',
    CENTRAL: 'Central',
    NOBODY: 'Nobody',
};

export function rolesToString(roles: Role[]) {
    return roles.map((role) => roleName[role]).join(', ');
}

export function userFriendlyRoleName(role: Role): string {
    return roleName[role];
}
