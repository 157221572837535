import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format, parse, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { BrowserView, MobileView } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Row, View, Text, Heading, Button, Column } from '../../../../../components/atoms';
import { TextInput, Select } from '../../../../../components/molecules/form';
import { BottomSheet, BottomSheetRef } from '../../../../../components/organisms';
import { useUser } from '../../../../../providers';
import {
    BookingEmailReportInput,
    useGetEforceMemberBookingReportQuery,
    useSendEmailReportLazyQuery,
} from '../../../../../service';
import { DateUtils } from '../../../../../util/date';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';
const today = new Date();

export const EForceReports = () => {
    const emailFormBottomSheetRef = useRef<BottomSheetRef>(null);
    // Session
    const { user, currentServiceLocation } = useUser();
    const [sendReport, { loading: sendEmailReportLoading }] = useSendEmailReportLazyQuery();

    const shareFormModalHandleHideModal = useCallback(() => {
        emailFormBottomSheetRef.current?.hide();
    }, []);

    const months = useMemo(() => {
        const currentMonth = today.getMonth();
        return Array.from({ length: currentMonth }, (_, i) => i + 1);
    }, []);

    const [selectedMonth, setSelectedMonth] = useState(months[months.length - 1]);
    const { control, handleSubmit } = useForm<BookingEmailReportInput>({
        defaultValues: {
            receiver: user?.email || '',
        },
    });

    const { data: bookingData, refetch: refetchMemberBookingData } =
        useGetEforceMemberBookingReportQuery({
            variables: {
                companyId: user?.company?.id,
                date: {
                    from: format(
                        DateUtils.getBeginningOfMonth(selectedMonth, today.getFullYear()),
                        EFORCE_BOOKING_FILTER_DATE_FORMAT,
                    ),
                    to: format(
                        DateUtils.getEndOfMonth(selectedMonth, today.getFullYear()),
                        EFORCE_BOOKING_FILTER_DATE_FORMAT,
                    ),
                },
            },
        });
    useEffect(() => {
        refetchMemberBookingData();
    }, [selectedMonth]);

    const attemptSendEmailReportSubmit = handleSubmit(async (data) => {
        await sendReport({
            variables: {
                input: {
                    receiver: data.receiver || '',
                    from: format(
                        DateUtils.getBeginningOfMonth(selectedMonth, today.getFullYear()),
                        EFORCE_BOOKING_FILTER_DATE_FORMAT,
                    ),
                    to: format(
                        DateUtils.getEndOfMonth(selectedMonth, today.getFullYear()),
                        EFORCE_BOOKING_FILTER_DATE_FORMAT,
                    ),
                },
            },
        });
        shareFormModalHandleHideModal();
    });

    const sortedBookings = useMemo(() => {
        if (bookingData) {
            return bookingData?.bookings
                ?.filter(Boolean)
                .sort((a, b) =>
                    a?.timeTrackingSummary?.started > b?.timeTrackingSummary?.started ? 1 : -1,
                );
        }
        return [];
    }, [bookingData]);

    const totalNumberOfHours = useMemo(() => {
        if (bookingData?.bookings) {
            return bookingData.bookings?.reduce((p, c) => {
                return p + (c?.timeTrackingSummary?.billableTimeMinutes || 0) / 60;
            }, 0);
        }
        return 0;
    }, [bookingData]);

    return (
        <Column mb={10}>
            <BrowserView>
                <Heading>Reports</Heading>
            </BrowserView>
            <ReportsWrapper px={[15, 15, 25]} py={[20, 20, 25]}>
                <FlexContainer
                    minHeight={[55, 55, 0]}
                    alignItems={['flex-start', 'flex-start', 'center']}
                    justifyContent={'space-between'}
                    flexDirection={['column', 'column', 'row']}
                    mb={[10, 10, 30]}
                >
                    <Text fontWeight="bold" fontSize={16}>
                        eForce Booking Report for{' '}
                        {format(parse(selectedMonth.toString(), 'M', new Date()), 'MMM, yyyy')}
                    </Text>
                    <EmailReportButton onClick={() => emailFormBottomSheetRef.current?.show()}>
                        Share CSV to email
                    </EmailReportButton>
                </FlexContainer>
                <BrowserView>
                    <Row mb={20}>
                        {months.map((month) => (
                            <MonthButtonSelect
                                variant={month == selectedMonth ? 'active' : 'inactive'}
                                key={month}
                                fontSize={12}
                                onClick={() => setSelectedMonth(month)}
                            >
                                {format(parse(month.toString(), 'M', new Date()), 'MMM yyyy')}
                            </MonthButtonSelect>
                        ))}
                    </Row>
                </BrowserView>
                <MobileView>
                    <View mt={15} mb={10}>
                        <Select
                            name={'month'}
                            values={months}
                            onChange={(month) => setSelectedMonth(month)}
                            labels={months.map((month) => {
                                return format(parse(month.toString(), 'M', new Date()), 'MMM yyyy');
                            })}
                        />
                    </View>
                </MobileView>
                <View>
                    {sortedBookings?.length ? (
                        <View>
                            <TableRow pt={[10, 10, 15]} pl={10} pr={10} pb={2} alignItems="center">
                                <View width={100} flex={[2, 2, 3]}>
                                    <Text fontSize={12} color={'#A1A1A1'}>
                                        Date
                                    </Text>
                                </View>
                                <View alignItems="flex-end" flex={3}>
                                    <Text fontSize={12} color={'#A1A1A1'}>
                                        Member Specialist
                                    </Text>
                                </View>
                                <View alignItems="flex-end" flex={1}>
                                    <Text textAlign={'right'} fontSize={12} color={'#A1A1A1'}>
                                        Duration
                                    </Text>
                                </View>
                            </TableRow>
                            <ScrollView>
                                {sortedBookings?.map((bookingItem) => (
                                    <TableRow
                                        pt={[10, 10, 20]}
                                        pb={[10, 10, 20]}
                                        pl={10}
                                        pr={10}
                                        key={bookingItem?.id}
                                        alignItems="center"
                                    >
                                        <View flex={[2, 2, 3]}>
                                            <Text fontSize={12}>
                                                {formatInTimeZone(
                                                    parseISO(
                                                        bookingItem?.timeTrackingSummary?.started,
                                                    ),
                                                    currentServiceLocation?.zone || '',
                                                    'MM/dd',
                                                )}
                                            </Text>
                                        </View>
                                        <View alignItems="flex-end" flex={3}>
                                            <Text fontSize={12}>{bookingItem?.assignee?.name}</Text>
                                        </View>
                                        <View alignItems={'flex-end'} flex={1}>
                                            <Text textAlign={'right'} fontSize={12}>
                                                {(
                                                    (bookingItem?.timeTrackingSummary
                                                        ?.billableTimeMinutes || 0) / 60
                                                ).toFixed(2)}
                                                h
                                            </Text>
                                        </View>
                                    </TableRow>
                                ))}
                            </ScrollView>
                            <Row justifyContent="flex-end" mt={10} pl={10} pr={10}>
                                <Text fontWeight={'bold'} fontSize={14}>
                                    Total: {totalNumberOfHours?.toFixed(2)}h
                                </Text>
                            </Row>
                        </View>
                    ) : (
                        <Text color="text.secondary" mt={10} fontSize={12}>
                            There are no bookings matching the date range criteria
                        </Text>
                    )}
                </View>
                <BottomSheet ref={emailFormBottomSheetRef}>
                    <View py={[20, 40]} px={30}>
                        <Heading>Share to email</Heading>
                        <Text fontSize={12} mt={15} mb={15} color="text.secondary">
                            The CSV export of your data will be sent to this email address.
                        </Text>
                        <TextInput
                            placeholder="Email address"
                            control={control}
                            name={'receiver'}
                            autoCapitalize="none"
                        />
                        <Text fontSize={10} mt={5} mb={15} color="text.secondary">
                            Please make sure you have access to this email.
                        </Text>
                        <Button
                            mt={15}
                            mb={15}
                            onClick={() => attemptSendEmailReportSubmit()}
                            loading={sendEmailReportLoading}
                        >
                            Send to email
                        </Button>
                    </View>
                </BottomSheet>
            </ReportsWrapper>
        </Column>
    );
};

const FlexContainer = styled(View)`
    display: flex;
`;

const TableRow = styled(Row)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
`;

const ReportsWrapper = styled(View)`
    background: #ffffff;
    box-shadow: 0 0 6px #00000012;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const ScrollView = styled(View)`
    overflow: auto;
    max-height: ${window.innerHeight - 420}px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;

const EmailReportButton = styled(Text)`
    background: #f2f2f2;
    border-radius: 5px;
    color: #101010;
    font-size: 12px;
    padding: 5px 8px;
    cursor: pointer;
`;

type MonthButtonSelectProps = {
    variant: string;
};
const MonthButtonSelect = styled(Text)<MonthButtonSelectProps>`
    background-color: ${(props) => (props.variant === 'active' ? '#101010' : 'white')};
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    border-color: ${(props) => (props.variant === 'active' ? '#101010' : '#E3E3E3')};
    color: ${(props) => (props.variant === 'active' ? '#FFFFFF' : '#101010')};
    border-radius: 5px;
    margin-right: 5px;
    padding: 5px 10px;
`;
