import React from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { Page } from '../../../components/molecules/Page';
import { UserForm } from '../components/UserForm';
import { UserDetails } from './UserDetails';
import { UserManagement } from './UserManagement';

export const UserManagementOverview = () => {
    return (
        <Routes>
            <Route element={<UserManagementWrapper />}>
                <Route path={'users'} element={<UserManagement />} />
                <Route path={'users/:userId'} element={<UserDetails />} />
            </Route>
        </Routes>
    );
};

const UserManagementWrapper = () => {
    return (
        <Page title="User management" addForm={UserForm}>
            <Outlet />
        </Page>
    );
};
