import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { StorageIcon, UploadIcon } from '../../../assets/icons';
import { Button, View, Text, Heading } from '../../../components/atoms';
import { Select, TextArea } from '../../../components/molecules/form';
import { useFlag } from '../../../hooks';
import { useUser } from '../../../providers';
import {
    Maybe,
    Role,
    StorageItem,
    StorageRequestInput,
    useConfirmBinaryUploadMutation,
    useEditStorageRequestMutation,
    useGenerateUploadLinkMutation,
    useGetCompaniesForFlexStorageFormQuery,
    useRequestFlexStorageMutation,
} from '../../../service';

type Props = {
    onClose: () => void;
    storageItem?: Maybe<DeepPartial<StorageItem>>;
};

export const FlexStorageForm = ({ onClose, storageItem }: Props): React.ReactElement => {
    const { currentServiceLocation, currentRole: role, user } = useUser();
    const [previewImage, setPreviewImage] = useState<any>();
    const myRef = useRef<HTMLInputElement>(null);

    // GraphQL
    const { data: companiesData } = useGetCompaniesForFlexStorageFormQuery({
        variables: {
            filter: {
                locationIds: currentServiceLocation?.id ? [currentServiceLocation?.id] : [],
            },
        },
    });

    const [requestFlexStorage] = useRequestFlexStorageMutation();
    const [editFlexStorageRequest] = useEditStorageRequestMutation();
    const [confirmBinaryUpload] = useConfirmBinaryUploadMutation();

    const [storageImage, setStorageImage] = useState<File>();
    const [loading, showLoading, hideLoading] = useFlag(false);

    const { control, handleSubmit, watch, reset } = useForm<StorageRequestInput>({
        defaultValues: {
            locationId: currentServiceLocation?.id,
            companyId: user?.company?.id,
        },
    });

    const companyId = watch('companyId');

    useEffect(() => {
        if (storageItem) {
            reset({
                companyId: storageItem.company?.id,
                description: storageItem.description,
            });
        }
    }, [storageItem]);

    const [generateUploadLink] = useGenerateUploadLinkMutation();

    const uploadToAws = async (signedRequest: string, file: any) => {
        try {
            return fetch(signedRequest, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                },
                body: file,
            });
        } catch (error) {
            return null;
        }
    };

    const attemptStorageRequestSubmit = handleSubmit(async (data) => {
        showLoading();
        let photoId;

        if (storageImage) {
            const generatedLink = await generateUploadLink({
                variables: {
                    request: {
                        companyId,
                        fileName: 'storageItem.png',
                    },
                },
            });

            if (generatedLink.data?.generateUploadLink?.id) {
                await uploadToAws(generatedLink.data?.generateUploadLink?.uploadLink, storageImage);
                const binaryUploadConfirmation = await confirmBinaryUpload({
                    variables: {
                        uploadId: generatedLink.data?.generateUploadLink?.id,
                    },
                });

                photoId = binaryUploadConfirmation.data?.confirmBinaryUpload?.id;
            }
        }

        if (storageItem) {
            await editFlexStorageRequest({
                variables: {
                    request: {
                        id: storageItem.id!,
                        ...data,
                        photoId: photoId || storageItem.photo?.id,
                    },
                },
                refetchQueries: ['getFlexStorageItems'],
            });
        } else {
            await requestFlexStorage({
                variables: {
                    request: {
                        ...data,
                        photoId,
                    },
                },
                refetchQueries: ['getFlexStorageItems'],
            });
        }
        hideLoading();
        onClose();
    });

    const locationCompanies = useMemo(() => {
        if (companiesData) {
            return companiesData?.companies
                ?.filter(Boolean)
                ?.sort((a, b) => ((a?.name || '') > (b?.name || '') ? 1 : -1));
        }
        return [];
    }, [companiesData, user]);

    const previewStorageImage = (file: File | undefined) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
        }
    };

    return (
        <FormContainer>
            <Heading mb={10}>Request Flex Storage</Heading>
            <Text mb={10} fontSize="medium">
                Please describe the pallet you would like to store. We will use this information to
                retrieve it later.
            </Text>
            {role !== Role.Member && companiesData?.companies && (
                <Select
                    style={{ marginBottom: 15 }}
                    control={control}
                    name="companyId"
                    label="Choose a company"
                    values={locationCompanies.map((c) => c?.id) || []}
                    labels={locationCompanies.map((c) => c?.name || '') || []}
                />
            )}
            <TextArea
                label={'Palette description'}
                control={control}
                name={'description'}
                placeholder="Example - Dog bones for small pups. Pallet ID 12345"
            />
            <PhotoInput>
                <PhotoWrapper>
                    {previewImage ? (
                        <img src={previewImage} alt="Flex storage image" />
                    ) : (
                        <PhotoPlaceholder />
                    )}
                </PhotoWrapper>
                <PhotoInputDescription>
                    <Text>Optionally add a photo of your pallet to make it easier to find.</Text>
                    <input
                        ref={myRef}
                        style={{ display: 'none' }}
                        id="flexStorageImageInput"
                        type="file"
                        onChange={(event) => {
                            setStorageImage(event.target.files?.[0]);
                            previewStorageImage(event.target.files?.[0]);
                        }}
                    />
                    <UploadPhotoButton onClick={() => myRef.current?.click()}>
                        <UploadIcon style={{ marginRight: '8px' }} />
                        <Text>Upload Photo</Text>
                    </UploadPhotoButton>
                </PhotoInputDescription>
            </PhotoInput>
            <Button
                mt="2x"
                onClick={() => {
                    !loading && attemptStorageRequestSubmit();
                }}
                loading={loading}
            >
                Request storage
            </Button>
        </FormContainer>
    );
};

const FormContainer = styled(View)`
    margin-bottom: 40px;
    padding: 30px;
`;

const PhotoInput = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const PhotoPlaceholder = styled(StorageIcon)`
    width: 100%;
    height: 100%;
`;

const PhotoWrapper = styled(View)`
    width: 130px;
    height: 150px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: 10px;
    border: 1px solid #e3e3e3;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const UploadPhotoButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    background: #000000;
    color: #ffffff;
`;

const PhotoInputDescription = styled(View)`
    display: flex;
    flex: 1;
    margin-left: 15px;
    flex-direction: column;
    align-items: flex-start;
`;
