import React, { FC, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowBackIcon, EditIcon, RoundPlusIcon } from '../../../assets/icons';
import { Heading, View, LinkButton, Row } from '../../../components/atoms';
import { Text } from '../../../components/atoms';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { Door, useGetLocationQuery } from '../../../service';
import { Theme } from '../../../theme';
import { AddDoorForm } from '../components/AddDoorForm';
import { EditDoorForm } from '../components/EditDoorForm';

export const LocationDetails: FC = () => {
    const navigate = useNavigate();
    const { locationId } = useParams();
    const { data: locationData, loading } = useGetLocationQuery({
        variables: {
            locationId: locationId!,
        },
    });

    const addDoorBottomSheetRef = useRef<BottomSheetRef>(null);
    const editDoorBottomSheetRef = useRef<BottomSheetRef>(null);

    // Local state
    const [selectedDoor, setSelectedDoor] = useState<Door>();

    const location = locationData?.location ? locationData?.location : null;

    const onDoorItemEditPress = (doorItem: Door) => {
        setSelectedDoor(doorItem);
        editDoorBottomSheetRef.current?.show();
    };

    return (
        <Wrapper>
            <Header px={[20, 20, 90]} py={[0, 25, 25]}>
                <LinkButton onClick={() => navigate('/location-management/locations')}>
                    <>
                        <ArrowBack width={20} height={20} />
                        Back to location list
                    </>
                </LinkButton>
            </Header>
            <ContentWrapper
                borderRadius={['20px 20px 0px 0px', '64px 64px 0px 0px', '64px 64px 0px 0px']}
            >
                {loading ? (
                    <Row mt={20} justifyContent={'center'} alignItems={'center'}>
                        <LoadingIndicator variant={'medium'} />
                    </Row>
                ) : (
                    <Content px={[25, 25, 115]} py={[25, 25, 45]}>
                        <Heading mb={20}>{location?.name}</Heading>
                        <Text fontWeight={'bold'} fontSize={'medium'} mb={10}>
                            Location details
                        </Text>
                        <Divider />
                        <Text mb={20} mt={20} fontSize={'medium'}>
                            Timezone: {location?.zone}
                        </Text>
                        <Row justifyContent={'space-between'} alignItems={'center'} mb={10} mt={10}>
                            <Text>Doors:</Text>
                            <Row alignItems={'center'} justifyContent={'center'}>
                                <LinkButton
                                    icon={<RoundPlusIcon />}
                                    onClick={() => addDoorBottomSheetRef?.current?.show()}
                                >
                                    Add Door
                                </LinkButton>
                            </Row>
                        </Row>
                        <Divider />
                        <ScrollView mt={20}>
                            {location?.doors?.map((door) => (
                                <DoorBlock
                                    justifyContent={'space-between'}
                                    key={door?.id}
                                    mb={10}
                                    onClick={() => onDoorItemEditPress(door as Door)}
                                >
                                    <Text>{door?.name}</Text>
                                    <View>
                                        <EditIcon cursor={'pointer'} />
                                    </View>
                                </DoorBlock>
                            ))}
                        </ScrollView>
                        <BottomSheet ref={addDoorBottomSheetRef}>
                            <AddDoorForm
                                location={location}
                                onDone={() => addDoorBottomSheetRef.current?.hide()}
                            />
                        </BottomSheet>
                        <BottomSheet ref={editDoorBottomSheetRef}>
                            <EditDoorForm
                                door={selectedDoor}
                                onDone={() => editDoorBottomSheetRef.current?.hide()}
                            />
                        </BottomSheet>
                    </Content>
                )}
            </ContentWrapper>
        </Wrapper>
    );
};
const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 400}px;
`;

const Wrapper = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Theme.colors.background.accent};
`;

const Header = styled(View)`
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled(View)`
    background: ${Theme.colors.background.light};
    height: 100%;
`;

const Content = styled(View)`
    flex: 1;
    max-width: 1200px;
`;

const ArrowBack = styled(ArrowBackIcon)`
    margin-right: 5px;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 0;
`;

const DoorBlock = styled(Row)`
    border: 1px solid #e3e3e3;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
`;
