import React, { FC, useCallback, useMemo } from 'react';

import { BrowserView } from 'react-device-detect';
import styled from 'styled-components';

import { Heading, View, Column, Row } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../../providers';
import { StorageItem, StorageState, useGetFlexStorageItemsQuery } from '../../../../../service';
import { FlexStorageReturnRequestCard } from '../../../components/FlexStorageReturnRequestCard';
import { FlexStorageStoreRequestCard } from '../../../components/FlexStorageStoreRequestCard';

export const FlexStorageRequests: FC = () => {
    const { currentServiceLocation } = useUser();
    const { data, loading } = useGetFlexStorageItemsQuery({
        variables: {
            filter: {
                locations: currentServiceLocation ? [currentServiceLocation.id] : [],
                states: [StorageState.StorageRequested, StorageState.ReturnRequested],
            },
        },
    });

    const flexStorageItems: StorageItem[] = useMemo(() => {
        const items = data?.storageItems;
        if (items) {
            return items.filter(Boolean) as StorageItem[];
        }
        return [];
    }, [data]);

    const renderCard = useCallback(
        (flexStorageItem: StorageItem) => {
            switch (flexStorageItem.state) {
                case StorageState.ReturnRequested:
                    return (
                        <FlexStorageReturnRequestCard
                            key={flexStorageItem.id}
                            flexStorageItem={flexStorageItem}
                        />
                    );
                case StorageState.StorageRequested:
                    return (
                        <FlexStorageStoreRequestCard
                            key={flexStorageItem.id}
                            flexStorageItem={flexStorageItem}
                        />
                    );
                default:
                    return null;
            }
        },
        [data],
    );

    return (
        <Column>
            <BrowserView>
                <Heading mb={30}>Requests</Heading>
            </BrowserView>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}
            <FlexStorageItemList>
                {flexStorageItems.map((flexStorageItem) => renderCard(flexStorageItem))}
            </FlexStorageItemList>
        </Column>
    );
};

const FlexStorageItemList = styled(View)`
    overflow: auto;
    height: ${window.innerHeight - 150}px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;
