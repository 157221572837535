import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { View } from '../../atoms';
import { ControlledInputProps } from './ControlledInputProps';
import { Label } from './Label';

export type TextInputProps = {
    label?: string;
    rules?: object;
} & React.ComponentPropsWithoutRef<'input'>;
const TextInputComponent: FC<TextInputProps> = ({ label, rules, ...rest }) => {
    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <TextInputField type="text" {...rest} />
        </Wrapper>
    );
};

export const TextInput: FC<TextInputProps & ControlledInputProps> = ({
    control,
    name,
    rules,
    ...rest
}) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <TextInputComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <TextInputComponent {...rest} />
    );
};

const Wrapper = styled(View)``;

const TextInputField = styled.input`
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    font-family: 'Inter', serif;
    font-size: 15px;
    color: black;
    width: 100%;
`;
