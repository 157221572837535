import React, { useCallback } from 'react';

import { useUser } from '../../providers';
import { Role } from '../../service';
import { Dashboard as AdminDashboard } from './view/admin/Dashboard';
import { Dashboard as AssociateDashboard } from './view/associate/Dashboard';
import { Dashboard as CentralDashboard } from './view/central/Dashboard';
import { Dashboard as ComDashboard } from './view/com/Dashboard';
import { Dashboard as MemberDashboard } from './view/member/Dashboard';

export const DashboardModule = () => {
    const { currentRole } = useUser();

    const renderContent = useCallback(() => {
        if (currentRole) {
            switch (currentRole) {
                case Role.Member:
                    return <MemberDashboard />;
                case Role.Admin:
                    return <AdminDashboard />;
                case Role.Com:
                    return <ComDashboard />;
                case Role.Associate:
                    return <AssociateDashboard />;
                case Role.Central:
                    return <CentralDashboard />;
            }
        }
        return null;
    }, [currentRole]);

    return renderContent();
};
