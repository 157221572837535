import React from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { Page } from '../../../components/molecules/Page';
import { CreateLocationForm } from '../components/CreateLocationForm';
import { LocationDetails } from './LocationDetails';
import { LocationManagement } from './LocationManagement';

export const LocationManagementOverview = () => {
    return (
        <Routes>
            <Route element={<LocationManagementWrapper />}>
                <Route path={'locations'} element={<LocationManagement />} />
                <Route path={'locations/:locationId'} element={<LocationDetails />} />
            </Route>
        </Routes>
    );
};

const LocationManagementWrapper = () => {
    return (
        <Page title="Location management" addForm={CreateLocationForm}>
            <Outlet />
        </Page>
    );
};
