import React, { FC, useEffect, useMemo, useState } from 'react';

import { formatInTimeZone } from 'date-fns-tz';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CheckIcon, RightArrowIcon } from '../../../assets/icons';
import { Row, Text, View } from '../../../components/atoms';
import { useFlag } from '../../../hooks';
import { useUser } from '../../../providers';
import { Maybe, Role } from '../../../service';
import { roleName } from '../../../util/roleNameMapping';
import { Divider } from './Divider';
import { LogoutButton } from './LogoutButton';

export const ProfileContextMenu: FC = () => {
    const {
        user,
        currentRole,
        serviceLocationBoundTimezone,
        currentServiceLocation,
        setCurrentRole,
    } = useUser();

    const [roleSwitchVisible, showRoleSwitch, hideRoleSwitch] = useFlag(false);
    const [time, setTime] = useState(new Date());

    const navigate = useNavigate();

    const hasMultipleRoles = useMemo(() => {
        return (user?.roles?.length || 0) > 1;
    }, [user]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Wrapper
            initial={{ left: 100, opacity: 0 }}
            animate={{
                left: 240,
                opacity: 1,
                height: roleSwitchVisible ? (user?.roles?.length || 0) * 65 : 185,
            }}
            transition={{ duration: 0.2, ease: ['easeInOut'] }}
            exit={{ left: 100, opacity: 0 }}
            onClick={() => false}
        >
            <AnimatePresence>
                <InnerContentWrapper key={'0'} animate={{ left: roleSwitchVisible ? -240 : 0 }}>
                    <View px={20} pt={15}>
                        <View clickable onClick={() => showRoleSwitch()}>
                            <Row alignItems={'center'} justifyContent={'space-between'}>
                                <View>
                                    <Row>
                                        <Text fontSize={'medium'} fontWeight={'bold'} mr={2}>
                                            {user?.name}
                                        </Text>
                                        <Text
                                            ml={2}
                                            fontSize={'medium'}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            • {roleName[currentRole!]}
                                        </Text>
                                    </Row>
                                    <Text fontSize={'medium'} color={'text.secondary'} mt={5}>
                                        {user?.email}
                                    </Text>
                                </View>
                                {hasMultipleRoles && <RightArrowIcon />}
                            </Row>
                        </View>

                        <Row mt={20} mb={10} justifyContent={'space-between'} alignItems={'center'}>
                            <Text fontSize={'medium'}>{currentServiceLocation?.name}</Text>
                            <Text fontSize={'small'}>
                                {formatInTimeZone(time, serviceLocationBoundTimezone, 'hh:mm aa')}
                            </Text>
                        </Row>
                        <Divider />
                        <ProfileLink
                            mt={10}
                            mb={10}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            onClick={() => navigate('/profile')}
                        >
                            <Text fontSize={'medium'}>Profile</Text>
                        </ProfileLink>
                        <Divider />
                    </View>
                    <View px={13}>
                        <LogoutButton />
                    </View>
                </InnerContentWrapper>

                <InnerContentWrapper
                    key={'1'}
                    initial={{ left: 240 }}
                    animate={{ left: roleSwitchVisible ? 0 : 240 }}
                    transition={{ duration: 0.2, ease: ['easeInOut'] }}
                >
                    <View p={10} onClick={hideRoleSwitch}>
                        <Text mt={5} ml={10} mb={10} fontSize={'medium'} color={'text.secondary'}>
                            Account access:
                        </Text>
                        {user?.roles?.filter(Boolean).map((role?: Maybe<Role>) => (
                            <View key={role} id={role?.toString()} px={10} py={5}>
                                <Row
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    clickable
                                    onClick={() => {
                                        setCurrentRole(role!);
                                        navigate('/');
                                    }}
                                >
                                    <View>
                                        <Text fontSize={'medium'} fontWeight={'bold'}>
                                            {roleName[role!]}
                                        </Text>
                                        <Text fontSize={'medium'} color={'text.secondary'}>
                                            {currentRole === role
                                                ? 'Currently active'
                                                : 'Press to change environment'}
                                        </Text>
                                    </View>
                                    {currentRole === role && <CheckIcon />}
                                </Row>
                            </View>
                        ))}
                    </View>
                </InnerContentWrapper>
            </AnimatePresence>
        </Wrapper>
    );
};

const Wrapper = styled(motion.div)`
    position: absolute;
    background-color: white;
    border-radius: 10px;
    bottom: 10px;
    left: 240px;
    min-width: 240px;
    box-shadow: 0 0 6px #00000012;
    z-index: 100;
    overflow: hidden;
    min-height: 160px;
    height: auto;
`;

const InnerContentWrapper = styled(motion.div)`
    position: absolute;
    background-color: white;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 160px;
    z-index: 2199;
`;

const ProfileLink = styled(Row)`
    cursor: pointer;
`;
