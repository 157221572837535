import React, { FC, useEffect, useMemo, useState } from 'react';

import { differenceInHours, format, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon } from '../../../../assets/icons';
import { Button, Heading, LinkButton, Row, Text, View } from '../../../../components/atoms';
import { DatePicker, TextArea } from '../../../../components/molecules/form';
import { Select, Stepper } from '../../../../components/molecules/form';
import { UserCard } from '../../../../components/organisms';
import { useUser } from '../../../../providers';
import {
    AvailableTimeSlot,
    BookingRequestInput,
    BookingResponse,
    Company,
    Maybe,
    Role,
    useCreateBookingMutation,
    useGetAssociatesAvailabilitiesQuery,
    useGetCompaniesForEForceBookingFormQuery,
    useModifyEforceBookingMutation,
    User,
} from '../../../../service';

export enum Steps {
    CompanySelection,
    Description,
    DateSelection,
    DurationSelection,
    SlotSelection,
    AssociateSelection,
    Summary,
}

type BookingRequestForm = {
    company: Maybe<DeepPartial<Company>>;
    description: string;
    date: Date;
    duration: number;
    slot: Maybe<DeepPartial<AvailableTimeSlot>>;
    associate: Maybe<DeepPartial<User>>;
};

type Props = {
    booking?: Maybe<DeepPartial<BookingResponse>>;
    onDone?: () => void;
};
export const EForceBookingForm: FC<Props> = ({ booking: passedInBooking, onDone }) => {
    const {
        user,
        currentRole,
        currentServiceLocation,
        serviceLocationBoundTimezone: timezone,
    } = useUser();

    const initialStep = currentRole === Role.Member ? Steps.Description : Steps.CompanySelection;
    const [step, setStep] = useState<Steps>(initialStep);

    // GraphQL

    const { data: companiesData } = useGetCompaniesForEForceBookingFormQuery({
        variables: {
            filter: {
                locationIds: currentServiceLocation ? [currentServiceLocation.id] : [],
            },
        },
    });

    const refetchQueries = [
        'GetEforceMemberBookings',
        'GetEforceAssociateBookings',
        'GetEforceComBookings',
    ];

    const [createBooking, { loading: bookingCreationLoading }] = useCreateBookingMutation({
        refetchQueries,
    });
    const [modifyBooking, { loading: bookingModificationLoading }] = useModifyEforceBookingMutation(
        {
            refetchQueries,
        },
    );

    // Form
    const { control, handleSubmit, watch, setValue, reset } = useForm<BookingRequestForm>({
        defaultValues: {
            company: passedInBooking ? passedInBooking.company : undefined,
            date: new Date(),
            duration: 1,
        },
    });

    const date = watch('date');
    const duration = watch('duration');
    const company = watch('company');
    const description = watch('description');
    const associate = watch('associate');
    const slot = watch('slot');

    useEffect(() => {
        if (passedInBooking) {
            try {
                const startTime = parseISO(passedInBooking?.slot?.startTime);
                const endTime = parseISO(passedInBooking?.slot?.endTime);

                const date = new Date(
                    formatInTimeZone(passedInBooking?.slot?.startTime, timezone, 'MM-dd-yyyy'),
                );
                reset({
                    date: date,
                    company: passedInBooking?.company,
                    slot: passedInBooking?.slot as AvailableTimeSlot,
                    duration: differenceInHours(endTime, startTime),
                    description: passedInBooking?.description,
                    associate: passedInBooking?.assignee,
                });
                setStep(Steps.Summary);
            } catch (e) {
                console.error(e);
            }
        }
    }, [passedInBooking]);

    useEffect(() => {
        if (companiesData) {
            if (currentRole === Role.Member) {
                setValue(
                    'company',
                    companiesData.companies?.filter(
                        (companyItem: Maybe<DeepPartial<Company>>) =>
                            companyItem?.id === user?.company?.id,
                    )?.[0],
                );
            } else {
                !company && setValue('company', companiesData?.companies?.[0]);
            }
        }
    }, [companiesData]);

    const { data: availabilityData } = useGetAssociatesAvailabilitiesQuery({
        variables: {
            request: {
                locationId: currentServiceLocation?.id || '',
                date: format(date, 'yyyy-MM-dd'),
                duration: duration * 60,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const onSubmit = handleSubmit(async () => {
        if (user && company?.id && currentServiceLocation && slot) {
            const payload: BookingRequestInput = {
                companyId: company?.id,
                locationId: currentServiceLocation.id,
                description,
                dateTimeSlot: {
                    duration: duration * 60,
                    startTime: slot.startTime,
                },
                preferredAssociateId: associate?.id,
            };

            if (passedInBooking?.id) {
                await modifyBooking({
                    variables: {
                        request: {
                            bookingId: passedInBooking?.id,
                            ...payload,
                        },
                    },
                });
            } else {
                await createBooking({
                    variables: {
                        request: { ...payload },
                    },
                });
            }
        }
        onDone && onDone();
    });

    const formCompletedOnce = useMemo(
        () => description && slot && associate && duration,
        [description, slot, associate, duration],
    );

    return (
        <View px={[20, 20, 40]} py={40} style={{ minHeight: 400 }}>
            {step !== initialStep && !passedInBooking && (
                <Row
                    alignItems={'center'}
                    mb={10}
                    ml={-5}
                    onClick={() => setStep(step.valueOf() - 1)}
                >
                    <LeftArrowIcon width={20} height={18} />
                    <LinkButton>Back</LinkButton>
                </Row>
            )}
            <Heading>Book eForce</Heading>
            {step === Steps.CompanySelection && (
                <>
                    <Text mt={10} mb={10}>
                        Which member are you booking for?
                    </Text>
                    <Select
                        control={control}
                        name={'company'}
                        values={companiesData?.companies || []}
                        labels={companiesData?.companies?.map((c) => c?.name || '') || []}
                    />
                    <Button
                        mt={20}
                        onClick={() => {
                            if (formCompletedOnce) {
                                setStep(Steps.Summary);
                            } else {
                                setStep(Steps.Description);
                            }
                        }}
                    >
                        Next
                    </Button>
                </>
            )}
            {step === Steps.Description && (
                <>
                    <Text mt={10} mb={10}>
                        How can we help?
                    </Text>
                    <TextArea control={control} name={'description'} />
                    <Button
                        mt={20}
                        onClick={() => {
                            if (formCompletedOnce) {
                                setStep(Steps.Summary);
                            } else {
                                setStep(Steps.DateSelection);
                            }
                        }}
                    >
                        Next
                    </Button>
                </>
            )}
            {step === Steps.DateSelection && (
                <>
                    <Text mt={10} mb={30}>
                        How can we help?
                    </Text>
                    <DatePicker control={control} name={'date'} />
                    <Button mt={20} onClick={() => setStep(Steps.DurationSelection)}>
                        Next
                    </Button>
                </>
            )}

            {step === Steps.DurationSelection && (
                <>
                    <Text mt={10} mb={30}>
                        For about how long do you need us?
                    </Text>
                    <Stepper
                        measurement="h"
                        lower={0.5}
                        step={0.5}
                        control={control}
                        name={'duration'}
                    />

                    <Button mt={20} onClick={() => setStep(Steps.SlotSelection)}>
                        Next
                    </Button>
                </>
            )}
            {step === Steps.SlotSelection && (
                <>
                    <Text mt={10} mb={30}>
                        Choose start time
                    </Text>
                    <SlotsWrapper>
                        {availabilityData?.associatesAvailability?.slots?.map(
                            (slot: Maybe<DeepPartial<AvailableTimeSlot>>) => (
                                <SlotView
                                    key={slot?.startTime}
                                    onClick={() => {
                                        setValue('slot', slot);
                                        setStep(Steps.AssociateSelection);
                                    }}
                                >
                                    {formatInTimeZone(slot?.startTime, timezone, 'hh:mm aa')}
                                </SlotView>
                            ),
                        )}
                    </SlotsWrapper>
                </>
            )}

            {step === Steps.AssociateSelection && (
                <>
                    <Text mt={10} mb={30}>
                        Do you have a preferred Member Specialist?
                    </Text>
                    <AssociatesWrapper>
                        {slot?.associates?.filter(Boolean)?.map((associate) => (
                            <View
                                key={associate?.id}
                                onClick={() => {
                                    setValue('associate', associate!);
                                    setStep(Steps.Summary);
                                }}
                            >
                                <UserCard user={associate!} />
                            </View>
                        ))}
                    </AssociatesWrapper>
                </>
            )}

            {step === Steps.Summary && (
                <>
                    <Text mt={10} mb={30}>
                        {passedInBooking ? 'What would you like to change?' : 'Does this look ok?'}
                    </Text>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Company</Text>
                            <Text mt={3}>{company?.name}</Text>
                        </View>
                        {currentRole !== Role.Member && (
                            <LinkButton
                                onClick={() => {
                                    setStep(Steps.CompanySelection);
                                }}
                            >
                                Edit
                            </LinkButton>
                        )}
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Description</Text>
                            {description && <Text mt={3}>{description}</Text>}
                            {!description && (
                                <Text mt={5} color="text.secondary">
                                    No description provided
                                </Text>
                            )}
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.Description);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Date</Text>
                            <Text mt={3}>{format(date, 'MM/dd/yyyy')}</Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.DateSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Start time</Text>
                            <Text mt={3}>
                                {formatInTimeZone(slot?.startTime, timezone, 'hh:mm aa')}
                            </Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.SlotSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Duration</Text>
                            <Text mt={3}>
                                {formatDuration(
                                    intervalToDuration({
                                        start: 0,
                                        end: duration * 60 * 60 * 1000,
                                    }),
                                    { format: ['hours', 'minutes'] },
                                )}
                            </Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.DurationSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Preferred Member Specialist</Text>
                            <Text mt={3}>{associate?.name}</Text>
                        </View>
                        {slot?.associates && (
                            <LinkButton
                                onClick={() => {
                                    setStep(Steps.AssociateSelection);
                                }}
                            >
                                Edit
                            </LinkButton>
                        )}
                    </SummaryRow>

                    <Button
                        mt={20}
                        onClick={() => onSubmit()}
                        loading={bookingModificationLoading || bookingCreationLoading}
                    >
                        Confirm booking
                    </Button>
                </>
            )}
        </View>
    );
};

const SlotsWrapper = styled(View)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
`;
const SlotView = styled(View)`
    background-color: #f9f8f6;
    border-radius: 5px;
    font-family: Inter, sans-serif;
    padding: 11px;
    font-size: 14px;
    cursor: pointer;
    flex: 1 0 20%;
    margin: 5px;
    text-align: center;
`;

const AssociatesWrapper = styled(View)`
    max-height: 300px;
    overflow: auto;
`;

const Option = styled(View)`
    background-color: #f9f8f6;
    padding: 11px 15px;
    margin-bottom: 5px;
    border-radius: 5px;
`;

const SummaryRow = styled(Option)`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-start;
`;
