import React, { FC, useRef, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../../../assets/graphics';
import { View } from '../../../components/atoms';
import { useClickOutside } from '../../../hooks';
import { MenuSection } from '../models/MenuSection';
import { Divider } from './Divider';
import { ExternalLinkMenuItem } from './ExternalLinkMenuItem';
import { MenuItem } from './MenuItem';
import { ProfileContextMenu } from './ProfileContextMenu';
import { ProfileWidget } from './ProfileWidget';
import { SectionLabel } from './SectionLabel';
import { SubMenuItem } from './SubMenuItem';

type Props = {
    items: MenuSection[];
};
export const SideMenu: FC<Props> = ({ items }) => {
    const [profileContextMenuVisible, setProfileContextMenuVisible] = useState(false);
    const profileContextMenuRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useClickOutside(profileContextMenuRef, () => {
        setProfileContextMenuVisible(false);
    });
    return (
        <Wrapper display={['none', 'none', 'flex']}>
            <View>
                <SideMenuLogo onClick={() => navigate('/')} />

                {items.map((section) => {
                    return (
                        <View key={section.name}>
                            {section.showNameInMenu && (
                                <>
                                    <Divider />
                                    <SectionLabel>SERVICES</SectionLabel>
                                </>
                            )}
                            {section.items.map((item) => {
                                return (
                                    <View key={item.route}>
                                        {!item.externalLink ? (
                                            <MenuItem
                                                key={item.route}
                                                to={item.route}
                                                icon={<item.icon />}
                                                title={item.name}
                                            />
                                        ) : (
                                            <ExternalLinkMenuItem
                                                link={item.route}
                                                icon={<item.icon />}
                                                title={item.name}
                                            />
                                        )}
                                        {item.subItems && (
                                            <SubMenu
                                                active={location.pathname.includes(item.route)}
                                            >
                                                {item.subItems?.map((subItem) => {
                                                    return (
                                                        <SubMenuItem
                                                            key={subItem.route}
                                                            to={subItem.route}
                                                            title={subItem.name}
                                                        />
                                                    );
                                                })}
                                            </SubMenu>
                                        )}
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </View>
            <View ref={profileContextMenuRef}>
                <Divider />
                <ProfileWidget onClick={() => setProfileContextMenuVisible(true)} />
                <AnimatePresence>
                    {profileContextMenuVisible && <ProfileContextMenu />}
                </AnimatePresence>
            </View>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    width: 220px;
    height: 100vh;
    background-color: white;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 3px 6px #00000012;
    z-index: 1;
`;

const SideMenuLogo = styled(Logo)`
    margin: 10px 10px 135px 10px;
`;

const SubMenu = styled(View)<{ active: boolean }>`
    display: ${(props) => (props.active ? 'block' : 'none')};
`;
