import React, { FC, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MobileLogo } from '../../../assets/graphics';
import { HamburgerIcon } from '../../../assets/icons';
import { View } from '../../../components/atoms';
import { MenuSection } from '../models/MenuSection';
import { Divider } from './Divider';
import { ExternalLinkMenuItem } from './ExternalLinkMenuItem';
import { LogoutButton } from './LogoutButton';
import { MenuItem } from './MenuItem';
import { ProfileWidget } from './ProfileWidget';
import { SectionLabel } from './SectionLabel';

const MENU_WIDTH = 320;

type Props = {
    items: MenuSection[];
};

export const MobileMenu: FC<Props> = ({ items }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <Wrapper display={['flex', 'flex', 'none']}>
            <MobileLogo onClick={() => navigate('/')} />
            <HamburgerIcon onClick={() => setOpen(true)} />
            <AnimatePresence>
                {open && (
                    <MenuInner>
                        <Backdrop
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            transition={{
                                duration: 0.2,
                            }}
                            exit={{ opacity: 0 }}
                            onClick={() => setOpen(false)}
                        />
                        <Menu
                            initial={{ right: -MENU_WIDTH }}
                            animate={{ right: 0 }}
                            exit={{ right: -MENU_WIDTH }}
                            drag={'x'}
                            dragConstraints={{ left: 0, right: 300 }}
                            dragElastic={0}
                            dragMomentum={false}
                            onDragEnd={(e, pointInfo) => {
                                setOpen(pointInfo.offset.x < 30);
                            }}
                        >
                            <View>
                                <View onClick={() => navigate('/profile')}>
                                    <ProfileWidget mb={10} />
                                </View>
                                <Divider />
                                {items.map((section) => (
                                    <View key={section.name}>
                                        {section.showNameInMenu && (
                                            <>
                                                <Divider />
                                                <SectionLabel ml={5}> Services </SectionLabel>
                                            </>
                                        )}
                                        {section.items.map((item) => (
                                            <View key={item.route}>
                                                {!item.externalLink ? (
                                                    <MenuItem
                                                        key={item.route}
                                                        to={item.route}
                                                        icon={<item.icon />}
                                                        title={item.name}
                                                    />
                                                ) : (
                                                    <ExternalLinkMenuItem
                                                        link={item.route}
                                                        icon={<item.icon />}
                                                        title={item.name}
                                                    />
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                ))}
                            </View>
                            <View>
                                <Divider />
                                <LogoutButton />
                            </View>
                        </Menu>
                    </MenuInner>
                )}
            </AnimatePresence>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    width: 100vw;
    height: 60px;
    justify-content: space-between;
    padding: 18px 25px;
    background-color: white;
    position: relative;
`;

const Backdrop = styled(motion.div)`
    width: 100vw;
    height: ${window.innerHeight}px;
    background-color: black;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 1;
`;

const Menu = styled(motion.div)`
    position: absolute;
    width: ${MENU_WIDTH}px;
    height: ${window.innerHeight}px;
    background-color: white;
    top: 0;
    right: 0;
    z-index: 10;
    touch-action: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MenuInner = styled(View)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${window.innerHeight}px;
    overflow-y: scroll;
`;
