import React, { FC, HTMLProps } from 'react';

import styled from 'styled-components';

import { Row } from './Row';
import { View } from './View';

type Props = {
    children?: string | React.ReactNode;
    icon?: React.ReactNode;
} & HTMLProps<HTMLElement>;
export const LinkButton: FC<Props> = ({ children, icon, onClick }) => {
    return (
        <LinkButtonWrapper justifyContent={'center'} alignItems={'center'} onClick={onClick}>
            <LinkButtonText mr={icon ? 10 : 0} alignItems={'center'}>
                {children}
            </LinkButtonText>
            {icon && icon}
        </LinkButtonWrapper>
    );
};

const LinkButtonWrapper = styled(Row)`
    cursor: pointer;
    width: fit-content;
`;

const LinkButtonText = styled(View)`
    font-size: ${(props) => props.theme.fontSizes.medium};
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
`;
