import React, { FC } from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { MemberBookingCardBrowser } from './MemberBookingCardBrowser';
import { MemberBookingCardMobile, MemberBookingProps } from './MemberBookingCardMobile';

export const MemberBookingCard: FC<MemberBookingProps> = (props: MemberBookingProps) => {
    return (
        <>
            <MobileView>
                <MemberBookingCardMobile {...props} />
            </MobileView>
            <BrowserView>
                <MemberBookingCardBrowser {...props} />
            </BrowserView>
        </>
    );
};
