import React, { FC } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { LocationPinIcon, RightArrowIcon } from '../../../assets/icons';
import { Column, Row, Text, View } from '../../../components/atoms';
import { Avatar } from '../../../components/molecules/Avatar';
import { User } from '../../../service';

type Props = {
    user: DeepPartial<User>;
};
export const UserInfoCard: FC<Props> = ({ user }) => {
    return (
        <>
            <Row>
                <Card>
                    <Row pb={10} minHeight={65} alignItems={'flex-start'}>
                        <Avatar size={60} user={user} />
                        <Column justifyContent={'center'} ml={10} width={'100%'}>
                            <Text fontWeight={'bold'} fontSize={'medium'}>
                                {user?.name}
                            </Text>
                            <ElipsedText fontSize={'medium'}>{user?.email}</ElipsedText>
                        </Column>
                    </Row>
                    <Divider />
                    <Row py={15} alignItems={'center'} justifyContent={'space-between'}>
                        <Row alignItems={'center'}>
                            <LocationPinIcon />
                            <Text ml={2} fontSize={'medium'}>
                                {user?.accessLocations?.reduce((out, current) => {
                                    return out + (out !== '' ? ', ' : '') + current?.name;
                                }, '') || '/'}
                            </Text>
                        </Row>
                        <RightArrowIcon />
                    </Row>
                </Card>
            </Row>
        </>
    );
};

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 0;
`;

const Card = styled(View)`
    min-height: 140px;
    padding: 20px 20px 0 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    flex: 1;
    position: relative;
`;

const ElipsedText = styled(Text)`
    margin-top: 5px;
    width: calc(100% - 45px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
