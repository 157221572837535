import React, { useCallback } from 'react';

import { useUser } from '../../providers';
import { Role } from '../../service';
import { LocationManagementOverview } from './view/LocationManagementOverview';

export const LocationManagementModule = () => {
    const { currentRole } = useUser();

    const renderContent = useCallback(() => {
        if (currentRole) {
            switch (currentRole) {
                case Role.Member:
                case Role.Associate:
                case Role.Com:
                    return <></>;
                case Role.Admin:
                    return <LocationManagementOverview></LocationManagementOverview>;
            }
        }
        return null;
    }, [currentRole]);

    return renderContent();
};
