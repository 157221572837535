import React, { FC } from 'react';

import { RightArrowIcon } from '../../../assets/icons';
import { Row, RowProps, Text, View } from '../../../components/atoms';
import { Avatar } from '../../../components/molecules/Avatar';
import { useUser } from '../../../providers';
import { roleName } from '../../../util/roleNameMapping';

type Props = {
    showCompany?: boolean;
    showEmail?: boolean;
    showRole?: boolean;
    onClick?: () => void;
} & RowProps;
export const ProfileWidget: FC<Props> = ({
    showCompany = true,
    showEmail = false,
    showRole = false,
    ...rest
}) => {
    const { user, currentServiceLocation, currentRole } = useUser();
    return (
        <Row {...rest} py={10} alignItems={'center'} justifyContent={'space-between'} clickable>
            <Row alignItems={'center'}>
                <Avatar user={user} size={[50, 50, 35]} />
                <View ml={8}>
                    <Text fontWeight={'bold'} fontSize={['default', 'default', 'medium']}>
                        {user?.name} {showRole && `• ${roleName[currentRole!]}`}
                    </Text>
                    {showCompany && (
                        <Text fontSize={'medium'} color={'text.secondary'} mt={1}>
                            {user?.company?.name} | {currentServiceLocation?.name}
                        </Text>
                    )}
                    {showEmail && (
                        <Text fontSize={'medium'} color={'text.secondary'} mt={1}>
                            {user?.email}
                        </Text>
                    )}
                </View>
            </Row>
            <RightArrowIcon />
        </Row>
    );
};
