import React, { FC, useMemo, useState } from 'react';

import { format, formatDuration, intervalToDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon } from '../../../../assets/icons';
import { Button, Heading, LinkButton, Row, Text, View } from '../../../../components/atoms';
import { DatePicker, TextArea } from '../../../../components/molecules/form';
import { Stepper } from '../../../../components/molecules/form';
import { useUser } from '../../../../providers';
import {
    AvailableTimeSlot,
    BlockingTimeRequestInput,
    Maybe,
    useCreateBlockTimeMutation,
    useGetAssociatesAvailabilitiesQuery,
} from '../../../../service';

export enum Steps {
    DateSelection,
    DurationSelection,
    SlotSelection,
    ReasonSelection,
    Description,
    Summary,
}

const REASON_OTHER = 'Other';

type BlockTimeRequestForm = {
    date: Date;
    duration: number;
    reason: string;
    slot: Maybe<DeepPartial<AvailableTimeSlot>>;
    description: string;
};

type Props = {
    onDone?: () => void;
};
export const BlockTimeForm: FC<Props> = ({ onDone }) => {
    const { user, currentServiceLocation, serviceLocationBoundTimezone: timezone } = useUser();
    const { associateTimeBlockingReasons } = useFlags();

    const initialStep = Steps.DateSelection;
    const [step, setStep] = useState<Steps>(initialStep);

    // GraphQL

    const [createBlockTime, { loading: createBlockTimeLoading }] = useCreateBlockTimeMutation({
        refetchQueries: ['GetEforceAssociateBookings', 'GetEforceComBookings'],
    });

    // Form
    const { control, handleSubmit, watch, setValue } = useForm<BlockTimeRequestForm>({
        defaultValues: {
            date: new Date(),
            duration: 1,
        },
    });

    const date = watch('date');
    const duration = watch('duration');
    const slot = watch('slot');
    const reason = watch('reason');
    const description = watch('description');

    const { data: availabilityData } = useGetAssociatesAvailabilitiesQuery({
        variables: {
            request: {
                assigneeId: user?.id,
                locationId: currentServiceLocation?.id || '',
                date: format(date, 'yyyy-MM-dd'),
                duration: duration * 60,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const onSubmit = handleSubmit(async () => {
        if (user && currentServiceLocation && slot && (description || reason !== REASON_OTHER)) {
            const payload: BlockingTimeRequestInput = {
                locationId: currentServiceLocation.id,
                reason: reason,
                description: description || reason,
                dateTimeSlot: {
                    duration: duration * 60,
                    startTime: slot.startTime,
                },
            };

            await createBlockTime({
                variables: {
                    request: { ...payload },
                },
            });
            onDone && onDone();
        }
    });

    const formCompletedOnce = useMemo(
        () => description && slot && duration && reason,
        [description, slot, duration, reason],
    );

    return (
        <View px={[20, 20, 40]} py={40} style={{ minHeight: 400 }}>
            {step !== initialStep && (
                <Row
                    alignItems={'center'}
                    mb={10}
                    ml={-5}
                    onClick={() => {
                        if (step === Steps.Summary && reason !== REASON_OTHER) {
                            setStep(Steps.ReasonSelection);
                        } else {
                            setStep(step.valueOf() - 1);
                        }
                    }}
                >
                    <LeftArrowIcon width={20} height={18} />
                    <LinkButton>Back</LinkButton>
                </Row>
            )}

            <Heading>Block My Time</Heading>

            {step === Steps.DateSelection && (
                <>
                    <Text mt={10} mb={30}>
                        When do you need to block your time?
                    </Text>
                    <Row justifyContent={'center'} width={'100%'}>
                        <DatePicker forceOneMonthView={true} control={control} name={'date'} />
                    </Row>
                    <Button mt={20} onClick={() => setStep(Steps.DurationSelection)}>
                        Next
                    </Button>
                </>
            )}

            {step === Steps.DurationSelection && (
                <>
                    <Text mt={10} mb={30}>
                        For about how long do you need it?
                    </Text>
                    <Stepper
                        measurement="h"
                        lower={0.5}
                        step={0.5}
                        control={control}
                        name={'duration'}
                    />

                    <Button mt={20} onClick={() => setStep(Steps.SlotSelection)}>
                        Next
                    </Button>
                </>
            )}

            {step === Steps.SlotSelection && (
                <>
                    <Text mt={10} mb={30}>
                        Choose start time
                    </Text>
                    <SlotsWrapper>
                        {availabilityData?.associatesAvailability?.slots?.map(
                            (slot: Maybe<DeepPartial<AvailableTimeSlot>>) => (
                                <SlotView
                                    key={slot?.startTime}
                                    onClick={() => {
                                        setValue('slot', slot);
                                        if (formCompletedOnce) {
                                            setStep(Steps.Summary);
                                        } else {
                                            setStep(Steps.ReasonSelection);
                                        }
                                    }}
                                >
                                    {formatInTimeZone(slot?.startTime, timezone, 'hh:mm aa')}
                                </SlotView>
                            ),
                        )}
                    </SlotsWrapper>
                </>
            )}

            {step === Steps.ReasonSelection && (
                <>
                    <Text mt={10} mb={30}>
                        Choose the reason for blocking
                    </Text>
                    <ReasonsWrapper>
                        {associateTimeBlockingReasons.sort().map((reason: string) => (
                            <ReasonView
                                my={[5, 10, 10]}
                                key={reason}
                                onClick={() => {
                                    setValue('reason', reason.replace(' ', '_'));
                                    if (formCompletedOnce || reason !== REASON_OTHER) {
                                        setStep(Steps.Summary);
                                    } else {
                                        setStep(Steps.Description);
                                    }
                                }}
                            >
                                {reason}
                            </ReasonView>
                        ))}
                    </ReasonsWrapper>
                </>
            )}

            {step === Steps.Description && (
                <>
                    <Text mt={10} mb={10}>
                        Describe blocking time reason.
                    </Text>
                    <TextArea control={control} name={'description'} />
                    <Button
                        mt={20}
                        onClick={() => {
                            setStep(Steps.Summary);
                        }}
                    >
                        Next
                    </Button>
                </>
            )}

            {step === Steps.Summary && (
                <>
                    <Text mt={10} mb={30}>
                        Does this look ok?
                    </Text>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Date</Text>
                            <Text mt={3}>{format(date, 'MM/dd/yyyy')}</Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.DateSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Duration</Text>
                            <Text mt={3}>
                                {formatDuration(
                                    intervalToDuration({
                                        start: 0,
                                        end: duration * 60 * 60 * 1000,
                                    }),
                                    { format: ['hours', 'minutes'] },
                                )}
                            </Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.DurationSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Start time</Text>
                            <Text mt={3}>
                                {formatInTimeZone(slot?.startTime, timezone, 'hh:mm aa')}
                            </Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.SlotSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    <SummaryRow>
                        <View>
                            <Text fontSize={'medium'}>Reason</Text>
                            <Text mt={3}>{reason.replace('_', ' ')}</Text>
                        </View>
                        <LinkButton
                            onClick={() => {
                                setStep(Steps.ReasonSelection);
                            }}
                        >
                            Edit
                        </LinkButton>
                    </SummaryRow>

                    {reason === REASON_OTHER && (
                        <SummaryRow>
                            <View>
                                <Text fontSize={'medium'}>Description</Text>
                                {description && <Text mt={3}>{description}</Text>}
                                {!description && (
                                    <Text mt={5} color="text.secondary">
                                        No description provided
                                    </Text>
                                )}
                            </View>
                            <LinkButton
                                onClick={() => {
                                    setStep(Steps.Description);
                                }}
                            >
                                Edit
                            </LinkButton>
                        </SummaryRow>
                    )}

                    <Button mt={20} onClick={() => onSubmit()} loading={createBlockTimeLoading}>
                        Confirm
                    </Button>
                </>
            )}
        </View>
    );
};

const SlotsWrapper = styled(View)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
`;
const SlotView = styled(View)`
    background-color: #f9f8f6;
    border-radius: 5px;
    font-family: Inter, sans-serif;
    padding: 11px;
    font-size: 14px;
    cursor: pointer;
    flex: 1 0 20%;
    margin: 5px;
    text-align: center;
`;

const ReasonsWrapper = styled(View)`
    max-height: 300px;
    overflow: auto;
`;
const ReasonView = styled(View)`
    cursor: pointer;
    height: 40px;
    width: 100%;
    background-color: #f9f8f6;
    border-radius: 5px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    padding: 11px;
`;

const Option = styled(View)`
    background-color: #f9f8f6;
    padding: 11px 15px;
    margin-bottom: 5px;
    border-radius: 5px;
`;

const SummaryRow = styled(Option)`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-start;
`;
