import React, { useEffect, useRef } from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { RoundPlusIcon } from '../../../../assets/icons';
import { Heading, LinkButton, Row, Text, View } from '../../../../components/atoms';
import { Tab, Tabs } from '../../../../components/molecules/Tabs';
import { BottomSheet, BottomSheetRef } from '../../../../components/organisms';
import { FlexStorageForm } from '../../components/FlexStorageForm';
import { FlexStorageRequests as AssociateFlexStorageRequests } from './FlexStorageRequests/FlexStorageRequests';
import { FlexStorageStoredItems as AssociateFlexStorageStoredItems } from './FlexStorageStoredItems/FlexStorageStoredItems';

export const FlexStorage = () => {
    return (
        <Routes>
            <Route element={<FlexStorageAssociateWrapper />}>
                <Route index element={<AssociateFlexStorageRequests />} />
                <Route path={'requests'} element={<AssociateFlexStorageRequests />} />
                <Route path={'stored'} element={<AssociateFlexStorageStoredItems />} />
            </Route>
        </Routes>
    );
};

const FlexStorageAssociateWrapper = () => {
    const bottomSheetRef = useRef<BottomSheetRef>(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/flex-storage') {
            navigate('/flex-storage/requests');
        }
    }, [location]);

    return (
        <View>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <MobileView>
                    <Heading mb={5}>Flex Storage</Heading>
                </MobileView>
                <BrowserView>
                    <Text mb={5} color={'text.secondary'}>
                        Flex Storage
                    </Text>
                </BrowserView>
                <Row alignItems={'center'} justifyContent={'center'}>
                    <LinkButton
                        icon={<RoundPlusIcon />}
                        onClick={() => bottomSheetRef?.current?.show()}
                    >
                        Add
                    </LinkButton>
                </Row>
            </Row>
            <MobileView>
                <Tabs mt={20}>
                    <Tab to={'requests'}>Requests</Tab>
                    <Tab to={'stored'}>Stored</Tab>
                </Tabs>
            </MobileView>
            <Outlet />
            <BottomSheet ref={bottomSheetRef}>
                <FlexStorageForm
                    onClose={() => {
                        bottomSheetRef?.current?.hide();
                    }}
                />
            </BottomSheet>
        </View>
    );
};
