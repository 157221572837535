import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ThemeProvider } from 'styled-components';

import { configuration } from './configuration';
import { SessionProvider, ApolloProvider, UserProvider, FullStoryProvider } from './providers';
import { Router } from './Router';
import { Theme } from './theme';

const App = () => {
    return (
        <ThemeProvider theme={Theme}>
            <Auth0Provider {...configuration.auth0}>
                <SessionProvider>
                    <ApolloProvider>
                        <UserProvider>
                            <FullStoryProvider>
                                <Router />
                            </FullStoryProvider>
                        </UserProvider>
                    </ApolloProvider>
                </SessionProvider>
            </Auth0Provider>
        </ThemeProvider>
    );
};

export default withLDProvider({ clientSideID: '6234beafcc58de14f4eda1e0' })(App);
