export const getInitialsFromName = (name = '') => {
    const tempArray = name.match(/(^\S\S?|\s\S)?/g) || [];

    if (tempArray.length === 0) {
        return '';
    }

    return tempArray
        .map((v) => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g)!
        .join('')
        .toLocaleUpperCase();
};
