import React, { FC } from 'react';

import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { User } from '../../service';
import { Row, Text } from '../atoms';
import { Avatar } from '../molecules/Avatar';

type Props = {
    user: DeepPartial<User>;
    variant?: 'regular' | 'light';
};
export const UserCard: FC<Props> = ({ user, variant = 'regular' }) => {
    return (
        <Wrapper alignItems={'center'} my={10} variant={variant}>
            <Avatar variant={variant === 'regular' ? 'light' : 'regular'} size={30} user={user} />
            <Text fontSize={'medium'} ml={10}>
                {user.name}
            </Text>
        </Wrapper>
    );
};

type WrapperProps = {
    variant: string;
};
const Wrapper = styled(Row)<WrapperProps>`
    background-color: ${(props) => (props.variant === 'regular' ? '#f9f8f6' : 'white')};
    padding: 11px 15px;
    border-radius: 5px;
    border: ${(props) => (props.variant === 'regular' ? 'none' : '1px solid #e1e1e1')};
`;
