import React from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { Page } from '../../../components/molecules/Page';
import { CompanyForm } from '../components/CompanyForm';
import { MemberForm } from '../components/MemberForm';
import { MemberManagementCompanies } from './MemberManagementCompanies';
import { MemberManagementMemberDetails } from './MemberManagementMemberDetails';
import { MemberManagementMembers } from './MemberManagementMembers';

export const MemberManagementOverview = () => {
    return (
        <Routes>
            <Route element={<MemberManagementWrapper />}>
                <Route path={'companies'} element={<MemberManagementCompanies />} />
                <Route path={'companies/:id'} element={<MemberManagementMembers />} />
                <Route
                    path={'companies/:id/members/:memberId'}
                    element={<MemberManagementMemberDetails />}
                />
            </Route>
        </Routes>
    );
};

const MemberManagementWrapper = () => {
    return (
        <Page addForm={CompanyForm}>
            <Outlet />
        </Page>
    );
};
