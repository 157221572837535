import React, { FC, useRef, useState } from 'react';

import styled from 'styled-components';

import { DotsIcon } from '../../assets/icons';
import { useClickOutside } from '../../hooks';
import { View, Text } from '../atoms';

type Props = {
    options: string[];
    onOptionSelected: (option: string) => void;
};
export const ContextMenu: FC<Props> = ({ options, onOptionSelected }) => {
    const [open, setOpen] = useState(false);
    const clickRef = useRef(null);

    useClickOutside(clickRef, () => {
        setOpen(false);
    });
    return (
        <>
            <ContextMenuButton ref={clickRef} width={18} onClick={() => setOpen(true)} />
            {open && (
                <OptionsWrapper>
                    {options.map((option) => (
                        <Text
                            fontSize={'medium'}
                            my={2}
                            key={option}
                            onClick={() => {
                                onOptionSelected(option);
                            }}
                        >
                            {option}
                        </Text>
                    ))}
                </OptionsWrapper>
            )}
        </>
    );
};

const ContextMenuButton = styled(DotsIcon)`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
`;

const OptionsWrapper = styled(View)`
    position: absolute;
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0 0 6px #0000000f;
    border-radius: 5px;
    min-width: 100px;
    top: 15px;
    right: 30px;
    ${Text} {
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }
`;
