import React, { FC, useMemo, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, View } from '../../../components/atoms';
import { SearchInput } from '../../../components/molecules/SearchInput';
import { Table } from '../../../components/molecules/Table';
import { useGetUsersQuery, User } from '../../../service';
import { userFriendlyRoleName } from '../../../util/roleNameMapping';
import { UserInfoCard } from '../components/UserInfoCard';

export const UserManagement: FC = () => {
    const navigate = useNavigate();
    const { data: usersData } = useGetUsersQuery();

    const [query, setQuery] = useState<string>('');
    const users = usersData?.users?.filter(Boolean);
    const filteredUsers = !query
        ? users
        : users?.filter(
              (user) =>
                  user?.name?.toLowerCase().includes(query.toLowerCase()) ||
                  user?.company?.name?.toLowerCase().includes(query.toLowerCase()) ||
                  user?.email.toLowerCase().includes(query.toLowerCase()),
          );
    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'eMail',
                accessor: 'email',
            },
            {
                Header: 'Roles',
                accessor: (row: User) => {
                    return (
                        row.roles?.reduce((out, current) => {
                            return out + (out !== '' ? ', ' : '') + userFriendlyRoleName(current!);
                        }, '') || '/'
                    );
                },
            },
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Company',
                accessor: 'company.name',
            },
            {
                Header: 'Locations',
                accessor: (row: User) => {
                    return (
                        row.accessLocations?.reduce((out, current) => {
                            return out + (out !== '' ? ', ' : '') + current?.name;
                        }, '') || '/'
                    );
                },
            },
        ];
    }, []);
    return (
        <View>
            <Heading>Users</Heading>
            <View mt={30}>
                <SearchInput
                    placeholder="Search for users..."
                    onInput={(event: React.FormEvent<HTMLInputElement>) =>
                        setQuery(event.currentTarget.value)
                    }
                />
            </View>
            <ScrollView flex={1} mt={30} display={['none', 'block']}>
                <Table
                    data={filteredUsers || []}
                    columns={columns}
                    onRowClicked={(user) => {
                        navigate('/user-management/users/' + user?.id);
                    }}
                />
            </ScrollView>
            <ScrollView flex={1} mt={30} display={['block', 'none']}>
                {filteredUsers?.map((user) => (
                    <StyledLink key={user?.id} to={'/user-management/users/' + user?.id}>
                        <View key={user?.id} mb={10}>
                            <UserInfoCard user={user!} />
                        </View>
                    </StyledLink>
                ))}
            </ScrollView>
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 200}px;
`;

const StyledLink = styled(NavLink)`
    color: black;
    text-decoration: none;
`;
