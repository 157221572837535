import React, { FC } from 'react';

import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { Row, Text, View } from '../../../components/atoms';
import { BookingResponse } from '../../../service';

type Props = {
    booking: DeepPartial<BookingResponse>;
    timezone?: string;
};
export const AssociateBlockedTimeCard: FC<Props> = ({
    booking,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
    return (
        <>
            <Card>
                <Row alignItems={'center'}>
                    <Text fontWeight={'bold'} fontSize={'medium'}>
                        Blocked Time for {booking.reason?.replace('_', ' ')}
                    </Text>
                </Row>
                <Text mt={10} fontWeight={'bold'}>
                    {formatInTimeZone(parseISO(booking?.slot?.startTime), timezone, 'h:mm aa')} -
                    {formatInTimeZone(parseISO(booking?.slot?.endTime), timezone, 'h:mm aa')}
                </Text>
            </Card>
        </>
    );
};

const Card = styled(View)`
    margin-left: 10px;
    padding: 15px;
    background: repeating-linear-gradient(-85deg, #ffeae0, #ffeae0 4px, #ffffff 4px, #ffffff 8px);
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    position: relative;
    margin-bottom: 10px;
    max-width: 300px;
    width: 100%;
    flex: 1;
`;
