import styled from 'styled-components';
import {
    MarginProps,
    FontWeightProps,
    FontSizeProps,
    ColorProps,
    margin,
    fontWeight,
    fontSize,
    color,
    SizeProps,
    TextAlignProps,
    WidthProps,
    FontFamilyProps,
    textAlign,
} from 'styled-system';

export type TextProps = MarginProps &
    FontWeightProps &
    FontSizeProps &
    ColorProps &
    SizeProps &
    TextAlignProps &
    WidthProps &
    FontFamilyProps;
export const Text = styled.p<TextProps>`
    font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.fontSizes.default};
    line-height: 17px;
    ${textAlign}
    ${margin}
    ${fontWeight}
    ${fontSize}
    ${color}
`;
