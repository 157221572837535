import React, { FC } from 'react';

import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon } from '../../../assets/icons';
import { Button, Heading, View, Text, Row } from '../../../components/atoms';
import { Select, TextInput } from '../../../components/molecules/form';
import {
    Location,
    Maybe,
    useCreateMemberCompanyMutation,
    useMemberLocationsQuery,
    UserInput,
} from '../../../service';

type Props = {
    partOfMemberForm?: boolean;
    onDone?: () => void;
};
export const CompanyForm: FC<Props> = ({ partOfMemberForm = false, onDone }) => {
    const { control, handleSubmit, reset } = useForm<UserInput>({
        defaultValues: {
            name: '',
            locationId: undefined,
        },
    });

    const [createCompany, { loading: companyCreationLoading }] = useCreateMemberCompanyMutation({
        refetchQueries: ['MemberCompanies'],
    });
    const { data: locationsData } = useMemberLocationsQuery();

    const submit = handleSubmit(async (data) => {
        const { name, locationId } = data;

        const payload = {
            name,
            locations: [{ locationId }],
        };

        await createCompany({
            variables: {
                company: {
                    ...payload,
                },
            },
        });
        reset();
        onDone && onDone();
    });

    return (
        <CompanyFormWrapper
            px={!partOfMemberForm ? [20, 20, 40] : 0}
            py={!partOfMemberForm ? 40 : 0}
            style={{ minHeight: 250 }}
        >
            {partOfMemberForm ? (
                <BackLink
                    onClick={() => onDone && onDone()}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <LeftArrowIcon />
                    <Text fontSize={'medium'}>Back to new member</Text>
                </BackLink>
            ) : (
                <></>
            )}

            <Heading>Create a company</Heading>
            <View mt={20}>
                <TextInput
                    placeholder="Company name"
                    label="Company name"
                    name="name"
                    control={control}
                />
            </View>
            <View mt={10}>
                <Select
                    label="Location"
                    control={control}
                    name="locationId"
                    values={
                        locationsData?.locations?.map(
                            (location: Maybe<DeepPartial<Location>>) => location!.id!,
                        ) || []
                    }
                    labels={
                        locationsData?.locations?.map(
                            (location: Maybe<DeepPartial<Location>>) => location!.name!,
                        ) || []
                    }
                />
            </View>
            <Button
                mt={30}
                mb={20}
                onClick={() => !companyCreationLoading && submit()}
                loading={companyCreationLoading}
            >
                Create
            </Button>
        </CompanyFormWrapper>
    );
};
const CompanyFormWrapper = styled(View)``;

const BackLink = styled(Row)`
    cursor: pointer;
    margin: 8px 0 20px 0;
    text-decoration: underline;
`;
