import React, { FC, useEffect, useState } from 'react';

import { DeepPartial, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LeftArrowIcon, LocationPinIcon, RightArrowIcon } from '../../../assets/icons';
import { Button, Heading, LinkButton, Row, Text, View } from '../../../components/atoms';
import { Select, TextInput } from '../../../components/molecules/form';
import { useFlag } from '../../../hooks';
import {
    Company,
    Maybe,
    Role,
    useMemberCompaniesQuery,
    useMemberLocationsQuery,
    UserInput,
    useUserCreateMutation,
} from '../../../service';
import { Theme } from '../../../theme';
import { CompanyForm } from './CompanyForm';

export enum Steps {
    FullName,
    Email,
    Company,
    Location,
    Confirm,
}

type Props = {
    onDone?: () => void;
};

export const MemberForm: FC<Props> = ({ onDone }) => {
    const { data: locationsData } = useMemberLocationsQuery();
    const { data: companyData } = useMemberCompaniesQuery();

    const initialStep = Steps.FullName;
    const [step, setStep] = useState<Steps>(initialStep);
    const [formCompletedOnce, setFormCompletedOnce] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState<null | string>();
    const [companyFormVisible, showCompanyForm, hideCompanyForm] = useFlag(false);

    const [createMember, { loading: memberCreationLoading }] = useUserCreateMutation({
        refetchQueries: ['Members'],
    });

    const { control, handleSubmit, reset, getValues, watch } = useForm<UserInput>({
        defaultValues: {
            name: '',
            email: '',
            companyId: undefined,
        },
    });

    const name = watch('name');
    const email = watch('email');
    const companyId = watch('companyId');

    const resetForm = () => {
        reset();
        setStep(initialStep);
        setSelectedLocationId(null);
        setFormCompletedOnce(false);
    };

    const submit = handleSubmit(async ({ companyId, email, name }) => {
        const payload = {
            name,
            companyId,
            email,
            locationId: selectedLocationId!,
            role: Role.Member,
        };

        await createMember({
            variables: {
                input: {
                    ...payload,
                },
            },
            refetchQueries: ['Members'],
        });
        resetForm();
        onDone && onDone();
    });

    const getSelectedCompanyName = () => {
        return companyData?.companies?.find((company) => company?.id === companyId)?.name || '';
    };

    const getSelectedLocationName = () => {
        return (
            locationsData?.locations?.find((location) => location?.id === selectedLocationId)
                ?.name || ''
        );
    };

    useEffect(() => {
        if (!formCompletedOnce && step === Steps.Confirm) {
            setFormCompletedOnce(true);
        }
    }, [step]);

    return (
        <View px={[20, 20, 40]} py={40} minHeight={step === Steps.Company ? 500 : 0}>
            {!companyFormVisible && (
                <>
                    {step !== initialStep && !formCompletedOnce && (
                        <Row
                            alignItems={'center'}
                            width={'fit-content'}
                            mb={25}
                            ml={-5}
                            onClick={() => {
                                if (formCompletedOnce) {
                                    setStep(Steps.Confirm);
                                } else {
                                    setStep(step.valueOf() - 1);
                                }
                            }}
                        >
                            <LeftArrow width={20} height={18} />
                            <LinkButton>Back</LinkButton>
                        </Row>
                    )}
                    <Heading>
                        {step === Steps.Confirm ? 'Confirm New Member' : 'Add New Member'}
                    </Heading>
                    {step === Steps.FullName && (
                        <>
                            <Text mt={10} mb={10}>
                                What is the full name of your new member?
                            </Text>
                            <View mt={20}>
                                <TextInput
                                    placeholder="Type full name..."
                                    name="name"
                                    control={control}
                                />
                            </View>
                            <Button
                                mt={30}
                                disabled={name.length < 2}
                                onClick={() => {
                                    if (formCompletedOnce) {
                                        setStep(Steps.Confirm);
                                    } else {
                                        setStep(Steps.Email);
                                    }
                                }}
                            >
                                {formCompletedOnce ? 'Save' : 'Next'}
                            </Button>
                        </>
                    )}
                    {step === Steps.Email && (
                        <>
                            <Text mt={10} mb={10}>
                                What is the member’s email?
                            </Text>
                            <View mt={20}>
                                <TextInput
                                    placeholder="Type email..."
                                    name="email"
                                    control={control}
                                />
                            </View>
                            <Button
                                mt={30}
                                disabled={email.length < 5}
                                onClick={() => {
                                    if (formCompletedOnce) {
                                        setStep(Steps.Confirm);
                                    } else {
                                        setStep(Steps.Company);
                                    }
                                }}
                            >
                                {formCompletedOnce ? 'Save' : 'Next'}
                            </Button>
                        </>
                    )}
                    {step === Steps.Company && (
                        <>
                            <Text mt={10} mb={10}>
                                What is their company?
                            </Text>
                            <View mt={20}>
                                <Select
                                    label="Company"
                                    control={control}
                                    name="companyId"
                                    values={
                                        companyData?.companies?.map(
                                            (company: Maybe<DeepPartial<Company>>) => company!.id!,
                                        ) || []
                                    }
                                    labels={
                                        companyData?.companies?.map(
                                            (company: Maybe<DeepPartial<Company>>) =>
                                                company!.name!,
                                        ) || []
                                    }
                                />
                            </View>
                            <NewCompanyLink
                                onClick={showCompanyForm}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                            >
                                <Text fontSize={'medium'}>Create new company</Text>
                                <RightArrowIcon />
                            </NewCompanyLink>
                            <Button
                                mt={30}
                                disabled={!companyId}
                                onClick={() => {
                                    if (formCompletedOnce) {
                                        setStep(Steps.Confirm);
                                    } else {
                                        setStep(Steps.Location);
                                    }
                                }}
                            >
                                {formCompletedOnce ? 'Save' : 'Next'}
                            </Button>
                        </>
                    )}
                    {step === Steps.Location && (
                        <>
                            <Text mt={10} mb={10}>
                                What is their location?
                            </Text>
                            <View mt={20} mb={10}>
                                <FieldsWrapper>
                                    {locationsData?.locations!.map((location) => (
                                        <SelectableField
                                            active={selectedLocationId === location?.id}
                                            onClick={() => setSelectedLocationId(location?.id)}
                                            key={location?.id}
                                            alignItems={'center'}
                                        >
                                            <PinIcon color="black" />
                                            <Text>{location?.name}</Text>
                                        </SelectableField>
                                    ))}
                                </FieldsWrapper>
                            </View>
                            <Text fontSize={'medium'} color={Theme.colors.text.secondary}>
                                <span
                                    style={{ fontWeight: 'bold', color: Theme.colors.text.primary }}
                                >
                                    Note:
                                </span>{' '}
                                You have to choose at least one service location. You can change
                                this or add another location later in profile settings.
                            </Text>
                            <Button
                                mt={30}
                                disabled={!selectedLocationId}
                                onClick={() => {
                                    setStep(Steps.Confirm);
                                }}
                            >
                                Next
                            </Button>
                        </>
                    )}
                    {step === Steps.Confirm && (
                        <>
                            <Text mt={10} mb={10}>
                                Does this look ok?
                            </Text>
                            <View mt={20} mb={10}>
                                <FieldsWrapper>
                                    <Field key={'fullName'}>
                                        <View
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'flex-start'}
                                            mb={5}
                                        >
                                            <Text fontWeight={'bold'} fontSize={12}>
                                                User Name
                                            </Text>
                                            <EditButton onClick={() => setStep(Steps.FullName)}>
                                                Edit
                                            </EditButton>
                                        </View>
                                        <Text fontSize={12}>{getValues().name}</Text>
                                    </Field>
                                    <Field key={'email'}>
                                        <View
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'flex-start'}
                                            mb={5}
                                        >
                                            <Text fontWeight={'bold'} fontSize={12}>
                                                Email
                                            </Text>
                                            <EditButton onClick={() => setStep(Steps.Email)}>
                                                Edit
                                            </EditButton>
                                        </View>
                                        <Text fontSize={12}>{getValues().email}</Text>
                                    </Field>
                                    <Field key={'company'}>
                                        <View
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'flex-start'}
                                            mb={5}
                                        >
                                            <Text fontWeight={'bold'} fontSize={12}>
                                                Company
                                            </Text>
                                            <EditButton onClick={() => setStep(Steps.Company)}>
                                                Edit
                                            </EditButton>
                                        </View>
                                        <Text fontSize={12}>{getSelectedCompanyName()}</Text>
                                    </Field>
                                    <Field key={'location'}>
                                        <View
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'flex-start'}
                                            mb={5}
                                        >
                                            <Text fontWeight={'bold'} fontSize={12}>
                                                Location
                                            </Text>
                                            <EditButton onClick={() => setStep(Steps.Location)}>
                                                Edit
                                            </EditButton>
                                        </View>
                                        <View display={'flex'} alignItems={'center'}>
                                            <PinIcon color="black" mr={2} />
                                            <Text fontSize={12}>{getSelectedLocationName()}</Text>
                                        </View>
                                    </Field>
                                    <Field key={'role'}>
                                        <View
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'flex-start'}
                                            mb={5}
                                        >
                                            <Text fontWeight={'bold'} fontSize={12}>
                                                Role
                                            </Text>
                                            <EditButton onClick={() => setStep(Steps.Location)}>
                                                Edit
                                            </EditButton>
                                        </View>
                                        <Text fontSize={12}>Member</Text>
                                    </Field>
                                </FieldsWrapper>
                            </View>
                            <Button mt={30} loading={memberCreationLoading} onClick={submit}>
                                Confirm
                            </Button>
                        </>
                    )}
                </>
            )}
            {companyFormVisible && <CompanyForm partOfMemberForm={true} onDone={hideCompanyForm} />}
        </View>
    );
};

const FieldsWrapper = styled(View)`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const Field = styled(Row)<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 15px 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: ${Theme.colors.background.primary};
    min-width: 140px;
`;

const SelectableField = styled(Row)<{ active?: boolean }>`
    font-size: 16px;
    padding: 15px 20px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: ${Theme.colors.background.primary};
    border: 1px solid;
    border-color: ${(props) =>
        props.active ? Theme.colors.background.dark : Theme.colors.background.primary};
    &:hover {
        background: #e3e3e3;
        cursor: pointer;
    }
`;

const PinIcon = styled(LocationPinIcon)<{ mr?: number }>`
    margin-right: ${(props) => (props.mr ? props.mr : 7)}px;
`;

const EditButton = styled(Text)`
    color: ${Theme.colors.text.secondary};
    font-size: 12px;
    &:hover {
        cursor: pointer;
    }
`;

const LeftArrow = styled(LeftArrowIcon)`
    &:hover {
        cursor: pointer;
    }
`;

const NewCompanyLink = styled(Row)`
    cursor: pointer;
    margin: 8px 0;
    text-decoration: underline;
`;
