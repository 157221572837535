import React, { FC } from 'react';

import styled from 'styled-components';

import { LocationPinIcon } from '../../../../assets/icons';
import { Heading, Row, View } from '../../../../components/atoms';
import { Text } from '../../../../components/atoms';
import { AccessLocation } from '../../../../service';
import { Theme } from '../../../../theme';
type Props = {
    locations: AccessLocation[];
    currentLocation: AccessLocation | undefined;
    onSelected: (location: AccessLocation) => void;
};
export const LocationSwitch: FC<Props> = ({ locations, onSelected, currentLocation }) => {
    return (
        <Wrapper>
            <Heading>Change Location</Heading>
            <Text mt={10}>To change your access location, select one of the locations below.</Text>
            <Locations>
                {locations.map((location) => (
                    <Location
                        active={currentLocation?.id == location.id}
                        onClick={() => onSelected(location)}
                        key={location.id}
                        alignItems={'center'}
                    >
                        <PinIcon color="black" />
                        <Text>{location.name}</Text>
                    </Location>
                ))}
            </Locations>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Locations = styled(View)`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const Location = styled(Row)<{ active: boolean }>`
    font-size: 16px;
    padding: 15px 20px;
    font-weight: bold;
    margin-bottom: 5px;
    border-radius: 5px;
    background: ${(props) =>
        props.active ? Theme.colors.background.primary : Theme.colors.background.light};
    &:hover {
        background: #e3e3e3;
        cursor: pointer;
    }
`;

const PinIcon = styled(LocationPinIcon)`
    margin-right: 7px;
`;
