import {
    DashboardIcon,
    EForceIcon,
    FlexStorageIcon,
    FulfillmentIcon,
    RentalEquipmentIcon,
} from '../../../assets/icons';
import { MenuSection } from '../models/MenuSection';

export const AssociateMenu: MenuSection[] = [
    {
        name: 'General',
        showNameInMenu: false,
        items: [
            {
                name: 'Dashboard',
                icon: DashboardIcon,
                route: '/',
            },
        ],
    },
    {
        name: 'Services',
        showNameInMenu: true,
        items: [
            {
                name: 'eForce',
                icon: EForceIcon,
                route: 'eforce/my-schedule',
                subItems: [
                    {
                        name: 'My Schedule',
                        route: 'eforce/my-schedule',
                    },
                ],
            },
            {
                name: 'Flex Storage',
                icon: FlexStorageIcon,
                route: '/flex-storage',
                subItems: [
                    {
                        name: 'Requests',
                        route: '/flex-storage/requests',
                    },
                    {
                        name: 'Stored',
                        route: '/flex-storage/stored',
                    },
                ],
            },
            {
                name: 'Fulfillment',
                icon: FulfillmentIcon,
                route: 'https://www.saltbox.com/fulfillment',
                externalLink: true,
            },
            /*{
                name: 'Rental Equipment',
                icon: RentalEquipmentIcon,
                route: '/rental-equipment',
            },*/
        ],
    },
];
