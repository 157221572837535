import React, { useMemo } from 'react';

import { differenceInHours, format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { BrowserView, MobileView } from 'react-device-detect';
import { DeepPartial } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { RightArrowIcon } from '../../../../../assets/icons';
import { Heading, Row, View, Text, Column } from '../../../../../components/atoms';
import { LoadingIndicator } from '../../../../../components/molecules/LoadingIndicator';
import { useUser } from '../../../../../providers';
import { BookingResponse, Maybe, useGetEforceMemberBookingsQuery } from '../../../../../service';
import { DateUtils } from '../../../../../util/date';
import { MemberBookingCard } from '../../../components/MemberBookingCard';

const EFORCE_BOOKING_FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const EForceSummary = () => {
    const { user, currentServiceLocation } = useUser();

    const timezone = useMemo(
        () => currentServiceLocation?.zone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        [currentServiceLocation],
    );
    const { data: bookingsData, loading } = useGetEforceMemberBookingsQuery({
        variables: {
            companyId: user?.company?.id,
            date: {
                from: format(
                    DateUtils.getBeginningOfCurrentMonth(),
                    EFORCE_BOOKING_FILTER_DATE_FORMAT,
                ),
                to: format(DateUtils.getEndOfCurrentMonth(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
            },
            today: format(new Date(), EFORCE_BOOKING_FILTER_DATE_FORMAT),
        },
    });

    const billableDuration = (bookings?: Maybe<Array<Maybe<DeepPartial<BookingResponse>>>>) => {
        return (
            (bookings
                ? bookings.reduce((sum, booking) => {
                      if (booking) {
                          const { timeTrackingSummary } = booking;
                          return sum + (timeTrackingSummary?.billableTimeMinutes || 0);
                      }
                      return sum;
                  }, 0)
                : 0) / 60.0
        ).toFixed(2);
    };

    const bookedDuration = (bookings?: Maybe<Array<Maybe<DeepPartial<BookingResponse>>>>) => {
        return (
            bookings
                ? bookings.reduce((sum, booking) => {
                      if (booking?.slot?.startTime && booking?.slot?.endTime) {
                          const { startTime, endTime } = booking.slot;
                          return sum + differenceInHours(parseISO(endTime!), parseISO(startTime!));
                      }
                      return sum;
                  }, 0)
                : 0
        ).toFixed(2);
    };

    const nextBooking = useMemo(() => {
        if (bookingsData && bookingsData.upcoming) {
            return bookingsData.upcoming?.filter(Boolean).sort((a, b) => {
                return a?.slot?.startTime > b?.slot?.startTime ? 1 : -1;
            })?.[0];
        }
        return null;
    }, [bookingsData]);

    return (
        <>
            <BrowserView>
                <Heading>Summary</Heading>
            </BrowserView>

            <FlexContainer mt={[0, 30, 50]} flexDirection={['column', 'column', 'row']}>
                <SummaryWrapper
                    width={'100%'}
                    mt={[30, 0, 0]}
                    mr={[0, 0, 15]}
                    px={20}
                    py={[25, 25, 20]}
                >
                    <Row>
                        <FlexContainer flexDirection={['column', 'column', 'row']} mr={22}>
                            <Column>
                                <Text fontWeight={'bold'}>Booked</Text>
                                <Text fontSize={'medium'} mt={5} color={'#505354'}>
                                    (Upcoming hours this month)
                                </Text>
                            </Column>
                            <Text fontWeight={'bold'} fontSize={'large'} mt={[41, 41, 0]}>
                                {bookedDuration(bookingsData?.upcoming)} hrs
                            </Text>
                        </FlexContainer>
                        <Line />
                        <FlexContainer flexDirection={['column', 'column', 'row']} ml={22}>
                            <Column>
                                <Text fontWeight={'bold'}>Billable</Text>
                                <Text fontSize={'medium'} mt={5} color={'#505354'}>
                                    (Completed hours this month)
                                </Text>
                            </Column>
                            <Text fontWeight={'bold'} fontSize={'large'} mt={[41, 41, 0]}>
                                {billableDuration(bookingsData?.approved)} hrs
                            </Text>
                        </FlexContainer>
                    </Row>
                </SummaryWrapper>

                <ReportsLink to={'/eforce/reports'}>
                    <ReportsLinkContent
                        height={80}
                        width={'100%'}
                        mt={[15, 15, 0]}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text fontWeight="bold">Previous eForce Reports</Text>
                        <RightArrowIcon />
                    </ReportsLinkContent>
                </ReportsLink>
            </FlexContainer>

            <Heading fontSize={16} mt={30} mb={20}>
                Next Scheduled Request
            </Heading>
            {loading ? (
                <Row justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : null}

            {nextBooking ? (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MemberBookingCard booking={nextBooking} timezone={timezone} />
                    </motion.div>
                </AnimatePresence>
            ) : (
                <Text color={'text.secondary'}>No upcoming bookings</Text>
            )}
        </>
    );
};

const FlexContainer = styled(View)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ReportsLink = styled(NavLink)`
    min-width: 280px;
    text-decoration: none;
    color: #000000;
`;

const ReportsLinkContent = styled(Row)`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #00000012;
`;

const SummaryWrapper = styled(View)`
    border-radius: 10px;
    background-color: #e3e3e3;
`;

const Line = styled.div`
    width: 1px;
    background-color: #bdbbbc;
`;
