import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Row } from '../atoms';

export const Tabs = styled(Row)`
    background-color: white;
    border-radius: 5px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #00000012;
    overflow: hidden;
`;
export const Tab = styled(NavLink)`
    font-family: Inter, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: black;
    padding: 10px 15px;
    flex: 1;
    text-align: center;
    &.active {
        background-color: black;
        color: white;
    }
`;
