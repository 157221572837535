import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { View } from '../../atoms';
import { ControlledInputProps } from './ControlledInputProps';
import { Label } from './Label';

type Props = { label?: string } & React.ComponentPropsWithoutRef<'textarea'>;
const TextAreaComponent: FC<Props> = ({ label, ...rest }) => {
    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <TextInputField {...rest} />
        </Wrapper>
    );
};

export const TextArea: FC<Props & ControlledInputProps> = ({ control, name, ...rest }) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            render={({ field: { onChange, value } }) => (
                <TextAreaComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <TextAreaComponent {...rest} />
    );
};

const Wrapper = styled(View)``;

const TextInputField = styled.textarea`
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 15px 14px;
    font-family: 'Inter', serif;
    font-size: 15px;
    min-height: 158px;
    color: black;
    width: 100%;
`;
