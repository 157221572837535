import React, { useRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PeopleIcon, BlockTimeIcon, RightArrowIcon } from '../../../../assets/icons';
import { Column, Heading, Row, Text, View } from '../../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../../components/organisms';
import { BlockTimeForm } from '../../components/BlockTimeForm';
import { BuildingAccess } from '../../components/BuildingAccess/BuildingAccess';

export const Dashboard = () => {
    const navigate = useNavigate();
    const bottomSheetRef = useRef<BottomSheetRef>(null);
    const { associateTimeBlocking } = useFlags();
    return (
        <Column>
            <BuildingAccess />
            <Heading mt={30}>Quick Actions</Heading>
            <QuickAccessCard mt={15} onClick={() => navigate('/eforce/my-schedule')}>
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center" pl={1}>
                        <PeopleIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Book eForce for members
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            {associateTimeBlocking && (
                <QuickAccessCard mt={15} onClick={() => bottomSheetRef?.current?.show()}>
                    <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                        <Row alignItems="center" pl={1}>
                            <View></View>
                            <BlockTimeIconWrapper>
                                <BlockTimeIcon width={25} height={25} />
                            </BlockTimeIconWrapper>
                            <Text ml={10} mr={10}>
                                Block My Time
                            </Text>
                        </Row>
                        <RightArrowIcon />
                    </Row>
                </QuickAccessCard>
            )}
            <BottomSheet variant={'narrow'} ref={bottomSheetRef}>
                <BlockTimeForm onDone={() => bottomSheetRef?.current?.hide()}></BlockTimeForm>
            </BottomSheet>
        </Column>
    );
};

const BlockTimeIconWrapper = styled(View)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: -3.5px;
    background: #fcf2ed;
    border-radius: 5px;
`;

const QuickAccessCard = styled(Row)`
    min-height: 50px;
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    align-items: center;
    cursor: pointer;
`;
