import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    DateTime: any;
    LocalTime: any;
};

export type AccessAudit = {
    __typename?: 'AccessAudit';
    accessResult: AccessResult;
    accessedBy: User;
    accessedWhen: Scalars['DateTime'];
    door: Door;
};

export type AccessAuditFilter = {
    doorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    range: DateInput;
    userId?: InputMaybe<Scalars['ID']>;
};

export type AccessLocation = {
    __typename?: 'AccessLocation';
    doors: Array<Maybe<Door>>;
    id: Scalars['ID'];
    name: Scalars['String'];
};

export enum AccessResult {
    Error = 'ERROR',
    Granted = 'GRANTED',
}

export type AssignCompanyToLocation = {
    companyId: Scalars['ID'];
    locationId: Scalars['ID'];
};

export type AssignCompanyToLocations = {
    companyId: Scalars['ID'];
    locations: Array<InputMaybe<Scalars['ID']>>;
};

export type AssignUserToRolesInput = {
    roles: Array<Role>;
    userId: Scalars['ID'];
};

export type AssociateFilterInput = {
    locationId?: InputMaybe<Scalars['ID']>;
};

export type AuditingResponse = {
    __typename?: 'AuditingResponse';
    by?: Maybe<User>;
};

export type AvailabilityRequestInput = {
    assigneeId?: InputMaybe<Scalars['ID']>;
    bookingToExclude?: InputMaybe<Scalars['ID']>;
    date: Scalars['Date'];
    duration: Scalars['Int'];
    locationId: Scalars['ID'];
};

export type AvailabilityResponse = {
    __typename?: 'AvailabilityResponse';
    slots: Array<Maybe<AvailableTimeSlot>>;
};

export type AvailableTimeSlot = {
    __typename?: 'AvailableTimeSlot';
    associates: Array<Maybe<User>>;
    startTime: Scalars['DateTime'];
};

export type Binary = {
    __typename?: 'Binary';
    id: Scalars['ID'];
    status?: Maybe<UploadStatus>;
    url?: Maybe<Scalars['String']>;
};

export type BlockingTimeRequestInput = {
    dateTimeSlot: DateTimeRange;
    description: Scalars['String'];
    locationId: Scalars['ID'];
    reason: Scalars['String'];
};

export type BookedTimeSlot = {
    __typename?: 'BookedTimeSlot';
    /** @deprecated No longer supported */
    date?: Maybe<Scalars['Date']>;
    endTime: Scalars['DateTime'];
    startTime: Scalars['DateTime'];
};

export type BookingCompletedResponse = {
    __typename?: 'BookingCompletedResponse';
    billableTimeMinutes?: Maybe<Scalars['Int']>;
    booking: BookingResponse;
    breaksMinutes?: Maybe<Scalars['Int']>;
    finished?: Maybe<Scalars['DateTime']>;
    sessionDurationMinutes?: Maybe<Scalars['Int']>;
    started?: Maybe<Scalars['DateTime']>;
};

export type BookingEmailReportInput = {
    from: Scalars['Date'];
    receiver: Scalars['String'];
    to: Scalars['Date'];
};

export type BookingFilterInput = {
    associateId?: InputMaybe<Scalars['ID']>;
    bookingId?: InputMaybe<Scalars['ID']>;
    bookingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    companyId?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<FilterDateRange>;
    locationId?: InputMaybe<Scalars['ID']>;
    pageable?: InputMaybe<Pageable>;
    state?: InputMaybe<BookingState>;
    status?: InputMaybe<BookingStatus>;
};

export type BookingRequestInput = {
    companyId: Scalars['ID'];
    dateTimeSlot: DateTimeRange;
    description: Scalars['String'];
    locationId: Scalars['ID'];
    preferredAssociateId?: InputMaybe<Scalars['ID']>;
};

export type BookingResponse = {
    __typename?: 'BookingResponse';
    assignee: User;
    company?: Maybe<Company>;
    created?: Maybe<AuditingResponse>;
    description: Scalars['String'];
    id: Scalars['ID'];
    location: ServiceLocation;
    reason?: Maybe<Scalars['String']>;
    scheduleType?: Maybe<ScheduleType>;
    slot: BookedTimeSlot;
    state: BookingState;
    status: BookingStatus;
    timeTrackingSummary?: Maybe<BookingCompletedResponse>;
    updated?: Maybe<AuditingResponse>;
};

export enum BookingState {
    Active = 'ACTIVE',
    Deleted = 'DELETED',
}

export enum BookingStatus {
    Approved = 'Approved',
    Completed = 'Completed',
    InProgress = 'InProgress',
    NotStarted = 'NotStarted',
    Paused = 'Paused',
}

export type ChannelMappingInput = {
    domain: DomainType;
    webHookUrl: Scalars['String'];
};

export type Company = {
    __typename?: 'Company';
    id: Scalars['ID'];
    locations: Array<Maybe<CompanyLocation>>;
    memberships: Array<Membership>;
    name: Scalars['String'];
    state: State;
};

export type CompanyFilterInput = {
    locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CompanyInput = {
    locations?: InputMaybe<Array<InputMaybe<CompanyLocationInput>>>;
    memberships?: InputMaybe<Array<InputMaybe<MembershipInput>>>;
    name: Scalars['String'];
};

export type CompanyLocation = {
    __typename?: 'CompanyLocation';
    extras?: Maybe<Extras>;
    id: Scalars['ID'];
    name: Scalars['String'];
    state: State;
};

export type CompanyLocationExtrasInput = {
    shipHeroId?: InputMaybe<Scalars['String']>;
};

export type CompanyLocationInput = {
    extras?: InputMaybe<CompanyLocationExtrasInput>;
    locationId: Scalars['ID'];
};

export type CompanyRestrictInput = {
    id: Scalars['ID'];
    reason?: InputMaybe<Scalars['String']>;
};

export type Cost = {
    __typename?: 'Cost';
    amount?: Maybe<Scalars['Int']>;
};

export type DateInput = {
    from: Scalars['Date'];
    to: Scalars['Date'];
};

export type DateTimeRange = {
    date?: InputMaybe<Scalars['Date']>;
    duration: Scalars['Int'];
    startTime: Scalars['DateTime'];
};

export enum DomainType {
    Storage = 'Storage',
    EForce = 'eForce',
    EForceReporting = 'eForceReporting',
}

export type Door = {
    __typename?: 'Door';
    description?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    lock?: Maybe<Lock>;
    name: Scalars['String'];
};

export type DoorCreateInput = {
    description?: InputMaybe<Scalars['String']>;
    externalId: Scalars['Int'];
    locationId: Scalars['ID'];
    name: Scalars['String'];
};

export type DoorInput = {
    description?: InputMaybe<Scalars['String']>;
    locationId: Scalars['ID'];
    name: Scalars['String'];
};

export type DoorPreference = {
    __typename?: 'DoorPreference';
    doorId: Scalars['String'];
    id: Scalars['ID'];
    userId: Scalars['String'];
};

export type DoorUpdateInput = {
    description?: InputMaybe<Scalars['String']>;
    externalId: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

export enum EForceSessionStatus {
    Approved = 'Approved',
    Completed = 'Completed',
    InProgress = 'InProgress',
    NotStarted = 'NotStarted',
    Paused = 'Paused',
}

export type EForceSessionSummary = {
    __typename?: 'EForceSessionSummary';
    totalApprovedMinutes: Scalars['Int'];
    totalTrackedMinutes: Scalars['Int'];
};

export type EForceSubscriptionSlotInput = {
    from: Scalars['LocalTime'];
    to: Scalars['LocalTime'];
};

export type EForceSubscriptionSlotResponse = {
    __typename?: 'EForceSubscriptionSlotResponse';
    from: Scalars['LocalTime'];
    to: Scalars['LocalTime'];
};

export enum EForceSubscriptionStatus {
    Active = 'Active',
    Canceled = 'Canceled',
}

export type EForceSummaryFilterInput = {
    date?: InputMaybe<DateInput>;
};

export type EForceSummaryResponse = {
    __typename?: 'EForceSummaryResponse';
    sessionSummary?: Maybe<EForceSessionSummary>;
};

export type EditStorageRequestInput = {
    companyId: Scalars['ID'];
    description: Scalars['String'];
    id: Scalars['ID'];
    photoId?: InputMaybe<Scalars['ID']>;
};

export type Extras = {
    __typename?: 'Extras';
    shipHeroId?: Maybe<Scalars['String']>;
};

export type FilterDateRange = {
    from?: InputMaybe<Scalars['Date']>;
    to?: InputMaybe<Scalars['Date']>;
};

export type GenerateUploadLinkInput = {
    companyId: Scalars['String'];
    fileName: Scalars['String'];
};

export type GenerateUploadLinkResult = {
    __typename?: 'GenerateUploadLinkResult';
    id: Scalars['ID'];
    uploadLink: Scalars['String'];
};

export type Location = {
    __typename?: 'Location';
    companies: Array<Maybe<LocationCompany>>;
    doors?: Maybe<Array<Maybe<Door>>>;
    id: Scalars['ID'];
    name: Scalars['String'];
    slackConfiguration?: Maybe<SlackConfiguration>;
    zone?: Maybe<Scalars['String']>;
};

export type LocationCompany = {
    __typename?: 'LocationCompany';
    id: Scalars['ID'];
    name: Scalars['String'];
    state: State;
};

export type LocationCreateInput = {
    name: Scalars['String'];
    zone: Scalars['String'];
};

export type LocationUpdateInput = {
    id: Scalars['ID'];
    name: Scalars['String'];
    zone: Scalars['String'];
};

export type Lock = {
    __typename?: 'Lock';
    id: Scalars['ID'];
    state?: Maybe<LockState>;
};

export type LockInput = {
    doorId: Scalars['ID'];
    externalId: Scalars['Int'];
};

export enum LockState {
    Locked = 'LOCKED',
    Unlocked = 'UNLOCKED',
}

export type Membership = {
    __typename?: 'Membership';
    id: Scalars['ID'];
    product: Product;
};

export type MembershipInput = {
    productId: Scalars['ID'];
};

export type ModifyAssociateInput = {
    fullName: Scalars['String'];
    id: Scalars['ID'];
    phoneNumber: Scalars['String'];
    workHoursEnd: Scalars['String'];
    workHoursStart: Scalars['String'];
};

export type ModifyBookingRequestInput = {
    bookingId: Scalars['ID'];
    companyId: Scalars['ID'];
    dateTimeSlot: DateTimeRange;
    description: Scalars['String'];
    locationId: Scalars['ID'];
    preferredAssociateId?: InputMaybe<Scalars['ID']>;
};

export type ModifySlackChannelInput = {
    channel: ChannelMappingInput;
    id: Scalars['ID'];
};

export type Mutation = {
    __typename?: 'Mutation';
    addAssociateToLocation?: Maybe<User>;
    addDoorPreference?: Maybe<DoorPreference>;
    addSlackChannelsToLocation?: Maybe<Location>;
    adjustSession?: Maybe<BookingCompletedResponse>;
    approveSession?: Maybe<BookingResponse>;
    /** @deprecated Use companyAssignToLocation action instead! */
    assignCompanyToLocations?: Maybe<Company>;
    assignRolesToUser: User;
    assignServiceAccess?: Maybe<User>;
    block?: Maybe<BookingResponse>;
    book?: Maybe<BookingResponse>;
    cancelBooking?: Maybe<BookingResponse>;
    cancelStorageRequest?: Maybe<StorageItem>;
    cancelStorageReturnRequest?: Maybe<StorageItem>;
    companyAssignToLocation?: Maybe<Company>;
    companyClearRestriction?: Maybe<Company>;
    companyRemoveFromLocation?: Maybe<Company>;
    companyRestrict?: Maybe<Company>;
    /** @deprecated No longer supported */
    complete: TimeTrackingResponse;
    completeWithSessionSummary: BookingCompletedResponse;
    confirmBinaryUpload?: Maybe<Binary>;
    /** @deprecated Use userCreate action instead! */
    createCentralUser?: Maybe<User>;
    /** @deprecated Use userCreate action instead! */
    createComUser?: Maybe<User>;
    createCompany?: Maybe<Company>;
    /** @deprecated Use doorCreate instead */
    createDoor?: Maybe<Door>;
    /** @deprecated Use locationCreate instead */
    createLocation?: Maybe<Location>;
    createLock?: Maybe<Lock>;
    /** @deprecated Use userCreate action instead! */
    createMemberUser?: Maybe<User>;
    createProduct?: Maybe<Product>;
    deleteBooking?: Maybe<BookingResponse>;
    deleteCompany?: Maybe<Company>;
    deleteLock?: Maybe<Lock>;
    deleteProduct?: Maybe<Product>;
    /** @deprecated Use userDelete action instead! */
    deleteUser?: Maybe<User>;
    doorCreate?: Maybe<Door>;
    doorUpdate?: Maybe<Door>;
    editStorageRequest?: Maybe<StorageItem>;
    generateUploadLink?: Maybe<GenerateUploadLinkResult>;
    grantUserAccessToLocation?: Maybe<User>;
    locationCreate?: Maybe<Location>;
    locationUpdate?: Maybe<Location>;
    modifyAssociate?: Maybe<Scalars['String']>;
    modifyBooking?: Maybe<BookingResponse>;
    pause: TimeTrackingResponse;
    removeAssociateFromLocation?: Maybe<User>;
    /** @deprecated Use companyRemoveFromLocation action instead! */
    removeCompanyFromLocation?: Maybe<Company>;
    removeDoorPreference?: Maybe<DoorPreference>;
    requestStorage?: Maybe<StorageItem>;
    requestStorageReturn?: Maybe<StorageItem>;
    resume: TimeTrackingResponse;
    returnItem?: Maybe<StorageItem>;
    revokeLocationAccess?: Maybe<User>;
    revokeServiceAccess?: Maybe<User>;
    revokeUserAccessFromLocation?: Maybe<User>;
    start: TimeTrackingResponse;
    storeItem?: Maybe<StorageItem>;
    subscriptionEForceAdd?: Maybe<SubscriptionEForceResponse>;
    subscriptionEForceCancel?: Maybe<SubscriptionEForceResponse>;
    subscriptionEForceSessionInitialize?: Maybe<SubscriptionEForceSessionResponse>;
    subscriptionEForceUpdate?: Maybe<SubscriptionEForceResponse>;
    test?: Maybe<Scalars['String']>;
    unlockDoor?: Maybe<Door>;
    updateSlackChannel?: Maybe<Location>;
    userAssignDoor?: Maybe<User>;
    userAssignRole?: Maybe<User>;
    userBlock?: Maybe<User>;
    userClearRestriction?: Maybe<User>;
    userCreate?: Maybe<User>;
    userDelete?: Maybe<User>;
    userRemoveDoor?: Maybe<User>;
    userRestrict?: Maybe<User>;
    userRevokeRole?: Maybe<User>;
    userUnblock?: Maybe<User>;
    userUpdate?: Maybe<User>;
};

export type MutationAddAssociateToLocationArgs = {
    input: UserInput;
};

export type MutationAddDoorPreferenceArgs = {
    doorId: Scalars['ID'];
};

export type MutationAddSlackChannelsToLocationArgs = {
    input: SlackChannelInput;
};

export type MutationAdjustSessionArgs = {
    input: SessionAdjustmentInput;
};

export type MutationApproveSessionArgs = {
    bookingId: Scalars['ID'];
};

export type MutationAssignCompanyToLocationsArgs = {
    input?: InputMaybe<AssignCompanyToLocations>;
};

export type MutationAssignRolesToUserArgs = {
    request?: InputMaybe<AssignUserToRolesInput>;
};

export type MutationAssignServiceAccessArgs = {
    input: UserAccessInput;
};

export type MutationBlockArgs = {
    request: BlockingTimeRequestInput;
};

export type MutationBookArgs = {
    request: BookingRequestInput;
};

export type MutationCancelBookingArgs = {
    bookingId: Scalars['ID'];
};

export type MutationCancelStorageRequestArgs = {
    id: Scalars['ID'];
};

export type MutationCancelStorageReturnRequestArgs = {
    id: Scalars['ID'];
};

export type MutationCompanyAssignToLocationArgs = {
    input?: InputMaybe<AssignCompanyToLocation>;
};

export type MutationCompanyClearRestrictionArgs = {
    id: Scalars['ID'];
};

export type MutationCompanyRemoveFromLocationArgs = {
    input?: InputMaybe<RemoveCompanyFromLocationInput>;
};

export type MutationCompanyRestrictArgs = {
    input: CompanyRestrictInput;
};

export type MutationCompleteArgs = {
    bookingId: Scalars['ID'];
};

export type MutationCompleteWithSessionSummaryArgs = {
    bookingId: Scalars['ID'];
};

export type MutationConfirmBinaryUploadArgs = {
    id: Scalars['ID'];
};

export type MutationCreateCentralUserArgs = {
    user: UserInput;
};

export type MutationCreateComUserArgs = {
    user: UserInput;
};

export type MutationCreateCompanyArgs = {
    company: CompanyInput;
};

export type MutationCreateDoorArgs = {
    door: DoorInput;
};

export type MutationCreateLocationArgs = {
    name: Scalars['String'];
};

export type MutationCreateLockArgs = {
    lock?: InputMaybe<LockInput>;
};

export type MutationCreateMemberUserArgs = {
    user: UserInput;
};

export type MutationCreateProductArgs = {
    product: ProductInput;
};

export type MutationDeleteBookingArgs = {
    bookingId: Scalars['ID'];
};

export type MutationDeleteCompanyArgs = {
    companyId: Scalars['ID'];
};

export type MutationDeleteLockArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteProductArgs = {
    productId: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
    userId: Scalars['ID'];
};

export type MutationDoorCreateArgs = {
    input: DoorCreateInput;
};

export type MutationDoorUpdateArgs = {
    input: DoorUpdateInput;
};

export type MutationEditStorageRequestArgs = {
    request: EditStorageRequestInput;
};

export type MutationGenerateUploadLinkArgs = {
    request: GenerateUploadLinkInput;
};

export type MutationGrantUserAccessToLocationArgs = {
    input: UserAccessInput;
};

export type MutationLocationCreateArgs = {
    input: LocationCreateInput;
};

export type MutationLocationUpdateArgs = {
    input: LocationUpdateInput;
};

export type MutationModifyAssociateArgs = {
    input: ModifyAssociateInput;
};

export type MutationModifyBookingArgs = {
    request: ModifyBookingRequestInput;
};

export type MutationPauseArgs = {
    bookingId: Scalars['ID'];
};

export type MutationRemoveAssociateFromLocationArgs = {
    userId: Scalars['ID'];
};

export type MutationRemoveCompanyFromLocationArgs = {
    input?: InputMaybe<RemoveCompanyFromLocationInput>;
};

export type MutationRemoveDoorPreferenceArgs = {
    preferenceId: Scalars['ID'];
};

export type MutationRequestStorageArgs = {
    request: StorageRequestInput;
};

export type MutationRequestStorageReturnArgs = {
    id: Scalars['ID'];
};

export type MutationResumeArgs = {
    bookingId: Scalars['ID'];
};

export type MutationReturnItemArgs = {
    id: Scalars['ID'];
};

export type MutationRevokeLocationAccessArgs = {
    input?: InputMaybe<UserAccessInput>;
};

export type MutationRevokeServiceAccessArgs = {
    input?: InputMaybe<UserAccessInput>;
};

export type MutationRevokeUserAccessFromLocationArgs = {
    input: UserAccessInput;
};

export type MutationStartArgs = {
    bookingId: Scalars['ID'];
};

export type MutationStoreItemArgs = {
    id: Scalars['ID'];
};

export type MutationSubscriptionEForceAddArgs = {
    input: SubscriptionEForceAddInput;
};

export type MutationSubscriptionEForceCancelArgs = {
    input: SubscriptionEForceCancelInput;
};

export type MutationSubscriptionEForceSessionInitializeArgs = {
    input: SubscriptionEForceSessionInitializeInput;
};

export type MutationSubscriptionEForceUpdateArgs = {
    input: SubscriptionEForceUpdateInput;
};

export type MutationUnlockDoorArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateSlackChannelArgs = {
    input: ModifySlackChannelInput;
};

export type MutationUserAssignDoorArgs = {
    input: UserAssignDoorInput;
};

export type MutationUserAssignRoleArgs = {
    input: UserRoleInput;
};

export type MutationUserBlockArgs = {
    input: UserBlockInput;
};

export type MutationUserClearRestrictionArgs = {
    id: Scalars['ID'];
};

export type MutationUserCreateArgs = {
    input?: InputMaybe<UserCreateInput>;
};

export type MutationUserDeleteArgs = {
    input: UserDeleteInput;
};

export type MutationUserRemoveDoorArgs = {
    input: UserRemoveDoorInput;
};

export type MutationUserRestrictArgs = {
    input: UserRestrictInput;
};

export type MutationUserRevokeRoleArgs = {
    input: UserRoleInput;
};

export type MutationUserUnblockArgs = {
    userId: Scalars['ID'];
};

export type MutationUserUpdateArgs = {
    input: UserUpdateInput;
};

export type Pageable = {
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type PreferredMemberSpecialistResponse = {
    __typename?: 'PreferredMemberSpecialistResponse';
    memberSpecialist: User;
    status: EForceSubscriptionStatus;
};

export type Product = {
    __typename?: 'Product';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type ProductInput = {
    name: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    accessAudits?: Maybe<Array<Maybe<AccessAudit>>>;
    associates?: Maybe<Array<Maybe<User>>>;
    associatesAvailability?: Maybe<AvailabilityResponse>;
    associatesByLocation?: Maybe<Array<Maybe<User>>>;
    binary?: Maybe<Binary>;
    booking: BookingResponse;
    bookingSendEmailReport?: Maybe<Scalars['Boolean']>;
    bookings?: Maybe<Array<Maybe<BookingResponse>>>;
    companies: Array<Maybe<Company>>;
    company?: Maybe<Company>;
    eForceSummary?: Maybe<EForceSummaryResponse>;
    location?: Maybe<Location>;
    locations?: Maybe<Array<Maybe<Location>>>;
    me: User;
    slackConfigurationByLocation?: Maybe<Location>;
    storageItem?: Maybe<StorageItem>;
    storageItems?: Maybe<Array<Maybe<StorageItem>>>;
    subscriptionsEForce?: Maybe<Array<Maybe<SubscriptionEForceResponse>>>;
    triggerReport?: Maybe<Scalars['String']>;
    users?: Maybe<Array<Maybe<User>>>;
};

export type QueryAccessAuditsArgs = {
    filter?: InputMaybe<AccessAuditFilter>;
};

export type QueryAssociatesArgs = {
    filter?: InputMaybe<AssociateFilterInput>;
};

export type QueryAssociatesAvailabilityArgs = {
    request: AvailabilityRequestInput;
};

export type QueryAssociatesByLocationArgs = {
    locationId: Scalars['ID'];
};

export type QueryBinaryArgs = {
    id: Scalars['ID'];
};

export type QueryBookingArgs = {
    id: Scalars['ID'];
};

export type QueryBookingSendEmailReportArgs = {
    input: BookingEmailReportInput;
};

export type QueryBookingsArgs = {
    filter: BookingFilterInput;
};

export type QueryCompaniesArgs = {
    filter?: InputMaybe<CompanyFilterInput>;
};

export type QueryCompanyArgs = {
    id: Scalars['ID'];
};

export type QueryEForceSummaryArgs = {
    filter: EForceSummaryFilterInput;
};

export type QueryLocationArgs = {
    id: Scalars['ID'];
};

export type QuerySlackConfigurationByLocationArgs = {
    locationId: Scalars['ID'];
};

export type QueryStorageItemArgs = {
    id: Scalars['ID'];
};

export type QueryStorageItemsArgs = {
    filter: StorageFilterInput;
};

export type QuerySubscriptionsEForceArgs = {
    input: SubscriptionsEForceFilterInput;
};

export type QueryUsersArgs = {
    filter?: InputMaybe<UsersFilterInput>;
};

export type RemoveCompanyFromLocationInput = {
    companyId: Scalars['ID'];
    locationId: Scalars['ID'];
};

export enum Role {
    Admin = 'ADMIN',
    Associate = 'ASSOCIATE',
    Central = 'CENTRAL',
    Com = 'COM',
    Member = 'MEMBER',
}

export enum ScheduleType {
    BlockingTime = 'BlockingTime',
    Booking = 'Booking',
}

export type ServiceLocation = {
    __typename?: 'ServiceLocation';
    id: Scalars['ID'];
    name: Scalars['String'];
    zone: Scalars['String'];
};

export type SessionAdjustmentInput = {
    bookingId: Scalars['ID'];
    breaks: Scalars['Int'];
    finished: Scalars['DateTime'];
    started: Scalars['DateTime'];
};

export type SlackChannel = {
    __typename?: 'SlackChannel';
    domain: DomainType;
    id: Scalars['ID'];
    webHookUrl: Scalars['String'];
};

export type SlackChannelInput = {
    channels: Array<InputMaybe<ChannelMappingInput>>;
    locationId: Scalars['ID'];
};

export type SlackConfiguration = {
    __typename?: 'SlackConfiguration';
    channels: Array<Maybe<SlackChannel>>;
};

export enum State {
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
    Deleted = 'DELETED',
    Restricted = 'RESTRICTED',
}

export type StorageAuditingInfo = {
    __typename?: 'StorageAuditingInfo';
    at?: Maybe<Scalars['DateTime']>;
    by?: Maybe<User>;
};

export type StorageFilterInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    locations: Array<Scalars['ID']>;
    states?: InputMaybe<Array<InputMaybe<StorageState>>>;
};

export type StorageItem = {
    __typename?: 'StorageItem';
    company?: Maybe<Company>;
    cost?: Maybe<Cost>;
    created?: Maybe<StorageAuditingInfo>;
    description: Scalars['String'];
    id: Scalars['ID'];
    lastUpdated?: Maybe<StorageAuditingInfo>;
    photo?: Maybe<Binary>;
    state: StorageState;
};

export type StorageRequestInput = {
    companyId: Scalars['ID'];
    description: Scalars['String'];
    locationId: Scalars['ID'];
    photoId?: InputMaybe<Scalars['ID']>;
};

export enum StorageState {
    Returned = 'RETURNED',
    ReturnRequested = 'RETURN_REQUESTED',
    StorageRequested = 'STORAGE_REQUESTED',
    StorageRequestCancelled = 'STORAGE_REQUEST_CANCELLED',
    Stored = 'STORED',
}

export type SubscriptionEForceAddInput = {
    companyId: Scalars['ID'];
    locationId: Scalars['ID'];
    preferredMemberSpecialists: Array<Scalars['ID']>;
    slot: EForceSubscriptionSlotInput;
};

export type SubscriptionEForceCancelInput = {
    id: Scalars['ID'];
    reason?: InputMaybe<Scalars['String']>;
};

export type SubscriptionEForceResponse = {
    __typename?: 'SubscriptionEForceResponse';
    company: Company;
    id: Scalars['ID'];
    location: Location;
    preferredMemberSpecialists: Array<Maybe<PreferredMemberSpecialistResponse>>;
    sessions?: Maybe<Array<Maybe<SubscriptionEForceSessionResponse>>>;
    slot: EForceSubscriptionSlotResponse;
};

export type SubscriptionEForceSessionFilterInput = {
    dateRange?: InputMaybe<DateInput>;
    status?: InputMaybe<EForceSessionStatus>;
};

export type SubscriptionEForceSessionInitializeInput = {
    memberSpecialistId: Scalars['ID'];
    subscriptionId: Scalars['ID'];
};

export type SubscriptionEForceSessionResponse = {
    __typename?: 'SubscriptionEForceSessionResponse';
    booking: BookingResponse;
};

export type SubscriptionEForceUpdateInput = {
    id: Scalars['ID'];
    slot: EForceSubscriptionSlotInput;
};

export type SubscriptionsEForceFilterInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    locationId?: InputMaybe<Scalars['ID']>;
    memberSpecialistId?: InputMaybe<Scalars['ID']>;
};

export type TimeTrackingResponse = {
    __typename?: 'TimeTrackingResponse';
    booking: BookingResponse;
    started: Scalars['DateTime'];
    state: Scalars['String'];
};

export enum UploadStatus {
    Initiated = 'INITIATED',
    Uploaded = 'UPLOADED',
}

export type User = {
    __typename?: 'User';
    /** @deprecated No longer supported */
    access?: Maybe<Array<Maybe<Location>>>;
    accessLocations: Array<Maybe<AccessLocation>>;
    company?: Maybe<Company>;
    email: Scalars['String'];
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    photo?: Maybe<Binary>;
    preferences?: Maybe<Array<Maybe<DoorPreference>>>;
    roles: Array<Maybe<Role>>;
    serviceLocations: Array<Maybe<ServiceLocation>>;
    state: State;
    workHoursEnd?: Maybe<Scalars['String']>;
    workHoursStart?: Maybe<Scalars['String']>;
};

export type UserAccessInput = {
    locationId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type UserAssignDoorInput = {
    doorId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type UserAssignRoleInput = {
    roles: Array<Role>;
    userId: Scalars['ID'];
};

export type UserBlockInput = {
    id: Scalars['ID'];
    reason?: InputMaybe<Scalars['String']>;
};

export type UserCreateInput = {
    companyId: Scalars['ID'];
    email: Scalars['String'];
    locationId: Scalars['ID'];
    name: Scalars['String'];
    phoneNumber?: InputMaybe<Scalars['String']>;
    role: Role;
};

export type UserDeleteInput = {
    id: Scalars['ID'];
    reason?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
    companyId: Scalars['ID'];
    email: Scalars['String'];
    locationId: Scalars['ID'];
    name: Scalars['String'];
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UserRemoveDoorInput = {
    doorId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type UserRestrictInput = {
    id: Scalars['ID'];
    reason?: InputMaybe<Scalars['String']>;
};

export type UserRoleInput = {
    role: Role;
    userId: Scalars['ID'];
};

export type UserUpdateInput = {
    name: Scalars['ID'];
    userId: Scalars['ID'];
};

export type UsersFilterInput = {
    email?: InputMaybe<Scalars['String']>;
    roles?: InputMaybe<Array<InputMaybe<Role>>>;
    state?: InputMaybe<State>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type AddDoorPreferenceMutationVariables = Exact<{
    doorId: Scalars['ID'];
}>;

export type AddDoorPreferenceMutation = {
    __typename?: 'Mutation';
    addDoorPreference?: {
        __typename?: 'DoorPreference';
        id: string;
        userId: string;
        doorId: string;
    } | null;
};

export type DoorDataQueryVariables = Exact<{ [key: string]: never }>;

export type DoorDataQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        accessLocations: Array<{
            __typename?: 'AccessLocation';
            id: string;
            name: string;
            doors: Array<{ __typename?: 'Door'; id: string; name: string } | null>;
        } | null>;
        preferences?: Array<{
            __typename?: 'DoorPreference';
            id: string;
            userId: string;
            doorId: string;
        } | null> | null;
    };
};

export type GetDoorPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDoorPreferencesQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        preferences?: Array<{
            __typename?: 'DoorPreference';
            id: string;
            doorId: string;
        } | null> | null;
    };
};

export type RemoveDoorPreferenceMutationVariables = Exact<{
    preferenceId: Scalars['ID'];
}>;

export type RemoveDoorPreferenceMutation = {
    __typename?: 'Mutation';
    removeDoorPreference?: {
        __typename?: 'DoorPreference';
        id: string;
        userId: string;
        doorId: string;
    } | null;
};

export type UnlockDoorMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type UnlockDoorMutation = {
    __typename?: 'Mutation';
    unlockDoor?: {
        __typename?: 'Door';
        id: string;
        name: string;
        description?: string | null;
    } | null;
};

export type CreateBlockTimeMutationVariables = Exact<{
    request: BlockingTimeRequestInput;
}>;

export type CreateBlockTimeMutation = {
    __typename?: 'Mutation';
    block?: { __typename?: 'BookingResponse'; id: string } | null;
};

export type GetEforceCompletedBookingsPerLocationQueryVariables = Exact<{
    locationId?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<FilterDateRange>;
}>;

export type GetEforceCompletedBookingsPerLocationQuery = {
    __typename?: 'Query';
    bookings?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
    } | null> | null;
};

export type AdjustEForceBookingSessionMutationVariables = Exact<{
    input: SessionAdjustmentInput;
}>;

export type AdjustEForceBookingSessionMutation = {
    __typename?: 'Mutation';
    adjustSession?: {
        __typename?: 'BookingCompletedResponse';
        started?: any | null;
        finished?: any | null;
        booking: { __typename?: 'BookingResponse'; id: string };
    } | null;
};

export type ApproveEForceBookingSessionMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type ApproveEForceBookingSessionMutation = {
    __typename?: 'Mutation';
    approveSession?: { __typename?: 'BookingResponse'; id: string } | null;
};

export type CancelEForceBookingMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type CancelEForceBookingMutation = {
    __typename?: 'Mutation';
    cancelBooking?: { __typename?: 'BookingResponse'; id: string } | null;
};

export type CompleteTimeTrackingMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type CompleteTimeTrackingMutation = {
    __typename?: 'Mutation';
    complete: {
        __typename?: 'TimeTrackingResponse';
        booking: { __typename?: 'BookingResponse'; id: string };
    };
};

export type CreateBookingMutationVariables = Exact<{
    request: BookingRequestInput;
}>;

export type CreateBookingMutation = {
    __typename?: 'Mutation';
    book?: { __typename?: 'BookingResponse'; id: string } | null;
};

export type GetEForceBookingQueryVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type GetEForceBookingQuery = {
    __typename?: 'Query';
    booking: {
        __typename?: 'BookingResponse';
        id: string;
        description: string;
        status: BookingStatus;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
        assignee: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
            workHoursStart?: string | null;
            workHoursEnd?: string | null;
            photo?: { __typename?: 'Binary'; url?: string | null; id: string } | null;
        };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
    };
};

export type GetCompaniesForEForceBookingFormQueryVariables = Exact<{
    filter?: InputMaybe<CompanyFilterInput>;
}>;

export type GetCompaniesForEForceBookingFormQuery = {
    __typename?: 'Query';
    companies: Array<{ __typename?: 'Company'; id: string; name: string } | null>;
};

export type EForceBookingInfoFragment = {
    __typename?: 'BookingResponse';
    id: string;
    status: BookingStatus;
    state: BookingState;
    description: string;
    scheduleType?: ScheduleType | null;
    reason?: string | null;
    company?: { __typename?: 'Company'; name: string; id: string } | null;
    assignee: { __typename?: 'User'; id: string; name?: string | null };
    slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
    timeTrackingSummary?: {
        __typename?: 'BookingCompletedResponse';
        started?: any | null;
        finished?: any | null;
        billableTimeMinutes?: number | null;
        breaksMinutes?: number | null;
        sessionDurationMinutes?: number | null;
    } | null;
};

export type GetEforceMemberBookingsQueryVariables = Exact<{
    companyId?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<FilterDateRange>;
    today?: InputMaybe<Scalars['Date']>;
}>;

export type GetEforceMemberBookingsQuery = {
    __typename?: 'Query';
    booked?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    completed?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    approved?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    upcoming?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
};

export type GetAssociatesAvailabilitiesQueryVariables = Exact<{
    request: AvailabilityRequestInput;
}>;

export type GetAssociatesAvailabilitiesQuery = {
    __typename?: 'Query';
    associatesAvailability?: {
        __typename?: 'AvailabilityResponse';
        slots: Array<{
            __typename?: 'AvailableTimeSlot';
            startTime: any;
            associates: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
                photo?: { __typename?: 'Binary'; url?: string | null } | null;
            } | null>;
        } | null>;
    } | null;
};

export type GetEforceAssociateBookingsQueryVariables = Exact<{
    filter: BookingFilterInput;
}>;

export type GetEforceAssociateBookingsQuery = {
    __typename?: 'Query';
    bookings?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    inProgress?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
};

export type EForceBookingInfoComFragment = {
    __typename?: 'BookingResponse';
    id: string;
    status: BookingStatus;
    state: BookingState;
    description: string;
    scheduleType?: ScheduleType | null;
    reason?: string | null;
    company?: { __typename?: 'Company'; name: string; id: string } | null;
    assignee: { __typename?: 'User'; id: string; name?: string | null };
    slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
    timeTrackingSummary?: {
        __typename?: 'BookingCompletedResponse';
        started?: any | null;
        finished?: any | null;
        billableTimeMinutes?: number | null;
        breaksMinutes?: number | null;
        sessionDurationMinutes?: number | null;
    } | null;
};

export type GetEforceComBookingsQueryVariables = Exact<{
    date: FilterDateRange;
    locationId?: InputMaybe<Scalars['ID']>;
    companyId?: InputMaybe<Scalars['ID']>;
    associateId?: InputMaybe<Scalars['ID']>;
}>;

export type GetEforceComBookingsQuery = {
    __typename?: 'Query';
    bookings?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    completed?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
    approved?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
};

export type GetEforceMemberBookingReportQueryVariables = Exact<{
    companyId?: InputMaybe<Scalars['ID']>;
    date?: InputMaybe<FilterDateRange>;
}>;

export type GetEforceMemberBookingReportQuery = {
    __typename?: 'Query';
    bookings?: Array<{
        __typename?: 'BookingResponse';
        id: string;
        status: BookingStatus;
        state: BookingState;
        description: string;
        scheduleType?: ScheduleType | null;
        reason?: string | null;
        company?: { __typename?: 'Company'; name: string; id: string } | null;
        assignee: { __typename?: 'User'; id: string; name?: string | null };
        slot: { __typename?: 'BookedTimeSlot'; startTime: any; endTime: any };
        timeTrackingSummary?: {
            __typename?: 'BookingCompletedResponse';
            started?: any | null;
            finished?: any | null;
            billableTimeMinutes?: number | null;
            breaksMinutes?: number | null;
            sessionDurationMinutes?: number | null;
        } | null;
    } | null> | null;
};

export type ModifyEforceBookingMutationVariables = Exact<{
    request: ModifyBookingRequestInput;
}>;

export type ModifyEforceBookingMutation = {
    __typename?: 'Mutation';
    modifyBooking?: { __typename?: 'BookingResponse'; id: string } | null;
};

export type PauseTimeTrackingMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type PauseTimeTrackingMutation = {
    __typename?: 'Mutation';
    pause: {
        __typename?: 'TimeTrackingResponse';
        booking: { __typename?: 'BookingResponse'; id: string };
    };
};

export type ResumeTimeTrackingMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type ResumeTimeTrackingMutation = {
    __typename?: 'Mutation';
    resume: {
        __typename?: 'TimeTrackingResponse';
        booking: { __typename?: 'BookingResponse'; id: string };
    };
};

export type SendEmailReportQueryVariables = Exact<{
    input: BookingEmailReportInput;
}>;

export type SendEmailReportQuery = {
    __typename?: 'Query';
    bookingSendEmailReport?: boolean | null;
};

export type StartTimeTrackingMutationVariables = Exact<{
    bookingId: Scalars['ID'];
}>;

export type StartTimeTrackingMutation = {
    __typename?: 'Mutation';
    start: {
        __typename?: 'TimeTrackingResponse';
        booking: { __typename?: 'BookingResponse'; id: string };
    };
};

export type CancelStorageRequestMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CancelStorageRequestMutation = {
    __typename?: 'Mutation';
    cancelStorageRequest?: { __typename?: 'StorageItem'; id: string } | null;
};

export type CancelStorageReturnRequestMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CancelStorageReturnRequestMutation = {
    __typename?: 'Mutation';
    cancelStorageReturnRequest?: { __typename?: 'StorageItem'; id: string } | null;
};

export type EditStorageRequestMutationVariables = Exact<{
    request: EditStorageRequestInput;
}>;

export type EditStorageRequestMutation = {
    __typename?: 'Mutation';
    editStorageRequest?: { __typename?: 'StorageItem'; id: string } | null;
};

export type GetCompaniesForFlexStorageFormQueryVariables = Exact<{
    filter?: InputMaybe<CompanyFilterInput>;
}>;

export type GetCompaniesForFlexStorageFormQuery = {
    __typename?: 'Query';
    companies: Array<{ __typename?: 'Company'; id: string; name: string } | null>;
};

export type GetFlexStorageItemsQueryVariables = Exact<{
    filter: StorageFilterInput;
}>;

export type GetFlexStorageItemsQuery = {
    __typename?: 'Query';
    storageItems?: Array<{
        __typename?: 'StorageItem';
        id: string;
        description: string;
        state: StorageState;
        cost?: { __typename?: 'Cost'; amount?: number | null } | null;
        photo?: { __typename?: 'Binary'; id: string; url?: string | null } | null;
        created?: { __typename?: 'StorageAuditingInfo'; at?: any | null } | null;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
    } | null> | null;
};

export type MarkStorageItemAsReturnedMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarkStorageItemAsReturnedMutation = {
    __typename?: 'Mutation';
    returnItem?: { __typename?: 'StorageItem'; id: string } | null;
};

export type MarkStorageItemAsStoredMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarkStorageItemAsStoredMutation = {
    __typename?: 'Mutation';
    storeItem?: { __typename?: 'StorageItem'; id: string } | null;
};

export type RequestFlexStorageMutationVariables = Exact<{
    request: StorageRequestInput;
}>;

export type RequestFlexStorageMutation = {
    __typename?: 'Mutation';
    requestStorage?: { __typename?: 'StorageItem'; id: string } | null;
};

export type RequestStorageReturnMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RequestStorageReturnMutation = {
    __typename?: 'Mutation';
    requestStorageReturn?: { __typename?: 'StorageItem'; id: string } | null;
};

export type CreateLockMutationVariables = Exact<{
    lock?: InputMaybe<LockInput>;
}>;

export type CreateLockMutation = {
    __typename?: 'Mutation';
    createLock?: { __typename?: 'Lock'; id: string } | null;
};

export type DoorCreateMutationVariables = Exact<{
    input: DoorCreateInput;
}>;

export type DoorCreateMutation = {
    __typename?: 'Mutation';
    doorCreate?: { __typename?: 'Door'; id: string; name: string } | null;
};

export type GetLocationQueryVariables = Exact<{
    locationId: Scalars['ID'];
}>;

export type GetLocationQuery = {
    __typename?: 'Query';
    location?: {
        __typename?: 'Location';
        id: string;
        name: string;
        zone?: string | null;
        doors?: Array<{
            __typename?: 'Door';
            id: string;
            name: string;
            description?: string | null;
            externalId?: number | null;
        } | null> | null;
    } | null;
};

export type LocationsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationsQuery = {
    __typename?: 'Query';
    locations?: Array<{
        __typename?: 'Location';
        id: string;
        name: string;
        zone?: string | null;
        doors?: Array<{ __typename?: 'Door'; id: string; name: string } | null> | null;
    } | null> | null;
};

export type LocationCreateMutationVariables = Exact<{
    input: LocationCreateInput;
}>;

export type LocationCreateMutation = {
    __typename?: 'Mutation';
    locationCreate?: { __typename?: 'Location'; id: string; name: string } | null;
};

export type DoorUpdateMutationVariables = Exact<{
    input: DoorUpdateInput;
}>;

export type DoorUpdateMutation = {
    __typename?: 'Mutation';
    doorUpdate?: { __typename?: 'Door'; id: string; name: string } | null;
};

export type AddMemberToAccessLocationMutationVariables = Exact<{
    input: UserAccessInput;
}>;

export type AddMemberToAccessLocationMutation = {
    __typename?: 'Mutation';
    grantUserAccessToLocation?: { __typename?: 'User'; id: string } | null;
};

export type AddMemberToServiceLocationMutationVariables = Exact<{
    input: UserAccessInput;
}>;

export type AddMemberToServiceLocationMutation = {
    __typename?: 'Mutation';
    assignServiceAccess?: { __typename?: 'User'; id: string } | null;
};

export type CompanyAssignToLocationMutationVariables = Exact<{
    input?: InputMaybe<AssignCompanyToLocation>;
}>;

export type CompanyAssignToLocationMutation = {
    __typename?: 'Mutation';
    companyAssignToLocation?: { __typename?: 'Company'; id: string } | null;
};

export type CompanyClearRestrictionMutationVariables = Exact<{
    companyId: Scalars['ID'];
}>;

export type CompanyClearRestrictionMutation = {
    __typename?: 'Mutation';
    companyClearRestriction?: { __typename?: 'Company'; id: string } | null;
};

export type CompanyRemoveFromLocationMutationVariables = Exact<{
    input?: InputMaybe<RemoveCompanyFromLocationInput>;
}>;

export type CompanyRemoveFromLocationMutation = {
    __typename?: 'Mutation';
    companyRemoveFromLocation?: { __typename?: 'Company'; id: string } | null;
};

export type CompanyRestrictMutationVariables = Exact<{
    input: CompanyRestrictInput;
}>;

export type CompanyRestrictMutation = {
    __typename?: 'Mutation';
    companyRestrict?: { __typename?: 'Company'; id: string } | null;
};

export type CreateMemberCompanyMutationVariables = Exact<{
    company: CompanyInput;
}>;

export type CreateMemberCompanyMutation = {
    __typename?: 'Mutation';
    createCompany?: { __typename?: 'Company'; id: string } | null;
};

export type CreateMemberUserMutationVariables = Exact<{
    user: UserInput;
}>;

export type CreateMemberUserMutation = {
    __typename?: 'Mutation';
    createMemberUser?: { __typename?: 'User'; id: string; name?: string | null } | null;
};

export type SubscriptionsEForceQueryVariables = Exact<{
    input: SubscriptionsEForceFilterInput;
}>;

export type SubscriptionsEForceQuery = {
    __typename?: 'Query';
    subscriptionsEForce?: Array<{
        __typename?: 'SubscriptionEForceResponse';
        id: string;
        location: { __typename?: 'Location'; id: string; name: string };
        slot: { __typename?: 'EForceSubscriptionSlotResponse'; from: any; to: any };
        preferredMemberSpecialists: Array<{
            __typename?: 'PreferredMemberSpecialistResponse';
            status: EForceSubscriptionStatus;
            memberSpecialist: { __typename?: 'User'; id: string; name?: string | null };
        } | null>;
    } | null> | null;
};

export type MemberCompaniesQueryVariables = Exact<{
    filter?: InputMaybe<CompanyFilterInput>;
}>;

export type MemberCompaniesQuery = {
    __typename?: 'Query';
    companies: Array<{
        __typename?: 'Company';
        id: string;
        name: string;
        state: State;
        locations: Array<{ __typename?: 'CompanyLocation'; id: string; name: string } | null>;
    } | null>;
};

export type MemberCompanyQueryVariables = Exact<{
    companyId: Scalars['ID'];
}>;

export type MemberCompanyQuery = {
    __typename?: 'Query';
    company?: {
        __typename?: 'Company';
        id: string;
        name: string;
        state: State;
        locations: Array<{ __typename?: 'CompanyLocation'; id: string; name: string } | null>;
    } | null;
};

export type MemberLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type MemberLocationsQuery = {
    __typename?: 'Query';
    locations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
};

export type MembersQueryVariables = Exact<{
    filter?: InputMaybe<UsersFilterInput>;
}>;

export type MembersQuery = {
    __typename?: 'Query';
    users?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        state: State;
        roles: Array<Role | null>;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
        accessLocations: Array<{ __typename?: 'AccessLocation'; id: string; name: string } | null>;
        serviceLocations: Array<{
            __typename?: 'ServiceLocation';
            id: string;
            name: string;
        } | null>;
    } | null> | null;
};

export type RemoveMemberFromAccessLocationMutationVariables = Exact<{
    input?: InputMaybe<UserAccessInput>;
}>;

export type RemoveMemberFromAccessLocationMutation = {
    __typename?: 'Mutation';
    revokeLocationAccess?: { __typename?: 'User'; id: string } | null;
};

export type RemoveMemberFromServiceLocationMutationVariables = Exact<{
    input?: InputMaybe<UserAccessInput>;
}>;

export type RemoveMemberFromServiceLocationMutation = {
    __typename?: 'Mutation';
    revokeServiceAccess?: { __typename?: 'User'; id: string } | null;
};

export type SubscriptionEForceAddMutationVariables = Exact<{
    input: SubscriptionEForceAddInput;
}>;

export type SubscriptionEForceAddMutation = {
    __typename?: 'Mutation';
    subscriptionEForceAdd?: { __typename?: 'SubscriptionEForceResponse'; id: string } | null;
};

export type SubscriptionEForceCancelMutationVariables = Exact<{
    input: SubscriptionEForceCancelInput;
}>;

export type SubscriptionEForceCancelMutation = {
    __typename?: 'Mutation';
    subscriptionEForceCancel?: { __typename?: 'SubscriptionEForceResponse'; id: string } | null;
};

export type UserUpdateMutationVariables = Exact<{
    input: UserUpdateInput;
}>;

export type UserUpdateMutation = {
    __typename?: 'Mutation';
    userUpdate?: { __typename?: 'User'; id: string; name?: string | null } | null;
};

export type UserAssignRoleMutationVariables = Exact<{
    input: UserRoleInput;
}>;

export type UserAssignRoleMutation = {
    __typename?: 'Mutation';
    userAssignRole?: { __typename?: 'User'; roles: Array<Role | null>; id: string } | null;
};

export type UserBlockMutationVariables = Exact<{
    input: UserBlockInput;
}>;

export type UserBlockMutation = {
    __typename?: 'Mutation';
    userBlock?: { __typename?: 'User'; id: string } | null;
};

export type UserClearRestrictionMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type UserClearRestrictionMutation = {
    __typename?: 'Mutation';
    userClearRestriction?: { __typename?: 'User'; id: string } | null;
};

export type UserDeleteMutationVariables = Exact<{
    input: UserDeleteInput;
}>;

export type UserDeleteMutation = {
    __typename?: 'Mutation';
    userDelete?: { __typename?: 'User'; id: string; name?: string | null } | null;
};

export type UserRestrictMutationVariables = Exact<{
    input: UserRestrictInput;
}>;

export type UserRestrictMutation = {
    __typename?: 'Mutation';
    userRestrict?: { __typename?: 'User'; id: string } | null;
};

export type UserRevokeRoleMutationVariables = Exact<{
    input: UserRoleInput;
}>;

export type UserRevokeRoleMutation = {
    __typename?: 'Mutation';
    userRevokeRole?: { __typename?: 'User'; id: string; roles: Array<Role | null> } | null;
};

export type UserUnblockMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type UserUnblockMutation = {
    __typename?: 'Mutation';
    userUnblock?: { __typename?: 'User'; id: string } | null;
};

export type ConfirmBinaryUploadMutationVariables = Exact<{
    uploadId: Scalars['ID'];
}>;

export type ConfirmBinaryUploadMutation = {
    __typename?: 'Mutation';
    confirmBinaryUpload?: {
        __typename?: 'Binary';
        id: string;
        url?: string | null;
        status?: UploadStatus | null;
    } | null;
};

export type GenerateUploadLinkMutationVariables = Exact<{
    request: GenerateUploadLinkInput;
}>;

export type GenerateUploadLinkMutation = {
    __typename?: 'Mutation';
    generateUploadLink?: {
        __typename?: 'GenerateUploadLinkResult';
        id: string;
        uploadLink: string;
    } | null;
};

export type GetCompaniesQueryVariables = Exact<{
    filter?: InputMaybe<CompanyFilterInput>;
}>;

export type GetCompaniesQuery = {
    __typename?: 'Query';
    companies: Array<{
        __typename?: 'Company';
        id: string;
        name: string;
        locations: Array<{ __typename?: 'CompanyLocation'; id: string; name: string } | null>;
    } | null>;
};

export type GetLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsQuery = {
    __typename?: 'Query';
    locations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
};

export type CreateAssociateUserMutationVariables = Exact<{
    input: UserInput;
}>;

export type CreateAssociateUserMutation = {
    __typename?: 'Mutation';
    addAssociateToLocation?: { __typename?: 'User'; id: string } | null;
};

export type AssociatesQueryVariables = Exact<{
    filter?: InputMaybe<AssociateFilterInput>;
}>;

export type AssociatesQuery = {
    __typename?: 'Query';
    associates?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        state: State;
        workHoursStart?: string | null;
        workHoursEnd?: string | null;
        photo?: { __typename?: 'Binary'; id: string; url?: string | null } | null;
        accessLocations: Array<{ __typename?: 'AccessLocation'; id: string; name: string } | null>;
    } | null> | null;
};

export type CurrentAssociateQueryVariables = Exact<{
    filter?: InputMaybe<UsersFilterInput>;
}>;

export type CurrentAssociateQuery = {
    __typename?: 'Query';
    users?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        state: State;
        roles: Array<Role | null>;
        workHoursStart?: string | null;
        workHoursEnd?: string | null;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
        photo?: { __typename?: 'Binary'; id: string; url?: string | null } | null;
        accessLocations: Array<{ __typename?: 'AccessLocation'; id: string; name: string } | null>;
    } | null> | null;
};

export type ModifyWorkingHoursMutationVariables = Exact<{
    input: ModifyAssociateInput;
}>;

export type ModifyWorkingHoursMutation = {
    __typename?: 'Mutation';
    modifyAssociate?: string | null;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
    __typename?: 'Query';
    users?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        state: State;
        roles: Array<Role | null>;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
        serviceLocations: Array<{
            __typename?: 'ServiceLocation';
            id: string;
            name: string;
        } | null>;
        accessLocations: Array<{ __typename?: 'AccessLocation'; id: string; name: string } | null>;
    } | null> | null;
};

export type UserCreateMutationVariables = Exact<{
    input?: InputMaybe<UserCreateInput>;
}>;

export type UserCreateMutation = {
    __typename?: 'Mutation';
    userCreate?: { __typename?: 'User'; id: string; name?: string | null } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
        state: State;
        note?: string | null;
        roles: Array<Role | null>;
        company?: {
            __typename?: 'Company';
            id: string;
            name: string;
            locations: Array<{ __typename?: 'CompanyLocation'; id: string; name: string } | null>;
        } | null;
        photo?: { __typename?: 'Binary'; url?: string | null } | null;
        serviceLocations: Array<{
            __typename?: 'ServiceLocation';
            id: string;
            name: string;
            zone: string;
        } | null>;
    };
};

export const EForceBookingInfoFragmentDoc = gql`
    fragment EForceBookingInfo on BookingResponse {
        id
        company {
            name
            id
        }
        assignee {
            id
            name
        }
        status
        state
        description
        scheduleType
        reason
        slot {
            startTime
            endTime
        }
        timeTrackingSummary {
            started
            finished
            billableTimeMinutes
            breaksMinutes
            sessionDurationMinutes
        }
    }
`;
export const EForceBookingInfoComFragmentDoc = gql`
    fragment EForceBookingInfoCOM on BookingResponse {
        id
        company {
            name
            id
        }
        assignee {
            id
            name
        }
        status
        state
        description
        scheduleType
        reason
        slot {
            startTime
            endTime
        }
        timeTrackingSummary {
            started
            finished
            billableTimeMinutes
            breaksMinutes
            sessionDurationMinutes
        }
    }
`;
export const AddDoorPreferenceDocument = gql`
    mutation addDoorPreference($doorId: ID!) {
        addDoorPreference(doorId: $doorId) {
            id
            userId
            doorId
        }
    }
`;
export type AddDoorPreferenceMutationFn = Apollo.MutationFunction<
    AddDoorPreferenceMutation,
    AddDoorPreferenceMutationVariables
>;

/**
 * __useAddDoorPreferenceMutation__
 *
 * To run a mutation, you first call `useAddDoorPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDoorPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDoorPreferenceMutation, { data, loading, error }] = useAddDoorPreferenceMutation({
 *   variables: {
 *      doorId: // value for 'doorId'
 *   },
 * });
 */
export function useAddDoorPreferenceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddDoorPreferenceMutation,
        AddDoorPreferenceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddDoorPreferenceMutation, AddDoorPreferenceMutationVariables>(
        AddDoorPreferenceDocument,
        options,
    );
}
export type AddDoorPreferenceMutationHookResult = ReturnType<typeof useAddDoorPreferenceMutation>;
export type AddDoorPreferenceMutationResult = Apollo.MutationResult<AddDoorPreferenceMutation>;
export type AddDoorPreferenceMutationOptions = Apollo.BaseMutationOptions<
    AddDoorPreferenceMutation,
    AddDoorPreferenceMutationVariables
>;
export const DoorDataDocument = gql`
    query DoorData {
        me {
            id
            accessLocations {
                id
                name
                doors {
                    id
                    name
                }
            }
            preferences {
                id
                userId
                doorId
            }
        }
    }
`;

/**
 * __useDoorDataQuery__
 *
 * To run a query within a React component, call `useDoorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDoorDataQuery(
    baseOptions?: Apollo.QueryHookOptions<DoorDataQuery, DoorDataQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DoorDataQuery, DoorDataQueryVariables>(DoorDataDocument, options);
}
export function useDoorDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DoorDataQuery, DoorDataQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DoorDataQuery, DoorDataQueryVariables>(DoorDataDocument, options);
}
export type DoorDataQueryHookResult = ReturnType<typeof useDoorDataQuery>;
export type DoorDataLazyQueryHookResult = ReturnType<typeof useDoorDataLazyQuery>;
export type DoorDataQueryResult = Apollo.QueryResult<DoorDataQuery, DoorDataQueryVariables>;
export const GetDoorPreferencesDocument = gql`
    query GetDoorPreferences {
        me {
            id
            preferences {
                id
                doorId
            }
        }
    }
`;

/**
 * __useGetDoorPreferencesQuery__
 *
 * To run a query within a React component, call `useGetDoorPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoorPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoorPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDoorPreferencesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetDoorPreferencesQuery,
        GetDoorPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDoorPreferencesQuery, GetDoorPreferencesQueryVariables>(
        GetDoorPreferencesDocument,
        options,
    );
}
export function useGetDoorPreferencesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDoorPreferencesQuery,
        GetDoorPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDoorPreferencesQuery, GetDoorPreferencesQueryVariables>(
        GetDoorPreferencesDocument,
        options,
    );
}
export type GetDoorPreferencesQueryHookResult = ReturnType<typeof useGetDoorPreferencesQuery>;
export type GetDoorPreferencesLazyQueryHookResult = ReturnType<
    typeof useGetDoorPreferencesLazyQuery
>;
export type GetDoorPreferencesQueryResult = Apollo.QueryResult<
    GetDoorPreferencesQuery,
    GetDoorPreferencesQueryVariables
>;
export const RemoveDoorPreferenceDocument = gql`
    mutation removeDoorPreference($preferenceId: ID!) {
        removeDoorPreference(preferenceId: $preferenceId) {
            id
            userId
            doorId
        }
    }
`;
export type RemoveDoorPreferenceMutationFn = Apollo.MutationFunction<
    RemoveDoorPreferenceMutation,
    RemoveDoorPreferenceMutationVariables
>;

/**
 * __useRemoveDoorPreferenceMutation__
 *
 * To run a mutation, you first call `useRemoveDoorPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDoorPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDoorPreferenceMutation, { data, loading, error }] = useRemoveDoorPreferenceMutation({
 *   variables: {
 *      preferenceId: // value for 'preferenceId'
 *   },
 * });
 */
export function useRemoveDoorPreferenceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveDoorPreferenceMutation,
        RemoveDoorPreferenceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveDoorPreferenceMutation, RemoveDoorPreferenceMutationVariables>(
        RemoveDoorPreferenceDocument,
        options,
    );
}
export type RemoveDoorPreferenceMutationHookResult = ReturnType<
    typeof useRemoveDoorPreferenceMutation
>;
export type RemoveDoorPreferenceMutationResult =
    Apollo.MutationResult<RemoveDoorPreferenceMutation>;
export type RemoveDoorPreferenceMutationOptions = Apollo.BaseMutationOptions<
    RemoveDoorPreferenceMutation,
    RemoveDoorPreferenceMutationVariables
>;
export const UnlockDoorDocument = gql`
    mutation unlockDoor($id: ID) {
        unlockDoor(id: $id) {
            id
            name
            description
        }
    }
`;
export type UnlockDoorMutationFn = Apollo.MutationFunction<
    UnlockDoorMutation,
    UnlockDoorMutationVariables
>;

/**
 * __useUnlockDoorMutation__
 *
 * To run a mutation, you first call `useUnlockDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockDoorMutation, { data, loading, error }] = useUnlockDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockDoorMutation(
    baseOptions?: Apollo.MutationHookOptions<UnlockDoorMutation, UnlockDoorMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UnlockDoorMutation, UnlockDoorMutationVariables>(
        UnlockDoorDocument,
        options,
    );
}
export type UnlockDoorMutationHookResult = ReturnType<typeof useUnlockDoorMutation>;
export type UnlockDoorMutationResult = Apollo.MutationResult<UnlockDoorMutation>;
export type UnlockDoorMutationOptions = Apollo.BaseMutationOptions<
    UnlockDoorMutation,
    UnlockDoorMutationVariables
>;
export const CreateBlockTimeDocument = gql`
    mutation createBlockTime($request: BlockingTimeRequestInput!) {
        block(request: $request) {
            id
        }
    }
`;
export type CreateBlockTimeMutationFn = Apollo.MutationFunction<
    CreateBlockTimeMutation,
    CreateBlockTimeMutationVariables
>;

/**
 * __useCreateBlockTimeMutation__
 *
 * To run a mutation, you first call `useCreateBlockTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockTimeMutation, { data, loading, error }] = useCreateBlockTimeMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBlockTimeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBlockTimeMutation,
        CreateBlockTimeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBlockTimeMutation, CreateBlockTimeMutationVariables>(
        CreateBlockTimeDocument,
        options,
    );
}
export type CreateBlockTimeMutationHookResult = ReturnType<typeof useCreateBlockTimeMutation>;
export type CreateBlockTimeMutationResult = Apollo.MutationResult<CreateBlockTimeMutation>;
export type CreateBlockTimeMutationOptions = Apollo.BaseMutationOptions<
    CreateBlockTimeMutation,
    CreateBlockTimeMutationVariables
>;
export const GetEforceCompletedBookingsPerLocationDocument = gql`
    query GetEforceCompletedBookingsPerLocation($locationId: ID, $date: FilterDateRange) {
        bookings(filter: { locationId: $locationId, status: Completed, date: $date }) {
            id
            status
            state
        }
    }
`;

/**
 * __useGetEforceCompletedBookingsPerLocationQuery__
 *
 * To run a query within a React component, call `useGetEforceCompletedBookingsPerLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEforceCompletedBookingsPerLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEforceCompletedBookingsPerLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetEforceCompletedBookingsPerLocationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetEforceCompletedBookingsPerLocationQuery,
        GetEforceCompletedBookingsPerLocationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEforceCompletedBookingsPerLocationQuery,
        GetEforceCompletedBookingsPerLocationQueryVariables
    >(GetEforceCompletedBookingsPerLocationDocument, options);
}
export function useGetEforceCompletedBookingsPerLocationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEforceCompletedBookingsPerLocationQuery,
        GetEforceCompletedBookingsPerLocationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEforceCompletedBookingsPerLocationQuery,
        GetEforceCompletedBookingsPerLocationQueryVariables
    >(GetEforceCompletedBookingsPerLocationDocument, options);
}
export type GetEforceCompletedBookingsPerLocationQueryHookResult = ReturnType<
    typeof useGetEforceCompletedBookingsPerLocationQuery
>;
export type GetEforceCompletedBookingsPerLocationLazyQueryHookResult = ReturnType<
    typeof useGetEforceCompletedBookingsPerLocationLazyQuery
>;
export type GetEforceCompletedBookingsPerLocationQueryResult = Apollo.QueryResult<
    GetEforceCompletedBookingsPerLocationQuery,
    GetEforceCompletedBookingsPerLocationQueryVariables
>;
export const AdjustEForceBookingSessionDocument = gql`
    mutation AdjustEForceBookingSession($input: SessionAdjustmentInput!) {
        adjustSession(input: $input) {
            booking {
                id
            }
            started
            finished
        }
    }
`;
export type AdjustEForceBookingSessionMutationFn = Apollo.MutationFunction<
    AdjustEForceBookingSessionMutation,
    AdjustEForceBookingSessionMutationVariables
>;

/**
 * __useAdjustEForceBookingSessionMutation__
 *
 * To run a mutation, you first call `useAdjustEForceBookingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustEForceBookingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustEForceBookingSessionMutation, { data, loading, error }] = useAdjustEForceBookingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdjustEForceBookingSessionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AdjustEForceBookingSessionMutation,
        AdjustEForceBookingSessionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AdjustEForceBookingSessionMutation,
        AdjustEForceBookingSessionMutationVariables
    >(AdjustEForceBookingSessionDocument, options);
}
export type AdjustEForceBookingSessionMutationHookResult = ReturnType<
    typeof useAdjustEForceBookingSessionMutation
>;
export type AdjustEForceBookingSessionMutationResult =
    Apollo.MutationResult<AdjustEForceBookingSessionMutation>;
export type AdjustEForceBookingSessionMutationOptions = Apollo.BaseMutationOptions<
    AdjustEForceBookingSessionMutation,
    AdjustEForceBookingSessionMutationVariables
>;
export const ApproveEForceBookingSessionDocument = gql`
    mutation ApproveEForceBookingSession($bookingId: ID!) {
        approveSession(bookingId: $bookingId) {
            id
        }
    }
`;
export type ApproveEForceBookingSessionMutationFn = Apollo.MutationFunction<
    ApproveEForceBookingSessionMutation,
    ApproveEForceBookingSessionMutationVariables
>;

/**
 * __useApproveEForceBookingSessionMutation__
 *
 * To run a mutation, you first call `useApproveEForceBookingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveEForceBookingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveEForceBookingSessionMutation, { data, loading, error }] = useApproveEForceBookingSessionMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useApproveEForceBookingSessionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ApproveEForceBookingSessionMutation,
        ApproveEForceBookingSessionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ApproveEForceBookingSessionMutation,
        ApproveEForceBookingSessionMutationVariables
    >(ApproveEForceBookingSessionDocument, options);
}
export type ApproveEForceBookingSessionMutationHookResult = ReturnType<
    typeof useApproveEForceBookingSessionMutation
>;
export type ApproveEForceBookingSessionMutationResult =
    Apollo.MutationResult<ApproveEForceBookingSessionMutation>;
export type ApproveEForceBookingSessionMutationOptions = Apollo.BaseMutationOptions<
    ApproveEForceBookingSessionMutation,
    ApproveEForceBookingSessionMutationVariables
>;
export const CancelEForceBookingDocument = gql`
    mutation CancelEForceBooking($bookingId: ID!) {
        cancelBooking(bookingId: $bookingId) {
            id
        }
    }
`;
export type CancelEForceBookingMutationFn = Apollo.MutationFunction<
    CancelEForceBookingMutation,
    CancelEForceBookingMutationVariables
>;

/**
 * __useCancelEForceBookingMutation__
 *
 * To run a mutation, you first call `useCancelEForceBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEForceBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEForceBookingMutation, { data, loading, error }] = useCancelEForceBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCancelEForceBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelEForceBookingMutation,
        CancelEForceBookingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelEForceBookingMutation, CancelEForceBookingMutationVariables>(
        CancelEForceBookingDocument,
        options,
    );
}
export type CancelEForceBookingMutationHookResult = ReturnType<
    typeof useCancelEForceBookingMutation
>;
export type CancelEForceBookingMutationResult = Apollo.MutationResult<CancelEForceBookingMutation>;
export type CancelEForceBookingMutationOptions = Apollo.BaseMutationOptions<
    CancelEForceBookingMutation,
    CancelEForceBookingMutationVariables
>;
export const CompleteTimeTrackingDocument = gql`
    mutation CompleteTimeTracking($bookingId: ID!) {
        complete(bookingId: $bookingId) {
            booking {
                id
            }
        }
    }
`;
export type CompleteTimeTrackingMutationFn = Apollo.MutationFunction<
    CompleteTimeTrackingMutation,
    CompleteTimeTrackingMutationVariables
>;

/**
 * __useCompleteTimeTrackingMutation__
 *
 * To run a mutation, you first call `useCompleteTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTimeTrackingMutation, { data, loading, error }] = useCompleteTimeTrackingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCompleteTimeTrackingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteTimeTrackingMutation,
        CompleteTimeTrackingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CompleteTimeTrackingMutation, CompleteTimeTrackingMutationVariables>(
        CompleteTimeTrackingDocument,
        options,
    );
}
export type CompleteTimeTrackingMutationHookResult = ReturnType<
    typeof useCompleteTimeTrackingMutation
>;
export type CompleteTimeTrackingMutationResult =
    Apollo.MutationResult<CompleteTimeTrackingMutation>;
export type CompleteTimeTrackingMutationOptions = Apollo.BaseMutationOptions<
    CompleteTimeTrackingMutation,
    CompleteTimeTrackingMutationVariables
>;
export const CreateBookingDocument = gql`
    mutation createBooking($request: BookingRequestInput!) {
        book(request: $request) {
            id
        }
    }
`;
export type CreateBookingMutationFn = Apollo.MutationFunction<
    CreateBookingMutation,
    CreateBookingMutationVariables
>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
        CreateBookingDocument,
        options,
    );
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
>;
export const GetEForceBookingDocument = gql`
    query getEForceBooking($bookingId: ID!) {
        booking(id: $bookingId) {
            id
            description
            company {
                id
                name
            }
            status
            timeTrackingSummary {
                started
                finished
                billableTimeMinutes
                breaksMinutes
                sessionDurationMinutes
            }
            assignee {
                id
                name
                email
                photo {
                    url
                    id
                }
                workHoursStart
                workHoursEnd
            }
            slot {
                startTime
                endTime
            }
        }
    }
`;

/**
 * __useGetEForceBookingQuery__
 *
 * To run a query within a React component, call `useGetEForceBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEForceBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEForceBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetEForceBookingQuery(
    baseOptions: Apollo.QueryHookOptions<GetEForceBookingQuery, GetEForceBookingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetEForceBookingQuery, GetEForceBookingQueryVariables>(
        GetEForceBookingDocument,
        options,
    );
}
export function useGetEForceBookingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEForceBookingQuery,
        GetEForceBookingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetEForceBookingQuery, GetEForceBookingQueryVariables>(
        GetEForceBookingDocument,
        options,
    );
}
export type GetEForceBookingQueryHookResult = ReturnType<typeof useGetEForceBookingQuery>;
export type GetEForceBookingLazyQueryHookResult = ReturnType<typeof useGetEForceBookingLazyQuery>;
export type GetEForceBookingQueryResult = Apollo.QueryResult<
    GetEForceBookingQuery,
    GetEForceBookingQueryVariables
>;
export const GetCompaniesForEForceBookingFormDocument = gql`
    query getCompaniesForEForceBookingForm($filter: CompanyFilterInput) {
        companies(filter: $filter) {
            id
            name
        }
    }
`;

/**
 * __useGetCompaniesForEForceBookingFormQuery__
 *
 * To run a query within a React component, call `useGetCompaniesForEForceBookingFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesForEForceBookingFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesForEForceBookingFormQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompaniesForEForceBookingFormQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetCompaniesForEForceBookingFormQuery,
        GetCompaniesForEForceBookingFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetCompaniesForEForceBookingFormQuery,
        GetCompaniesForEForceBookingFormQueryVariables
    >(GetCompaniesForEForceBookingFormDocument, options);
}
export function useGetCompaniesForEForceBookingFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCompaniesForEForceBookingFormQuery,
        GetCompaniesForEForceBookingFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCompaniesForEForceBookingFormQuery,
        GetCompaniesForEForceBookingFormQueryVariables
    >(GetCompaniesForEForceBookingFormDocument, options);
}
export type GetCompaniesForEForceBookingFormQueryHookResult = ReturnType<
    typeof useGetCompaniesForEForceBookingFormQuery
>;
export type GetCompaniesForEForceBookingFormLazyQueryHookResult = ReturnType<
    typeof useGetCompaniesForEForceBookingFormLazyQuery
>;
export type GetCompaniesForEForceBookingFormQueryResult = Apollo.QueryResult<
    GetCompaniesForEForceBookingFormQuery,
    GetCompaniesForEForceBookingFormQueryVariables
>;
export const GetEforceMemberBookingsDocument = gql`
    query GetEforceMemberBookings($companyId: ID, $date: FilterDateRange, $today: Date) {
        booked: bookings(filter: { companyId: $companyId, date: $date }) {
            ...EForceBookingInfo
        }
        completed: bookings(filter: { companyId: $companyId, date: $date, status: Completed }) {
            ...EForceBookingInfo
        }
        approved: bookings(filter: { companyId: $companyId, date: $date, status: Approved }) {
            ...EForceBookingInfo
        }
        upcoming: bookings(
            filter: { companyId: $companyId, status: NotStarted, date: { from: $today } }
        ) {
            ...EForceBookingInfo
        }
    }
    ${EForceBookingInfoFragmentDoc}
`;

/**
 * __useGetEforceMemberBookingsQuery__
 *
 * To run a query within a React component, call `useGetEforceMemberBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEforceMemberBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEforceMemberBookingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      date: // value for 'date'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useGetEforceMemberBookingsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetEforceMemberBookingsQuery,
        GetEforceMemberBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetEforceMemberBookingsQuery, GetEforceMemberBookingsQueryVariables>(
        GetEforceMemberBookingsDocument,
        options,
    );
}
export function useGetEforceMemberBookingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEforceMemberBookingsQuery,
        GetEforceMemberBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetEforceMemberBookingsQuery, GetEforceMemberBookingsQueryVariables>(
        GetEforceMemberBookingsDocument,
        options,
    );
}
export type GetEforceMemberBookingsQueryHookResult = ReturnType<
    typeof useGetEforceMemberBookingsQuery
>;
export type GetEforceMemberBookingsLazyQueryHookResult = ReturnType<
    typeof useGetEforceMemberBookingsLazyQuery
>;
export type GetEforceMemberBookingsQueryResult = Apollo.QueryResult<
    GetEforceMemberBookingsQuery,
    GetEforceMemberBookingsQueryVariables
>;
export const GetAssociatesAvailabilitiesDocument = gql`
    query getAssociatesAvailabilities($request: AvailabilityRequestInput!) {
        associatesAvailability(request: $request) {
            slots {
                associates {
                    id
                    name
                    photo {
                        url
                    }
                }
                startTime
            }
        }
    }
`;

/**
 * __useGetAssociatesAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useGetAssociatesAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociatesAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociatesAvailabilitiesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAssociatesAvailabilitiesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAssociatesAvailabilitiesQuery,
        GetAssociatesAvailabilitiesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAssociatesAvailabilitiesQuery,
        GetAssociatesAvailabilitiesQueryVariables
    >(GetAssociatesAvailabilitiesDocument, options);
}
export function useGetAssociatesAvailabilitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAssociatesAvailabilitiesQuery,
        GetAssociatesAvailabilitiesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAssociatesAvailabilitiesQuery,
        GetAssociatesAvailabilitiesQueryVariables
    >(GetAssociatesAvailabilitiesDocument, options);
}
export type GetAssociatesAvailabilitiesQueryHookResult = ReturnType<
    typeof useGetAssociatesAvailabilitiesQuery
>;
export type GetAssociatesAvailabilitiesLazyQueryHookResult = ReturnType<
    typeof useGetAssociatesAvailabilitiesLazyQuery
>;
export type GetAssociatesAvailabilitiesQueryResult = Apollo.QueryResult<
    GetAssociatesAvailabilitiesQuery,
    GetAssociatesAvailabilitiesQueryVariables
>;
export const GetEforceAssociateBookingsDocument = gql`
    query GetEforceAssociateBookings($filter: BookingFilterInput!) {
        bookings(filter: $filter) {
            ...EForceBookingInfo
        }
        inProgress: bookings(filter: { status: InProgress }) {
            ...EForceBookingInfo
        }
    }
    ${EForceBookingInfoFragmentDoc}
`;

/**
 * __useGetEforceAssociateBookingsQuery__
 *
 * To run a query within a React component, call `useGetEforceAssociateBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEforceAssociateBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEforceAssociateBookingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEforceAssociateBookingsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEforceAssociateBookingsQuery,
        GetEforceAssociateBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEforceAssociateBookingsQuery,
        GetEforceAssociateBookingsQueryVariables
    >(GetEforceAssociateBookingsDocument, options);
}
export function useGetEforceAssociateBookingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEforceAssociateBookingsQuery,
        GetEforceAssociateBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEforceAssociateBookingsQuery,
        GetEforceAssociateBookingsQueryVariables
    >(GetEforceAssociateBookingsDocument, options);
}
export type GetEforceAssociateBookingsQueryHookResult = ReturnType<
    typeof useGetEforceAssociateBookingsQuery
>;
export type GetEforceAssociateBookingsLazyQueryHookResult = ReturnType<
    typeof useGetEforceAssociateBookingsLazyQuery
>;
export type GetEforceAssociateBookingsQueryResult = Apollo.QueryResult<
    GetEforceAssociateBookingsQuery,
    GetEforceAssociateBookingsQueryVariables
>;
export const GetEforceComBookingsDocument = gql`
    query GetEforceComBookings(
        $date: FilterDateRange!
        $locationId: ID
        $companyId: ID
        $associateId: ID
    ) {
        bookings(
            filter: {
                date: $date
                locationId: $locationId
                companyId: $companyId
                associateId: $associateId
                status: NotStarted
            }
        ) {
            ...EForceBookingInfoCOM
        }
        completed: bookings(
            filter: {
                date: $date
                locationId: $locationId
                companyId: $companyId
                associateId: $associateId
                status: Completed
            }
        ) {
            ...EForceBookingInfoCOM
        }
        approved: bookings(
            filter: {
                date: $date
                locationId: $locationId
                companyId: $companyId
                associateId: $associateId
                status: Approved
            }
        ) {
            ...EForceBookingInfoCOM
        }
    }
    ${EForceBookingInfoComFragmentDoc}
`;

/**
 * __useGetEforceComBookingsQuery__
 *
 * To run a query within a React component, call `useGetEforceComBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEforceComBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEforceComBookingsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      locationId: // value for 'locationId'
 *      companyId: // value for 'companyId'
 *      associateId: // value for 'associateId'
 *   },
 * });
 */
export function useGetEforceComBookingsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEforceComBookingsQuery,
        GetEforceComBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetEforceComBookingsQuery, GetEforceComBookingsQueryVariables>(
        GetEforceComBookingsDocument,
        options,
    );
}
export function useGetEforceComBookingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEforceComBookingsQuery,
        GetEforceComBookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetEforceComBookingsQuery, GetEforceComBookingsQueryVariables>(
        GetEforceComBookingsDocument,
        options,
    );
}
export type GetEforceComBookingsQueryHookResult = ReturnType<typeof useGetEforceComBookingsQuery>;
export type GetEforceComBookingsLazyQueryHookResult = ReturnType<
    typeof useGetEforceComBookingsLazyQuery
>;
export type GetEforceComBookingsQueryResult = Apollo.QueryResult<
    GetEforceComBookingsQuery,
    GetEforceComBookingsQueryVariables
>;
export const GetEforceMemberBookingReportDocument = gql`
    query GetEforceMemberBookingReport($companyId: ID, $date: FilterDateRange) {
        bookings(filter: { companyId: $companyId, date: $date, status: Approved }) {
            ...EForceBookingInfo
        }
    }
    ${EForceBookingInfoFragmentDoc}
`;

/**
 * __useGetEforceMemberBookingReportQuery__
 *
 * To run a query within a React component, call `useGetEforceMemberBookingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEforceMemberBookingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEforceMemberBookingReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetEforceMemberBookingReportQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetEforceMemberBookingReportQuery,
        GetEforceMemberBookingReportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEforceMemberBookingReportQuery,
        GetEforceMemberBookingReportQueryVariables
    >(GetEforceMemberBookingReportDocument, options);
}
export function useGetEforceMemberBookingReportLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEforceMemberBookingReportQuery,
        GetEforceMemberBookingReportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEforceMemberBookingReportQuery,
        GetEforceMemberBookingReportQueryVariables
    >(GetEforceMemberBookingReportDocument, options);
}
export type GetEforceMemberBookingReportQueryHookResult = ReturnType<
    typeof useGetEforceMemberBookingReportQuery
>;
export type GetEforceMemberBookingReportLazyQueryHookResult = ReturnType<
    typeof useGetEforceMemberBookingReportLazyQuery
>;
export type GetEforceMemberBookingReportQueryResult = Apollo.QueryResult<
    GetEforceMemberBookingReportQuery,
    GetEforceMemberBookingReportQueryVariables
>;
export const ModifyEforceBookingDocument = gql`
    mutation ModifyEforceBooking($request: ModifyBookingRequestInput!) {
        modifyBooking(request: $request) {
            id
        }
    }
`;
export type ModifyEforceBookingMutationFn = Apollo.MutationFunction<
    ModifyEforceBookingMutation,
    ModifyEforceBookingMutationVariables
>;

/**
 * __useModifyEforceBookingMutation__
 *
 * To run a mutation, you first call `useModifyEforceBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyEforceBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyEforceBookingMutation, { data, loading, error }] = useModifyEforceBookingMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useModifyEforceBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ModifyEforceBookingMutation,
        ModifyEforceBookingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ModifyEforceBookingMutation, ModifyEforceBookingMutationVariables>(
        ModifyEforceBookingDocument,
        options,
    );
}
export type ModifyEforceBookingMutationHookResult = ReturnType<
    typeof useModifyEforceBookingMutation
>;
export type ModifyEforceBookingMutationResult = Apollo.MutationResult<ModifyEforceBookingMutation>;
export type ModifyEforceBookingMutationOptions = Apollo.BaseMutationOptions<
    ModifyEforceBookingMutation,
    ModifyEforceBookingMutationVariables
>;
export const PauseTimeTrackingDocument = gql`
    mutation PauseTimeTracking($bookingId: ID!) {
        pause(bookingId: $bookingId) {
            booking {
                id
            }
        }
    }
`;
export type PauseTimeTrackingMutationFn = Apollo.MutationFunction<
    PauseTimeTrackingMutation,
    PauseTimeTrackingMutationVariables
>;

/**
 * __usePauseTimeTrackingMutation__
 *
 * To run a mutation, you first call `usePauseTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseTimeTrackingMutation, { data, loading, error }] = usePauseTimeTrackingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function usePauseTimeTrackingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PauseTimeTrackingMutation,
        PauseTimeTrackingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<PauseTimeTrackingMutation, PauseTimeTrackingMutationVariables>(
        PauseTimeTrackingDocument,
        options,
    );
}
export type PauseTimeTrackingMutationHookResult = ReturnType<typeof usePauseTimeTrackingMutation>;
export type PauseTimeTrackingMutationResult = Apollo.MutationResult<PauseTimeTrackingMutation>;
export type PauseTimeTrackingMutationOptions = Apollo.BaseMutationOptions<
    PauseTimeTrackingMutation,
    PauseTimeTrackingMutationVariables
>;
export const ResumeTimeTrackingDocument = gql`
    mutation ResumeTimeTracking($bookingId: ID!) {
        resume(bookingId: $bookingId) {
            booking {
                id
            }
        }
    }
`;
export type ResumeTimeTrackingMutationFn = Apollo.MutationFunction<
    ResumeTimeTrackingMutation,
    ResumeTimeTrackingMutationVariables
>;

/**
 * __useResumeTimeTrackingMutation__
 *
 * To run a mutation, you first call `useResumeTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeTimeTrackingMutation, { data, loading, error }] = useResumeTimeTrackingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useResumeTimeTrackingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResumeTimeTrackingMutation,
        ResumeTimeTrackingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResumeTimeTrackingMutation, ResumeTimeTrackingMutationVariables>(
        ResumeTimeTrackingDocument,
        options,
    );
}
export type ResumeTimeTrackingMutationHookResult = ReturnType<typeof useResumeTimeTrackingMutation>;
export type ResumeTimeTrackingMutationResult = Apollo.MutationResult<ResumeTimeTrackingMutation>;
export type ResumeTimeTrackingMutationOptions = Apollo.BaseMutationOptions<
    ResumeTimeTrackingMutation,
    ResumeTimeTrackingMutationVariables
>;
export const SendEmailReportDocument = gql`
    query SendEmailReport($input: BookingEmailReportInput!) {
        bookingSendEmailReport(input: $input)
    }
`;

/**
 * __useSendEmailReportQuery__
 *
 * To run a query within a React component, call `useSendEmailReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendEmailReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendEmailReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailReportQuery(
    baseOptions: Apollo.QueryHookOptions<SendEmailReportQuery, SendEmailReportQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SendEmailReportQuery, SendEmailReportQueryVariables>(
        SendEmailReportDocument,
        options,
    );
}
export function useSendEmailReportLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SendEmailReportQuery, SendEmailReportQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SendEmailReportQuery, SendEmailReportQueryVariables>(
        SendEmailReportDocument,
        options,
    );
}
export type SendEmailReportQueryHookResult = ReturnType<typeof useSendEmailReportQuery>;
export type SendEmailReportLazyQueryHookResult = ReturnType<typeof useSendEmailReportLazyQuery>;
export type SendEmailReportQueryResult = Apollo.QueryResult<
    SendEmailReportQuery,
    SendEmailReportQueryVariables
>;
export const StartTimeTrackingDocument = gql`
    mutation StartTimeTracking($bookingId: ID!) {
        start(bookingId: $bookingId) {
            booking {
                id
            }
        }
    }
`;
export type StartTimeTrackingMutationFn = Apollo.MutationFunction<
    StartTimeTrackingMutation,
    StartTimeTrackingMutationVariables
>;

/**
 * __useStartTimeTrackingMutation__
 *
 * To run a mutation, you first call `useStartTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTimeTrackingMutation, { data, loading, error }] = useStartTimeTrackingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useStartTimeTrackingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        StartTimeTrackingMutation,
        StartTimeTrackingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<StartTimeTrackingMutation, StartTimeTrackingMutationVariables>(
        StartTimeTrackingDocument,
        options,
    );
}
export type StartTimeTrackingMutationHookResult = ReturnType<typeof useStartTimeTrackingMutation>;
export type StartTimeTrackingMutationResult = Apollo.MutationResult<StartTimeTrackingMutation>;
export type StartTimeTrackingMutationOptions = Apollo.BaseMutationOptions<
    StartTimeTrackingMutation,
    StartTimeTrackingMutationVariables
>;
export const CancelStorageRequestDocument = gql`
    mutation cancelStorageRequest($id: ID!) {
        cancelStorageRequest(id: $id) {
            id
        }
    }
`;
export type CancelStorageRequestMutationFn = Apollo.MutationFunction<
    CancelStorageRequestMutation,
    CancelStorageRequestMutationVariables
>;

/**
 * __useCancelStorageRequestMutation__
 *
 * To run a mutation, you first call `useCancelStorageRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStorageRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStorageRequestMutation, { data, loading, error }] = useCancelStorageRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelStorageRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelStorageRequestMutation,
        CancelStorageRequestMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelStorageRequestMutation, CancelStorageRequestMutationVariables>(
        CancelStorageRequestDocument,
        options,
    );
}
export type CancelStorageRequestMutationHookResult = ReturnType<
    typeof useCancelStorageRequestMutation
>;
export type CancelStorageRequestMutationResult =
    Apollo.MutationResult<CancelStorageRequestMutation>;
export type CancelStorageRequestMutationOptions = Apollo.BaseMutationOptions<
    CancelStorageRequestMutation,
    CancelStorageRequestMutationVariables
>;
export const CancelStorageReturnRequestDocument = gql`
    mutation cancelStorageReturnRequest($id: ID!) {
        cancelStorageReturnRequest(id: $id) {
            id
        }
    }
`;
export type CancelStorageReturnRequestMutationFn = Apollo.MutationFunction<
    CancelStorageReturnRequestMutation,
    CancelStorageReturnRequestMutationVariables
>;

/**
 * __useCancelStorageReturnRequestMutation__
 *
 * To run a mutation, you first call `useCancelStorageReturnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStorageReturnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStorageReturnRequestMutation, { data, loading, error }] = useCancelStorageReturnRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelStorageReturnRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelStorageReturnRequestMutation,
        CancelStorageReturnRequestMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CancelStorageReturnRequestMutation,
        CancelStorageReturnRequestMutationVariables
    >(CancelStorageReturnRequestDocument, options);
}
export type CancelStorageReturnRequestMutationHookResult = ReturnType<
    typeof useCancelStorageReturnRequestMutation
>;
export type CancelStorageReturnRequestMutationResult =
    Apollo.MutationResult<CancelStorageReturnRequestMutation>;
export type CancelStorageReturnRequestMutationOptions = Apollo.BaseMutationOptions<
    CancelStorageReturnRequestMutation,
    CancelStorageReturnRequestMutationVariables
>;
export const EditStorageRequestDocument = gql`
    mutation EditStorageRequest($request: EditStorageRequestInput!) {
        editStorageRequest(request: $request) {
            id
        }
    }
`;
export type EditStorageRequestMutationFn = Apollo.MutationFunction<
    EditStorageRequestMutation,
    EditStorageRequestMutationVariables
>;

/**
 * __useEditStorageRequestMutation__
 *
 * To run a mutation, you first call `useEditStorageRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStorageRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStorageRequestMutation, { data, loading, error }] = useEditStorageRequestMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useEditStorageRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EditStorageRequestMutation,
        EditStorageRequestMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditStorageRequestMutation, EditStorageRequestMutationVariables>(
        EditStorageRequestDocument,
        options,
    );
}
export type EditStorageRequestMutationHookResult = ReturnType<typeof useEditStorageRequestMutation>;
export type EditStorageRequestMutationResult = Apollo.MutationResult<EditStorageRequestMutation>;
export type EditStorageRequestMutationOptions = Apollo.BaseMutationOptions<
    EditStorageRequestMutation,
    EditStorageRequestMutationVariables
>;
export const GetCompaniesForFlexStorageFormDocument = gql`
    query getCompaniesForFlexStorageForm($filter: CompanyFilterInput) {
        companies(filter: $filter) {
            id
            name
        }
    }
`;

/**
 * __useGetCompaniesForFlexStorageFormQuery__
 *
 * To run a query within a React component, call `useGetCompaniesForFlexStorageFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesForFlexStorageFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesForFlexStorageFormQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompaniesForFlexStorageFormQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetCompaniesForFlexStorageFormQuery,
        GetCompaniesForFlexStorageFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetCompaniesForFlexStorageFormQuery,
        GetCompaniesForFlexStorageFormQueryVariables
    >(GetCompaniesForFlexStorageFormDocument, options);
}
export function useGetCompaniesForFlexStorageFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCompaniesForFlexStorageFormQuery,
        GetCompaniesForFlexStorageFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCompaniesForFlexStorageFormQuery,
        GetCompaniesForFlexStorageFormQueryVariables
    >(GetCompaniesForFlexStorageFormDocument, options);
}
export type GetCompaniesForFlexStorageFormQueryHookResult = ReturnType<
    typeof useGetCompaniesForFlexStorageFormQuery
>;
export type GetCompaniesForFlexStorageFormLazyQueryHookResult = ReturnType<
    typeof useGetCompaniesForFlexStorageFormLazyQuery
>;
export type GetCompaniesForFlexStorageFormQueryResult = Apollo.QueryResult<
    GetCompaniesForFlexStorageFormQuery,
    GetCompaniesForFlexStorageFormQueryVariables
>;
export const GetFlexStorageItemsDocument = gql`
    query getFlexStorageItems($filter: StorageFilterInput!) {
        storageItems(filter: $filter) {
            id
            description
            state
            cost {
                amount
            }
            photo {
                id
                url
            }
            created {
                at
            }
            company {
                id
                name
            }
        }
    }
`;

/**
 * __useGetFlexStorageItemsQuery__
 *
 * To run a query within a React component, call `useGetFlexStorageItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlexStorageItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlexStorageItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFlexStorageItemsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetFlexStorageItemsQuery,
        GetFlexStorageItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetFlexStorageItemsQuery, GetFlexStorageItemsQueryVariables>(
        GetFlexStorageItemsDocument,
        options,
    );
}
export function useGetFlexStorageItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetFlexStorageItemsQuery,
        GetFlexStorageItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetFlexStorageItemsQuery, GetFlexStorageItemsQueryVariables>(
        GetFlexStorageItemsDocument,
        options,
    );
}
export type GetFlexStorageItemsQueryHookResult = ReturnType<typeof useGetFlexStorageItemsQuery>;
export type GetFlexStorageItemsLazyQueryHookResult = ReturnType<
    typeof useGetFlexStorageItemsLazyQuery
>;
export type GetFlexStorageItemsQueryResult = Apollo.QueryResult<
    GetFlexStorageItemsQuery,
    GetFlexStorageItemsQueryVariables
>;
export const MarkStorageItemAsReturnedDocument = gql`
    mutation MarkStorageItemAsReturned($id: ID!) {
        returnItem(id: $id) {
            id
        }
    }
`;
export type MarkStorageItemAsReturnedMutationFn = Apollo.MutationFunction<
    MarkStorageItemAsReturnedMutation,
    MarkStorageItemAsReturnedMutationVariables
>;

/**
 * __useMarkStorageItemAsReturnedMutation__
 *
 * To run a mutation, you first call `useMarkStorageItemAsReturnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStorageItemAsReturnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStorageItemAsReturnedMutation, { data, loading, error }] = useMarkStorageItemAsReturnedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkStorageItemAsReturnedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MarkStorageItemAsReturnedMutation,
        MarkStorageItemAsReturnedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MarkStorageItemAsReturnedMutation,
        MarkStorageItemAsReturnedMutationVariables
    >(MarkStorageItemAsReturnedDocument, options);
}
export type MarkStorageItemAsReturnedMutationHookResult = ReturnType<
    typeof useMarkStorageItemAsReturnedMutation
>;
export type MarkStorageItemAsReturnedMutationResult =
    Apollo.MutationResult<MarkStorageItemAsReturnedMutation>;
export type MarkStorageItemAsReturnedMutationOptions = Apollo.BaseMutationOptions<
    MarkStorageItemAsReturnedMutation,
    MarkStorageItemAsReturnedMutationVariables
>;
export const MarkStorageItemAsStoredDocument = gql`
    mutation MarkStorageItemAsStored($id: ID!) {
        storeItem(id: $id) {
            id
        }
    }
`;
export type MarkStorageItemAsStoredMutationFn = Apollo.MutationFunction<
    MarkStorageItemAsStoredMutation,
    MarkStorageItemAsStoredMutationVariables
>;

/**
 * __useMarkStorageItemAsStoredMutation__
 *
 * To run a mutation, you first call `useMarkStorageItemAsStoredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStorageItemAsStoredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStorageItemAsStoredMutation, { data, loading, error }] = useMarkStorageItemAsStoredMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkStorageItemAsStoredMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MarkStorageItemAsStoredMutation,
        MarkStorageItemAsStoredMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MarkStorageItemAsStoredMutation,
        MarkStorageItemAsStoredMutationVariables
    >(MarkStorageItemAsStoredDocument, options);
}
export type MarkStorageItemAsStoredMutationHookResult = ReturnType<
    typeof useMarkStorageItemAsStoredMutation
>;
export type MarkStorageItemAsStoredMutationResult =
    Apollo.MutationResult<MarkStorageItemAsStoredMutation>;
export type MarkStorageItemAsStoredMutationOptions = Apollo.BaseMutationOptions<
    MarkStorageItemAsStoredMutation,
    MarkStorageItemAsStoredMutationVariables
>;
export const RequestFlexStorageDocument = gql`
    mutation requestFlexStorage($request: StorageRequestInput!) {
        requestStorage(request: $request) {
            id
        }
    }
`;
export type RequestFlexStorageMutationFn = Apollo.MutationFunction<
    RequestFlexStorageMutation,
    RequestFlexStorageMutationVariables
>;

/**
 * __useRequestFlexStorageMutation__
 *
 * To run a mutation, you first call `useRequestFlexStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFlexStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFlexStorageMutation, { data, loading, error }] = useRequestFlexStorageMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRequestFlexStorageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestFlexStorageMutation,
        RequestFlexStorageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RequestFlexStorageMutation, RequestFlexStorageMutationVariables>(
        RequestFlexStorageDocument,
        options,
    );
}
export type RequestFlexStorageMutationHookResult = ReturnType<typeof useRequestFlexStorageMutation>;
export type RequestFlexStorageMutationResult = Apollo.MutationResult<RequestFlexStorageMutation>;
export type RequestFlexStorageMutationOptions = Apollo.BaseMutationOptions<
    RequestFlexStorageMutation,
    RequestFlexStorageMutationVariables
>;
export const RequestStorageReturnDocument = gql`
    mutation requestStorageReturn($id: ID!) {
        requestStorageReturn(id: $id) {
            id
        }
    }
`;
export type RequestStorageReturnMutationFn = Apollo.MutationFunction<
    RequestStorageReturnMutation,
    RequestStorageReturnMutationVariables
>;

/**
 * __useRequestStorageReturnMutation__
 *
 * To run a mutation, you first call `useRequestStorageReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestStorageReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestStorageReturnMutation, { data, loading, error }] = useRequestStorageReturnMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestStorageReturnMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestStorageReturnMutation,
        RequestStorageReturnMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RequestStorageReturnMutation, RequestStorageReturnMutationVariables>(
        RequestStorageReturnDocument,
        options,
    );
}
export type RequestStorageReturnMutationHookResult = ReturnType<
    typeof useRequestStorageReturnMutation
>;
export type RequestStorageReturnMutationResult =
    Apollo.MutationResult<RequestStorageReturnMutation>;
export type RequestStorageReturnMutationOptions = Apollo.BaseMutationOptions<
    RequestStorageReturnMutation,
    RequestStorageReturnMutationVariables
>;
export const CreateLockDocument = gql`
    mutation CreateLock($lock: LockInput) {
        createLock(lock: $lock) {
            id
        }
    }
`;
export type CreateLockMutationFn = Apollo.MutationFunction<
    CreateLockMutation,
    CreateLockMutationVariables
>;

/**
 * __useCreateLockMutation__
 *
 * To run a mutation, you first call `useCreateLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLockMutation, { data, loading, error }] = useCreateLockMutation({
 *   variables: {
 *      lock: // value for 'lock'
 *   },
 * });
 */
export function useCreateLockMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateLockMutation, CreateLockMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateLockMutation, CreateLockMutationVariables>(
        CreateLockDocument,
        options,
    );
}
export type CreateLockMutationHookResult = ReturnType<typeof useCreateLockMutation>;
export type CreateLockMutationResult = Apollo.MutationResult<CreateLockMutation>;
export type CreateLockMutationOptions = Apollo.BaseMutationOptions<
    CreateLockMutation,
    CreateLockMutationVariables
>;
export const DoorCreateDocument = gql`
    mutation DoorCreate($input: DoorCreateInput!) {
        doorCreate(input: $input) {
            id
            name
        }
    }
`;
export type DoorCreateMutationFn = Apollo.MutationFunction<
    DoorCreateMutation,
    DoorCreateMutationVariables
>;

/**
 * __useDoorCreateMutation__
 *
 * To run a mutation, you first call `useDoorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doorCreateMutation, { data, loading, error }] = useDoorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoorCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<DoorCreateMutation, DoorCreateMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DoorCreateMutation, DoorCreateMutationVariables>(
        DoorCreateDocument,
        options,
    );
}
export type DoorCreateMutationHookResult = ReturnType<typeof useDoorCreateMutation>;
export type DoorCreateMutationResult = Apollo.MutationResult<DoorCreateMutation>;
export type DoorCreateMutationOptions = Apollo.BaseMutationOptions<
    DoorCreateMutation,
    DoorCreateMutationVariables
>;
export const GetLocationDocument = gql`
    query GetLocation($locationId: ID!) {
        location(id: $locationId) {
            id
            name
            doors {
                id
                name
                description
                externalId
            }
            zone
        }
    }
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationQuery(
    baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
        GetLocationDocument,
        options,
    );
}
export function useGetLocationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
        GetLocationDocument,
        options,
    );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<
    GetLocationQuery,
    GetLocationQueryVariables
>;
export const LocationsDocument = gql`
    query Locations {
        locations {
            id
            name
            zone
            doors {
                id
                name
            }
        }
    }
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
}
export function useLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LocationCreateDocument = gql`
    mutation LocationCreate($input: LocationCreateInput!) {
        locationCreate(input: $input) {
            id
            name
        }
    }
`;
export type LocationCreateMutationFn = Apollo.MutationFunction<
    LocationCreateMutation,
    LocationCreateMutationVariables
>;

/**
 * __useLocationCreateMutation__
 *
 * To run a mutation, you first call `useLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationCreateMutation, { data, loading, error }] = useLocationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LocationCreateMutation,
        LocationCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LocationCreateMutation, LocationCreateMutationVariables>(
        LocationCreateDocument,
        options,
    );
}
export type LocationCreateMutationHookResult = ReturnType<typeof useLocationCreateMutation>;
export type LocationCreateMutationResult = Apollo.MutationResult<LocationCreateMutation>;
export type LocationCreateMutationOptions = Apollo.BaseMutationOptions<
    LocationCreateMutation,
    LocationCreateMutationVariables
>;
export const DoorUpdateDocument = gql`
    mutation DoorUpdate($input: DoorUpdateInput!) {
        doorUpdate(input: $input) {
            id
            name
        }
    }
`;
export type DoorUpdateMutationFn = Apollo.MutationFunction<
    DoorUpdateMutation,
    DoorUpdateMutationVariables
>;

/**
 * __useDoorUpdateMutation__
 *
 * To run a mutation, you first call `useDoorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doorUpdateMutation, { data, loading, error }] = useDoorUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoorUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<DoorUpdateMutation, DoorUpdateMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DoorUpdateMutation, DoorUpdateMutationVariables>(
        DoorUpdateDocument,
        options,
    );
}
export type DoorUpdateMutationHookResult = ReturnType<typeof useDoorUpdateMutation>;
export type DoorUpdateMutationResult = Apollo.MutationResult<DoorUpdateMutation>;
export type DoorUpdateMutationOptions = Apollo.BaseMutationOptions<
    DoorUpdateMutation,
    DoorUpdateMutationVariables
>;
export const AddMemberToAccessLocationDocument = gql`
    mutation AddMemberToAccessLocation($input: UserAccessInput!) {
        grantUserAccessToLocation(input: $input) {
            id
        }
    }
`;
export type AddMemberToAccessLocationMutationFn = Apollo.MutationFunction<
    AddMemberToAccessLocationMutation,
    AddMemberToAccessLocationMutationVariables
>;

/**
 * __useAddMemberToAccessLocationMutation__
 *
 * To run a mutation, you first call `useAddMemberToAccessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToAccessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToAccessLocationMutation, { data, loading, error }] = useAddMemberToAccessLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberToAccessLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddMemberToAccessLocationMutation,
        AddMemberToAccessLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AddMemberToAccessLocationMutation,
        AddMemberToAccessLocationMutationVariables
    >(AddMemberToAccessLocationDocument, options);
}
export type AddMemberToAccessLocationMutationHookResult = ReturnType<
    typeof useAddMemberToAccessLocationMutation
>;
export type AddMemberToAccessLocationMutationResult =
    Apollo.MutationResult<AddMemberToAccessLocationMutation>;
export type AddMemberToAccessLocationMutationOptions = Apollo.BaseMutationOptions<
    AddMemberToAccessLocationMutation,
    AddMemberToAccessLocationMutationVariables
>;
export const AddMemberToServiceLocationDocument = gql`
    mutation AddMemberToServiceLocation($input: UserAccessInput!) {
        assignServiceAccess(input: $input) {
            id
        }
    }
`;
export type AddMemberToServiceLocationMutationFn = Apollo.MutationFunction<
    AddMemberToServiceLocationMutation,
    AddMemberToServiceLocationMutationVariables
>;

/**
 * __useAddMemberToServiceLocationMutation__
 *
 * To run a mutation, you first call `useAddMemberToServiceLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToServiceLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToServiceLocationMutation, { data, loading, error }] = useAddMemberToServiceLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberToServiceLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddMemberToServiceLocationMutation,
        AddMemberToServiceLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AddMemberToServiceLocationMutation,
        AddMemberToServiceLocationMutationVariables
    >(AddMemberToServiceLocationDocument, options);
}
export type AddMemberToServiceLocationMutationHookResult = ReturnType<
    typeof useAddMemberToServiceLocationMutation
>;
export type AddMemberToServiceLocationMutationResult =
    Apollo.MutationResult<AddMemberToServiceLocationMutation>;
export type AddMemberToServiceLocationMutationOptions = Apollo.BaseMutationOptions<
    AddMemberToServiceLocationMutation,
    AddMemberToServiceLocationMutationVariables
>;
export const CompanyAssignToLocationDocument = gql`
    mutation CompanyAssignToLocation($input: AssignCompanyToLocation) {
        companyAssignToLocation(input: $input) {
            id
        }
    }
`;
export type CompanyAssignToLocationMutationFn = Apollo.MutationFunction<
    CompanyAssignToLocationMutation,
    CompanyAssignToLocationMutationVariables
>;

/**
 * __useCompanyAssignToLocationMutation__
 *
 * To run a mutation, you first call `useCompanyAssignToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAssignToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAssignToLocationMutation, { data, loading, error }] = useCompanyAssignToLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAssignToLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompanyAssignToLocationMutation,
        CompanyAssignToLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompanyAssignToLocationMutation,
        CompanyAssignToLocationMutationVariables
    >(CompanyAssignToLocationDocument, options);
}
export type CompanyAssignToLocationMutationHookResult = ReturnType<
    typeof useCompanyAssignToLocationMutation
>;
export type CompanyAssignToLocationMutationResult =
    Apollo.MutationResult<CompanyAssignToLocationMutation>;
export type CompanyAssignToLocationMutationOptions = Apollo.BaseMutationOptions<
    CompanyAssignToLocationMutation,
    CompanyAssignToLocationMutationVariables
>;
export const CompanyClearRestrictionDocument = gql`
    mutation CompanyClearRestriction($companyId: ID!) {
        companyClearRestriction(id: $companyId) {
            id
        }
    }
`;
export type CompanyClearRestrictionMutationFn = Apollo.MutationFunction<
    CompanyClearRestrictionMutation,
    CompanyClearRestrictionMutationVariables
>;

/**
 * __useCompanyClearRestrictionMutation__
 *
 * To run a mutation, you first call `useCompanyClearRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyClearRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyClearRestrictionMutation, { data, loading, error }] = useCompanyClearRestrictionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyClearRestrictionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompanyClearRestrictionMutation,
        CompanyClearRestrictionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompanyClearRestrictionMutation,
        CompanyClearRestrictionMutationVariables
    >(CompanyClearRestrictionDocument, options);
}
export type CompanyClearRestrictionMutationHookResult = ReturnType<
    typeof useCompanyClearRestrictionMutation
>;
export type CompanyClearRestrictionMutationResult =
    Apollo.MutationResult<CompanyClearRestrictionMutation>;
export type CompanyClearRestrictionMutationOptions = Apollo.BaseMutationOptions<
    CompanyClearRestrictionMutation,
    CompanyClearRestrictionMutationVariables
>;
export const CompanyRemoveFromLocationDocument = gql`
    mutation CompanyRemoveFromLocation($input: RemoveCompanyFromLocationInput) {
        companyRemoveFromLocation(input: $input) {
            id
        }
    }
`;
export type CompanyRemoveFromLocationMutationFn = Apollo.MutationFunction<
    CompanyRemoveFromLocationMutation,
    CompanyRemoveFromLocationMutationVariables
>;

/**
 * __useCompanyRemoveFromLocationMutation__
 *
 * To run a mutation, you first call `useCompanyRemoveFromLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRemoveFromLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRemoveFromLocationMutation, { data, loading, error }] = useCompanyRemoveFromLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyRemoveFromLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompanyRemoveFromLocationMutation,
        CompanyRemoveFromLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompanyRemoveFromLocationMutation,
        CompanyRemoveFromLocationMutationVariables
    >(CompanyRemoveFromLocationDocument, options);
}
export type CompanyRemoveFromLocationMutationHookResult = ReturnType<
    typeof useCompanyRemoveFromLocationMutation
>;
export type CompanyRemoveFromLocationMutationResult =
    Apollo.MutationResult<CompanyRemoveFromLocationMutation>;
export type CompanyRemoveFromLocationMutationOptions = Apollo.BaseMutationOptions<
    CompanyRemoveFromLocationMutation,
    CompanyRemoveFromLocationMutationVariables
>;
export const CompanyRestrictDocument = gql`
    mutation CompanyRestrict($input: CompanyRestrictInput!) {
        companyRestrict(input: $input) {
            id
        }
    }
`;
export type CompanyRestrictMutationFn = Apollo.MutationFunction<
    CompanyRestrictMutation,
    CompanyRestrictMutationVariables
>;

/**
 * __useCompanyRestrictMutation__
 *
 * To run a mutation, you first call `useCompanyRestrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRestrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRestrictMutation, { data, loading, error }] = useCompanyRestrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyRestrictMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompanyRestrictMutation,
        CompanyRestrictMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CompanyRestrictMutation, CompanyRestrictMutationVariables>(
        CompanyRestrictDocument,
        options,
    );
}
export type CompanyRestrictMutationHookResult = ReturnType<typeof useCompanyRestrictMutation>;
export type CompanyRestrictMutationResult = Apollo.MutationResult<CompanyRestrictMutation>;
export type CompanyRestrictMutationOptions = Apollo.BaseMutationOptions<
    CompanyRestrictMutation,
    CompanyRestrictMutationVariables
>;
export const CreateMemberCompanyDocument = gql`
    mutation CreateMemberCompany($company: CompanyInput!) {
        createCompany(company: $company) {
            id
        }
    }
`;
export type CreateMemberCompanyMutationFn = Apollo.MutationFunction<
    CreateMemberCompanyMutation,
    CreateMemberCompanyMutationVariables
>;

/**
 * __useCreateMemberCompanyMutation__
 *
 * To run a mutation, you first call `useCreateMemberCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberCompanyMutation, { data, loading, error }] = useCreateMemberCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateMemberCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMemberCompanyMutation,
        CreateMemberCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMemberCompanyMutation, CreateMemberCompanyMutationVariables>(
        CreateMemberCompanyDocument,
        options,
    );
}
export type CreateMemberCompanyMutationHookResult = ReturnType<
    typeof useCreateMemberCompanyMutation
>;
export type CreateMemberCompanyMutationResult = Apollo.MutationResult<CreateMemberCompanyMutation>;
export type CreateMemberCompanyMutationOptions = Apollo.BaseMutationOptions<
    CreateMemberCompanyMutation,
    CreateMemberCompanyMutationVariables
>;
export const CreateMemberUserDocument = gql`
    mutation CreateMemberUser($user: UserInput!) {
        createMemberUser(user: $user) {
            id
            name
        }
    }
`;
export type CreateMemberUserMutationFn = Apollo.MutationFunction<
    CreateMemberUserMutation,
    CreateMemberUserMutationVariables
>;

/**
 * __useCreateMemberUserMutation__
 *
 * To run a mutation, you first call `useCreateMemberUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberUserMutation, { data, loading, error }] = useCreateMemberUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateMemberUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMemberUserMutation,
        CreateMemberUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMemberUserMutation, CreateMemberUserMutationVariables>(
        CreateMemberUserDocument,
        options,
    );
}
export type CreateMemberUserMutationHookResult = ReturnType<typeof useCreateMemberUserMutation>;
export type CreateMemberUserMutationResult = Apollo.MutationResult<CreateMemberUserMutation>;
export type CreateMemberUserMutationOptions = Apollo.BaseMutationOptions<
    CreateMemberUserMutation,
    CreateMemberUserMutationVariables
>;
export const SubscriptionsEForceDocument = gql`
    query SubscriptionsEForce($input: SubscriptionsEForceFilterInput!) {
        subscriptionsEForce(input: $input) {
            id
            location {
                id
                name
            }
            slot {
                from
                to
            }
            preferredMemberSpecialists {
                status
                memberSpecialist {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useSubscriptionsEForceQuery__
 *
 * To run a query within a React component, call `useSubscriptionsEForceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsEForceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsEForceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionsEForceQuery(
    baseOptions: Apollo.QueryHookOptions<
        SubscriptionsEForceQuery,
        SubscriptionsEForceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SubscriptionsEForceQuery, SubscriptionsEForceQueryVariables>(
        SubscriptionsEForceDocument,
        options,
    );
}
export function useSubscriptionsEForceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SubscriptionsEForceQuery,
        SubscriptionsEForceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SubscriptionsEForceQuery, SubscriptionsEForceQueryVariables>(
        SubscriptionsEForceDocument,
        options,
    );
}
export type SubscriptionsEForceQueryHookResult = ReturnType<typeof useSubscriptionsEForceQuery>;
export type SubscriptionsEForceLazyQueryHookResult = ReturnType<
    typeof useSubscriptionsEForceLazyQuery
>;
export type SubscriptionsEForceQueryResult = Apollo.QueryResult<
    SubscriptionsEForceQuery,
    SubscriptionsEForceQueryVariables
>;
export const MemberCompaniesDocument = gql`
    query MemberCompanies($filter: CompanyFilterInput) {
        companies(filter: $filter) {
            id
            name
            state
            locations {
                id
                name
            }
        }
    }
`;

/**
 * __useMemberCompaniesQuery__
 *
 * To run a query within a React component, call `useMemberCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberCompaniesQuery(
    baseOptions?: Apollo.QueryHookOptions<MemberCompaniesQuery, MemberCompaniesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MemberCompaniesQuery, MemberCompaniesQueryVariables>(
        MemberCompaniesDocument,
        options,
    );
}
export function useMemberCompaniesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MemberCompaniesQuery, MemberCompaniesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MemberCompaniesQuery, MemberCompaniesQueryVariables>(
        MemberCompaniesDocument,
        options,
    );
}
export type MemberCompaniesQueryHookResult = ReturnType<typeof useMemberCompaniesQuery>;
export type MemberCompaniesLazyQueryHookResult = ReturnType<typeof useMemberCompaniesLazyQuery>;
export type MemberCompaniesQueryResult = Apollo.QueryResult<
    MemberCompaniesQuery,
    MemberCompaniesQueryVariables
>;
export const MemberCompanyDocument = gql`
    query MemberCompany($companyId: ID!) {
        company(id: $companyId) {
            id
            name
            state
            locations {
                id
                name
            }
        }
    }
`;

/**
 * __useMemberCompanyQuery__
 *
 * To run a query within a React component, call `useMemberCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMemberCompanyQuery(
    baseOptions: Apollo.QueryHookOptions<MemberCompanyQuery, MemberCompanyQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MemberCompanyQuery, MemberCompanyQueryVariables>(
        MemberCompanyDocument,
        options,
    );
}
export function useMemberCompanyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MemberCompanyQuery, MemberCompanyQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MemberCompanyQuery, MemberCompanyQueryVariables>(
        MemberCompanyDocument,
        options,
    );
}
export type MemberCompanyQueryHookResult = ReturnType<typeof useMemberCompanyQuery>;
export type MemberCompanyLazyQueryHookResult = ReturnType<typeof useMemberCompanyLazyQuery>;
export type MemberCompanyQueryResult = Apollo.QueryResult<
    MemberCompanyQuery,
    MemberCompanyQueryVariables
>;
export const MemberLocationsDocument = gql`
    query MemberLocations {
        locations {
            id
            name
        }
    }
`;

/**
 * __useMemberLocationsQuery__
 *
 * To run a query within a React component, call `useMemberLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<MemberLocationsQuery, MemberLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MemberLocationsQuery, MemberLocationsQueryVariables>(
        MemberLocationsDocument,
        options,
    );
}
export function useMemberLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MemberLocationsQuery, MemberLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MemberLocationsQuery, MemberLocationsQueryVariables>(
        MemberLocationsDocument,
        options,
    );
}
export type MemberLocationsQueryHookResult = ReturnType<typeof useMemberLocationsQuery>;
export type MemberLocationsLazyQueryHookResult = ReturnType<typeof useMemberLocationsLazyQuery>;
export type MemberLocationsQueryResult = Apollo.QueryResult<
    MemberLocationsQuery,
    MemberLocationsQueryVariables
>;
export const MembersDocument = gql`
    query Members($filter: UsersFilterInput) {
        users(filter: $filter) {
            id
            name
            email
            state
            roles
            company {
                id
                name
            }
            accessLocations {
                id
                name
            }
            serviceLocations {
                id
                name
            }
        }
    }
`;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersQuery(
    baseOptions?: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
}
export function useMembersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
}
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const RemoveMemberFromAccessLocationDocument = gql`
    mutation RemoveMemberFromAccessLocation($input: UserAccessInput) {
        revokeLocationAccess(input: $input) {
            id
        }
    }
`;
export type RemoveMemberFromAccessLocationMutationFn = Apollo.MutationFunction<
    RemoveMemberFromAccessLocationMutation,
    RemoveMemberFromAccessLocationMutationVariables
>;

/**
 * __useRemoveMemberFromAccessLocationMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromAccessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromAccessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromAccessLocationMutation, { data, loading, error }] = useRemoveMemberFromAccessLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberFromAccessLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveMemberFromAccessLocationMutation,
        RemoveMemberFromAccessLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RemoveMemberFromAccessLocationMutation,
        RemoveMemberFromAccessLocationMutationVariables
    >(RemoveMemberFromAccessLocationDocument, options);
}
export type RemoveMemberFromAccessLocationMutationHookResult = ReturnType<
    typeof useRemoveMemberFromAccessLocationMutation
>;
export type RemoveMemberFromAccessLocationMutationResult =
    Apollo.MutationResult<RemoveMemberFromAccessLocationMutation>;
export type RemoveMemberFromAccessLocationMutationOptions = Apollo.BaseMutationOptions<
    RemoveMemberFromAccessLocationMutation,
    RemoveMemberFromAccessLocationMutationVariables
>;
export const RemoveMemberFromServiceLocationDocument = gql`
    mutation RemoveMemberFromServiceLocation($input: UserAccessInput) {
        revokeServiceAccess(input: $input) {
            id
        }
    }
`;
export type RemoveMemberFromServiceLocationMutationFn = Apollo.MutationFunction<
    RemoveMemberFromServiceLocationMutation,
    RemoveMemberFromServiceLocationMutationVariables
>;

/**
 * __useRemoveMemberFromServiceLocationMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromServiceLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromServiceLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromServiceLocationMutation, { data, loading, error }] = useRemoveMemberFromServiceLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberFromServiceLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveMemberFromServiceLocationMutation,
        RemoveMemberFromServiceLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RemoveMemberFromServiceLocationMutation,
        RemoveMemberFromServiceLocationMutationVariables
    >(RemoveMemberFromServiceLocationDocument, options);
}
export type RemoveMemberFromServiceLocationMutationHookResult = ReturnType<
    typeof useRemoveMemberFromServiceLocationMutation
>;
export type RemoveMemberFromServiceLocationMutationResult =
    Apollo.MutationResult<RemoveMemberFromServiceLocationMutation>;
export type RemoveMemberFromServiceLocationMutationOptions = Apollo.BaseMutationOptions<
    RemoveMemberFromServiceLocationMutation,
    RemoveMemberFromServiceLocationMutationVariables
>;
export const SubscriptionEForceAddDocument = gql`
    mutation SubscriptionEForceAdd($input: SubscriptionEForceAddInput!) {
        subscriptionEForceAdd(input: $input) {
            id
        }
    }
`;
export type SubscriptionEForceAddMutationFn = Apollo.MutationFunction<
    SubscriptionEForceAddMutation,
    SubscriptionEForceAddMutationVariables
>;

/**
 * __useSubscriptionEForceAddMutation__
 *
 * To run a mutation, you first call `useSubscriptionEForceAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionEForceAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionEForceAddMutation, { data, loading, error }] = useSubscriptionEForceAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionEForceAddMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SubscriptionEForceAddMutation,
        SubscriptionEForceAddMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SubscriptionEForceAddMutation,
        SubscriptionEForceAddMutationVariables
    >(SubscriptionEForceAddDocument, options);
}
export type SubscriptionEForceAddMutationHookResult = ReturnType<
    typeof useSubscriptionEForceAddMutation
>;
export type SubscriptionEForceAddMutationResult =
    Apollo.MutationResult<SubscriptionEForceAddMutation>;
export type SubscriptionEForceAddMutationOptions = Apollo.BaseMutationOptions<
    SubscriptionEForceAddMutation,
    SubscriptionEForceAddMutationVariables
>;
export const SubscriptionEForceCancelDocument = gql`
    mutation SubscriptionEForceCancel($input: SubscriptionEForceCancelInput!) {
        subscriptionEForceCancel(input: $input) {
            id
        }
    }
`;
export type SubscriptionEForceCancelMutationFn = Apollo.MutationFunction<
    SubscriptionEForceCancelMutation,
    SubscriptionEForceCancelMutationVariables
>;

/**
 * __useSubscriptionEForceCancelMutation__
 *
 * To run a mutation, you first call `useSubscriptionEForceCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionEForceCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionEForceCancelMutation, { data, loading, error }] = useSubscriptionEForceCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionEForceCancelMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SubscriptionEForceCancelMutation,
        SubscriptionEForceCancelMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SubscriptionEForceCancelMutation,
        SubscriptionEForceCancelMutationVariables
    >(SubscriptionEForceCancelDocument, options);
}
export type SubscriptionEForceCancelMutationHookResult = ReturnType<
    typeof useSubscriptionEForceCancelMutation
>;
export type SubscriptionEForceCancelMutationResult =
    Apollo.MutationResult<SubscriptionEForceCancelMutation>;
export type SubscriptionEForceCancelMutationOptions = Apollo.BaseMutationOptions<
    SubscriptionEForceCancelMutation,
    SubscriptionEForceCancelMutationVariables
>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($input: UserUpdateInput!) {
        userUpdate(input: $input) {
            id
            name
        }
    }
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<
    UserUpdateMutation,
    UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
        UserUpdateDocument,
        options,
    );
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
    UserUpdateMutation,
    UserUpdateMutationVariables
>;
export const UserAssignRoleDocument = gql`
    mutation UserAssignRole($input: UserRoleInput!) {
        userAssignRole(input: $input) {
            roles
            id
        }
    }
`;
export type UserAssignRoleMutationFn = Apollo.MutationFunction<
    UserAssignRoleMutation,
    UserAssignRoleMutationVariables
>;

/**
 * __useUserAssignRoleMutation__
 *
 * To run a mutation, you first call `useUserAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAssignRoleMutation, { data, loading, error }] = useUserAssignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAssignRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UserAssignRoleMutation,
        UserAssignRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserAssignRoleMutation, UserAssignRoleMutationVariables>(
        UserAssignRoleDocument,
        options,
    );
}
export type UserAssignRoleMutationHookResult = ReturnType<typeof useUserAssignRoleMutation>;
export type UserAssignRoleMutationResult = Apollo.MutationResult<UserAssignRoleMutation>;
export type UserAssignRoleMutationOptions = Apollo.BaseMutationOptions<
    UserAssignRoleMutation,
    UserAssignRoleMutationVariables
>;
export const UserBlockDocument = gql`
    mutation UserBlock($input: UserBlockInput!) {
        userBlock(input: $input) {
            id
        }
    }
`;
export type UserBlockMutationFn = Apollo.MutationFunction<
    UserBlockMutation,
    UserBlockMutationVariables
>;

/**
 * __useUserBlockMutation__
 *
 * To run a mutation, you first call `useUserBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userBlockMutation, { data, loading, error }] = useUserBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserBlockMutation(
    baseOptions?: Apollo.MutationHookOptions<UserBlockMutation, UserBlockMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserBlockMutation, UserBlockMutationVariables>(
        UserBlockDocument,
        options,
    );
}
export type UserBlockMutationHookResult = ReturnType<typeof useUserBlockMutation>;
export type UserBlockMutationResult = Apollo.MutationResult<UserBlockMutation>;
export type UserBlockMutationOptions = Apollo.BaseMutationOptions<
    UserBlockMutation,
    UserBlockMutationVariables
>;
export const UserClearRestrictionDocument = gql`
    mutation UserClearRestriction($id: ID!) {
        userClearRestriction(id: $id) {
            id
        }
    }
`;
export type UserClearRestrictionMutationFn = Apollo.MutationFunction<
    UserClearRestrictionMutation,
    UserClearRestrictionMutationVariables
>;

/**
 * __useUserClearRestrictionMutation__
 *
 * To run a mutation, you first call `useUserClearRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserClearRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userClearRestrictionMutation, { data, loading, error }] = useUserClearRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserClearRestrictionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UserClearRestrictionMutation,
        UserClearRestrictionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserClearRestrictionMutation, UserClearRestrictionMutationVariables>(
        UserClearRestrictionDocument,
        options,
    );
}
export type UserClearRestrictionMutationHookResult = ReturnType<
    typeof useUserClearRestrictionMutation
>;
export type UserClearRestrictionMutationResult =
    Apollo.MutationResult<UserClearRestrictionMutation>;
export type UserClearRestrictionMutationOptions = Apollo.BaseMutationOptions<
    UserClearRestrictionMutation,
    UserClearRestrictionMutationVariables
>;
export const UserDeleteDocument = gql`
    mutation UserDelete($input: UserDeleteInput!) {
        userDelete(input: $input) {
            id
            name
        }
    }
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<
    UserDeleteMutation,
    UserDeleteMutationVariables
>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDeleteMutation(
    baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(
        UserDeleteDocument,
        options,
    );
}
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
    UserDeleteMutation,
    UserDeleteMutationVariables
>;
export const UserRestrictDocument = gql`
    mutation UserRestrict($input: UserRestrictInput!) {
        userRestrict(input: $input) {
            id
        }
    }
`;
export type UserRestrictMutationFn = Apollo.MutationFunction<
    UserRestrictMutation,
    UserRestrictMutationVariables
>;

/**
 * __useUserRestrictMutation__
 *
 * To run a mutation, you first call `useUserRestrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRestrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRestrictMutation, { data, loading, error }] = useUserRestrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRestrictMutation(
    baseOptions?: Apollo.MutationHookOptions<UserRestrictMutation, UserRestrictMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserRestrictMutation, UserRestrictMutationVariables>(
        UserRestrictDocument,
        options,
    );
}
export type UserRestrictMutationHookResult = ReturnType<typeof useUserRestrictMutation>;
export type UserRestrictMutationResult = Apollo.MutationResult<UserRestrictMutation>;
export type UserRestrictMutationOptions = Apollo.BaseMutationOptions<
    UserRestrictMutation,
    UserRestrictMutationVariables
>;
export const UserRevokeRoleDocument = gql`
    mutation UserRevokeRole($input: UserRoleInput!) {
        userRevokeRole(input: $input) {
            id
            roles
        }
    }
`;
export type UserRevokeRoleMutationFn = Apollo.MutationFunction<
    UserRevokeRoleMutation,
    UserRevokeRoleMutationVariables
>;

/**
 * __useUserRevokeRoleMutation__
 *
 * To run a mutation, you first call `useUserRevokeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRevokeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRevokeRoleMutation, { data, loading, error }] = useUserRevokeRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRevokeRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UserRevokeRoleMutation,
        UserRevokeRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserRevokeRoleMutation, UserRevokeRoleMutationVariables>(
        UserRevokeRoleDocument,
        options,
    );
}
export type UserRevokeRoleMutationHookResult = ReturnType<typeof useUserRevokeRoleMutation>;
export type UserRevokeRoleMutationResult = Apollo.MutationResult<UserRevokeRoleMutation>;
export type UserRevokeRoleMutationOptions = Apollo.BaseMutationOptions<
    UserRevokeRoleMutation,
    UserRevokeRoleMutationVariables
>;
export const UserUnblockDocument = gql`
    mutation UserUnblock($id: ID!) {
        userUnblock(userId: $id) {
            id
        }
    }
`;
export type UserUnblockMutationFn = Apollo.MutationFunction<
    UserUnblockMutation,
    UserUnblockMutationVariables
>;

/**
 * __useUserUnblockMutation__
 *
 * To run a mutation, you first call `useUserUnblockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUnblockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUnblockMutation, { data, loading, error }] = useUserUnblockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserUnblockMutation(
    baseOptions?: Apollo.MutationHookOptions<UserUnblockMutation, UserUnblockMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserUnblockMutation, UserUnblockMutationVariables>(
        UserUnblockDocument,
        options,
    );
}
export type UserUnblockMutationHookResult = ReturnType<typeof useUserUnblockMutation>;
export type UserUnblockMutationResult = Apollo.MutationResult<UserUnblockMutation>;
export type UserUnblockMutationOptions = Apollo.BaseMutationOptions<
    UserUnblockMutation,
    UserUnblockMutationVariables
>;
export const ConfirmBinaryUploadDocument = gql`
    mutation confirmBinaryUpload($uploadId: ID!) {
        confirmBinaryUpload(id: $uploadId) {
            id
            url
            status
        }
    }
`;
export type ConfirmBinaryUploadMutationFn = Apollo.MutationFunction<
    ConfirmBinaryUploadMutation,
    ConfirmBinaryUploadMutationVariables
>;

/**
 * __useConfirmBinaryUploadMutation__
 *
 * To run a mutation, you first call `useConfirmBinaryUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBinaryUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBinaryUploadMutation, { data, loading, error }] = useConfirmBinaryUploadMutation({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useConfirmBinaryUploadMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ConfirmBinaryUploadMutation,
        ConfirmBinaryUploadMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmBinaryUploadMutation, ConfirmBinaryUploadMutationVariables>(
        ConfirmBinaryUploadDocument,
        options,
    );
}
export type ConfirmBinaryUploadMutationHookResult = ReturnType<
    typeof useConfirmBinaryUploadMutation
>;
export type ConfirmBinaryUploadMutationResult = Apollo.MutationResult<ConfirmBinaryUploadMutation>;
export type ConfirmBinaryUploadMutationOptions = Apollo.BaseMutationOptions<
    ConfirmBinaryUploadMutation,
    ConfirmBinaryUploadMutationVariables
>;
export const GenerateUploadLinkDocument = gql`
    mutation GenerateUploadLink($request: GenerateUploadLinkInput!) {
        generateUploadLink(request: $request) {
            id
            uploadLink
        }
    }
`;
export type GenerateUploadLinkMutationFn = Apollo.MutationFunction<
    GenerateUploadLinkMutation,
    GenerateUploadLinkMutationVariables
>;

/**
 * __useGenerateUploadLinkMutation__
 *
 * To run a mutation, you first call `useGenerateUploadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadLinkMutation, { data, loading, error }] = useGenerateUploadLinkMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGenerateUploadLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        GenerateUploadLinkMutation,
        GenerateUploadLinkMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<GenerateUploadLinkMutation, GenerateUploadLinkMutationVariables>(
        GenerateUploadLinkDocument,
        options,
    );
}
export type GenerateUploadLinkMutationHookResult = ReturnType<typeof useGenerateUploadLinkMutation>;
export type GenerateUploadLinkMutationResult = Apollo.MutationResult<GenerateUploadLinkMutation>;
export type GenerateUploadLinkMutationOptions = Apollo.BaseMutationOptions<
    GenerateUploadLinkMutation,
    GenerateUploadLinkMutationVariables
>;
export const GetCompaniesDocument = gql`
    query getCompanies($filter: CompanyFilterInput) {
        companies(filter: $filter) {
            id
            name
            locations {
                id
                name
            }
        }
    }
`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompaniesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
        GetCompaniesDocument,
        options,
    );
}
export function useGetCompaniesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
        GetCompaniesDocument,
        options,
    );
}
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<
    GetCompaniesQuery,
    GetCompaniesQueryVariables
>;
export const GetLocationsDocument = gql`
    query getLocations {
        locations {
            id
            name
        }
    }
`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
        GetLocationsDocument,
        options,
    );
}
export function useGetLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
        GetLocationsDocument,
        options,
    );
}
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<
    GetLocationsQuery,
    GetLocationsQueryVariables
>;
export const CreateAssociateUserDocument = gql`
    mutation CreateAssociateUser($input: UserInput!) {
        addAssociateToLocation(input: $input) {
            id
        }
    }
`;
export type CreateAssociateUserMutationFn = Apollo.MutationFunction<
    CreateAssociateUserMutation,
    CreateAssociateUserMutationVariables
>;

/**
 * __useCreateAssociateUserMutation__
 *
 * To run a mutation, you first call `useCreateAssociateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssociateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssociateUserMutation, { data, loading, error }] = useCreateAssociateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssociateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAssociateUserMutation,
        CreateAssociateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateAssociateUserMutation, CreateAssociateUserMutationVariables>(
        CreateAssociateUserDocument,
        options,
    );
}
export type CreateAssociateUserMutationHookResult = ReturnType<
    typeof useCreateAssociateUserMutation
>;
export type CreateAssociateUserMutationResult = Apollo.MutationResult<CreateAssociateUserMutation>;
export type CreateAssociateUserMutationOptions = Apollo.BaseMutationOptions<
    CreateAssociateUserMutation,
    CreateAssociateUserMutationVariables
>;
export const AssociatesDocument = gql`
    query Associates($filter: AssociateFilterInput) {
        associates(filter: $filter) {
            id
            name
            email
            state
            photo {
                id
                url
            }
            accessLocations {
                id
                name
            }
            workHoursStart
            workHoursEnd
        }
    }
`;

/**
 * __useAssociatesQuery__
 *
 * To run a query within a React component, call `useAssociatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAssociatesQuery(
    baseOptions?: Apollo.QueryHookOptions<AssociatesQuery, AssociatesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AssociatesQuery, AssociatesQueryVariables>(AssociatesDocument, options);
}
export function useAssociatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AssociatesQuery, AssociatesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AssociatesQuery, AssociatesQueryVariables>(
        AssociatesDocument,
        options,
    );
}
export type AssociatesQueryHookResult = ReturnType<typeof useAssociatesQuery>;
export type AssociatesLazyQueryHookResult = ReturnType<typeof useAssociatesLazyQuery>;
export type AssociatesQueryResult = Apollo.QueryResult<AssociatesQuery, AssociatesQueryVariables>;
export const CurrentAssociateDocument = gql`
    query CurrentAssociate($filter: UsersFilterInput) {
        users(filter: $filter) {
            id
            name
            email
            state
            roles
            company {
                id
                name
            }
            photo {
                id
                url
            }
            accessLocations {
                id
                name
            }
            workHoursStart
            workHoursEnd
        }
    }
`;

/**
 * __useCurrentAssociateQuery__
 *
 * To run a query within a React component, call `useCurrentAssociateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAssociateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAssociateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCurrentAssociateQuery(
    baseOptions?: Apollo.QueryHookOptions<CurrentAssociateQuery, CurrentAssociateQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurrentAssociateQuery, CurrentAssociateQueryVariables>(
        CurrentAssociateDocument,
        options,
    );
}
export function useCurrentAssociateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CurrentAssociateQuery,
        CurrentAssociateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurrentAssociateQuery, CurrentAssociateQueryVariables>(
        CurrentAssociateDocument,
        options,
    );
}
export type CurrentAssociateQueryHookResult = ReturnType<typeof useCurrentAssociateQuery>;
export type CurrentAssociateLazyQueryHookResult = ReturnType<typeof useCurrentAssociateLazyQuery>;
export type CurrentAssociateQueryResult = Apollo.QueryResult<
    CurrentAssociateQuery,
    CurrentAssociateQueryVariables
>;
export const ModifyWorkingHoursDocument = gql`
    mutation ModifyWorkingHours($input: ModifyAssociateInput!) {
        modifyAssociate(input: $input)
    }
`;
export type ModifyWorkingHoursMutationFn = Apollo.MutationFunction<
    ModifyWorkingHoursMutation,
    ModifyWorkingHoursMutationVariables
>;

/**
 * __useModifyWorkingHoursMutation__
 *
 * To run a mutation, you first call `useModifyWorkingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyWorkingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyWorkingHoursMutation, { data, loading, error }] = useModifyWorkingHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyWorkingHoursMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ModifyWorkingHoursMutation,
        ModifyWorkingHoursMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ModifyWorkingHoursMutation, ModifyWorkingHoursMutationVariables>(
        ModifyWorkingHoursDocument,
        options,
    );
}
export type ModifyWorkingHoursMutationHookResult = ReturnType<typeof useModifyWorkingHoursMutation>;
export type ModifyWorkingHoursMutationResult = Apollo.MutationResult<ModifyWorkingHoursMutation>;
export type ModifyWorkingHoursMutationOptions = Apollo.BaseMutationOptions<
    ModifyWorkingHoursMutation,
    ModifyWorkingHoursMutationVariables
>;
export const GetUsersDocument = gql`
    query GetUsers {
        users {
            id
            name
            email
            company {
                id
                name
            }
            state
            serviceLocations {
                id
                name
            }
            accessLocations {
                id
                name
            }
            roles
        }
    }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UserCreateDocument = gql`
    mutation UserCreate($input: UserCreateInput) {
        userCreate(input: $input) {
            id
            name
        }
    }
`;
export type UserCreateMutationFn = Apollo.MutationFunction<
    UserCreateMutation,
    UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(
        UserCreateDocument,
        options,
    );
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
    UserCreateMutation,
    UserCreateMutationVariables
>;
export const CurrentUserDocument = gql`
    query CurrentUser {
        me {
            id
            name
            email
            state
            note
            company {
                id
                name
                locations {
                    id
                    name
                }
            }
            photo {
                url
            }
            serviceLocations {
                id
                name
                zone
            }
            roles
        }
    }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
        CurrentUserDocument,
        options,
    );
}
export function useCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
        CurrentUserDocument,
        options,
    );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
    CurrentUserQuery,
    CurrentUserQueryVariables
>;
