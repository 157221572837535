import React, { FC, useRef } from 'react';

import styled from 'styled-components';

import { CirclePlusIcon, CloseIcon } from '../../../assets/icons';
import { Column, Heading, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms/BottomSheet';
import { Role, useUserAssignRoleMutation, useUserRevokeRoleMutation } from '../../../service';
import { roleName } from '../../../util/roleNameMapping';

type Props = {
    title: string;
    roles: Role[];
    userId: string;
};
export const RoleSelector: FC<Props> = ({ title, roles, userId }) => {
    const addBottomSheetRef = useRef<BottomSheetRef>(null);

    const sharedOptions = {
        refetchQueries: ['Users'],
    };
    const [assignRole] = useUserAssignRoleMutation(sharedOptions);
    const [revokeRole] = useUserRevokeRoleMutation(sharedOptions);

    const addRole = (role: Role) => {
        return assignRole({
            variables: {
                input: {
                    userId,
                    role,
                },
            },
        });
    };

    const removeRole = (role: Role) => {
        return revokeRole({
            variables: {
                input: {
                    userId,
                    role,
                },
            },
        });
    };

    return (
        <Column my={'20px'} maxWidth={'400px'} width={'100%'}>
            <Row mb={'5px'} justifyContent={'space-between'}>
                <Text fontSize={'medium'} color={'text.secondary'}>
                    {title}
                </Text>
                <AddLink alignItems={'center'} onClick={() => addBottomSheetRef.current?.show()}>
                    <UnderlinedText fontSize={'medium'} fontWeight={'bold'}>
                        Add
                    </UnderlinedText>
                    <CirclePlusIcon />
                </AddLink>
            </Row>
            <Column>
                {roles?.map((role) => (
                    <RoleRow mb={'5px'} key={role} justifyContent={'space-between'}>
                        <Row alignItems={'center'}>
                            <Text ml={'6px'}>{roleName[role]}</Text>
                        </Row>
                        {roles.length > 1 && (
                            <RemoveIcon
                                onClick={async () => {
                                    await removeRole(role);
                                    addBottomSheetRef.current?.hide();
                                }}
                            />
                        )}
                    </RoleRow>
                ))}
            </Column>

            <BottomSheet ref={addBottomSheetRef}>
                <View p={['20px', '40px', '40px']}>
                    <Heading>Assign role to user</Heading>
                    <Text my={'10px'}>Choose a role you want to assign to this user</Text>
                    <Column my={'24px'}>
                        {Object.values(Role)
                            .filter((role) => !roles?.includes(role))
                            .map((roleItem) => (
                                <PickableRoleRow
                                    mb={'5px'}
                                    key={roleItem}
                                    justifyContent={'space-between'}
                                    onClick={async () => {
                                        await addRole(roleItem);
                                        addBottomSheetRef.current?.hide();
                                    }}
                                >
                                    <Row alignItems={'center'}>
                                        <Text ml={'6px'}>{roleName[roleItem]}</Text>
                                    </Row>
                                </PickableRoleRow>
                            ))}
                    </Column>
                </View>
            </BottomSheet>
        </Column>
    );
};

const AddLink = styled(Row)`
    cursor: pointer;
`;

const RemoveIcon = styled(CloseIcon)`
    cursor: pointer;
`;

const UnderlinedText = styled(Text)`
    text-decoration: underline;
`;

const RoleRow = styled(Row)`
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
`;

const PickableRoleRow = styled(RoleRow)`
    cursor: pointer;
`;
