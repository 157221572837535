import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { DashboardModule } from './modules/dashboard';
import { EForceModule } from './modules/eforce';
import { FlexStorageModule } from './modules/flex-storage';
import { Home } from './modules/home';
import { LocationManagementModule } from './modules/location-management';
import { MemberManagementModule } from './modules/member-management';
import { ProfileModule } from './modules/profile';
import { TeamManagementModule } from './modules/team-management';
import { UserManagementModule } from './modules/user-management';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Home />}>
                    <Route index element={<DashboardModule />} />
                    <Route path={'/member-management/*'} element={<MemberManagementModule />} />
                    <Route path={'/team-management/*'} element={<TeamManagementModule />} />
                    <Route path={'/user-management/*'} element={<UserManagementModule />} />
                    <Route path={'/location-management/*'} element={<LocationManagementModule />} />
                    <Route path={'/eforce/*'} element={<EForceModule />} />
                    <Route path={'/flex-storage/*'} element={<FlexStorageModule />} />
                    <Route path={'/profile'} element={<ProfileModule />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
