import styled from 'styled-components';
import { variant } from 'styled-system';

import { Theme } from '../../theme';
import { View } from './View';

export type TagVariant = 'accent' | 'dark' | 'disabled' | 'neutral' | 'info';
export type TagProps = { variant: TagVariant };
export const Tag = styled(View)<TagProps>`
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 20px;
    padding: 3px 10px;
    font-family: Inter, sans-serif;
    text-align: center;

    ${variant({
        variants: {
            neutral: {
                backgroundColor: '#eeeeee',
            },
            accent: {
                backgroundColor: Theme.colors.background.accent,
            },
            dark: {
                backgroundColor: Theme.colors.background.dark,
                color: 'white',
            },
            info: {
                backgroundColor: Theme.colors.background.info,
            },
        },
    })}
`;
