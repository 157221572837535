import React, { FC, useMemo } from 'react';

import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import styled, { useTheme } from 'styled-components';

import { Column, Heading, Text } from '../../../components/atoms';
import { DateUtils } from '../../../util/date';

type Props = {
    date: Date | string;
    timezone?: string;
};
export const DateBox: FC<Props> = ({
    date,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
    const parsedDate = useMemo(() => {
        if (typeof date === 'string') {
            return parseISO(date);
        }
        return date;
    }, [date]);

    return (
        <DateBoxWrapper active={DateUtils.isToday(parsedDate)}>
            <Text fontSize={10} mb={4}>
                {formatInTimeZone(parsedDate, timezone, 'MMM')}
            </Text>
            <Heading fontSize={20}>{formatInTimeZone(parsedDate, timezone, 'dd')}</Heading>
        </DateBoxWrapper>
    );
};

type BoxWrapperProps = {
    active: boolean;
};
const DateBoxWrapper = styled(Column)<BoxWrapperProps>`
    background-color: ${(props) => (props.active ? props.theme.colors.background.accent : 'white')};
    border-radius: 10px;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
`;
