import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FulfillmentIcon, HouseIcon, PeopleIcon, RightArrowIcon } from '../../../../assets/icons';
import { Row, View, Text, Heading } from '../../../../components/atoms';
import { BuildingAccess } from '../../components/BuildingAccess/BuildingAccess';

export const Dashboard = () => {
    const navigate = useNavigate();
    return (
        <View>
            <BuildingAccess></BuildingAccess>
            <Heading mt={[40, 50, 50]}>Discover services</Heading>
            <QuickAccessCard mt={[15, 20, 20]} onClick={() => navigate('/eforce/scheduled')}>
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center" pl={1}>
                        <PeopleIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Book eForce
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            <QuickAccessCard mt={[5, 10, 10]} onClick={() => navigate('/flex-storage/requests')}>
                <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                    <Row alignItems="center" pl={1}>
                        <HouseIcon width={25} height={25} />
                        <Text ml={10} mr={10}>
                            Reserve Flex Storage
                        </Text>
                    </Row>
                    <RightArrowIcon />
                </Row>
            </QuickAccessCard>
            <QuickAccessLink href="https://www.saltbox.com/fulfillment" target="_blank">
                <QuickAccessCard mt={[5, 10, 10]}>
                    <Row alignItems="center" justifyContent="space-between" width={'100%'} pr={10}>
                        <Row alignItems="center" pl={1}>
                            <FulfillmentIcon width={25} height={25} />
                            <Text ml={10} mr={10}>
                                Fulfillment
                            </Text>
                        </Row>
                        <RightArrowIcon />
                    </Row>
                </QuickAccessCard>
            </QuickAccessLink>
        </View>
    );
};

const QuickAccessLink = styled.a`
    color: #000000;
    text-decoration: none;
`;

const QuickAccessCard = styled(Row)`
    min-height: 50px;
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    align-items: center;
    cursor: pointer;
`;
