import React, { FC, HTMLProps, useState } from 'react';

import styled from 'styled-components';

import { EditIcon } from '../../assets/icons';
import { Theme } from '../../theme';
import { LinkButton, Row, Text } from '../atoms';

type Props = HTMLProps<HTMLElement>;

const EditButton: FC<Props> = ({ onClick }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <LinkButton onClick={onClick}>
            <Wrapper
                pl={isHovering ? 5 : 0}
                alignItems={'center'}
                onMouseOver={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <Label style={{ width: isHovering ? 20 : 0, opacity: isHovering ? 1 : 0 }}>
                    Edit
                </Label>
                <EditIcon width={25} height={25} />
            </Wrapper>
        </LinkButton>
    );
};

export default EditButton;

const Wrapper = styled(Row)`
    background-color: ${Theme.colors.background.lightGray};
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: normal;
`;

const Label = styled(Text)`
    font-size: ${Theme.fontSizes.medium};
    transition: all 0.3s ease;
    user-select: none;
`;
