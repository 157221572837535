import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Row, Text, View } from '../../../components/atoms';
import { StorageItem } from '../../../service';
import { FlexStorageImage } from './FlexStorageImage';
type Props = {
    flexStorageItem: StorageItem;
};
export const FlexStorageReturnedCard: FC<Props> = ({ flexStorageItem }) => {
    return (
        <Row mb={10} height={[132, 132, 160]}>
            <FlexStorageImage
                state={flexStorageItem?.state}
                price={flexStorageItem?.cost?.amount || 0}
                imageUrl={flexStorageItem?.photo?.url || ''}
            />
            <RequestDetails>
                <ItemDescription>{flexStorageItem.description}</ItemDescription>
                <Row alignItems={'flex-end'} justifyContent={'space-between'}>
                    <DateText fontSize={'small'}>
                        {format(parseISO(flexStorageItem?.created?.at), 'MMM dd, yyyy')}
                    </DateText>
                </Row>
            </RequestDetails>
        </Row>
    );
};

const ItemDescription = styled(Text)`
    font-size: 12px;
    font-weight: bold;
`;

const DateText = styled(Text)`
    color: #505354;
    flex: 1;
`;

const RequestDetails = styled(View)`
    flex: 1;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 14px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 10px 6px #00000012;
    position: relative;
`;
