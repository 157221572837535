import React, { FC } from 'react';

import { useForm } from 'react-hook-form';

import { Button, View, Heading } from '../../../components/atoms';
import { TextInput } from '../../../components/molecules/form';
import { useDoorCreateMutation } from '../../../service';
import { DIGITS_ONLY_REGEX } from '../../../util/validation';

type Props = {
    onDone?: () => void;
    location: any;
};

export const AddDoorForm: FC<Props> = ({ onDone, location }) => {
    const [createDoor, { loading: doorCreateLoading }] = useDoorCreateMutation();

    // Form
    const {
        control,
        reset,
        handleSubmit,
        watch,
        formState: { isValid },
    } = useForm({
        defaultValues: {
            name: '',
            externalId: '',
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const name = watch('name');
    const externalId = watch('externalId');

    const submit = handleSubmit(async () => {
        if (!location) {
            return;
        }

        await createDoor({
            variables: {
                input: {
                    locationId: location.id!,
                    name,
                    externalId: +externalId!,
                },
            },
            refetchQueries: ['GetLocation'],
        });
        reset();
        onDone && onDone();
    });

    return (
        <View px={[20, 20, 40]} py={40}>
            <Heading>Add Door</Heading>
            <View mt={30}>
                <TextInput
                    name="name"
                    placeholder="Type door name..."
                    label="Door name"
                    autoFocus
                    control={control}
                    rules={{ required: true }}
                />
            </View>
            <View mt={20}>
                <TextInput
                    name="externalId"
                    placeholder="Type Brivo id..."
                    label="Brivo id"
                    control={control}
                    rules={{ pattern: DIGITS_ONLY_REGEX, required: true }}
                />
            </View>
            <Button mt={20} onClick={submit} disabled={!isValid} loading={doorCreateLoading}>
                Create
            </Button>
        </View>
    );
};
