import React from 'react';

import { useUser } from '../../providers';
import { Role } from '../../service';
import { FlexStorage as AssociateFlexStorage } from './view/associate/FlexStorage';
import { FlexStorage as MemberFlexStorage } from './view/member/FlexStorage';

export const FlexStorageModule = () => {
    const { currentRole } = useUser();

    switch (currentRole) {
        case Role.Member:
            return <MemberFlexStorage />;
        case Role.Associate:
            return <AssociateFlexStorage />;
        case Role.Com:
            return <AssociateFlexStorage />;
        default:
            return null;
    }
};
