import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowBackIcon, InfoIcon } from '../../../assets/icons';
import { Column, Heading, Text, View, Row, LinkButton } from '../../../components/atoms';
import EditButton from '../../../components/molecules/EditButton';
import { Select, TextInput } from '../../../components/molecules/form';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { DangerModal } from '../../../components/organisms/DangerModal';
import { useUser } from '../../../providers';
import {
    Location,
    useMembersQuery,
    useMemberLocationsQuery,
    Role,
    State,
    useUserBlockMutation,
    useUserRestrictMutation,
    useUserUnblockMutation,
    useUserClearRestrictionMutation,
    useUserDeleteMutation,
    User,
} from '../../../service';
import { Theme } from '../../../theme';
import { rolesToString } from '../../../util/roleNameMapping';
import { EditUsernameForm } from '../components/EditUsernameForm';
import { LocationSelector, LocationSelectorType } from '../components/LocationSelector';

const getInitialsFromName = (name = '') => {
    const tempArray = name.match(/(^\S\S?|\s\S)?/g) || [];

    if (tempArray.length === 0) {
        return '';
    }

    return tempArray
        .map((v) => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g)!
        .join('')
        .toLocaleUpperCase();
};

type ReasonObject = {
    [key: string]: string;
};

const BLOCK_REASON: ReasonObject = {
    RequestedByMember: 'Requested by member',
    Other: 'Other',
};
const defaultBlockReason = Object.keys(BLOCK_REASON)[0];

const RESTRICT_REASON: ReasonObject = {
    PaymentFailed: 'Payment failed',
    CommunityGuidelinesViolation: 'Community Guidelines Violation',
    Other: 'Other',
};
const defaultRestrictReason = Object.keys(RESTRICT_REASON)[0];

export const MemberManagementMemberDetails: FC = () => {
    const navigate = useNavigate();
    const { memberId } = useParams();
    const { currentRole } = useUser();
    const { data: membersData } = useMembersQuery({
        variables: {
            filter: {
                userId: memberId!,
            },
        },
    });
    const { data: locationsData, loading } = useMemberLocationsQuery();
    const [blockUser, { loading: userBlockLoading }] = useUserBlockMutation();
    const [unblockUser, { loading: userUnblockLoading }] = useUserUnblockMutation();
    const [restrictUser, { loading: userRestrictLoading }] = useUserRestrictMutation();
    const [clearUserRestriction, { loading: userClearRestrictLoading }] =
        useUserClearRestrictionMutation();
    const [deleteUser, { loading: userDeleteLoading }] = useUserDeleteMutation();

    const member = membersData?.users && membersData.users.length > 0 ? membersData.users[0] : null;
    const allLocations = locationsData?.locations as Location[];
    const accessLocations = member?.accessLocations as Location[];
    const serviceLocations = member?.serviceLocations as Location[];

    const blockUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const restrictUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const deleteUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const editUserBottomSheetRef = useRef<BottomSheetRef>(null);

    const [selectedBlockReason, setSelectedBlockReason] = useState(defaultBlockReason);
    const [customBlockReason, setCustomBlockReason] = useState('');

    const [selectedRestrictReason, setSelectedRestrictReason] = useState(defaultRestrictReason);
    const [customRestrictReason, setCustomRestrictReason] = useState('');

    const restrictActionVisible =
        (currentRole === Role.Admin || currentRole === Role.Com) &&
        (member?.state === State.Active || member?.state === State.Restricted);
    const blockActionVisible =
        (currentRole === Role.Admin || currentRole === Role.Com) &&
        (member?.state === State.Active || member?.state === State.Blocked);

    const memberRoles = useMemo(() => {
        const roles = member?.roles;
        if (roles) {
            return rolesToString(roles.filter(Boolean) as Role[]); // filter - removes null values
        }
        return '';
    }, [member?.roles]);

    const onUserBlock = async () => {
        const reason =
            selectedBlockReason === BLOCK_REASON.Other
                ? customBlockReason
                : BLOCK_REASON[selectedBlockReason];

        if (memberId) {
            await blockUser({
                variables: {
                    input: {
                        id: memberId,
                        reason,
                    },
                },
                refetchQueries: ['Members'],
            });
            setSelectedBlockReason('');
            setCustomBlockReason('');
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserUnblock = async () => {
        if (memberId) {
            await unblockUser({
                variables: {
                    id: memberId,
                },
                refetchQueries: ['Members'],
            });
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserRestrict = async () => {
        const reason =
            selectedRestrictReason === RESTRICT_REASON.Other
                ? customRestrictReason
                : RESTRICT_REASON[selectedRestrictReason];

        if (memberId) {
            await restrictUser({
                variables: {
                    input: {
                        id: memberId,
                        reason,
                    },
                },
                refetchQueries: ['Members'],
            });
            setSelectedRestrictReason('');
            setCustomRestrictReason('');
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    const onClearRestriction = async () => {
        if (memberId) {
            await clearUserRestriction({
                variables: {
                    id: memberId,
                },
                refetchQueries: ['Members'],
            });
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    const onUserDelete = async () => {
        if (memberId) {
            await deleteUser({
                variables: {
                    input: {
                        id: memberId,
                    },
                },
                refetchQueries: ['Members'],
            });
            deleteUserBottomSheetRef.current?.hide();
            navigate(-1);
        }
    };

    return (
        <Wrapper>
            <Header px={90} pb={25}>
                <LinkButton onClick={() => navigate('/member-management/companies')}>
                    <>
                        <ArrowBack width={20} height={20} />
                        Back to company users
                    </>
                </LinkButton>
            </Header>
            <ContentWrapper
                borderRadius={['20px 20px 0px 0px', '64px 64px 0px 0px', '64px 64px 0px 0px']}
            >
                <Content px={[25, 25, 115]} py={[25, 25, 45]}>
                    <Row alignItems={'flex-start'} justifyContent={'space-between'}>
                        <ProfilePhoto mb={25}>
                            <Text fontSize={35}>{getInitialsFromName(member?.name || '')}</Text>
                        </ProfilePhoto>
                    </Row>
                    <Row alignItems={'flex-start'} justifyContent={'flex-start'}>
                        <Heading mb={10} mr={15}>
                            {member?.name}
                        </Heading>
                        <EditButton onClick={() => editUserBottomSheetRef?.current?.show()} />
                    </Row>

                    {loading ? (
                        <Row mt={50} justifyContent={'center'} alignItems={'center'}>
                            <LoadingIndicator variant={'medium'} />
                        </Row>
                    ) : (
                        <>
                            <View my={[20, 40, 40]}>
                                <Text fontSize={'medium'} fontWeight={'bold'}>
                                    Employee details
                                </Text>
                                <Divider />
                                <Row
                                    justifyContent={'space-between'}
                                    alignItems={'flex-start'}
                                    pt={10}
                                >
                                    <Column flex={1} my={20}>
                                        <Row>
                                            <Column>
                                                <Text fontSize={'medium'} color={'text.secondary'}>
                                                    Status
                                                </Text>
                                                <Text fontSize={'medium'}>{member?.state}</Text>
                                            </Column>
                                        </Row>
                                        <Column mt={30}>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Email
                                            </Text>
                                            <Text fontSize={'medium'}>{member?.email}</Text>
                                        </Column>
                                        <Column mt={30}>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Roles
                                            </Text>
                                            <Text fontSize={'medium'}>{memberRoles}</Text>
                                        </Column>
                                        <Column mt={30}>
                                            <Text fontSize={'medium'} color={'text.secondary'}>
                                                Company
                                            </Text>
                                            <CompanyLink
                                                fontSize={'medium'}
                                                onClick={() => {
                                                    navigate(
                                                        '/member-management/companies/' +
                                                            member?.company?.id,
                                                    );
                                                }}
                                            >
                                                {member?.company?.name}
                                            </CompanyLink>
                                        </Column>
                                        <View mt={70}>
                                            {restrictActionVisible && (
                                                <Row>
                                                    <Column>
                                                        <LinkButton
                                                            onClick={() =>
                                                                restrictUserBottomSheetRef.current?.show()
                                                            }
                                                        >
                                                            <ProfileActionLabel
                                                                fontSize={'medium'}
                                                                color={'text.danger'}
                                                            >
                                                                {member.state === State.Restricted
                                                                    ? 'Clear User Access Restriction'
                                                                    : 'Restrict User Access'}
                                                            </ProfileActionLabel>
                                                        </LinkButton>
                                                    </Column>
                                                </Row>
                                            )}
                                            {blockActionVisible && (
                                                <Row mt={20}>
                                                    <Column>
                                                        <LinkButton
                                                            onClick={() =>
                                                                blockUserBottomSheetRef.current?.show()
                                                            }
                                                        >
                                                            <ProfileActionLabel
                                                                fontSize={'medium'}
                                                                color={'text.danger'}
                                                            >
                                                                {member.state === State.Blocked
                                                                    ? 'Unblock User'
                                                                    : 'Block User'}
                                                            </ProfileActionLabel>
                                                        </LinkButton>
                                                    </Column>
                                                </Row>
                                            )}

                                            {currentRole === Role.Admin && (
                                                <Row mt={20}>
                                                    <Column>
                                                        <LinkButton
                                                            onClick={() =>
                                                                deleteUserBottomSheetRef.current?.show()
                                                            }
                                                        >
                                                            <ProfileActionLabel
                                                                fontSize={'medium'}
                                                                color={'text.danger'}
                                                            >
                                                                Delete user
                                                            </ProfileActionLabel>
                                                        </LinkButton>
                                                    </Column>
                                                </Row>
                                            )}
                                        </View>
                                    </Column>
                                    <Column flex={1} alignItems={'flex-end'}>
                                        <LocationSelector
                                            type={LocationSelectorType.Access}
                                            memberId={member?.id}
                                            title={'Access to locations:'}
                                            allLocations={allLocations}
                                            selectedLocations={accessLocations}
                                        />
                                        <LocationSelector
                                            type={LocationSelectorType.Service}
                                            memberId={member?.id}
                                            title={'Can manage services at:'}
                                            allLocations={allLocations}
                                            selectedLocations={serviceLocations}
                                        />
                                    </Column>
                                </Row>
                            </View>
                        </>
                    )}
                </Content>
            </ContentWrapper>
            <BottomSheet ref={editUserBottomSheetRef}>
                <EditUsernameForm
                    onDone={() => editUserBottomSheetRef?.current?.hide()}
                    user={member}
                />
            </BottomSheet>
            <DangerModal
                ref={deleteUserBottomSheetRef}
                heading="Delete User"
                subheading="Are you sure you want to delete user from Saltbox OS?"
                loading={userDeleteLoading}
                onConfirm={onUserDelete}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Deleting user will remove it completely from the system including
                            Saltbox account and Slack contact.
                        </Text>
                    </Row>
                </NoteWrapper>
            </DangerModal>

            <DangerModal
                ref={blockUserBottomSheetRef}
                heading={member?.state === State.Blocked ? 'Unblock User' : 'Block User'}
                subheading={
                    member?.state === State.Blocked
                        ? 'Are you sure you want to unblock this user?'
                        : 'Are you sure you want to block this user?'
                }
                loading={userBlockLoading || userUnblockLoading}
                primaryButtonDisabled={
                    selectedBlockReason === BLOCK_REASON.Other && customBlockReason === ''
                }
                onConfirm={member?.state === State.Blocked ? onUserUnblock : onUserBlock}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users that are blocked will not be able to login to Saltbox OS using
                            their account until Admin or Manager unblocks them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {member?.state !== State.Blocked && (
                    <Select
                        label="Reason"
                        value={selectedBlockReason}
                        values={Object.keys(BLOCK_REASON)}
                        labels={Object.values(BLOCK_REASON)}
                        onChange={(option) => setSelectedBlockReason(option)}
                    />
                )}
                {selectedBlockReason === BLOCK_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomBlockReason(e.target.value)}
                        />
                    </View>
                )}
            </DangerModal>

            <DangerModal
                ref={restrictUserBottomSheetRef}
                heading={
                    member?.state === State.Restricted
                        ? 'Clear User Access Restriction'
                        : 'Restrict User Access'
                }
                subheading={
                    member?.state === State.Restricted
                        ? 'Are you sure you want to clear restriction access for this user?'
                        : 'Are you sure you want to restrict access for this user?'
                }
                loading={userRestrictLoading || userClearRestrictLoading}
                onConfirm={member?.state === State.Restricted ? onClearRestriction : onUserRestrict}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users having restricted access will be able to login to Saltbox OS using
                            their account but all features will be disabled for them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {member?.state !== State.Restricted && (
                    <Select
                        label="Reason"
                        value={selectedRestrictReason}
                        values={Object.keys(RESTRICT_REASON)}
                        labels={Object.values(RESTRICT_REASON)}
                        onChange={(option) => setSelectedRestrictReason(option)}
                    />
                )}
                {selectedRestrictReason === RESTRICT_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomRestrictReason(e.target.value)}
                        />
                    </View>
                )}
                {member?.state !== State.Restricted && (
                    <NoteWrapper>
                        <Row>
                            <InfoIcon />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                Users with restricted access will be able to see this reason.
                            </Text>
                        </Row>
                    </NoteWrapper>
                )}
            </DangerModal>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Theme.colors.background.accent};
`;

const Header = styled(View)`
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled(View)`
    background: ${Theme.colors.background.light};
    height: 100%;
`;

const Content = styled(View)`
    flex: 1;
    max-width: 1200px;
`;

const ProfilePhoto = styled(View)`
    background: ${Theme.colors.background.disabled};
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Divider = styled(View)`
    background-color: #505354;
    height: 1px;
    width: 100%;
    margin: 2px 0;
    opacity: 0.1;
`;

const ArrowBack = styled(ArrowBackIcon)`
    margin-right: 5px;
`;

const ProfileActionLabel = styled(Text)`
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: normal;
    user-select: none;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;

const CompanyLink = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
`;
