import styled from 'styled-components';

import { View } from '../../../components/atoms';

export const Divider = styled(View)`
    background-color: #505354;
    height: 1px;
    width: 100%;
    margin: 5px 0;
    opacity: 0.1;
`;
