import React, { FC, useEffect, useState } from 'react';

import { clamp } from 'lodash';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { MinusIcon, PlusIcon } from '../../../assets/icons';
import { Row, Text, View } from '../../atoms';
import { BaseInputProps } from './BaseInputProps';
import { ControlledInputProps } from './ControlledInputProps';
import { Label } from './Label';

type Props = BaseInputProps<number> & {
    step?: number;
    measurement?: string;
    upper?: number;
    lower?: number;
};
const StepperComponent: FC<Props> = ({
    step = 1,
    label,
    measurement,
    value: passedValue = 0,
    onChange,
    upper = 8,
    lower = 0,
}) => {
    const [value, setValue] = useState(passedValue);

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <Row alignItems={'center'}>
                <Button onClick={() => setValue(clamp(value - step, lower, upper))}>
                    <MinusIcon />
                </Button>
                <Value>
                    {value}
                    {measurement}
                </Value>
                <Button
                    onClick={() => {
                        setValue(clamp(value + step, lower, upper));
                    }}
                >
                    <PlusIcon />
                </Button>
            </Row>
        </Wrapper>
    );
};

export const Stepper: FC<Props & ControlledInputProps> = ({ control, name, ...rest }) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            render={({ field: { onChange, value } }) => (
                <StepperComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <StepperComponent {...rest} />
    );
};

const Wrapper = styled(View)`
    margin-bottom: 30px;
`;

const Button = styled(View)`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`;
const Value = styled(Text)`
    width: 54px;
    margin: 0 15px;
    font-weight: 600;
    text-align: center;
`;
