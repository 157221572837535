import React, { FC, useCallback } from 'react';

import styled from 'styled-components';

import { StorageIcon } from '../../../assets/icons';
import { View, Tag } from '../../../components/atoms';
import { StorageState } from '../../../service';
type Props = {
    children?: React.ReactNode;
    imageUrl: string;
    inContextOf?: string;
    price?: number;
    state?: string;
};
export const FlexStorageImage: FC<Props> = ({
    inContextOf = '',
    children,
    imageUrl,
    state,
    price,
}) => {
    const renderState = useCallback(() => {
        switch (state) {
            case StorageState.ReturnRequested:
                if (inContextOf == 'Stored') {
                    return <PositionedTag variant={'info'}>Return Initiated</PositionedTag>;
                } else {
                    return <PositionedTag variant={'info'}>Return</PositionedTag>;
                }
            case StorageState.StorageRequested:
                return <PositionedTag variant={'accent'}>Storage</PositionedTag>;
            default:
                return null;
        }
    }, [state]);

    const renderPrice = useCallback(() => {
        if (price) {
            return <PriceTag variant={'dark'}>${price / 100}</PriceTag>;
        } else {
            return null;
        }
    }, [price]);

    return (
        <FlexStorageImageWrapper width={[104, 104, 120]}>
            {imageUrl != '' ? (
                <Image src={imageUrl}>{children}</Image>
            ) : (
                <FallbackImage>{children}</FallbackImage>
            )}
            {renderState()}
            {renderPrice()}
        </FlexStorageImageWrapper>
    );
};

const FlexStorageImageWrapper = styled(View)`
    position: relative;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    box-shadow: 0 10px 6px #00000012;
    background: #ffffff;
`;

const FallbackImage = styled(StorageIcon)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-right: 10px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PriceTag = styled(Tag)`
    position: absolute;
    bottom: 7px;
    left: 7px;
`;

const PositionedTag = styled(Tag)`
    position: absolute;
    top: 7px;
    left: 7px;
`;
