import React, { FC, useMemo, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, View } from '../../../components/atoms';
import { SearchInput } from '../../../components/molecules/SearchInput';
import { Table } from '../../../components/molecules/Table';
import { useUser } from '../../../providers';
import { User, useAssociatesQuery } from '../../../service';
import { AssociateInfoCard } from '../components/AssociateInfoCard';

export const AssociatesListing: FC = () => {
    const navigate = useNavigate();
    const { currentServiceLocation } = useUser();
    const [query, setQuery] = useState<string>('');
    const { data: usersData } = useAssociatesQuery({
        variables: {
            filter: {
                locationId: currentServiceLocation?.id,
            },
        },
    });

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'eMail',
                accessor: 'email',
            },
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Locations',
                accessor: (row: User) => {
                    return (
                        row.accessLocations?.reduce((out, current) => {
                            return out + (out !== '' ? ', ' : '') + current?.name;
                        }, '') || '/'
                    );
                },
            },
        ];
    }, []);

    const associates = usersData?.associates?.filter(Boolean);
    const filteredAssociates = !query
        ? associates
        : associates?.filter(
              (user) =>
                  user?.name?.toLowerCase().includes(query.toLowerCase()) ||
                  user?.email.toLowerCase().includes(query.toLowerCase()),
          );

    return (
        <View>
            <Heading fontSize={[20, 25, 25]} mt={-25}>
                Team
            </Heading>

            <View mt={30}>
                <SearchInput
                    placeholder="Search for associates..."
                    onInput={(event: React.FormEvent<HTMLInputElement>) =>
                        setQuery(event.currentTarget.value)
                    }
                ></SearchInput>
            </View>

            <ScrollView flex={1} mt={30} display={['none', 'block']}>
                <Table
                    data={filteredAssociates || []}
                    columns={columns}
                    onRowClicked={(user) => {
                        navigate('/team-management/associates/' + user?.id);
                    }}
                />
            </ScrollView>

            <ScrollView flex={1} mt={30} display={['block', 'none']}>
                {filteredAssociates?.map((user) => (
                    <StyledLink key={user?.id} to={'/team-management/associates/' + user?.id}>
                        <View key={user?.id} mb={10}>
                            <AssociateInfoCard associate={user!} />
                        </View>
                    </StyledLink>
                ))}
            </ScrollView>
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 150}px;
`;

const StyledLink = styled(NavLink)`
    color: black;
    text-decoration: none;
`;
