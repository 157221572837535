import React, { FC } from 'react';

import { useTable } from 'react-table';
import styled from 'styled-components';

import { View } from '../atoms';

type Props<T = any> = {
    data: T[];
    columns: any;
    onRowClicked?: (row: T) => void;
};
export const Table: FC<Props> = ({ columns, data, onRowClicked }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });

    return (
        <Wrapper>
            <TableView {...getTableProps()}>
                <thead>
                    {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                        // Apply the header cell props
                                        // eslint-disable-next-line react/jsx-key
                                        <th {...column.getHeaderProps()}>
                                            {
                                                // Render the header
                                                column.render('Header')
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    {
                        // Loop over the table rows
                        rows.map((row) => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                                // Apply the row props
                                // eslint-disable-next-line react/jsx-key
                                <tr
                                    {...row.getRowProps()}
                                    onClick={() => {
                                        onRowClicked && onRowClicked(row.original);
                                    }}
                                >
                                    {
                                        // Loop over the rows cells
                                        row.cells.map((cell) => {
                                            // Apply the cell props
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        // Render the cell contents
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </TableView>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    background: #ffffff;
    box-shadow: 0 0 6px #00000012;
    border-radius: 10px;
    padding: 20px;
    border-collapse: collapse;
    position: relative;
`;
const TableView = styled.table`
    font-family: Inter, sans-serif;
    font-size: 12px;
    color: #101010;
    width: 100%;

    thead {
        position: sticky;
        top: 0;
        color: #a1a1a1;
        font-size: 11px;
        text-align: left;
        background-color: white;

        tr {
            th {
                padding: 10px 0;

                &:first-child {
                    padding-left: 8px;
                }
            }

            &:hover {
                background-color: white;
            }
        }

        border-bottom: 1px solid #e3e3e3;
    }

    tr {
        border-bottom: 1px solid #e3e3e3;
        cursor: pointer;
        transition: background 0.2s ease;

        td {
            padding: 22px 0;

            &:first-child {
                padding-left: 8px;
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background-color: #f9f8f6;
        }
    }
`;
