import React, { FC, useRef } from 'react';

import styled from 'styled-components';

import { CirclePlusIcon, CloseIcon, LocationPinIcon } from '../../../assets/icons';
import { Column, Heading, Row, Text, View } from '../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms/BottomSheet';
import {
    Location,
    useAddMemberToAccessLocationMutation,
    useAddMemberToServiceLocationMutation,
    useRemoveMemberFromAccessLocationMutation,
    useRemoveMemberFromServiceLocationMutation,
} from '../../../service';

export enum LocationSelectorType {
    Access = 'Access',
    Service = 'Service',
}

type Props = {
    title: string;
    memberId?: string;
    type: LocationSelectorType;
    allLocations?: Location[];
    selectedLocations?: Location[];
};
export const LocationSelector: FC<Props> = ({
    title,
    memberId,
    type,
    allLocations,
    selectedLocations,
}) => {
    const addBottomSheetRef = useRef<BottomSheetRef>(null);

    const isSelected = (currentLocation: Location) => {
        return !!selectedLocations?.filter((location) => location.id === currentLocation.id).length;
    };

    const sharedOptions = {
        refetchQueries: ['Members', 'CurrentAssociate', 'Associates'],
    };
    const [addToAccess] = useAddMemberToAccessLocationMutation(sharedOptions);
    const [addToService] = useAddMemberToServiceLocationMutation(sharedOptions);
    const [removeFromAccess] = useRemoveMemberFromAccessLocationMutation(sharedOptions);
    const [removeFromService] = useRemoveMemberFromServiceLocationMutation(sharedOptions);

    const addLocation = (location: Location) => {
        if (!isSelected(location) && memberId) {
            if (type === LocationSelectorType.Access) {
                return addToAccess({
                    variables: {
                        input: {
                            userId: memberId,
                            locationId: location.id,
                        },
                    },
                });
            } else if (type === LocationSelectorType.Service) {
                return addToService({
                    variables: {
                        input: {
                            userId: memberId,
                            locationId: location.id,
                        },
                    },
                });
            }
        }
    };

    const removeLocation = (location: Location) => {
        if (memberId) {
            if (type === LocationSelectorType.Access) {
                return removeFromAccess({
                    variables: {
                        input: {
                            userId: memberId,
                            locationId: location.id,
                        },
                    },
                });
            } else if (type === LocationSelectorType.Service) {
                return removeFromService({
                    variables: {
                        input: {
                            userId: memberId,
                            locationId: location.id,
                        },
                    },
                });
            }
        }
    };

    return (
        <Column my={'20px'} maxWidth={'400px'} width={'100%'}>
            <Row mb={'5px'} justifyContent={'space-between'}>
                <Text fontSize={'medium'} color={'text.secondary'}>
                    {title}
                </Text>
                <AddLink alignItems={'center'} onClick={() => addBottomSheetRef.current?.show()}>
                    <UnderlinedText fontSize={'medium'} fontWeight={'bold'}>
                        Add
                    </UnderlinedText>
                    <CirclePlusIcon />
                </AddLink>
            </Row>
            <Column>
                {selectedLocations?.map((location) => (
                    <LocationRow mb={'5px'} key={location.id} justifyContent={'space-between'}>
                        <Row alignItems={'center'}>
                            <LocationPinIcon />
                            <Text ml={'6px'}>{location.name}</Text>
                        </Row>
                        <RemoveIcon
                            onClick={async () => {
                                await removeLocation(location);
                                addBottomSheetRef.current?.hide();
                            }}
                        />
                    </LocationRow>
                ))}
            </Column>

            <BottomSheet ref={addBottomSheetRef}>
                <View p={['20px', '40px', '40px']}>
                    <Heading>Choose a location</Heading>
                    <Text my={'10px'}>Pick a location you want to assign this user to</Text>
                    <Column my={'24px'}>
                        {allLocations?.map((location) => (
                            <PickableLocationRow
                                mb={'5px'}
                                key={location.id}
                                justifyContent={'space-between'}
                                onClick={async () => {
                                    await addLocation(location);
                                    addBottomSheetRef.current?.hide();
                                }}
                            >
                                <Row alignItems={'center'}>
                                    <LocationPinIcon />
                                    <Text ml={'6px'}>{location.name}</Text>
                                </Row>
                                {isSelected(location) && (
                                    <Text fontSize={'small'} color={'text.secondary'}>
                                        Already assigned
                                    </Text>
                                )}
                            </PickableLocationRow>
                        ))}
                    </Column>
                </View>
            </BottomSheet>
        </Column>
    );
};

const AddLink = styled(Row)`
    cursor: pointer;
`;

const RemoveIcon = styled(CloseIcon)`
    cursor: pointer;
`;

const UnderlinedText = styled(Text)`
    text-decoration: underline;
`;

const LocationRow = styled(Row)`
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
`;

const PickableLocationRow = styled(LocationRow)`
    cursor: pointer;
`;
