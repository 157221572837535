import React, { FC, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { LockedIcon, StarIcon, UnlockedIcon } from '../../../../assets/icons';
import { Text, View } from '../../../../components/atoms';
import {
    Door,
    useAddDoorPreferenceMutation,
    useGetDoorPreferencesQuery,
    useRemoveDoorPreferenceMutation,
    useUnlockDoorMutation,
} from '../../../../service';
import { Theme } from '../../../../theme';
type Props = {
    door: Door;
};
export const DoorCard: FC<Props> = ({ door }) => {
    // GraphQL
    const { data: preferencesData, refetch: refetchPreferences } = useGetDoorPreferencesQuery();
    const [unlock, { error }] = useUnlockDoorMutation();
    const [addPreference] = useAddDoorPreferenceMutation();
    const [removePreference] = useRemoveDoorPreferenceMutation();

    // Local state
    const [locked, setLocked] = useState(true);

    const onUnlock = async () => {
        setLocked(false);
        await unlock({
            variables: {
                id: door.id,
            },
        });
        setTimeout(() => {
            setLocked(true);
        }, 5000);
    };

    const preferenceId = useMemo(() => {
        return preferencesData?.me?.preferences?.find(
            (preference) => preference?.doorId === door.id,
        )?.id;
    }, [preferencesData]);

    useEffect(() => {
        if (error) {
            console.error('Error opening the door');
        }
    }, [error]);

    const onTogglePreference = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (!preferenceId) {
            await addPreference({
                variables: {
                    doorId: door.id,
                },
            });
        } else {
            await removePreference({
                variables: {
                    preferenceId,
                },
            });
        }
        refetchPreferences();
    };

    return (
        <Card onClick={onUnlock} width={220} height={220} active={!locked}>
            {locked ? (
                <LockedIcon width={40} height={60} />
            ) : (
                <UnlockedIcon width={40} height={60} />
            )}
            <Title>{door.name}</Title>
            <Tag>{locked ? 'Locked' : 'Unlocked'}</Tag>
            <PreferencesWrapper onClick={onTogglePreference}>
                <StarIcon
                    color={
                        preferenceId
                            ? Theme.colors.background.accent
                            : Theme.colors.background.disabled
                    }
                />
            </PreferencesWrapper>
        </Card>
    );
};

const Card = styled(View)<{ active: boolean }>`
    cursor: pointer;
    background: white;
    display: flex;
    flex-direction: column;
    margin: 0 13px 13px 0;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    padding-top: 48px;
    align-items: center;
    background: ${(props) =>
        props.active ? Theme.colors.background.accent : Theme.colors.background.light};
`;

const Title = styled(Text)`
    margin-top: 29px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: black;
`;

const Tag = styled(Text)`
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    padding: 9px 20px;
    width: 100px;
    background-color: black;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const PreferencesWrapper = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
`;
