import React, { FC, useRef } from 'react';

import { differenceInHours, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DeepPartial } from 'react-hook-form';
import styled from 'styled-components';

import { InfoIcon } from '../../../../assets/icons';
import { Button, Heading, Row, Text, View } from '../../../../components/atoms';
import { Avatar } from '../../../../components/molecules/Avatar';
import { BottomSheet, BottomSheetRef, ContextMenu } from '../../../../components/organisms';
import {
    BookingResponse,
    BookingStatus,
    useCancelEForceBookingMutation,
} from '../../../../service';
import { DateBox } from '../DateBox';
import { EForceBookingForm } from '../EForceBookingForm';

export type MemberBookingProps = {
    booking: DeepPartial<BookingResponse>;
    timezone?: string;
};

export const MemberBookingCardMobile: FC<MemberBookingProps> = ({
    booking,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
    const editBookingBottomSheetRef = useRef<BottomSheetRef>(null);
    const cancelBookingBottomSheetRef = useRef<BottomSheetRef>(null);

    const [cancelBooking, { loading: cancellationLoading }] = useCancelEForceBookingMutation();

    const attemptBookingCancellation = async () => {
        if (booking) {
            await cancelBooking({
                variables: { bookingId: booking.id! },
                refetchQueries: [
                    'GetEforceMemberBookings',
                    'GetEforceAssociateBookings',
                    'GetEforceComBookings',
                ],
            });
            cancelBookingBottomSheetRef.current?.hide();
        }
    };
    return (
        <>
            <Row>
                <DateBox date={booking.slot?.startTime} timezone={timezone} />
                <Card>
                    <Row alignItems={'center'}>
                        <Avatar size={45} user={booking.assignee} />
                        <Text fontWeight={'bold'} fontSize={'medium'} ml={10}>
                            {booking.assignee?.name}
                        </Text>
                    </Row>
                    <Text mt={10} fontSize={'medium'} fontWeight={'bold'}>
                        {booking.company?.name}
                    </Text>
                    <Text mt={10} fontSize={'medium'}>
                        {booking.description}
                    </Text>
                    <Divider />
                    <Row justifyContent={'space-between'}>
                        <Text fontSize={'medium'}>
                            From:{' '}
                            {formatInTimeZone(
                                parseISO(booking?.slot?.startTime),
                                timezone,
                                'h:mm aa',
                            )}
                        </Text>
                        <Text fontSize={'medium'}>
                            {differenceInHours(
                                parseISO(booking?.slot?.endTime),
                                parseISO(booking?.slot?.startTime),
                            )}{' '}
                            hours
                        </Text>
                    </Row>

                    {booking.status === BookingStatus.NotStarted && (
                        <ContextMenu
                            options={['Edit', 'Cancel']}
                            onOptionSelected={(option) => {
                                if (option === 'Edit') {
                                    editBookingBottomSheetRef.current?.show();
                                }
                                if (option === 'Cancel') {
                                    cancelBookingBottomSheetRef.current?.show();
                                }
                            }}
                        />
                    )}
                </Card>
            </Row>
            <BottomSheet ref={editBookingBottomSheetRef}>
                <EForceBookingForm
                    booking={booking}
                    onDone={() => editBookingBottomSheetRef.current?.hide()}
                />
            </BottomSheet>

            <BottomSheet ref={cancelBookingBottomSheetRef}>
                <View py={[20, 40]} px={30}>
                    <Heading>Cancel booking?</Heading>

                    <NoteWrapper>
                        <Row>
                            <InfoIcon />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                Canceling request will remove it permanently from the system. You
                                can always create a new one.
                            </Text>
                        </Row>
                    </NoteWrapper>
                    <Text mt={5} mb={30}>
                        Are you sure you want to cancel this booking?
                    </Text>
                    <Row>
                        <Button
                            mr={5}
                            variant={'outlined'}
                            onClick={() => cancelBookingBottomSheetRef.current?.hide()}
                        >
                            Go back
                        </Button>
                        <Button
                            ml={5}
                            onClick={() => attemptBookingCancellation()}
                            loading={cancellationLoading}
                        >
                            Confirm
                        </Button>
                    </Row>
                </View>
            </BottomSheet>
        </>
    );
};

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
    margin: 10px 0;
`;

const Card = styled(View)`
    margin-left: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000012;
    flex: 1;
    max-width: 300px;
    position: relative;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
    max-width: 450px;
`;
