import React, { FC } from 'react';

import { useForm } from 'react-hook-form';

import { Button, View, Heading } from '../../../components/atoms';
import { TextInput } from '../../../components/molecules/form';
import { Door, useDoorUpdateMutation } from '../../../service';
import { DIGITS_ONLY_REGEX } from '../../../util/validation';

type Props = {
    onDone?: () => void;
    door: Door | undefined;
};

export const EditDoorForm: FC<Props> = ({ onDone, door }) => {
    const [updateDoor, { loading: doorUpdateLoading }] = useDoorUpdateMutation();
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { isValid },
    } = useForm({
        defaultValues: {
            name: door?.name || '',
            externalId: door?.externalId || '',
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const name = watch('name');
    const externalId = watch('externalId');

    const submit = handleSubmit(async () => {
        if (!door) return;
        await updateDoor({
            variables: {
                input: {
                    id: door.id,
                    name,
                    externalId: +externalId!,
                },
            },
            refetchQueries: ['GetLocation'],
        });
        reset();
        onDone && onDone();
    });

    return (
        <View px={[20, 20, 40]} py={40}>
            <Heading>Edit Door</Heading>
            <View mt={30}>
                <TextInput
                    placeholder="Type new door name..."
                    label="Door name"
                    name="name"
                    autoFocus
                    control={control}
                    rules={{ required: true }}
                />
            </View>
            <View mt={20}>
                <TextInput
                    name="externalId"
                    placeholder="Type Brivo id..."
                    label="Brivo id"
                    control={control}
                    rules={{ pattern: DIGITS_ONLY_REGEX, required: true }}
                />
            </View>
            <Button mt={30} onClick={submit} disabled={!isValid} loading={doorUpdateLoading}>
                Save
            </Button>
        </View>
    );
};
