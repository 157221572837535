import React, { FC } from 'react';

import { DayPicker, DayPickerProps } from 'react-day-picker';
import { useDeviceSelectors } from 'react-device-detect';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { View } from '../../atoms';
import { BaseInputProps } from './BaseInputProps';
import { ControlledInputProps } from './ControlledInputProps';
import { Label } from './Label';

import 'react-day-picker/dist/style.css';

type Props = BaseInputProps<Date> & DayPickerProps & { forceOneMonthView?: boolean };
const DatePickerComponent: FC<Props> = ({ label, onChange, value, forceOneMonthView }) => {
    const [selectors] = useDeviceSelectors(window.navigator.userAgent);
    const { isMobile } = selectors;
    const today = new Date();

    return (
        <>
            <style>{css}</style>
            <Wrapper>
                {label && <Label>{label}</Label>}
                <DayPicker
                    selected={value}
                    onDayClick={onChange}
                    disabled={[{ before: today }]}
                    numberOfMonths={isMobile || forceOneMonthView ? 1 : 2}
                    weekStartsOn={1}
                    fixedWeeks
                />
            </Wrapper>
        </>
    );
};

export const DatePicker: FC<Props & ControlledInputProps> = ({ control, name, ...rest }) => {
    return control ? (
        <Controller
            control={control}
            name={name!}
            render={({ field: { onChange, value } }) => (
                <DatePickerComponent {...rest} onChange={onChange} value={value} />
            )}
        />
    ) : (
        <DatePickerComponent {...rest} />
    );
};

const Wrapper = styled(View)``;

const css = `
    .rdp{
        margin: 0;
        padding: 0;
        font-family: 'Inter';
    }
    .rdp-nav{
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        color: #101010;
        width: 30px;
        height: 30px;
    }
    .rdp-month{
        margin-left: 0;
    }
    .rdp-nav_button {
        padding: 0 !important;
        border: none;
        width: 30px;
        height: 30px;
    }
    .rdp-nav_button svg {
        width: 8px;
        margin-bottom: 2px;
    }
    .rdp-caption_label{
        font-size: 12px;
    }
    .rdp-table{
        margin-top: 10px;
     }
    .rdp-head_cell {
        font-weight: 300;
        opacity: 0.3;
        text-transform: capitalize;
    }
    .rdp-head_row{
        border-bottom: 0.5px solid #e3e3e3;
    }
    .rdp-day{
        font-size: 12px;
        border: none !important;
        outline: none;
    }
    .rdp-day:hover{
        border: none !important;
        outline: none;
        background: #FDB706 !important;
    }
    
    .rdp-day_outside{
        opacity: 0.3;
        border: none !important;
        outline: none;
    }
    .rdp-day_selected{
        background-color: #FDB706 !important;
        color: black !important;
        font-weight: bold;
        border: none !important
    }
`;
