export const Theme = {
    fonts: {
        body: 'Inter',
        heading: 'Publico',
    },

    fontSizes: {
        heading: '25px',
        large: '16px',
        semi: '15px',
        default: '14px',
        medium: '12px',
        small: '10px',
    },

    fontWeights: {
        bold: 700,
        normal: 400,
    },

    colors: {
        text: {
            primary: '#101010',
            secondary: '#A1A1A1',
            danger: '#D25A1B',
            accent: '#FDB706',
            disabled: '#A1A1A1',
            light: '#ffffff',
        },
        background: {
            primary: '#F9F8F6',
            danger: '#D25A1B',
            accent: '#FDB706',
            light: '#ffffff',
            dark: '#101010',
            disabled: '#e3e3e3',
            info: '#9AD0D2',
            none: 'rgba(255,255,255,0)',
            lightGray: '#F2F2F2',
        },
    },
    space: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
