import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useDeviceSelectors } from 'react-device-detect';
import styled from 'styled-components';

import { View } from '../atoms';
import { Portal } from './Portal';

type Props = {
    children?: React.ReactNode;
};

type VariantProps = {
    variant?: 'narrow' | 'wide';
};

export type BottomSheetRef = {
    show: () => void;
    hide: () => void;
};
export const BottomSheet = React.forwardRef<BottomSheetRef, Props & VariantProps>(
    ({ children, variant = 'wide' }, ref) => {
        const modalContentRef = useRef<HTMLDivElement>(null);

        const [open, setOpen] = useState(false);
        const [selectors] = useDeviceSelectors(window.navigator.userAgent);
        const { isMobile } = selectors;

        useImperativeHandle(ref, () => ({
            show: () => setOpen(true),
            hide: () => setOpen(false),
        }));

        const maxWidthValue = variant === 'narrow' ? 480 : 700;
        const [topSpacing, setTopSpacing] = useState(0);

        const calculateModalSpacing = () => {
            const windowHeight = window.innerHeight;
            const modalHeight = modalContentRef.current?.clientHeight || 0;

            setTopSpacing((windowHeight - modalHeight) / 2);
        };
        const resizeObserver = useMemo(() => new ResizeObserver(calculateModalSpacing), []);

        useEffect(() => {
            if (open) {
                resizeObserver.observe(modalContentRef.current!);
            } else {
                resizeObserver.disconnect();
            }
        }, [open]);

        return (
            <Portal>
                <AnimatePresence>
                    {open && (
                        <>
                            <Backdrop
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.2, zIndex: 1 }}
                                transition={{ duration: 0.3 }}
                                exit={{ opacity: 0 }}
                                onClick={() => setOpen(false)}
                            />
                            <ModalContent
                                ref={modalContentRef}
                                variant={variant}
                                initial={isMobile ? { bottom: -400 } : { top: window.innerHeight }}
                                animate={isMobile ? { bottom: 0 } : { top: topSpacing }}
                                exit={isMobile ? { bottom: -400 } : { top: window.innerHeight }}
                                transition={{
                                    duration: 0.3,
                                }}
                                drag={'y'}
                                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                                dragElastic={0.1}
                                dragMomentum={false}
                                onDragEnd={(e, pointInfo) => {
                                    setOpen(pointInfo.offset.y < 50);
                                }}
                            >
                                <ModalInner
                                    mx={[0, 20, 20]}
                                    maxWidth={maxWidthValue}
                                    borderRadius={['15px 15px 0 0', '15px 15px 0 0', '0 0 0 0']}
                                >
                                    <ModalHandle display={['block', 'block', 'none']} />
                                    <ModalInnerContainer maxHeight={['550px', '650px', '800px']}>
                                        {children}
                                    </ModalInnerContainer>
                                </ModalInner>
                            </ModalContent>
                        </>
                    )}
                </AnimatePresence>
            </Portal>
        );
    },
);
BottomSheet.displayName = 'BottomSheet';

const ModalContent = styled(motion.div)<VariantProps>`
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    max-width: ${(props) => (props.variant === 'narrow' ? '480' : '700')}px;
    z-index: 1;
`;

const ModalInner = styled(View)`
    background-color: white;
    overflow: hidden;
    max-height: 800px;
    position: relative;
`;
const ModalInnerContainer = styled(View)`
    overflow: auto;
`;

const ModalHandle = styled(View)`
    background-color: #ababab;
    height: 5px;
    width: 50px;
    margin: 10px auto;
    z-index: 10;
    border-radius: 10px;
`;
const Backdrop = styled(motion.div)`
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`;
