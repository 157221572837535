import React, { FC, useRef } from 'react';

import { RoundPlusIcon } from '../../assets/icons';
import { LinkButton, Row, Text, View } from '../atoms';
import { BottomSheet, BottomSheetRef } from '../organisms';

type Props = {
    title?: string;
    children: React.ReactNode;
    addForm?: (props: { onDone: () => void }) => React.ReactNode;
};
export const Page: FC<Props> = ({ children, addForm, title }) => {
    const bottomSheetRef = useRef<BottomSheetRef>(null);
    const onDone = () => bottomSheetRef.current?.hide();
    return (
        <View>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Text mb={5} color={'text.secondary'}>
                    {title}
                </Text>
                {addForm && (
                    <Row alignItems={'center'} justifyContent={'center'}>
                        <LinkButton
                            icon={<RoundPlusIcon />}
                            onClick={() => bottomSheetRef?.current?.show()}
                        >
                            Add
                        </LinkButton>
                    </Row>
                )}
            </Row>
            {children}
            {addForm && <BottomSheet ref={bottomSheetRef}>{addForm({ onDone })}</BottomSheet>}
        </View>
    );
};
