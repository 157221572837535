import React, { FC, useMemo } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, Row, View } from '../../../components/atoms';
import { LoadingIndicator } from '../../../components/molecules/LoadingIndicator';
import { Table } from '../../../components/molecules/Table';
import { useLocationsQuery, Location } from '../../../service';
import { LocationInfoCard } from '../components/LocationInfoCard';

export const LocationManagement: FC = () => {
    const navigate = useNavigate();
    const { data: locationsData, loading } = useLocationsQuery();

    const locations = locationsData?.locations?.filter(Boolean);
    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Timezone',
                accessor: 'zone',
            },
            {
                Header: 'Doors',
                accessor: (row: Location) => {
                    return row.doors?.length || 0;
                },
            },
        ];
    }, []);
    return (
        <View>
            <Heading>Locations</Heading>
            {loading ? (
                <Row mt={20} justifyContent={'center'} alignItems={'center'}>
                    <LoadingIndicator variant={'medium'} />
                </Row>
            ) : (
                <View>
                    <ScrollView flex={1} mt={30} display={['none', 'block']}>
                        <Table
                            data={locations || []}
                            columns={columns}
                            onRowClicked={(user) => {
                                navigate('/location-management/locations/' + user?.id);
                            }}
                        />
                    </ScrollView>
                    <ScrollView flex={1} mt={30} display={['block', 'none']}>
                        {locations?.map((location) => (
                            <StyledLink
                                key={location?.id}
                                to={'/location-management/locations/' + location?.id}
                            >
                                <View key={location?.id} mb={10}>
                                    <LocationInfoCard location={location!} />
                                </View>
                            </StyledLink>
                        ))}
                    </ScrollView>
                </View>
            )}
        </View>
    );
};

const ScrollView = styled(View)`
    overflow: auto;
    padding-bottom: 30px;
    height: ${window.innerHeight - 150}px;
`;

const StyledLink = styled(NavLink)`
    color: black;
    text-decoration: none;
`;
