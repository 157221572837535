import React, { FC, useMemo, useRef, useState } from 'react';

import { format, parse } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowBackIcon, InfoIcon } from '../../../assets/icons';
import { Column, Heading, LinkButton, Row, Text, View } from '../../../components/atoms';
import EditButton from '../../../components/molecules/EditButton';
import { Select, TextInput } from '../../../components/molecules/form';
import { BottomSheet, BottomSheetRef } from '../../../components/organisms';
import { DangerModal } from '../../../components/organisms/DangerModal';
import {
    Location,
    useMemberLocationsQuery,
    useCurrentAssociateQuery,
    State,
    useUserBlockMutation,
    useUserUnblockMutation,
    useUserRestrictMutation,
    useUserClearRestrictionMutation,
    Role,
} from '../../../service';
import { Theme } from '../../../theme';
import { getInitialsFromName } from '../../../util/helperFunctions';
import { rolesToString } from '../../../util/roleNameMapping';
import { EditUsernameForm } from '../../member-management/components/EditUsernameForm';
import {
    LocationSelector,
    LocationSelectorType,
} from '../../member-management/components/LocationSelector';
import { WorkingHoursForm } from '../components/WorkingHoursForm';

const DEFAULT_START_TIME = '09:00:00';
const DEFAULT_END_TIME = '17:00:00';
const TIME_PARSE_FORMAT = 'kk:mm:ss';
const TIME_DISPLAY_FORMAT = 'hh:mm aa';

type ReasonObject = {
    [key: string]: string;
};

const BLOCK_REASON: ReasonObject = {
    RequestedByMember: 'Requested by member',
    Other: 'Other',
};
const defaultBlockReason = Object.keys(BLOCK_REASON)[0];

const RESTRICT_REASON: ReasonObject = {
    PaymentFailed: 'Payment failed',
    CommunityGuidelinesViolation: 'Community Guidelines Violation',
    Other: 'Other',
};
const defaultRestrictReason = Object.keys(RESTRICT_REASON)[0];

export const AssociateDetails: FC = () => {
    const navigate = useNavigate();
    const { id: associateId } = useParams();
    const { data: associateData } = useCurrentAssociateQuery({
        variables: {
            filter: {
                userId: associateId!,
            },
        },
    });
    const { data: locationsData } = useMemberLocationsQuery();

    const [blockUser, { loading: userBlockLoading }] = useUserBlockMutation();
    const [unblockUser, { loading: userUnblockLoading }] = useUserUnblockMutation();
    const [restrictUser, { loading: userRestrictLoading }] = useUserRestrictMutation();
    const [clearUserRestriction, { loading: userClearRestrictLoading }] =
        useUserClearRestrictionMutation();

    const associate =
        associateData?.users && associateData.users.length > 0 ? associateData.users[0] : null;
    const allLocations = locationsData?.locations as Location[];
    const locations = associate?.accessLocations as Location[];

    const workingTimeBottomSheetRef = useRef<BottomSheetRef>(null);
    const blockUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const restrictUserBottomSheetRef = useRef<BottomSheetRef>(null);
    const editUserBottomSheetRef = useRef<BottomSheetRef>(null);

    const [selectedBlockReason, setSelectedBlockReason] = useState(defaultBlockReason);
    const [customBlockReason, setCustomBlockReason] = useState('');

    const [selectedRestrictReason, setSelectedRestrictReason] = useState(defaultRestrictReason);
    const [customRestrictReason, setCustomRestrictReason] = useState('');

    const restricActionVisible =
        associate?.state === State.Active || associate?.state === State.Restricted;
    const blockActionVisible =
        associate?.state === State.Active || associate?.state === State.Blocked;

    const associateRoles = useMemo(() => {
        const roles = associate?.roles;
        if (roles) {
            return rolesToString(roles.filter(Boolean) as Role[]); // filter - removes null values
        }
        return '';
    }, [associate?.roles]);

    const onUserBlock = async () => {
        const associateId = associate?.id;
        const reason =
            selectedBlockReason === BLOCK_REASON.Other
                ? customBlockReason
                : BLOCK_REASON[selectedBlockReason];

        if (associateId) {
            await blockUser({
                variables: {
                    input: {
                        id: associateId,
                        reason,
                    },
                },
                refetchQueries: ['Associates'],
            });
            setSelectedBlockReason('');
            setCustomBlockReason('');
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserUnblock = async () => {
        const associateId = associate?.id;

        if (associateId) {
            await unblockUser({
                variables: {
                    id: associateId,
                },
                refetchQueries: ['Associates'],
            });
            blockUserBottomSheetRef.current?.hide();
        }
    };

    const onUserRestrict = async () => {
        const associateId = associate?.id;
        const reason =
            selectedRestrictReason === RESTRICT_REASON.Other
                ? customRestrictReason
                : RESTRICT_REASON[selectedRestrictReason];

        if (associateId) {
            await restrictUser({
                variables: {
                    input: {
                        id: associateId,
                        reason,
                    },
                },
                refetchQueries: ['Associates'],
            });
            setSelectedRestrictReason('');
            setCustomRestrictReason('');
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    const onClearRestriction = async () => {
        const associateId = associate?.id;

        if (associateId) {
            await clearUserRestriction({
                variables: {
                    id: associateId,
                },
                refetchQueries: ['Associates'],
            });
            restrictUserBottomSheetRef.current?.hide();
        }
    };

    return (
        <Wrapper>
            <Header px={90} pb={25}>
                <LinkButton onClick={() => navigate(-1)}>
                    <>
                        <ArrowBack width={20} height={20} />
                        Back to team
                    </>
                </LinkButton>
            </Header>
            <ContentWrapper
                borderRadius={['20px 20px 0px 0px', '64px 64px 0px 0px', '64px 64px 0px 0px']}
            >
                <Content px={[25, 25, 115]} py={[25, 25, 45]}>
                    <Row alignItems={'flex-start'} justifyContent={'space-between'}>
                        <ProfilePhoto mb={25}>
                            <Text fontSize={35}>{getInitialsFromName(associate?.name || '')}</Text>
                        </ProfilePhoto>
                    </Row>
                    <Row alignItems={'flex-start'} justifyContent={'flex-start'}>
                        <Heading mb={10} mr={15}>
                            {associate?.name}
                        </Heading>
                        <EditButton onClick={() => editUserBottomSheetRef?.current?.show()} />
                    </Row>

                    <View my={[20, 40, 40]}>
                        <Text fontSize={'medium'} fontWeight={'bold'}>
                            Employee details
                        </Text>
                        <Divider />

                        <Row justifyContent={'space-between'} alignItems={'flex-start'} pt={10}>
                            <Column my={20} flex={1}>
                                <Column>
                                    <Text fontSize={'medium'} color={'text.secondary'}>
                                        Status
                                    </Text>
                                    <Text fontSize={'medium'}>{associate?.state}</Text>
                                </Column>
                                <Column mt={30}>
                                    <Text fontSize={'medium'} color={'text.secondary'}>
                                        Email
                                    </Text>
                                    <Text fontSize={'medium'}>{associate?.email}</Text>
                                </Column>
                                <Column mt={30}>
                                    <Text fontSize={'medium'} color={'text.secondary'}>
                                        Roles
                                    </Text>
                                    <Text fontSize={'medium'}>{associateRoles}</Text>
                                </Column>
                                <Column mt={30}>
                                    <Text fontSize={'medium'} color={'text.secondary'}>
                                        Company
                                    </Text>
                                    <CompanyLink
                                        fontSize={'medium'}
                                        onClick={() => {
                                            navigate(
                                                '/member-management/companies/' +
                                                    associate?.company?.id,
                                            );
                                        }}
                                    >
                                        {associate?.company?.name}
                                    </CompanyLink>
                                </Column>
                            </Column>
                            <Column flex={1} alignItems={'flex-end'}>
                                <LocationSelector
                                    type={LocationSelectorType.Access}
                                    memberId={associate?.id}
                                    title={'Access to locations:'}
                                    allLocations={allLocations}
                                    selectedLocations={locations}
                                />
                                <Row
                                    width={'100%'}
                                    maxWidth={'400px'}
                                    my={['15px', '30px', '30px']}
                                    justifyContent={'space-between'}
                                >
                                    <Column>
                                        <Text fontSize={'medium'} color={'text.secondary'}>
                                            Working Hours
                                        </Text>
                                        <Text fontSize={'medium'}>
                                            {format(
                                                parse(
                                                    associate?.workHoursStart || DEFAULT_START_TIME,
                                                    TIME_PARSE_FORMAT,
                                                    new Date(),
                                                ),
                                                TIME_DISPLAY_FORMAT,
                                            )}
                                            {' - '}
                                            {format(
                                                parse(
                                                    associate?.workHoursEnd || DEFAULT_END_TIME,
                                                    TIME_PARSE_FORMAT,
                                                    new Date(),
                                                ),
                                                TIME_DISPLAY_FORMAT,
                                            )}
                                        </Text>
                                    </Column>
                                    <WorkTimeEditLink
                                        fontSize={'medium'}
                                        onClick={() => workingTimeBottomSheetRef.current?.show()}
                                    >
                                        Edit
                                    </WorkTimeEditLink>
                                </Row>
                            </Column>
                        </Row>

                        <View mt={70}>
                            {restricActionVisible && (
                                <Row>
                                    <Column>
                                        <LinkButton
                                            onClick={() =>
                                                restrictUserBottomSheetRef.current?.show()
                                            }
                                        >
                                            <ActionLabel fontSize={'medium'} color={'text.danger'}>
                                                {associate.state === State.Restricted
                                                    ? 'Clear User Access Restriction'
                                                    : 'Restrict User Access'}
                                            </ActionLabel>
                                        </LinkButton>
                                    </Column>
                                </Row>
                            )}
                            {blockActionVisible && (
                                <Row mt={20}>
                                    <Column>
                                        <LinkButton
                                            onClick={() => blockUserBottomSheetRef.current?.show()}
                                        >
                                            <ActionLabel fontSize={'medium'} color={'text.danger'}>
                                                {associate.state === State.Blocked
                                                    ? 'Unblock User'
                                                    : 'Block User'}
                                            </ActionLabel>
                                        </LinkButton>
                                    </Column>
                                </Row>
                            )}
                        </View>
                    </View>
                </Content>
            </ContentWrapper>

            <BottomSheet ref={workingTimeBottomSheetRef}>
                <WorkingHoursForm
                    associate={associate!}
                    onDone={() => workingTimeBottomSheetRef.current?.hide()}
                />
            </BottomSheet>
            <BottomSheet ref={editUserBottomSheetRef}>
                <EditUsernameForm
                    onDone={() => editUserBottomSheetRef?.current?.hide()}
                    user={associate}
                />
            </BottomSheet>
            <DangerModal
                ref={blockUserBottomSheetRef}
                heading={associate?.state === State.Blocked ? 'Unblock User' : 'Block User'}
                subheading={
                    associate?.state === State.Blocked
                        ? 'Are you sure you want to unblock this user?'
                        : 'Are you sure you want to block this user?'
                }
                loading={userBlockLoading || userUnblockLoading}
                primaryButtonDisabled={
                    selectedBlockReason === BLOCK_REASON.Other && customBlockReason === ''
                }
                onConfirm={associate?.state === State.Blocked ? onUserUnblock : onUserBlock}
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users that are blocked will not be able to login to Saltbox OS using
                            their account until Admin or Manager unblocks them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {associate?.state !== State.Blocked && (
                    <Select
                        label="Reason"
                        value={selectedBlockReason}
                        values={Object.keys(BLOCK_REASON)}
                        labels={Object.values(BLOCK_REASON)}
                        onChange={(option) => setSelectedBlockReason(option)}
                    />
                )}
                {selectedBlockReason === BLOCK_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomBlockReason(e.target.value)}
                        />
                    </View>
                )}
            </DangerModal>

            <DangerModal
                ref={restrictUserBottomSheetRef}
                heading={
                    associate?.state === State.Restricted
                        ? 'Clear User Access Restriction'
                        : 'Restrict User Access'
                }
                subheading={
                    associate?.state === State.Restricted
                        ? 'Are you sure you want to clear restriction access for this user?'
                        : 'Are you sure you want to restrict access for this user?'
                }
                loading={userRestrictLoading || userClearRestrictLoading}
                onConfirm={
                    associate?.state === State.Restricted ? onClearRestriction : onUserRestrict
                }
            >
                <NoteWrapper>
                    <Row>
                        <InfoIcon />
                        <Text ml={10} fontSize={'medium'} color={'#505354'}>
                            Users having restricted access will be able to login to Saltbox OS using
                            their account but all features will be disabled for them.
                        </Text>
                    </Row>
                </NoteWrapper>
                {associate?.state !== State.Restricted && (
                    <Select
                        label="Reason"
                        value={selectedRestrictReason}
                        values={Object.keys(RESTRICT_REASON)}
                        labels={Object.values(RESTRICT_REASON)}
                        onChange={(option) => setSelectedRestrictReason(option)}
                    />
                )}
                {selectedRestrictReason === RESTRICT_REASON.Other && (
                    <View mt={20}>
                        <TextInput
                            placeholder="Enter the reason..."
                            onChange={(e) => setCustomRestrictReason(e.target.value)}
                        />
                    </View>
                )}
                {associate?.state !== State.Restricted && (
                    <NoteWrapper>
                        <Row>
                            <InfoIcon />
                            <Text ml={10} fontSize={'medium'} color={'#505354'}>
                                Users with restricted access will be able to see this reason.
                            </Text>
                        </Row>
                    </NoteWrapper>
                )}
            </DangerModal>
        </Wrapper>
    );
};

const Wrapper = styled(View)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Theme.colors.background.accent};
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

const Header = styled(View)`
    width: 100%;
    min-height: 130px;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled(View)`
    background: ${Theme.colors.background.light};
    flex: 1;
`;

const Content = styled(View)`
    max-width: 1200px;
`;

const ArrowBack = styled(ArrowBackIcon)`
    margin-right: 5px;
`;

const ProfilePhoto = styled(View)`
    background: ${Theme.colors.background.disabled};
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-top: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Divider = styled(View)`
    background-color: #505354;
    height: 1px;
    width: 100%;
    margin: 2px 0;
    opacity: 0.1;
`;

const ActionLabel = styled(Text)`
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: normal;
    user-select: none;
`;

const NoteWrapper = styled(View)`
    margin-top: 17px;
    margin-bottom: 20px;
    background-color: #f9f8f6;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    padding: 18px;
`;

const CompanyLink = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
`;

const WorkTimeEditLink = styled(Text)`
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
`;
