import React, { useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import { LocationPinIcon } from '../../../../assets/icons';
import { Column, Heading, Row, Text, View } from '../../../../components/atoms';
import { BottomSheet, BottomSheetRef } from '../../../../components/organisms';
import { AccessLocation, Door, useDoorDataQuery } from '../../../../service';
import { DoorCard } from './DoorCard';
import { LocationSwitch } from './LocationSwitch';

const SELECTED_ACCESS_LOCATION_KEY = 'selected_access_location';

export const BuildingAccess = () => {
    const [currentAccessLocation, setCurrentAccessLocation] = useState<AccessLocation>();
    const { data } = useDoorDataQuery();
    const ref = useRef<BottomSheetRef>(null);

    const defaultLocation = () => {
        if (data?.me?.accessLocations?.[0]) {
            localStorage.setItem(SELECTED_ACCESS_LOCATION_KEY, data?.me?.accessLocations?.[0].id);
            return data.me.accessLocations[0];
        }
    };

    useEffect(() => {
        const storedAccessLocationId = localStorage.getItem(SELECTED_ACCESS_LOCATION_KEY);
        if (data?.me?.accessLocations?.length && storedAccessLocationId) {
            let isStoredLocationFoundInUserLocations = false;
            data.me.accessLocations.forEach((location) => {
                if (location && location.id === storedAccessLocationId) {
                    isStoredLocationFoundInUserLocations = true;
                    setCurrentAccessLocation(location);
                }
            });
            if (!isStoredLocationFoundInUserLocations) {
                setCurrentAccessLocation(defaultLocation());
            }
        } else {
            setCurrentAccessLocation(defaultLocation());
        }
    }, [data]);

    const doors: Door[] = useMemo(() => {
        const d = currentAccessLocation?.doors;
        if (d) {
            return d.filter(Boolean).sort((referentDoor) => {
                return data?.me?.preferences?.find(
                    (preference) => preference?.doorId === referentDoor?.id,
                )
                    ? -1
                    : 1;
            }) as Door[];
        }
        return [];
    }, [currentAccessLocation]);

    const locations: AccessLocation[] = useMemo(() => {
        const d = data?.me?.accessLocations;
        if (d) {
            return data?.me?.accessLocations as AccessLocation[];
        }
        return [];
    }, [data]);

    const show = () => {
        ref.current?.show();
    };

    return (
        <Column mt={10}>
            <Row justifyContent={'space-between'} alignItems={'center'}>
                <Heading>Building access</Heading>
                <SwitcherButton alignItems={'center'} onClick={show}>
                    <LocationPin color="black" />
                    <Text fontSize={'medium'}>{currentAccessLocation?.name}</Text>
                </SwitcherButton>
            </Row>
            <DoorWrapper>
                <DoorScroller>
                    <DoorList flexWrap={['nowrap', 'wrap', 'wrap']}>
                        {doors.map((door) => (
                            <DoorCard key={door.id} door={door} />
                        ))}
                    </DoorList>
                </DoorScroller>
            </DoorWrapper>
            <BottomSheet ref={ref}>
                <LocationSwitch
                    currentLocation={currentAccessLocation}
                    onSelected={(location) => {
                        setCurrentAccessLocation(location);
                        ref.current?.hide();
                    }}
                    locations={locations}
                />
            </BottomSheet>
        </Column>
    );
};

const SwitcherButton = styled(View)`
    display: flex;
    border: none;
    height: 25px;
    font-weight: 500;
    background-color: #fbb733;
    border-radius: 5px;
    padding: 3px 10px 3px 6px;
    cursor: pointer;
`;

const LocationPin = styled(LocationPinIcon)`
    margin-right: 3px;
`;

const DoorWrapper = styled(View)`
    overflow: hidden;
    margin-top: 15px;
`;

const DoorScroller = styled(View)`
    width: 100%;
    display: flex;
    overflow-x: auto;
`;

const DoorList = styled(View)`
    display: flex;
    //flex-wrap: wrap;
    flex-direction: row;
`;
