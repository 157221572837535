import styled from 'styled-components';

import { Text } from '../../../components/atoms';

export const SectionLabel = styled(Text)`
    color: ${(props) => props.theme.colors.text.secondary};
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: bold;
`;
